import React,{useState, useEffect} from 'react';
//import React from 'react';
//Style
//import {MainColors} from '../Sources/SourcesConst';
import { makeStyles} from '@material-ui/core/styles';
//import {Avatar, Grid,Button} from '@material-ui/core';
//components
//import StatusOrderRestaurant from './Restaurant/StatusOrderRestaurant';
import ListNoOrders from './Restaurant/ListNoOrders';
import StatusDelivery from './Delivery/StatusDelivery';
//import ScanQR from '../QR/ScanQR';
import {makeNormalDialog} from '../../utilscomponents/DialogAsk';
//import {setCurrentDbOrders,setCurrentDbOrder} from '../../../service/ManagerOrders';
import {setCurrentDbOrder} from '../../../service/ManagerOrders';
//import {refreshTokenUser, signInGeneral, signIn} from '../../../service/ManagerApis';
import {signIn, signalRConnectionBuild} from '../../../service/ManagerApis';
import { isDelivery_, isIdOrden_, isForOrder_, login_, ValidacionPorUsuario_} from '../../Constants';
import {getBoolStorage} from './Restaurant/HelperArray';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr'
//import SwipeableViews from 'react-swipeable-views';
//import {AppBar,Tabs,Tab} from '@material-ui/core';
//Routes
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link
// } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
//import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    width: '100vw',
  },
  title: {
      fontSize: '3.5vh',
      padding: '1vh',
      textAlign: 'center',
      margin: '1rem',
      //color: MainColors.main,
  },
  tab: {
      //color: MainColors.accent,
      //Cambiar con base en la cantidad de pestañas
      width: '50vw',
      textDecoration: 'none !important',
      '&hover' : {
        textDecoration: 'none',
      }
  }
}));



// function a11yProps(index) {
//     return {
//       id: `simple-tab-${index}`,
//       'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

export default function FullWidthTabs(props) {
  //const [openBtn,setOpenBtn] = useState(false);
  //const [indexAction,setIndexAction] = useState(0);
  const classes = useStyles();
  const isLogin = getBoolStorage(login_);
  const validarUser = getBoolStorage(ValidacionPorUsuario_);
  //const isDelivery = getBoolStorage(isDelivery_);
  //const isOrder = getBoolStorage(isIdOrden_);
  
  const handleClickSignIn = () => {
    signIn('MesaGeneral','Ribo1234',(success) => {
        if(success){
            //props.changePage(true);
            console.log('MesaGeneral: ' + success);
            localStorage.setItem(login_,true);
            localStorage.setItem(isIdOrden_,true);
            localStorage.setItem(isDelivery_,false);
        }else{
            makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr token="passUserWrong"/>,null);
        }
    });
    
  }

  // const handleClickOption = (index) => {
  //   //if(!validarUser){
  //       // localStorage.setItem(isIdOrden_,false);
  //       // localStorage.setItem(isDelivery_,false);
  //       //localStorage.setItem(IsQueja_,true);
  //       changePage(index);
  //       // if(index === -2 ){
  //       //     changePage(0);
  //       // }else{
  //       //     //validateStatusRes(true,true,index);
  //       //     //makeNormalDialog(<TranslateStr token="somewrong"/>,<TranslateStr token="nocheckorder"/>,null);
  //       //     //setOpenBtn(false);
  //       //     changePage(-1);
  //       // }
  //   //}else{
  //       //makeNormalDialog(<TranslateStr token="emptyfields"/>, <TranslateStr token="requiredComplaints"/>,null);
  //    //   setOpenBtn(false);
  //   //}
  // }

  // const validateStatusRes = (okRes,isActive,indexPage) => {
  //   if(okRes){
  //       if(!isActive){
  //           setOpenBtn(false);
  //           makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr tokne="untilOpen"/>,null);
  //       }else{
  //           changePage(indexPage);
  //       }
  //   }else{
  //       makeNormalDialog(<TranslateStr token="somewrong"/>,<TranslateStr token="nocheckorder"/>,null);
  //   }
  // }

  // const changePage = (index) => {
  //   console.log('index: ' + index);
  //   if(index===-2){
  //     setIndexAction(0);
  //     getOrdenesDB();
  //     setOpenBtn(false);
  //   }
  //   else{
  //     setIndexAction(0);
  //   }
  // }

  // const handleOpen = () => {
  //     //Solo la opcion de restaurante por mientras
  //     handleClickOption(0);
  // };

  // const handleClose = () => {
  //     setOpenBtn(false);
  // }

  const getOrdenesDB = () => {
    if(isLogin ){
      //handleOpen();
      console.log('Actualiza ordenes de la BD');
      localStorage.setItem(isIdOrden_,true);
      localStorage.setItem(isDelivery_,false);
      localStorage.setItem(isForOrder_,true);
      //setCurrentDbOrders();
      setCurrentDbOrder();
      localStorage.setItem(isIdOrden_,true);
      localStorage.setItem(isDelivery_,false);
    }
    else{
      console.log('Login es falso');
      console.log('Validar por Usuario: ' + validarUser);
      if(!validarUser && !isLogin){
        localStorage.setItem(isForOrder_,true);
        handleClickSignIn();
      }
    }
  }

  useEffect(() => {
    // if(getBoolStorage(login_) || !validarUser){
    //   refreshTokenUser(() => {});
    // }
    getOrdenesDB();
  },[]);

//   return(
//     <div className={classes.root}>
//         {
//             (function() {
//                 switch(indexAction){
//                     case 0:
//                         return <div>
//                             {/* <Backdrop className={sty.backdrop} open={openBtn}/> */}
//                             <ListNoOrders getOrdenesDB={getOrdenesDB} showInformationOn={props.showInformationOn} setIndexAction={setIndexAction}/>
//                         </div>;
//                     case 1:
//                         return <ScanQR callwaiter={false} changeEmpresa={false} sendOrden={false} sendQueja={false} setInfoForm={getOrdenesDB} comment={props.showInformationOn} back={handleClickOption}/>
//                     default:
//                         return;
//                 }
//             })()
//         }
//     </div>
// )

  return (
      <Router>
          <div className={classes.root}>
            <Switch>
                <Route path="/restaurant">
                    <ListNoOrders getOrdenesDB={getOrdenesDB} showInformationOn={props.showInformationOn} changePage={props.changePage}/>
                </Route>
                <Route path="/delivery">
                    <StatusDelivery/>
                </Route>
                <Route path="/">
                    <ListNoOrders getOrdenesDB={getOrdenesDB} showInformationOn={props.showInformationOn} changePage={props.changePage}/>
                </Route>
            </Switch>
          </div>
      </Router>
  );
}