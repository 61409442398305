import React,{useState, useEffect} from 'react';
//Components
import {Grid,TextField} from '@material-ui/core';
import {getCustomButton} from '../../Generics/CustomButton';
import {makeNormalDialog} from '../../../utilscomponents/DialogAsk';
import TitleBack from '../../../utilscomponents/TitleBack';
//Apis
import {getMsgErrorApi,getInfoUser,updateUser} from '../../../../service/ManagerApis';
//Styles
import {MainColors} from '../../Sources/SourcesConst';
import { makeStyles} from '@material-ui/core/styles';
import {color_secondary_} from '../../../Constants';

import TranslateStr from '../../ConfigPage/HelperLanguage/TranslateStr';

const useStyles = makeStyles(({
    back: {
       width: '100%',
       height: '100%',
       padding: '1vh',
       //color: MainColors.accent,
       backgroundColor: '#fff',
   },
   img:{
       margin: '0',
       width: '6vh',
       height: '6vh',
   },
    title: {
       //color: MainColors.main,
       fontSize: '4vh',
       fontWeight: '300',
       margin: '1vh',
       padding: '0',
    },
    textField: {
        width: '70vw',
        margin: '0.5vh'
    },
    contBtn: {
        margin: '1.5vh 0'
    }
 }));

const ValidateButton = getCustomButton(MainColors.secondary,'2vh','bold','#fff','#fff');

export default function Registro(props){

    const sty = useStyles();
    const [infoUser,setInfoUser] = useState({
        name : "",
        apeMa : "",
        apePa : "",
        userName : "",
        phone : "",
        mail: ""
    });

    const getInfo = () => {
        getInfoUser(dataUser => {
            if(dataUser){
                setInfoUser({
                    // name : dataUser.nombreUsuario,
                    // apeMa : dataUser.apellidoMat,
                    // apePa : dataUser.apellidoPat,
                    // userName : dataUser.cuentaDeUsuario,
                    // phone : dataUser.celular,
                    // mail: dataUser.email
                    name : dataUser.nombre,
                    apeMa : dataUser.apellidoMat,
                    apePa : dataUser.apellidoPat,
                    userName : dataUser.userName,
                    phone : dataUser.phoneNumber,
                    mail: dataUser.email
                });
            }
        });
    }

    useEffect(() => {
        getInfo();
    }, []);

    const validateData = () => {
        if(infoUser.name === "" || infoUser.apePa === "" || infoUser.userName === "" || infoUser.phone === "" || infoUser.mail === ""){
            makeNormalDialog(<TranslateStr token="emptyfields"/>,'',null);
        }else{
            updateUser(infoUser.name,infoUser.apePa,infoUser.apeMa,infoUser.userName,infoUser.phone, infoUser.mail, status => {
                if(status){
                    makeNormalDialog(<TranslateStr token="saveddata"/>,<TranslateStr token="updateddata"/>,null);
                    getInfo();
                }else{
                    makeNormalDialog(<TranslateStr token="faildEdit"/>,getMsgErrorApi(<TranslateStr token="tryagain"/>),null);
                }
            });
        }
    }

    const onInputName = (e) => {
        e.persist();
        setInfoUser({
            name : e.target.value,
            apeMa : infoUser.apeMa,
            apePa : infoUser.apePa,
            userName : infoUser.userName,
            phone : infoUser.phone,
            mail : infoUser.mail
        });
    }

    const onInputApePa = (e) => {
        e.persist();
        setInfoUser({
            name : infoUser.name,
            apeMa : infoUser.apeMa,
            apePa : e.target.value,
            userName : infoUser.userName,
            phone : infoUser.phone,
            mail  : infoUser.mail
        });
    }

    const onInputApeMa = (e) => {
        e.persist();
        setInfoUser({
            name : infoUser.name,
            apeMa : e.target.value,
            apePa : infoUser.apePa,
            userName : infoUser.userName,
            phone : infoUser.phone,
            mail  : infoUser.mail
        });
    }

    const onInputUserName = (e) => {
        e.persist();
        setInfoUser({
            name : infoUser.name,
            apeMa : infoUser.apeMa,
            apePa : infoUser.apePa,
            userName : e.target.value,
            phone : infoUser.phone,
            mail  : infoUser.mail
        });
    }

    const onInputPhone = (e) => {
        e.persist();
        setInfoUser({
            name : infoUser.name,
            apeMa : infoUser.apeMa,
            apePa : infoUser.apePa,
            userName : infoUser.userName,
            phone : e.target.value,
            mail  : infoUser.mail
        });
    }

    return(
        <Grid container direction="column" justify="center" alignItems="center">
            
            <TitleBack msg={<TranslateStr token="updateProfile"/>} back={props.changePage}/>

            <Grid item>
                <TextField 
                    className={sty.textField}
                    value={infoUser.name} 
                    onChange={onInputName}
                    label={<TranslateStr token="name"/>}
                />
            </Grid>

            <Grid item>
                <TextField 
                    className={sty.textField}
                    value={infoUser.apePa} 
                    onChange={onInputApePa}
                    label={<TranslateStr token="lastnameOne"/>} 
                />
            </Grid>

            <Grid item>
                <TextField 
                    className={sty.textField}
                    value={infoUser.apeMa} 
                    onChange={onInputApeMa}
                    label={<TranslateStr token="lastnameTwo"/>} 
                />
            </Grid>

            <Grid item>
                <TextField 
                    className={sty.textField}
                    value={infoUser.userName} 
                    onChange={onInputUserName}
                    label={<TranslateStr token="username"/>} 
                />
            </Grid>

            <Grid item>
                <TextField 
                    className={sty.textField}
                    value={infoUser.phone} 
                    onChange={onInputPhone}
                    label={<TranslateStr token="phone"/>}
                />
            </Grid>

            <Grid className={sty.contBtn} item>
                <ValidateButton style={{color: localStorage.getItem(color_secondary_)}} onClick={validateData} variant="contained">
                        <TranslateStr token="update"/>
                </ValidateButton>
            </Grid>
        </Grid>
    )
}
