import React, {useState,useEffect} from 'react';
//Components
import LandingPage from './components/mainpages/LandingPage';
import Splash from './components/mainpages/Splash';
import MainContainer from './components/mainpages/MainContainer';
import ScanQR from './components/mainpages/QR/ScanQR';
import {Toast} from 'react-bootstrap';
import { isBilingual_ } from './components/Constants';
import {getBoolStorage} from './service/HelperStorage';
//import CloseIcon from '@material-ui/icons/Close';
//Firebase
import {getTokenFire,onMessageListener} from './firebase';
//Styles
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'bootstrap/dist/css/bootstrap.css';
//signalR
//import * as signalR from "@microsoft/signalr";

  
  export default function App() {
    //const URL = "https://signalrdev.ontable.app"; //or whatever your backend port is
    //const urlHub = `${URL}/notify`;
    //const accessToken = localStorage.getItem('token');
    //const httpOptions = {
    //  headers:{ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken  },
    //  withCredentials: false
    //};

    const [landing,setLanding] = useState({showSplash: true, showLanding: false, idEmpresaL: null});
    const [seconds,setSeconds] = useState(2);
    const [isActive, setIsActive] = useState(true);
    const [indexPage,setIndexPage] = useState({index:0,isRecentOpenApp:true});
    const [cantidad,setconfigCarrito] = useState(0);
    const [bilingual,setBilingual] = useState(getBoolStorage(isBilingual_));
    var checkParams = true;

    const [showNot, setShowNot] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [getFirebase, setFireBase] = useState(true);

    //const[NewhubConnectionBuilder, setHubConnectionBuilder] = useState(null);

    // const hubConnection = new signalR.HubConnectionBuilder()
    // .withUrl(urlHub, httpOptions)
    // .withAutomaticReconnect({
    //     // Configure the reconnection options here
    //     // For example, you can specify the interval between reconnect attempts
    //     // and the maximum number of reconnect attempts
    //     nextRetryDelayInMilliseconds: retryContext => {
    //         // Define your custom retry delay logic here
    //         // retryContext.previousRetryCount contains the number of previous retries
    //         // retryContext.elapsedMilliseconds contains the elapsed time in milliseconds
    //         // Return the delay in milliseconds for the next reconnect attempt
    //         // or null to stop the reconnection process
    //     },
    //     maxRetryDelayInMilliseconds: 600000, // Maximum delay between reconnect attempts
    //     reconnectDelayInMilliseconds: 60000, // Delay between each reconnect attempt
    //     maxReconnectAttempts: null, // Maximum number of reconnect attempts (null for unlimited)
    // })
    // .configureLogging(signalR.LogLevel.Information)
    // .build();  

    onMessageListener().then(payload => {
      setShowNot(true);
      setNotification({title: payload.notification.title, body: payload.notification.body})
      console.log(payload);
    }).catch(err => console.log('failed: ', err));
    
    useEffect(() => {

      // (async() =>{
      //   await hubConnection.start().then(setHubConnectionBuilder(hubConnection)).then(console.log("Hub Connected"))
      // })();

      
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const empresa = urlParams.get('idempresa');
      const land = urlParams.get('landing');

      if(getFirebase){
        getTokenFire();
        setFireBase(false);
      }

      let interval = null;
      if (isActive && !landing.showLanding) {

        if(empresa !== null && empresa !== undefined && land !== null && land !== undefined && checkParams === true){
          checkParams = false;
          setLanding({showSplash: landing.showLanding,showLanding:true,idEmpresaL:empresa});
        }

        interval = setInterval(() => {
          if(seconds > 0){
            setSeconds(seconds => seconds - 1);
          }else{
            setIsActive(false);
            setLanding({showSplash: false,showLanding:landing.showLanding,idEmpresaL:landing.idEmpresaL});
            clearInterval(interval);
          }
        }, 1000);
      }
      return () => clearInterval(interval);
    },[seconds,isActive])

    const goMenus = () => {

      setLanding({showSplash: false,showLanding:false,idEmpresaL:landing.idEmpresaL});
      //setconfigCarrito(cantidad);
    }

    const onBackPress = (index) =>{
      if(index === -1){
        setIndexPage({index: 1,isRecentOpenApp:indexPage.isRecentOpenApp});
      }else{
        setIndexPage({index: index,isRecentOpenApp:indexPage.isRecentOpenApp});
      }
    }

    const backToScan = () => {
      setIndexPage({index: 0,isRecentOpenApp:indexPage.isRecentOpenApp});
    }

    const empresaHasChange = () => {
      setIndexPage({index: 1,isRecentOpenApp:indexPage.isRecentOpenApp});
    }

    return (
      <div>
        <Toast onClose={() => setShowNot(false)} show={showNot} delay={15000} autohide animation style={{
          position: 'absolute',
          zIndex: 10,
          top: 20,
          right: 20,
          width: '90vw'
        }}>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="mr-auto">{notification.title}</strong>
            <small>Now</small>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
        {
          landing.showLanding === true ? 
          <LandingPage idEmpresa={landing.idEmpresaL} back={goMenus} isBtnBack={false} isBilingual = {bilingual} setconfigCarrito={setconfigCarrito}/>
          :
          landing.showSplash === true ? 
                <Splash/>
              :
                localStorage.getItem('idEmpresa') !== null && localStorage.getItem('idEmpresa') !== 0 && indexPage.isRecentOpenApp ?
                  <MainContainer changePage={setIndexPage}/>
                :
                  (function(){
                    switch(indexPage.index){
                      case 1:
                        return <MainContainer changePage={setIndexPage} />;
                      case 0: 
                        return <ScanQR callwaiter={false} sendOrden={false} sendQueja={false} back={onBackPress} changeEmpresa={true}/>
                      case 2:
                        return <LandingPage idEmpresa={null} back={empresaHasChange} isBtnBack={true} backIfBtn={backToScan} isBilingual = {bilingual} setconfigCarrito={setconfigCarrito}/>
                      default:
                        return;
                    }
                  })()
        }
        <div id='modal-root' />
      </div>
    );
}