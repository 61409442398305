import React from 'react';
//Components
import {Grid,Avatar} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//Styles
//import { MainColors } from '../mainpages/Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
import {color_accent_, color_secondary_} from '../Constants';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '2vh',
    },
    msg:{
        fontSize: '4vh',
        fontWeight: '150',
        //color: MainColors.secondary,
        margin: '0',
    },
    back: {
        width: '100%',
        height: '100%',
        padding: 'auto',
        //color: MainColors.accent,
        backgroundColor: '#fff',
    },
    img:{
        margin: 'auto',
        width: '6vh',
        height: '6vh',
    },
}));

export default function TitleBack(props){

    const sty = useStyles();

    return(
        <Grid className={sty.root} container direction="row" justify="center" alignItems="center">
            <Grid item xs={3}>
                <Avatar onClick={() => props.back()} className={sty.back} style={{color: localStorage.getItem(color_accent_)}} alt="Add icon">
                    <ArrowBackIcon className={sty.img}/>
                </Avatar>
            </Grid>
            <Grid item xs={9}>
                <p className={sty.msg} style={{color: localStorage.getItem(color_secondary_)}}>{props.msg}</p>
            </Grid>
        </Grid>
    )
}