
const getInstanceOpenpay = () => {
    const openpay = window.OpenPay;
    openpay.setId('mzhlu6gwsuab5uun988f');
    openpay.setApiKey('pk_d19aaea92e6340edbc7b5a4fe22f658d');
    openpay.setSandboxMode(true);
    return openpay;
}

const getDeviceId = (instance) => {
    return instance.deviceData.setup();
}

export {getInstanceOpenpay,getDeviceId}