import React,{useState} from 'react';
//Components
import ListOrders from './ListOrders';
import {Grid,Collapse,Paper,Checkbox,Typography,} from '@material-ui/core';
import TranslateStr from '../../ConfigPage/HelperLanguage/TranslateStr';
//import {makeArraysUserOrders,getBoolStorage, getTotalSelected,getTotalOrder,getCountSelected} from './HelperArray';
import {formatoMexico} from '../../../../service/ManagerCarrito';

//Styles
import { makeStyles } from '@material-ui/core/styles';
//import { ContactSupportOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100% !important',
    },
    root: {
         flexGrow: 1,
         margin: '0.4vh 0.4vh 0 0.4vh'
    },
    paper: {
      padding: theme.spacing(0.5),
      margin: 0,
      width: '100%',
      border: '0.2vh solid #EBEDEF',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        marginBottom: '0',
        padding: '0px 0px 0px 5px'
    },
    subTitle:{
        fontSize: '2vh',
        color: '#5D6D7E',
        textAlign: 'left',
        margin: '0',
    },
    price: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        marginBottom: '0',
        textAlign: 'left',
        padding: '0px 0px 0px 5px'
    },
}));

const getSelected = (arrayParent) => {
    var array = [];
    arrayParent.children.map(item => {
        var val = false;
        if(item.isChecked === true){
            val = true;
        }
        array.push(val);
    })
    return array;
}

export default function SelectAddress(props){
    //var totalUser =  props.totalUser;
    // useEffect(() => {
    //     props.reload();
    // },[]);
    // const getSelected = (arrayParent) => {
    //     var array = [];
    //     arrayParent.children.map(item => {
    //         var val = false;
    //         if(item.isChecked === true){
    //             val = true;
    //         }
    //         array.push(val);
    //     })
    //     return array;
    // }

    const setMsgstatus = (idStatus) => {
        
        var msg = "";
        switch (idStatus) {
            case 1:
                msg = <TranslateStr token="makingOder"/>;
                break;
            case 4:
                msg = <TranslateStr token="partialOrder"/>;
                break;
            case 5: //Lis: 2022-10-28 estado PorEntrega
                msg = <TranslateStr token="assignedOrder"/>;
                break;
            case 7:
                msg = <TranslateStr token="validatingOrder"/>;
                break;
            case 8:
                msg = <TranslateStr token="assignedOrder"/>;
                break;
            default:
                //msg=idStatus;
                console.log(msg);
                break;
        }
        return msg;
    }

    const sty = useStyles();
    //const {dataUser,selectParent,selectChild,totalUser,totalOrder} = props;
    const {dataUser,selectParent,selectChild,totalUser} = props;
    const [expanded, setExpanded] = useState(false);
    //const [settingsParent,setSettingsParent] = useState({arraySelected:getSelectedAll(),checkedParent:dataUser.isChecked,totalUser:props.totalUser});
    const [settingsParent,setSettingsParent] = useState({arraySelected:getSelected(dataUser),checkedParent:dataUser.isChecked,totalUser:totalUser});
    //const [settingsParent,setSettingsParent] = useState({arraySelected:getSelected(dataUser),checkedParent:true,totalUser:totalUser});

    //getSelectedAll={props.getSelectedAll}
    const onSelectAllClick = () => {
        var newarray = [];
        settingsParent.arraySelected.map(() =>{
            newarray.push(!settingsParent.checkedParent);
        });
        setSettingsParent({arraySelected:newarray,checkedParent:!settingsParent.checkedParent});
        selectParent(dataUser);
    }

    const onSelectChild = (parent,indexChild) => {
        var array    = [];
        var checkedP = false;
        var count    = 0;

        settingsParent.arraySelected.map((item,index) => {
            if(index === indexChild){
                array.push(!item);
            }
            else{
                array.push(item);
            }
        })

        array.map(item => {
            if(item){
                count++;
            }
        })

        if(count === settingsParent.arraySelected.length){
            
            checkedP = true;
        }
        console.log(' settingsParent.arraySelected');
        console.log(array);

        setSettingsParent({arraySelected:array,checkedParent:checkedP});
        selectChild(parent,indexChild);
        // console.log('dataUser');
        // console.log(dataUser.children);
       // totalUser = getTotalSelected(dataUser.children);
    }

    return(
        <Grid container direction="row" justify="center" alignItems="center">
            <Paper className={sty.paper}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Checkbox
                            indeterminate={settingsParent.checkedParent === false}
                            checked={settingsParent.checkedParent}
                            onChange={onSelectAllClick}
                        />
                    </Grid>
                    <Grid item xs={10} container direction="column" onClick={() => setExpanded(!expanded)}>
                        <Grid item xs container direction="row">
                            <Grid item xs={10}>
                                <Typography className={sty.title}>
                                {<TranslateStr token="numOrder"/>}{' '}{dataUser.idOrden}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={3}> */}
                                {/* <Typography className={sty.price} >{'$' + dataUser.total}</Typography> */}
                                {/* <Typography className={sty.price} >{'$' + props.totalUser}</Typography> */}
                            {/* </Grid> */}
                        </Grid>
                        <Grid item xs container direction="row">
                            <Grid item xs={9}>
                                <Typography className={sty.price} >{' Total $' + formatoMexico(totalUser)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs container direction="row">
                            <Grid item xs={12}>
                                <Typography className={sty.subTitle}>
                                    {setMsgstatus(props.idStatus)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <ListOrders 
                        arraySelected={settingsParent.arraySelected} 
                        dataUser={dataUser}  
                        orders={dataUser.children} 
                        selectChild={onSelectChild}
                    />
                </Collapse>
            </Paper>
        </Grid>
    )
}