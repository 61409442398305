import React, { useState } from 'react';
import ModalTopping from './ModalTopping';

// Renders a modal to the modal root and handles the visibility state
// of this modal.
//
// NOTE: Each modal you want to render should use a separate hook!!!
// Otherwise your modals will share their visibility state which might lead
// to overlapping and unclosable elements.
export const useModalTopping = () => {
    const [isVisible,setIsVisible] = useState(false);
    const showTopping = () => setIsVisible(true);
    const hideTopping = () => setIsVisible(false);

    const RenderModalTopping = ({children}:{children:React.ReactChild}) => (
        <React.Fragment>
            {isVisible && <ModalTopping closeModalTopping={hideTopping}>{children}</ModalTopping>}
        </React.Fragment>
    )

    return{
        showTopping,hideTopping,RenderModalTopping
    }
}