import React,{useState, useEffect} from 'react';
//DB
//import {addComment,updateCommentDB, formatoMexico, decimalAdjust} from '../../../../service/ManagerCarrito';
//import {Service} from '../../../../service/DBService';
//Styles
import { makeStyles,withStyles } from '@material-ui/core/styles';
//import {MainColors} from '../../Sources/SourcesConst';
//Apis
//import {makeRequest} from '../../../../service/ManagerRequest';
//import {color_main_, color_secondary_, idLan_} from '../../../Constants';
//Components
//import {Grid,Button,TextField,Checkbox} from '@material-ui/core';
import ItemTopping from './ItemTopping';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
//import AddIcon from '@material-ui/icons/Add';
//import RemoveIcon from '@material-ui/icons/Remove';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import TranslateStr from '../../../ConfigPage/HelperLanguage/TranslateStr';
import { List } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100% !important',
  },
  root: {
    width: '90vw',
    height: 'auto',
    maxWidth: '60vh',
    padding: '5px 0 0 0',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  paper: {
    padding: theme.spacing(0.5),
    margin: 0,
    width: '100%',
    border: '0.2vh solid #EBEDEF',
  },
  image: {
    width: '8vh',
    height: '8vh',
    margin: 'auto',
  },
  indication: {
      //color: MainColors.secondary,
      fontSize: '3vh',
      fontWeight: 'bold',
      textAlign: 'left',
      marginBottom: '0',
  },
  title: {
      color: '#5D6D7E',
      fontWeight: 'light',
      fontSize: '3vh',
      marginBottom: '0',
  },
  titleprod: {
    color: '#5D6D7E',
    fontWeight: 'light',
    fontSize: '2vh',
    marginBottom: '0',
  },
  subTitle:{
      fontSize: '2vh',
      color: '#5D6D7E',
      textAlign: 'left',
      margin: '0',
  },
  price: {
    color: '#5D6D7E',
    fontWeight: 'light',
    fontSize: '2.5vh',
    marginBottom: '0',
    textAlign: 'right',
  },
  total: {
    //color: MainColors.main,
    fontSize: '3vh',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '0',
  },
  cantidad: {
    //color: MainColors.secondary,
    fontSize: '3vh',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '0',
  },
  buttonTopping: {
      lineheight: '12px',
      width: '20px',
      fontSize: '1rem',
      fontFamily: 'tahoma',
      //margintop: '1px',
      // marginright: '2px',
      position: 'absolute',
      //top: theme.spacing(0),
      bottom: theme.spacing(5),
      //top: theme.spacing(1),
      left: theme.spacing(4),
      color: '#fff',
      backgroundColor: 'transparent',
          '&:hover': {
              backgroundColor: 'transparent',
          },
  },
  iconComment: {
    //color: MainColors.secondary,
    backgroundColor: '#fff',
  },
  customHoverFocus: {
      //marginRight: '10px',
      //width: '4vh',
      //height: '4vh',
      color: '#fff',
      backgroundColor: "transparent",
      "&:hover, &.Mui-focusVisible": { backgroundColor: "transparent" }
  },
    
  }));
  
  const DeleteBtn = withStyles(() => ({
    root: {
      color: '#E74C3C',
      backgroundColor: '#FBFCFC',
    },
  }))(Button);
  
  const AddBtn = withStyles(() => ({
    root: {
      color: '#5499C7',
      backgroundColor: '#FBFCFC',
    },
  }))(Button);

export default function AddTopping(props){
    const classes = useStyles();
    const [isCheck, setcheck] = React.useState(false);
    const [checked, setChecked] = useState([]);
    const [cost,setCost] = useState(props.total);
    const dataSub = props.dataSub;
    const dataTopping = props.arrayTopping;
    //const price = dataSub.precio;
    //const idProducto = dataSub.idProducto;
    //const idIdioma =  localStorage.getItem(idLan_);
    //const [openDelete, setOpenDelete] = useState(false);
    //const [openSuccess, setOpenSuccess] = useState(false);
    const [arrayTopping,setToppings] = useState([{cantidad:0, index:0, array: dataTopping}]);
    //const msg = <TranslateStr token="add"/> + ' Toppings';
    //let sumTotal = props.total
    // const handleResTopping = (data,success) =>{
    //   console.log(data);
    //   if(success){
    //     setToppings(data);
    //   }
    // }

    // Add/Remove checked item from list topping
    // const handleCheck = (event) => {
    //   console.log( event);
    //   event.checked = !event.checked;
    //   //find student with that id and set checked value to it
    //   //let currentValue=parseFloat(props.total);

    //   let _total=props.arrayTopping.map(item => item).reduce((accumulator, current) =>  accumulator + (current.checked ? (current.precioDescuento) : 0), props.total);
    //   //let _total = props.arrayTopping.reduce((total, item) => total + item.precioDescuento); 
    //   console.log('_total: ' + _total);
    //   sumTotal = _total;// + props.total;
    //   console.log(sumTotal);
    //   setCost(_total);
    // //update student list state
    // //this.setState({ studentList: this.state.studentList});
    //   // var updatedList = [...checked];
    //   // if (event.target.checked) {
    //   //   updatedList = [...checked, event.target.value];
    //   // } else {
    //   //   updatedList.splice(checked.indexOf(event.target.value), 1);
    //   // }
    //   // setChecked(updatedList);
    // };

    // Generate string of checked items
    const checkedItems = checked.length
      ? checked.reduce((total, item) => {
        return total + ", " + item;
      })
    : "";

    // Return classes based on whether item is checked
    //checked.includes(item) ? "checked-item" : "not-checked-item";
    var isChecked = (item) =>
    checked.includes(item) ? true : false;
    
    // const handleCloseDelete = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }

    //     setOpenDelete(false);
    // };

  // const handleCloseSuccess = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setOpenSuccess(false);
  // };

  const setMsgstatus = () => {
        
    var msg = "";
    msg = <TranslateStr token="makingOder"/>;// <TranslateStr token="addTopping"/>;
    
    return msg;
}

const handleResTopping = (data,success) =>{
        
  console.log('success: ' + success);
  if(success){
    //for (var i = 0; i < data.length; i++) {
    // data.forEach(element => {
    //       //element.checked = false;
    //       element.cantidad = 0;
    //       Service.getidx('ItemTopping','idTopping',props.ID).then(item=>{
    //           console.log('busca idx'); 
    //           console.log(item);
    //           element.cantidad = item.length;
    //           // if(item !== undefined && item.length!=0){
    //           //     element.checked = true;
    //           // }
    //           // else{
    //           //     element.checked = false;
    //           // }
    //       });
    // });
    console.log(data);
    setToppings({cantidad: 0, index:0, array: data});
    //RenderModal(true);
  }
}

useEffect(() => {
  //makeRequest('application/json',null,'GET','masterdata/Productos/MisProductos/' + idProducto + '/Topping?idIdioma=' + idIdioma + '&EsActivo=1',false,false,(dataRes,success) => handleResTopping(dataRes,success))
},[])

  // const saveItem = () => {
  //   //const idTipoMenu = props.idTipoMenu;
  //   //localStorage.setItem('idTipoOrden',props.idTipoMenu);
  //   // saveItemCarrito(dataSub.idProducto,dataSub,false,() => {
  //   //   getInfoCarrito((totalR,countR) => {
  //   //     props.setconfigCarrito(countR);
  //   //   });
  //   //});
   
  //   setOpenSuccess(true);
    
  // }

  // const deleteItem = () => {
  //   // deleteItemCarrito(dataSub.idProducto,() => {
  //   //   getInfoCarrito((totalR,countR) => {
  //   //     props.setconfigCarrito(countR);
  //   //   });
  //   // });
    
  //   setOpenDelete(true);
  // }

  const handleExpandClick = () => {
    //setExpanded(!expanded);
  };

  const handleCantidad = (index) => {
    return arrayTopping[index].cantidad;
  }

  const handlePlus = (item, index) => {
    const cantiad_ = item.cantidad+1;
    console.log('Agrega topping...');
    console.log(item);
    console.log('index:' + index);
    console.log ('Cant. total: ' + cantiad_);
    item.cantidad = cantiad_;
    arrayTopping[index].cantidad = cantiad_;
    setToppings(arrayTopping);
    //saveItem();
    //setCost({total:cantiad_*price,cantidad:cantiad_})
  }

  const handleLess = (indexAction) => {
    // if(cost.cantidad > 0){
    //   deleteItem();
    //   const cantiad_ = cost.cantidad-1;
    //   setCost({total:cantiad_*price,cantidad:cantiad_})
    // }
    console.log('index:' + indexAction);
    console.log ('Cant. total: ' + arrayTopping[indexAction].cantidad );
  }

  const changeStatus = () => {
    setcheck(!isCheck)
  }

  return (
    <Card className={classes.root} >
      <CardHeader
        title={'Toppings'}
        subheader={dataSub.name +' $' + (cost)} >
      </CardHeader>
      <CardContent className={classes.cardcont}>
        <Grid item xs container direction="row">
            <Grid item xs={9}>
              <Typography className={classes.total} >{' Total $' + (cost + props.totalToppings)}</Typography>
            </Grid>
        </Grid>
        <List>
          {
            dataTopping.map(dish => (
              <ItemTopping
                  key={dataTopping.indexOf(dish)}
                  //setTotaltoppings = {props.setTotaltoppings}
                  //setCostTotal = {setCost}
                  //costTotal = {props.total}
                  data={dish}
                  //ID = {props.ID}
                  subTotal = {dish.precio}
                  cantidad = {dish.cantidad}
                  //getToppings = {props.getToppings}
                  //reload={props.reload}
              />
            ))
            // arrayTopping.map((item,index) => (
            //   <Paper key={index} className={classes.paper}>
            //     <Grid container spacing={1}>
            //       <Grid item xs={3}>
            //         <Avatar className={classes.image} alt="Menu Photo" src={item.imagenUri} />
            //       </Grid>
            //       <Grid item xs={9} container direction="column">
            //         <Grid item xs container direction="row">
            //           <Grid item xs={9}>
            //             <Typography className={classes.titleprod}>
            //               {item.nombreSubMenu}
            //             </Typography>
            //           </Grid>
            //           <Grid item xs={3}>
            //             <Typography className={classes.price}  >{'$' + formatoMexico(item.precioDescuento)}</Typography>
            //           </Grid>
            //         </Grid>
            //         <Grid item xs container direction="row" justify="space-between" alignItems="center">
            //           <Grid item>
            //             <p className={classes.cantidad} style={{ color: localStorage.getItem(color_secondary_) }}>{arrayTopping[index].cantidad }</p>
            //           </Grid>
            //           <Grid item>
            //             <p className={classes.total} style={{ color: localStorage.getItem(color_main_) }}>{"$" + formatoMexico(decimalAdjust('round', parseFloat(item.cantidad * item.precioDescuento), -2))}</p>
            //           </Grid>
            //           <Grid item>
            //             {/* <DeleteBtn
            //               variant="contained"
            //               color="secondary"
            //               startIcon={<RemoveIcon/>}
            //             >
            //             </DeleteBtn>
            //             <AddBtn
            //               variant="contained"
            //               color="primary"
            //               endIcon={<AddIcon />}
            //             >
            //             </AddBtn> */}
            //             <IconButton onClick={() => handleLess(index)} aria-label="delete" size="small">
            //               <RemoveIcon className={classes.iconComment} color="secondary" />
            //             </IconButton>
            //             <IconButton onClick={() => handlePlus(item,index)} aria-label="add" size="small">
            //               <AddIcon className={classes.iconComment} color="primary" />
            //             </IconButton>
            //           </Grid>
            //         </Grid>
            //       </Grid>
            //     </Grid>
            //   </Paper>
            // ))
          }
        </List>
      </CardContent>
    </Card>
    // <List>
    //   {
    //     props.arrayTopping.map((item,index) => (
    //       <Paper key={index} className={classes.paper}>
    //         <Grid container spacing={1}>
    //           <Grid item xs={2}>
    //             <Checkbox
    //               checked={item.checked}
    //               onChange={() => handleCheck(item)} 
    //             />
    //           </Grid>
    //           <Grid item xs={3} container direction="column">
    //             <Grid item xs container direction="row">
    //               <Grid item xs={9}>
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //           <Grid item xs={7} container direction="column">
    //             <Grid item xs container direction="row">
    //               <Grid item xs={9}>
    //                 <Typography className={classes.title}>
    //                   {item.nombreProducto}
    //                 </Typography>
    //               </Grid>

    //               <Grid item xs={3}>
    //                 <Typography className={classes.price}>{'$' + item.precioDescuento}</Typography>
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //       </Paper>
    //     ))
    //   }
    // </List>
  );
}