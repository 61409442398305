import React from 'react';
import ReactDom from 'react-dom';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
//import {MainSize,MainColors} from '../Sources/SourcesConst';
//Styles 
import {color_secondary_} from '../../Constants';

type Props = {
  children: React.ReactChild
  closeModal: () => void
}

const useStyles = makeStyles({
  modal: {
    zIndex: 3,
    opacity: '70%',
    background: '#000',
    width:'100%',
    height: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  contentLayer: {
    zIndex: 4,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor:'#fff', 
  },
  btn: {
    zIndex: 10,
    color: '#fff', // '#000', //'#fff',
    //backgroundColor: MainColors.secondary; // == null ? '#1ABC9C' :  MainColors.secondary, //'transparent',
    //padding: '5px 0 0 5px',
    //padding: '4vh 0 0 5vw',
    top: '14vh',
    //left: '53vh',
    right: '3vh',
    position: 'fixed',
  },
})

const Modal = React.memo(({children,closeModal}: Props) => {
  const domEl = document.getElementById('modal-root');
  const sty = useStyles();
  const colorfondo = localStorage.getItem(color_secondary_);

  if(!domEl) return null

  // This is where the magic happens -> our modal div will be rendered into our 'modal-root' div, no matter where we
  // use this component inside our React tree
  //style={{backgroundColor: localStorage.getItem(color_secondary_)}}
  return ReactDom.createPortal(
    <div>
      <div className={sty.contentLayer} >
        <Avatar className={sty.btn} style={{ background: `${colorfondo}` }} onClick={closeModal}>
          <CloseIcon />
        </Avatar>
        {children}
      </div>
      <div onClick={closeModal} className={sty.modal}>
      </div>
    </div>,
    domEl
  )
})

export default Modal;