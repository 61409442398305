import React,{useState, useEffect} from 'react';
//Apis
import {detailDeliveryOrder,deleteInfoOrder} from '../../../../service/ManagerApis';
//Components
import ListOrders from './ListOrders';
import { confirmAlert } from 'react-confirm-alert';
import { Grid } from '@material-ui/core';
//Styles
import {MainColors} from '../../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
//Others
import {idOrden_} from '../../../Constants';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        width: '100%',
        fontSize: '3.5vh',
        color: MainColors.main,
        textAlign: 'left',
        margin: '1vh',
    },
    titleStatus:{
        fontSize: '3vh',
        color: MainColors.secondary,
        textAlign: 'center',
        margin: '0.5vh',
        fontWeight: '450'
    },
    msgStatus:{
        color: '#273746',
        fontSize: '3vh',
        fontWeight: 500,
        margin: '0',
        textAlign: 'center',
        padding: '0.5vh',
    },
    msgNoOrder: {
        fontSize: '3vh',
        color: MainColors.accent,
        textAlign: 'center',
        margin: '2vh',
    },
    subInfo:{
        color: MainColors.secondary,
        fontSize: '2vh',
        fontWeight: 300,
        margin: '0',
        textAlign: 'center',
        padding: '0.5vh',
    },
    subInfoDes:{
        color: MainColors.accent,
        fontSize: '2.5vh',
        fontWeight: 300,
        margin: '0',
        textAlign: 'center',
        padding: '0.5vh',
    },
}));

const showDialog = (title, subTitle) => {
    confirmAlert({
        title: title,
        message: subTitle,
        buttons: []
})};

export default function StatusDelivery() {

    const classes = useStyles();

    const isD = localStorage.getItem('isOrderDelivery');
    const isO = localStorage.getItem('isIdOrden');
    const isDelivery = isD === null || isD === 'false' ? false : isD;
    const isOrder = isO === null || isO === 'false' ? false : isO;

    //const [showCancelOrder,setShowCancelOrder] = useState(false);
    const [detailOrder,setDetailOrder] = useState(null);
    const [seconds,setSeconds] = useState(30);

    const getInfoOrder = () => {
        console.log(isDelivery);
        console.log(isOrder);
        if(isOrder && isDelivery){
            detailDeliveryOrder(localStorage.getItem(idOrden_),dataOrder => {
                if(dataOrder !== null){
                    setDetailOrder(dataOrder)
                }
            });
        }
    }

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
        if(seconds > 0){
            if(seconds === 30){
                getInfoOrder();
            }
            setSeconds(seconds => seconds - 1);
        }else{
            setSeconds(30);
        }
        }, 1000);
        return () => clearInterval(interval);
    },[seconds])

    const setMsgstatus = (idStatus) => {
        var msg = "";
        const idOrder = localStorage.getItem('idOrden');
        switch (idStatus) {
            case 1:
                msg = 'Su pedido esta en espera de ser confirmado';
                break;
            case 2:
                msg = 'Sus platillos estan siendo preparados';
                break;
            case 3:
                deleteInfoOrder(idOrder);
                showDialog('Lo sentimos','Su orden fue rechazada')
                break;
            case 4:
                msg = '¡Orden lista!, en breve un repartidor tomará su pedido';
                break;
            case 5:
                msg = 'Su pedido está en camino';
                break;
            case 6:
                showDialog('Pedido entregado','Gracias por su compra');
                deleteInfoOrder(idOrder);
                break;
            case 7:
                showDialog('Ha cancelado el pedido','Lamentamos los inconvenientes');
                deleteInfoOrder(idOrder);
                break;
            case 8:
                showDialog('Se ha cancelado la orden','contactenos para mas información');
                deleteInfoOrder(idOrder);
                break;
            case 9:
                msg = 'Se asignó un repartidor en breve se pondrá en camino';
                break;
            default:
                break;
        }
        return msg;
    }

    return (
        <div className={classes.root}>
            {
                isOrder && isDelivery ?
                    <Grid container direction="column" justify="center" alignItems="center">
                        <p className={classes.title}>Detalle de entrega</p>
                        {
                            detailOrder !== null &&
                            <Grid item container direction="column" justify="center" alignItems="center" spacing={1}>
                                <Grid item container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <p className={classes.titleStatus}>Estado</p>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <p className={classes.msgStatus}>{setMsgstatus(detailOrder.idEstatusEntrega)}</p>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="row" justify="center" alignItems="flex-start">
                                    <Grid item xs={6} container direction="column" justify="center" alignItems="center">
                                        <p className={classes.subInfo} >Dirección:</p>
                                        <p className={classes.subInfoDes}>{detailOrder.domicilio}</p>
                                    </Grid>
                                    <Grid item xs={6} container direction="column" justify="center" alignItems="center">
                                        <p className={classes.subInfo}>Indicaciones:</p>
                                        <p className={classes.subInfoDes}>{detailOrder.opcionEntrega}</p>
                                    </Grid>
                                </Grid>
                                <ListOrders/>
                            </Grid>
                        }
                    </Grid>
                :
                    <p className={classes.msgNoOrder}>Por el momento no tiene una orden a domicilio abierta</p>
            }
        </div>
    );
}
