import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import { Grid } from '@material-ui/core';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
    },
    des: {
      fontSize: '3vh',
      color: '#fff',
      textAlign: 'center',
    },
    avatar: {
        margin: 'auto',
        color: '#EC7063',
        backgroundColor: '#fff',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        background: '#EC7063',
      },
  }));

export default function ItemSwipeLeft(){
    const sty = useStyles();
    return(
        <div className={sty.container}>
            <Paper className={sty.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={9} sm container></Grid>
                    <Grid item xs={3}>
                        <Grid item>
                            <Avatar className={sty.avatar} alt="Remove icon">
                                <RemoveShoppingCartIcon/>
                            </Avatar>
                        </Grid>
                        <Grid>
                            <p className={sty.des}><TranslateStr token="remove"/></p>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}