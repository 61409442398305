
var arrayTipos = [];


const getCountSelected = (array) => {
    var total = 0;
    array.forEach(element => {
        if(element.isChecked){
            total += parseInt(element.children.length);
        }else{
            element.children.forEach(child => {
                if(child.isChecked === true){
                    total += 1;
                }
            });
        }
    });
    return total;
}

const getTotalSelected = (array) => {
    var total = 0.0;
    array.forEach(element => {
        if(element.isChecked){
            total += parseFloat(element.total);
        }else{
            element.children.forEach(child => {
                if(child.isChecked === true){
                    total += parseFloat(child.price);
                }
            });
        }
    });
    return total;
}

const getTotalCarrito = (array) => {
    var total = 0.0;
    array.forEach(item => {
        total += parseFloat(item.total);
    });
    return total;
}

const makeArraysMenuTipos = (mainArray) => {
    var i=0;
    mainArray.forEach(element => {
        if(!element.idTipoMenu === 1 && i===0){
            asignarRegistro(element);
            i=i+1;
        }
    });
    
    i=0;
    mainArray.forEach(element => {
        if(!element.idTipoMenu === 2 && i===0){
            asignarRegistro(element);
            i=i+1;
        }
    });

    if(arrayTipos.length > 1){
        topTipoList();
    }

    arrayTipos.forEach((element,index) => {
        if(index === 0){
            element.isChecked = true;
            element.children.forEach(child => {
                child.isChecked = true;
            })
        }
    });

    const _array = arrayTipos;
    arrayTipos = [];
    return _array;
}

const asignarRegistro = (itemPlatillo) => {
    const position = searchTipoName(itemPlatillo);
    const itemCarrito = parseCarritoItem(itemPlatillo,position);
    if(position === -1){
        const itemTipo  = parseTipoItem(itemPlatillo,-1);
        itemTipo.total += itemCarrito.price;
        Object.assign(itemTipo,{children:[itemCarrito]});
        arrayTipos.push(itemTipo);
    }else{
        arrayTipos[position].total += itemCarrito.price;
        arrayTipos[position].children.push(itemCarrito);
    }
}

const topTipoList = (tipoOrden) => {
    const idCurrentTipo = tipoOrden;
    arrayTipos.forEach(element => {
        if(JSON.stringify(element.idTipoMenu) === idCurrentTipo){
            var arr = arrayTipos.filter(item => item !== element);
            var firstItem = arr[0];
            arr[0] = element;
            arr.push(firstItem);
            arrayTipos = arr;
        }
    });
    arrayTipos.forEach((idTipoMenu,index) => {
        idTipoMenu.index = index;
    })
}

const searchTipoName = (item) => {
    var position = -1;
    arrayTipos.forEach((element,index) => {
        if(element.idTipoMenu === item.idTipoMenu){
            position = index;
        }
    });
    return position;
}

const parseTipoItem = (item) => {
    return {
        index: arrayTipos.length === 0 ? 0 : arrayTipos.length,
        isChecked: false,
        name: item.tipo,
        total: 0,
        expandList: false,
        idTipo: item.idTipoMenu
    };
}

const parseCarritoItem = (item,indexParent) => {
    var indexF = 0;
    if(indexParent !== -1){
        const lenOrders = arrayTipos[indexParent].children.length;
        indexF = lenOrders === 0 ? 0: lenOrders;
    }

    return {
        index: indexF,
        isChecked: false,
        name: item.name,
        url: item.url,
        idProduct: item.id,
        idProducto: item.id,
        idTipoMenu: item.idTipoMenu,
        comments: item.comments,
        price: item.price,
        des: item.des
    }
}

const getArrayIdProductCarrito = (mainArray) => {
    var array  = [];
    mainArray.forEach(element => {
        element.children.forEach(child => {
            if(child.isChecked){
                array.push(child.idProducto);
            }
        });
    });
    console.log("idProductOrderArray");
    console.log(array);
    return array;
}

const getRestaPorPagar = (mainArray) => {
    var total = 0.0;
    console.log("Array inicial");
    console.log(mainArray);
    mainArray.forEach(element => {
        element.children.forEach(child => {
            if(!child.isChecked){
                total += parseFloat(child.price);
            }
        });
    });
    console.log("get resta por pagar");
    console.log(total);
    return total;
}

const getProductCarritoFromResponse = (arrayRes) => {
    var array  = [];
    arrayRes.forEach(element => {
        array.push(element.idProducto);
    });
    console.log("idProductCarritoArrayResponse");
    console.log(array);
    return array;
}

const getTotalFromResponse = (arrayRes) => {
    var total = 0.0;
    arrayRes.forEach(element => {
        total += parseFloat(element.precio);
    });
    console.log("Total order");
    console.log(total);
    return total;
}

const getBoolStorage = (key) => {
    const storage = localStorage.getItem(key);
    return storage === null || storage === 'false' ? false : true;
}

export {
    getTotalFromResponse,
    getProductCarritoFromResponse,
    getRestaPorPagar,
    getArrayIdProductCarrito,
    makeArraysMenuTipos,
    getBoolStorage,
    getTotalSelected,
    getTotalCarrito,
    getCountSelected
}
