
var arrayUsers = [];

const getCountSelected = (array) => {
    var total = 0;
    array.forEach(element => {
        if(element.isChecked){
            total += parseInt(element.children.length);
        }else{
            element.children.forEach(child => {
                if(child.isChecked === true){
                    total += 1;
                }
            });
        }
    });
    return total;
}

const getTotalSelected = (array) => {
    var total = 0;
    array.forEach(element => {
        if(element.isChecked){
            total += parseFloat(element.total);
        }else{
            element.children.forEach(child => {
                if(child.isChecked === true && !child.esTopping){
                    total += parseFloat(child.price+child.montoToppings);
                }
            });
        }
    });
    // console.log('getTotalSelected')
    // console.log(total);
    // console.log(array);
    return total;
}

const getTotalOrder = (array) => {
    var total = 0.0;
    array.forEach(item => {
        total += parseFloat(item.total);
    });
    return total;
}

const makeArraysUserOrders = (idOrden,mainArray) => {
    //console.log('makeArraysUserOrders...');
    //console.log(mainArray);
    const productosComandaActiva = mainArray;
    mainArray.forEach(element => {
        if(!element.pagado === true && element.idOrden===idOrden){
            asignarRegistro(element,productosComandaActiva);
        }
    });

    if(arrayUsers.length > 1){
        topUserList();
    }

    arrayUsers.forEach((element,index) => {
        if(index === 0){
            element.isChecked = true;
            element.children.forEach(child => {
                child.isChecked = true;
            })
        }
    });

    const _array = arrayUsers;
    arrayUsers = [];
    return _array;
}

const asignarRegistro = (itemPlatillo,listaProductos) => {
    const position = searchUserName(itemPlatillo);
    const itemOrder = parseOrderItem(itemPlatillo,position,listaProductos);
    if(position === -1){
        const itemUser  = parseUserItem(itemPlatillo,-1, listaProductos);
        itemUser.total += (itemOrder.price);
        Object.assign(itemUser,{children:[itemOrder]});
        arrayUsers.push(itemUser);
    }else{
        arrayUsers[position].total += (itemOrder.price );
        arrayUsers[position].children.push(itemOrder);
    }
}

const topUserList = (idOrden) => {
    //const idCurrentUser = localStorage.getItem('idUsuario');
    arrayUsers.forEach(element => {
        //if(JSON.stringify(element.idUser) === idCurrentUser && element.idOrden === idOrden){
            if(element.idOrden === idOrden){
            var arr = arrayUsers.filter(item => item !== element);
            var firstItem = arr[0];
            arr[0] = element;
            arr.push(firstItem);
            arrayUsers = arr;
        }
    });
    arrayUsers.forEach((user,index) => {
        user.index = index;
    })
}

const searchUserName = (item) => {
    var position = -1;
    arrayUsers.forEach((element,index) => {
        //if(element.name === item.usuario && element.idOrden===item.idOrden){
        if( element.idOrden===item.idOrden){
            position = index;
        }
    });
    return position;
}

const parseUserItem = (item) => {
    return {
        index: arrayUsers.length === 0 ? 0 : arrayUsers.length,
        isChecked: arrayUsers.length === 0 ? false : true,
        name: item.usuario,
        total: 0,
        expandList: false,
        idUser: item.idOrden, // item.idUsuario,
        idOrden: item.idOrden
    };
}

const getToppingsDeProductoOrden = (idProductoOrden, listaProductosComanda) =>{
    var array  = [];
    listaProductosComanda.forEach(element => {
        if( element.idTopping===idProductoOrden && element.esTopping){
            array.push(element);
        }
    });

    return array;
}

const parseOrderItem = (item,indexParent,listaProductosComanda) => {

    console.log('parseOrderItem...');
    console.log(item);
    var indexF = 0;
    if(indexParent !== -1){
        const lenOrders = arrayUsers[indexParent].children.length;
        indexF = lenOrders === 0 ? 0: lenOrders;
    }
    
    const arrayTopping = getToppingsDeProductoOrden(item.idProductoOrden,listaProductosComanda);
    var montoToppings = 0;
    if(arrayTopping.length>0){
        montoToppings += arrayTopping.map(t => t.precio).reduce((a, c) => { return a + c });
    }
    
    return {
        index: indexF,
        isChecked: true, // false,
        name: item.subMenu,
        url: item.imageUri,
        idProduct: item.idProducto,
        idProductoOrden: item.idProductoOrden,
        idUser: item.idOrden, //item.idUsuario,
        indication: item.indicaciones,
        price:  item.precio,
        nameUser: item.usuario,
        idOrden: item.idOrden,
        idTopping: item.idTopping,
        tieneTopping: item.tieneTopping,
        esTopping: item.esTopping,
        montoToppings : montoToppings,
        arrayTopping : arrayTopping
    }
}

const getArrayIdProductOrder = (mainArray) => {
    var array  = [];
    mainArray.forEach(element => {
        element.children.forEach(child => {
            if(child.isChecked){
                array.push(child.idProductoOrden);
                //array.push(child.idTopping);
            }
        });
    });
    console.log("idProductOrderArray");
    console.log(array);
    return array;
}

const getRestaPorPagar = (mainArray) => {
    var total = 0.0;
    console.log("Array inicial");
    console.log(mainArray);
    mainArray.forEach(element => {
        element.children.forEach(child => {
            if(!child.isChecked && !child.esTopping){
                total += parseFloat(child.price + child.montoToppings);
            }
        });
    });
    console.log("get resta por pagar");
    console.log(total);
    return total;
}

const getProductOrderFromResponse = (arrayRes) => {
    var array  = [];
    arrayRes.forEach(element => {
        array.push(element.idProductoOrden);
        //array.push(element.idTopping);
    });
    console.log("idProductOrderArrayResponse");
    console.log(array);
    return array;
}

const getTotalFromResponse = (arrayRes) => {
    var total = 0.0;
    arrayRes.forEach(element => {
        total += parseFloat(element.precio);
    });
    console.log("Total order");
    console.log(total);
    return total;
}

const getBoolStorage = (key) => {
    const storage = localStorage.getItem(key);
    return storage === null || storage === 'false' ? false : true;
}

export {
    getTotalFromResponse,
    getProductOrderFromResponse,
    getRestaPorPagar,
    getArrayIdProductOrder,
    makeArraysUserOrders,
    getBoolStorage,
    getTotalSelected,
    getTotalOrder,
    getCountSelected
}