import React,{useState, useEffect} from 'react';
//Components
import {Grid,List,ListItem,ListItemIcon,ListItemText} from '@material-ui/core';
//Apis
import {optionsDelivery} from '../../../service/ManagerApis';
import {indexOptionDel_,desOptDel_,idOptDel_} from '../../Constants';
//Styles
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {MainColors} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title:{
        color: MainColors.secondary,
        fontSize: '3vh',
        fontWeight: 250,
        margin: '0 0 1vh 0',
    },
});

export default function SelectOption(){

    const sty = useStyles();
    const [arrayOptions,setArrayOptions] = useState([]);
    const [selectedIndex,setSelectedIndex] = useState(localStorage.getItem(indexOptionDel_) !== null ? parseInt(localStorage.getItem(indexOptionDel_)) : 0);

    useEffect(() => {
        optionsDelivery((arr) => {
            console.log(arr);
            setArrayOptions(arr);
        })
    },[]);

    const handleListItemClick = (e,index) => {
        setSelectedIndex(index);
        localStorage.setItem(idOptDel_,arrayOptions[index].idOpcion);
        localStorage.setItem(desOptDel_,arrayOptions[index].opcion);
        localStorage.setItem(indexOptionDel_,index);
    }

    return(
        <Grid container justify="center" alignItems="center">
            <p className={sty.title}>Seleccione indicación extra a su pedido</p>
            <List component="nav" aria-label="main mailbox folders">
                {
                    arrayOptions.map((item,index) => (
                        <ListItem
                            role={undefined} 
                            dense
                            key={index}
                            button
                            selected={selectedIndex === index}
                            onClick={(event) => handleListItemClick(event, index)}
                        >
                            <ListItemIcon>
                                <KeyboardArrowRightIcon />
                            </ListItemIcon>

                            <ListItemText primary={item.opcion} />
                            
                        </ListItem>
                    ))
                }
            </List>
        </Grid>
    )
}