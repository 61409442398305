import React, { useState } from 'react';
//Components
//import Registro from '../Registro/Registro';
//import {Button,TextField,Grid,FormControl,InputAdornment,Input,IconButton,InputLabel,Avatar} from '@material-ui/core';
//import {AccountCircle,Visibility,VisibilityOff,ArrowBack} from '@material-ui/icons';
//import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
//FcGoogle
import { FcGoogle } from 'react-icons/fc';
import {makeNormalDialog} from '../../utilscomponents/DialogAsk';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
import {getInfoCarrito} from '../../../service/ManagerCarrito';
//import GoogleLogin from 'react-google-login';
//import { GoogleOAuthProvider } from '@react-oauth/google';
//import { GoogleLogin } from '@react-oauth/google';
//import jwt_decode from "jwt-decode";

import {useGoogleLogin} from '@react-oauth/google';
import axios from "axios"

//import FacebookLogin from 'react-facebook-login';

  
//Apis
import {registroSocialNetwork} from '../../../service/ManagerApis';
//Styles
import {makeStyles} from '@material-ui/core/styles';
//import {MainColors} from '../Sources/SourcesConst';
//Utils
//import {logOut} from '../../../service/HelperStorage';
//import ConfirmOrder from '../Delivery/Confirm/ConfirmOrder';
import {color_accent_, color_main_, color_secondary_, nameUser_, emailUser_, token_} from '../../Constants';

const useStyles = makeStyles(({
    root: {
        height: '100%',
        width: '100%',
        margin: '0',
        textAlign: 'center',
    },
    imgUser: {
        width: '4vh',
        height: '4vh',
        //color:  MainColors.main,
    },
    title: {
        padding: '0',
        margin: '0',
        fontSize: '3vh',
        //color: MainColors.main,
    },
    subTitle: {
        padding: '0',
        margin: '0',
        fontSize: '2vh',
        //color: MainColors.secondary,
    },
    textFieldUser: {
        width: '67vw',
    },
    textFieldPass: {
        width: '70vw',
    },
    back: {
        width: '100%',
        height: '100%',
        padding: 'auto',
        //color: MainColors.accent,
        backgroundColor: '#fff',
    },
    img:{
        margin: '0',
        paddingRight: '3px',
        paddingBottom: '3px',
        width: '3vh',
        height: '3vh',
    },
    txtBtnSignUp: {
        padding: '0',
        margin: '0',
        fontSize: '2vh',
        fontWeight: '300',
        textAlign: 'center',
        //color: MainColors.accent,
    },
    facebookbutton: {
      width: '100%',
      //minwith: '200px',
      height: '30px',
      borderradius: '1px',
      background: 'Transparent', // '#ff8000',
      //color: 'White',
      border: '0',
      bordercolor: '#ff8000',
      textAlign: 'center',
      verticalAlign: 'middle',
      //margin: '5px',
      padding: '0px 10px 10px 10px',
      fontfamily: 'Helvetica',
      fontsize: '12px',
      '&:focus': {
        outline: 'none',
      },
    }
}));

export default function Logingoogle(props){
    const sty = useStyles();

    const responseGoogle = useGoogleLogin({
        onSuccess: async respose => {
            try {
                console.log('response usuario google');
                console.log(respose);

                const token = respose.access_token;
                const scope = respose.scope;
                const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });

                console.log(res.data);
                if(res.data !== null){
                    if(res.data.length !== 0){
                        registroSocialNetwork(res.data.given_name,res.data.email,res.data.email,res.data.picture,token,res.data.sub,(okSignIn => {
                            if(okSignIn){
                                localStorage.setItem(emailUser_,res.data.email);
                                localStorage.setItem(nameUser_,res.data.name);
                                localStorage.setItem(token_,token)
                                props.setemailuser(res.data.email);
                                props.setfullUserName(res.data.name);

                                getInfoCarrito((totalR,countR,toppingsR) => {
                                    //localStorage.setItem(countCarrito_,countR);
                                    //setInfoOrder({total:totalR,count: countR,readyLogin:true});
                                    
                                    props.setconfigCarrito(countR);
                                 
                                });

                                props.changePage(true);
                            //     signIn(res.data.email,token,(success) => {
                            //         if(success){
                            //             props.changePage(true);
                            }else{
                                makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr token="passUserWrong"/>,null);
                            }
                            //     });
                            //}
                        }));
                    }
                }
            } catch (err) {
                console.log(err);

            }

        }
    });

    return (
        <div className={sty.root}>
            {/* <header className={sty.root}> */}
                <button className={sty.facebookbutton} onClick={responseGoogle}>
                    <FcGoogle className={sty.img} />  
                    <TranslateStr token="buttonTextGoogle"/>
                </button>
                {/* <GoogleLogin
                    type='icon'
                    size='small'
                    onSuccess={credentialResponse => {
                    console.log(credentialResponse.credential);
                    var decoded = jwt_decode(credentialResponse.credential);
                    console.log(decoded)
                }}
                    onError={() => {
                    console.log('Login Failed');
                }}/> */}
            {/* </header> */}
        </div>
    );
    
}