import {nameUser_,token_,idUser_,rolUser_,emailUser_,login_} from '../components/Constants';
import {resetStorageOrder,resetInfoDelivery} from '../service/ManagerApis';
import {deleteAllDbOrder} from '../service/ManagerOrders';

export const getBoolStorage = (key) => {
    const storage = localStorage.getItem(key);
    return storage === null || storage === 'false' ? false : true;
}

export const getIntStorage = (key) => {
    const storage = localStorage.getItem(key);
    return storage === null || storage === 'null' ? -1 : storage;
}

export const getStringStorage = (key) => {
    const storage = localStorage.getItem(key);
    return storage === null || storage === 'null' ? '' : storage;
}


export const logOut = () => {

    var authF = window.FB;
    //console.log(authF);
    if(authF!==null && authF!==undefined){
        window.FB.getLoginStatus(function(response) {
            console.log(response.satatus);    
            window.FB.logout((response)=>{
                console.log(response);
            });
        });
    }
    //var authG = window.gapi.auth2;
    var authG = window.gapi;
    if(authG !== null && authG !== undefined){
        const auth2 = authG.auth2.getAuthInstance();

        auth2.signOut().then(function () {
        });
        auth2.disconnect();
    }

    localStorage.setItem(nameUser_,"");
    localStorage.setItem(token_,"");
    localStorage.setItem(idUser_,0);
    //localStorage.setItem(rolUser_,0);
    localStorage.setItem(rolUser_,"");
    localStorage.setItem(emailUser_,"");
    localStorage.setItem(login_,false);
    //localStorage.setItem(InfoOn_,false);
    deleteAllDbOrder();
    resetStorageOrder();
    resetInfoDelivery();
}
