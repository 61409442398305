import React, { useEffect, useState } from 'react';
//Components
//import OrdenDelivery from '../Delivery/OrdenDelivery';
import ScanQR from '../QR/ScanQR';
//import ListCarrito from '../Carrito/ListCarrito';
//import {Backdrop, Grid} from '@material-ui/core';
//import {Box,Backdrop,Button,TextField,TextareaAutosize,Grid,FormControl,InputAdornment,Input,IconButton,InputLabel,Avatar} from '@material-ui/core';
import {Box,Backdrop,TextField,Grid} from '@material-ui/core';
import { SpeedDial, SpeedDialAction}  from '@material-ui/lab';
import {makeNormalDialog} from '../../utilscomponents/DialogAsk';
//import Rating from '@material-ui/lab/Rating';
//Styles
import SendIcon from '@material-ui/icons/Send';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import HomeIcon from '@material-ui/icons/Home';
//import {MainColors} from '../Sources/SourcesConst';
import { makeStyles,withStyles } from '@material-ui/core/styles';
//import StarBorderIcon from '@material-ui/icons/StarBorder';
//import {getCustomTextField} from '../Generics/TextField';
//import {getCustomButton} from '../Generics/CustomButton';
//BD
//import {allItemCarrito,decimalAdjust} from '../../../service/ManagerCarrito';
//Utils
import {getBoolStorage} from '../../../service/HelperStorage';
import {login_,IsQueja_, color_main_, color_secondary_} from '../../Constants';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
//Apis
//import { checkStatusOrder,statusDeliveryOrder,refreshTokenUser } from '../../../service/ManagerApis';
import {refreshTokenUser } from '../../../service/ManagerApis';
//const CommentTextField = getCustomTextField(localStorage.getItem(color_secondary_));

const CommentTextField = withStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
        color: localStorage.getItem(color_secondary_), //MainColors.secondary,
     },
      '& .MuiInput-underline:after': {
        color: localStorage.getItem(color_secondary_),
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: localStorage.getItem(color_secondary_),
        },
        '&:hover fieldset': {
          borderColor: localStorage.getItem(color_secondary_),
        },
        '&.Mui-focused fieldset': {
          borderColor: localStorage.getItem(color_secondary_),
        },
      },
    },
}))(TextField);

//const AcceptButton = getCustomButton(MainColors.secondary,'3vh','bold','#fff','#fff');


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
        margin: '0',
        textAlign: 'center',
    },
    textField: {
        width: '70vw',
        //height: '80vw',
        height: 'auto',
        margin: '6vh',
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(10),
        right: theme.spacing(2),
    },
    fab: {
        zIndex: '2',
        'backgroundColor': localStorage.getItem(color_main_) + '!important',
        color: '#fff',
    },
    speedDialAction: {
        //color: MainColors.secondary,
        background: '#fff',
    },
    list: {
        zIndex: '-1',
    },
    modalOptions:{
        zIndex: '3',
    },
    backdrop:{
        zIndex: '1',
    },
    title:{
        fontSize: '5vh',
        padding: '2vh 0 0 5vw',
        margin: '0',
        //color: MainColors.main,
    },
    modal: {
        position: 'absolute',
        bottom: theme.spacing(10),
        right: theme.spacing(5),
        //zIndex: '1',
        fontSize: '9pt',
        fontWeight: 'bold',
        color: '#CB4335',
        opacity: '100%',
        background:'transparent',
        width:'137px',
        height: 'auto',
        textAlign:'left',
        //left: '50%',
        //transform: 'translate(-50%, -50%)',
    },
}));

const actions = [
    { icon: <RestaurantIcon/>, name: <TranslateStr token="optionRes"/> },
    { icon: <HomeIcon/> , name: <TranslateStr token="optionDel"/>},
];

export default function Queja(props){
    //const [arrayCarrito,setArrayCarrito] = useState([]);
    const [openBtn,setOpenBtn] = useState(false);
    const [indexAction,setIndexAction] = useState(0);
    const sty = useStyles();
    const [infoForm,setInfoForm] = useState({comment:"",rating:0});

    const onInputComment = (e) => {
        e.persist();
        setInfoForm({comment:e.target.value,rating:infoForm.rating});
    }

    // const onInputRating = (e) => {
    //     e.persist();
    //     setInfoForm({comment:infoForm.comment,rating:e.target.value});
    // }

    const handleClickOption = (index) => {
        if(infoForm.comment !== "" || infoForm.rating !== 0){
            
            // localStorage.setItem(isIdOrden_,false);
            // localStorage.setItem(isDelivery_,false);
            localStorage.setItem(IsQueja_,true);

            const IsQueja = getBoolStorage(IsQueja_);
            console.log(index);
            if(index === -1 || !IsQueja){
                changePage(index);
            }else{
                validateStatusRes(true,true,index);
            }
        }else{
            makeNormalDialog(<TranslateStr token="emptyfields"/>, <TranslateStr token="requiredComplaints"/>,null);
            setOpenBtn(false);
        }
    }

    const validateStatusRes = (okRes,isActive,indexPage) => {
        if(okRes){
            if(!isActive){
                setOpenBtn(false);
                makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr tokne="untilOpen"/>,null);
            }else{
                changePage(indexPage);
            }
        }else{
            makeNormalDialog(<TranslateStr token="somewrong"/>,<TranslateStr token="nocheckorder"/>,null);
        }
    }

    const changePage = (index) => {
        setIndexAction(index+1);
        if(index === -1){
            setOpenBtn(false);
        }
    }

    const handleOpen = () => {
        //Solo la opcion de restaurante por mientras
        handleClickOption(0);
    };

    const handleClose = () => {
        setOpenBtn(false);
    }

    useEffect(() => {
        if(getBoolStorage(login_)){
            refreshTokenUser(() => {});
        }
    },[]);

    return(
        <div className={sty.root}>
            {
                (function() {
                    switch(indexAction){
                        case 0:
                            return <div>
                                <Grid container direction="row" justify="flex-start" alignItems="center">
                                    <Grid item xs>
                                        <p className={sty.title} style={{color: localStorage.getItem(color_main_)}}><TranslateStr token="titleComplaints"/></p>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center">
                                    <Box component="fieldset" mb={3} borderColor="transparent">
                                        {/* <Rating
                                        onChange={onInputRating}
                                        name="customized-empty"
                                        defaultValue={0}
                                        precision={1}
                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                        /> */}
                                    </Box>
                                    <Grid item xs>
                                        <CommentTextField value={infoForm.comment} onChange={onInputComment} id="outlined-basic" 
                                        label={<TranslateStr token="comment"/>} variant="outlined"
                                        className={sty.textField} />
                                    </Grid>
                                    {/* <Grid item xs>
                                        <AcceptButton onClick={validateFields} variant="contained">
                                            <TranslateStr token="continue"/>
                                        </AcceptButton>
                                    </Grid> */}
                                </Grid>
                                <Backdrop className={sty.backdrop} open={openBtn}/>
                                {<p className={sty.modal}>{props.showInformationOn ? <TranslateStr token="showInfoComplaints"/> : ''}</p>}
                                <SpeedDial
                                    ariaLabel="speed dial tooltip example"
                                    className={sty.speedDial}
                                    icon={
                                        <SendIcon/>
                                    }
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    open={openBtn}
                                    FabProps={{className : sty.fab}}>
                                    {
                                        actions.map((action,index) => (
                                            <SpeedDialAction
                                                className={sty.speedDialAction}
                                                style={{color: localStorage.getItem(color_secondary_)}}
                                                key={index}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                                tooltipOpen
                                                onClick={() => handleClickOption(index)}
                                            />
                                        ))
                                    }
                                </SpeedDial>
                            </div>;
                        case 1:
                            return <ScanQR callwaiter={false} changeEmpresa={false} sendOrden={false} sendQueja={true} setInfoForm={setInfoForm} comment={infoForm.comment} back={handleClickOption} 
                                    setfullUserName={props.setfullUserName} setemailuser={props.setemailuser} fulluserName={props.fulluserName} emailuser={props.emailuser}/>
                        default:
                            return;
                    }
                })()
            }
        </div>
    )
}