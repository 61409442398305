import React,{useState} from 'react';
//Components
import {Grid,Paper,Typography,Avatar} from '@material-ui/core';
import RateOrder from './RateOrder';
import StarIcon from '@material-ui/icons/Star';
//Styles
import { makeStyles } from '@material-ui/core/styles';
//import {MainColors} from '../../Sources/SourcesConst';
import {color_accent_, color_main_, color_secondary_} from '../../../Constants';
//Modal
import {useModal} from '../../Generics/useModal';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(1),
      margin: '0 1vh 0 2vh',
      width: '95%',
      border: '0.2vh solid #EBEDEF',
    },
    image: {
      width: '6vh',
      height: '6vh',
      margin: 'auto',
    },
    indication: {
        //color: MainColors.secondary,
        fontSize: '1,5vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    cal:{
        //color: MainColors.accent,
        fontSize: '2vh',
        fontWeight: 'bold',
        textAlign: 'center',
        margin: '0 1vh 0 0.4vh'
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2.1vh',
        marginBottom: '0',
    },
    price: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2.3vh',
        marginBottom: '0',
        textAlign: 'right',
    },
    contImg: {
        width: '100%',
        height: '100%',
        padding: 'auto',
        //color: MainColors.main,
        backgroundColor: '#fff',
    },
    img:{
        margin: 'auto',
        width: '2vh',
        height: '2vh',
    },
}));

export default function ItemOrder(props){

    const {data} = props;
    const sty = useStyles();
    const [dataOrder,setDataOrder] = useState({
        calNumber : data.calificacion,
        comment : data.comentario
    })
    const isRate = parseInt(dataOrder.calNumber) !== 0;
    const isCommented = dataOrder.comment.length !== 0;
    const { hide,show,RenderModal } = useModal();

    const okAddRate = (comment,rating) => {
        setDataOrder({calNumber:rating,comment:comment});
    }

    const tryComment = () => {
        if(!isCommented && !isRate){
            show();
        }
    }

    return(
        <div  className={sty.paper}>
            <Paper onClick={() => tryComment()}>
                <Grid container spacing={1} justify="center" alignItems="center">
                    <Grid item xs={3}>
                        <Avatar className={sty.image} alt="Menu Photo" src={data.imageUri} />
                    </Grid>
                    <Grid item xs={9} container direction="column">
                        <Grid item xs container direction="row">
                            <Grid item xs={9}>
                                <Typography className={sty.title}>
                                {data.subMenu}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={sty.price} >{'$' + data.precio}</Typography>
                            </Grid>
                        </Grid>
                        {
                            (isCommented || isRate) &&
                            <Grid item xs container direction="row" justify="flex-start" alignItems="center">
                                {
                                    isRate && 
                                    <Grid item>
                                        <Avatar className={sty.contImg} style={{color: localStorage.getItem(color_main_)}} alt="Add icon">
                                            <StarIcon className={sty.img}/>
                                        </Avatar>
                                    </Grid>
                                }
                                {
                                    isRate &&
                                    <Grid item>
                                        <p className={sty.cal} style={{color: localStorage.getItem(color_accent_)}}>{dataOrder.calNumber}</p>
                                    </Grid>
                                }
                                {
                                    isCommented && 
                                    <Grid item>
                                        <p className={sty.indication} style={{color: localStorage.getItem(color_secondary_)}}>{dataOrder.comment}</p>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Paper>
            <RenderModal>
                <RateOrder hideDialog={hide} dataOrder={data} onUpdateComment={okAddRate}/>
            </RenderModal>
        </div>
    )
}