//Translated
import { LocalizationContext } from './LanguageProvider';
import React, {useContext}  from 'react';
import {lan_, idLan_, isBilingual_,  color_accent_, color_secondary_} from '../../../Constants';
//import {getIntStorage, getBoolStorage} from '../../../service/HelperStorage';
import {Grid,Avatar} from '@material-ui/core';
import {Language} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
//import {MainColors} from '../../Sources/SourcesConst';
import Translator from './Translator';
//import Select from "react-select";

const useStyles = makeStyles(() => ({
  root:{
    width: '100%',
    height: '100%',
  },
  select:{
    margin: '0',
    padding: '0.5rem',
    //color: MainColors.accent,
    // backgroundColor: '#1b1a1a !important',
    // "&:hover": { backgroundColor: "red  !important'" },
  },
  option: {
    backgroundColor: '#1b1a1a !important',
    color: '#357b1d !important',
    "&:hover": { backgroundColor: "red  !important'" },
  },
  
  title:{
    textAlign: 'start',
    //color: MainColors.accent,
    fontSize: '2.5vh',
    margin: '0',
    padding: '0',  
  },
  avatar:{
    width: '100%',
    height: '100%',
    padding: '1vh',
    //color: MainColors.secondary,
    backgroundColor: '#fff',
  },
  img:{
      margin: '1vh',
      width: '5vh',
      height: '5vh',
  },
}));

const langs = [
  { shortCode: 'ES', label: "Español" },
  { shortCode: 'EN', label: "English" },
];

export default function Dropdown(props) {

  const sty = useStyles();

  const {
    localizationStore: [ state, dispatch ]
  } = useContext(LocalizationContext);

  const { selectedLanguage } = state;

  const handleSelected = (e) => {
    const { target: { value } } = e;
    if(value === 'EN'){
        localStorage.setItem(lan_,value);
        localStorage.setItem(idLan_,2);
    }else{
        localStorage.setItem(lan_,value);
        localStorage.setItem(idLan_,1);
    }
    props.setConfigMailbox(
      {showMailbox:true,
      changeRestaurant: <Translator token="changeRestaurant"/>
    });
    dispatch({ type: 'LANGUAGE_UPDATE', payload: value });
  }

  function getOptions(langs) {
    return langs.map(({ shortCode, label }) => {
      return <option value={shortCode} key={shortCode}>{label}</option>
    });
  }

  return (
    <Grid className={sty.root} item container direction="row" justify="flex-start" alignItems="center">

        <Grid item>
            <Avatar className= {sty.avatar} style={{color: localStorage.getItem(color_secondary_)}} alt="User icon">
                <Language className={sty.img}/>
            </Avatar>
        </Grid>
        <Grid item>
            <p className={sty.title} style={{color: localStorage.getItem(color_accent_)}}><Translator token="language"/></p>
            <select className={sty.select} style={{color: localStorage.getItem(color_accent_)}} onChange={handleSelected} value={selectedLanguage}>
              {getOptions(langs)}
            </select>
        </Grid>
    </Grid>
  );

}