import React,{useState, useEffect} from 'react';
//Components
import ListToSend  from './ListToSend';
import {Grid} from '@material-ui/core';
//DB
import {allItemCarrito} from '../../../../service/ManagerCarrito';
//Styles
import {MainColors} from '../../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
//Others
import { desOptDel_,desAddressDel_} from '../../../Constants';
import {getStringStorage} from '../../../../service/HelperStorage';

const useStyles = makeStyles({
    root: {
    },
    title:{
        color: MainColors.main,
        fontSize: '3vh',
        fontWeight: 300,
        margin: '0 0 0.5vh 0',
        textAlign: 'center',
    },
    subInfo:{
        color: MainColors.secondary,
        fontSize: '2vh',
        fontWeight: 300,
        margin: '0',
        textAlign: 'center',
        padding: '0.5vh',
    },
    subInfoDes:{
        color: MainColors.accent,
        fontSize: '2.5vh',
        fontWeight: 300,
        margin: '0',
        textAlign: 'center',
        padding: '0.5vh',
    },
    total:{
        color: MainColors.accent,
        fontSize: '4vh',
        fontWeight: 450,
        margin: '0 0 1vh 1vh',
    },
});

export default function ConfirmOrder(){

    const sty = useStyles();
    const [total,setTotal] = useState(0.0);
    const address = getStringStorage(desAddressDel_); 
    const option = getStringStorage(desOptDel_);

    useEffect(() => {
        allItemCarrito( array => {
            var totalArray = 0.0;
            array.forEach(element => {
                totalArray = totalArray + parseFloat(element.price) * parseInt(element.cantidad);
            });
            setTotal(totalArray);
        })
    },[]);

    return(
        <Grid container justify="center" alignItems="center">
            <p className={sty.title}>Confirmación de orden</p>
            <Grid item container direction="row" justify="center" alignItems="flex-start">
                <Grid item xs={6} container direction="column" justify="center" alignItems="center">
                    <p className={sty.subInfo} >Dirección:</p>
                    <p className={sty.subInfoDes}>{address}</p>
                </Grid>
                <Grid item xs={6} container direction="column" justify="center" alignItems="center">
                    <p className={sty.subInfo}>Indicaciones:</p>
                    <p className={sty.subInfoDes}>{option}</p>
                </Grid>
            </Grid>
            <Grid item container direction="row" justify="center" alignItems="center">
                <p className={sty.title}>Total:</p>
                <p className={sty.total}>{'$' + total}</p>
            </Grid>
            <ListToSend/>
        </Grid>
    )
}