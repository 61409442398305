//import React,{useEffect} from 'react';
import React from 'react';
//Components
//import {Grid,Paper,Checkbox,Typography,Avatar} from '@material-ui/core';
import {Grid} from '@material-ui/core';
//Styles
import { makeStyles } from '@material-ui/core/styles';
//import {MainColors} from '../../Sources/SourcesConst';
import StatusOrderRestaurant from './StatusOrderRestaurant';
//import { color_accent_, color_main_, color_secondary_} from '../../../Constants';
//import {allItemOrders,setCurrentDbOrder,allItemOrdersDB} from '../../../../service/ManagerOrders';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
    },
    paper: {
      padding: theme.spacing(1),
      margin: 'auto',
      maxWidth: 500,
      border: '0.2vh solid #EBEDEF',
    },
    image: {
      width: '8vh',
      height: '8vh',
      margin: 'auto',
    },
    indication: {
        //color: MainColors.secondary,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        marginBottom: '0',
    },
    price: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        marginBottom: '0',
        textAlign: 'right',
    },
}));

export default function ItemNoOrder(props){
    const {setSettingsOrder,settingsOrder} = props;
    const sty = useStyles();

    //useEffect(() => {
        //props.reload();
        // allItemOrdersDB( array => {
        //     props.setArrayOrdenes(array);
        // });
    //},[]);

    return(
        <div className={sty.root}>
            <Grid item container direction="row" justify="center" alignItems="center">
                <Grid item>
                    {/* <Typography className={sty.title}>
                        {'#'}{props.NumOrden}
                    </Typography> */}
                    <StatusOrderRestaurant id={props.NumOrden} loadOrdenes={props.loadOrdenes} 
                    reload={props.reload} contOrden={props.contOrden} ArrayOrdenes={props.ArrayOrdenes} 
                    setSettingsOrder={setSettingsOrder} settingsOrder={settingsOrder}/>
                </Grid>
            </Grid>
        </div>
    )
}