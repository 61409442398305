//Styles
import { withStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

const getCustomButton = (_color,_fontSize,_fontWeight,_backG,_hoverBackG) => {
    return  withStyles(() => ({
        root: {
          color: _color,
          fontSize: _fontSize,
          fontWeight: _fontWeight,
          backgroundColor: _backG,
          '&:hover': {
            backgroundColor: _hoverBackG,
          },
        },
    }))(Button);
}

export {
    getCustomButton
}