import React from 'react';
//Components
import {Grid,Avatar} from '@material-ui/core';
import FastfoodIcon from '@material-ui/icons/Fastfood';
//Styles
import {MainColors} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        height: '15vh',
    },
    labelUserName:{
        color: MainColors.main,
        padding: '0',
        margin: '0 0 1vh 2vh',
        fontSize: '3vh',
        fontWeight: '250',
        textAlign: 'start'
    },
    labelAddress:{
        color: MainColors.accent,
        padding: '0',
        margin: '0 0 0 2vh',
        fontSize: '2vh',
        fontWeight: '250',
        textAlign: 'start'
    },
    back: {
        width: '100%',
        height: '100%',
        padding: '1vh',
        color: MainColors.accent,
        backgroundColor: '#fff',
    },
    img:{
        margin: '0',
        width: '4vh',
        height: '4vh',
    },
    msgAccepted:{
        color: '#FFCA28',
        padding: '0',
        margin: '0.5vh',
        fontSize: '2.8vh',
        fontWeight: '250',
        textAlign: 'center'
    },
}));

export default function ItemOrder(props){

    const {dataOrder,changePage} = props;
    const sty = useStyles();
    const isAccepted = dataOrder !== null ? parseInt(dataOrder.idEstatusEntrega) === 5 : false

    return(
        <Grid onClick={() => changePage(1,dataOrder)} direction="row" container justify="center" alignItems="center">
            <Grid item xs={2}>
                <Avatar onClick={() => props.changePage(0)} className= {sty.back} alt="Add icon">
                    <FastfoodIcon className={sty.img}/>
                </Avatar>
            </Grid>
            <Grid item xs={10} direction="column" container justify="center" alignItems="flex-start">
                <p className={sty.labelUserName}>{dataOrder.cuentaDeUsuario}</p>
                <p className={sty.labelAddress}>{dataOrder.domicilio}</p>
                {
                    isAccepted &&
                    <p className={sty.msgAccepted}>Orden aceptada</p>
                }
            </Grid>
        </Grid>
    )
}