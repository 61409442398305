//import { ContactSupport } from "@material-ui/icons";
//import TipoDeMenu from "./mainpages/TipoDeMenu/TipoDeMenu";

//Generics
export const idMesa_ = 'idMesa';
export const tagMesa_ = 'tagMesa';
export const ubicacion_ = 'ubicacion';
export const esHabitacion_ = 'esHabitacion';
export const errorApi_ = 'errorApi';
export const firstError_ = 'isFirstError';
export const InfoOn_ = 'InfoOn';
export const ValidacionPorUsuario_ = 'ValidacionPorUsuario';
export const CriterioBusqueda_ = 'CriterioBusqueda';
//export const countCarrito_ = 'countCarrito';
//Restaurant
export const idEmpresa_ = 'idEmpresa';
export const nameEmpresa_ = 'nameEmpresa';
export const isLandingForChangeEmpresa_ = "isLandingForChangeEmpresa_";
export const idForChangeEmpresa_ = "isLandingForChangeEmpresa_";
export const color_accent_ =  "color_accent";
export const color_secondary_ = "color_secondary";
export const color_main_ = "color_main";
//Restaurant order
export const idOrden_ = 'idOrden';
export const isIdOrden_ = 'isIdOrden';
export const idTipoOrden_ = 'idTipoOrden';
export const DosCarritos_ = 'DosCarritos';
export const IsRestaurant_ = 'IsRestaurant';
export const IsQueja_ = 'IsQueja';
//Delivery Order
export const idMesaDel_ = 'idDeliveryMesa';
export const isDelivery_ = 'isOrderDelivery';
//
export const idAddressDel_ = 'idAddressDelivery';
export const desAddressDel_ = 'desAddressDel';
export const indexAddressDel_ = "indexAddressDel";
//
export const idOptDel_ = 'idOptionDelivery';
export const desOptDel_ = 'desOptionDelivery';
export const indexOptionDel_ = "indexOptionDel"; 
//User
export const idUser_ = 'idUsuario';
export const nameUser_ = 'fullNameUser';
export const rolUser_ = "rolUser";
export const emailUser_ = "emailUser";
export const login_ = 'isLogin';
export const token_ = 'token'; 
export const refreshToken_ = 'refreshtoken'; 
export const isForOrder_ = 'idForOrder';

//Config
export const lan_ = 'language';
export const idLan_ = 'idlanguage';
export const isBilingual_ ='isBilingual'

