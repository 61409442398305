import React, {useEffect,useState } from 'react';
//Components
//import Formsearch from './Formsearch';
import SubMenu from '../Submenus/Submenu';

import { Typography,Grid,Avatar } from '@material-ui/core';
//Apis
import {makeRequest} from '../../../service/ManagerRequest';
import {idLan_, idEmpresa_, CriterioBusqueda_, color_accent_, color_secondary_} from '../../Constants';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
//Styles
//import {MainColors} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//Swipe List
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        padding: theme.spacing(1),
        margin: 'auto',
        maxWidth: 500,
    },
    avatar: {
        width: '100%',
        height: '100%',
        padding: 'auto',
        //color: MainColors.accent,
        backgroundColor: '#fff',
    },
    container : {
        width : '95vw',
        margin : '0.5vh 2vw',
        //backgroundColor: MainColors.secondary,
        borderRadius: '0.5vw',
    },
    img:{
        margin: 'auto',
        width: '6vh',
        height: '6vh',
    },
    nameSub:{
        fontSize: '5vh',
        fontWeight: '150',
        //color: MainColors.secondary,
        margin: '0',
    },
    modalleft: {
        position: "absolute",
        zIndex: '3',
        //bottom: '0vh',
        //top: '10vh',
        right: '3vh',
        fontSize: '9pt',
        fontWeight: 'bold',
        color: '#CB4335',
        opacity: '100%',
        background:'transparent',
        width:'137px',
        height: 'auto',
        textAlign:'left',
        margin: '0px 0 0 -20px',
        //left: '50%',
        //transform: 'translate(-50%, -50%)',
      },
      modalright: {
        position: "absolute",
        zIndex: '3',
        //bottom: '0vh',
        //right: '6vh',
        fontSize: '9pt',
        fontWeight: 'bold',
        color: '#CB4335',
        opacity: '100%',
        background:'transparent',
        width:'137px',
        height: 'auto',
        textAlign:'right',
        left: '3vh',
        margin: '0px 0 0 -20px',
        //transform: 'translate(-50%, -50%)',
      },
}));

export default function SearchList(props){
    const [arraySubs,setSubs] = useState([]);
    const sty = useStyles();

    const handleResSubmenus = (data,success) =>{
        console.log('handleResSubmenus...');
        console.log(data);
        if(success){
            setSubs(data);
        }
    }
    // const onBackPress = (index) => {
    //     // if(index === -1){
    //     //     setPage(0);
    //     // }
        
    //     props.setShow(true);
    //     props.back(index-1);
    // }

    const handleCriterion = (search,onResult) => {
        //var criterion = search;
        // let queryString='';
        var criterion = search.split(" ");

        console.log('criterio = ' + criterion);

        const query = criterion.reduce(
            (prevValue, currentValue) => prevValue += `&criterioBusqueda=${currentValue}`,
            ''
        );

    
        onResult(query);
    }

    useEffect(() => {
        handleCriterion(localStorage.getItem(CriterioBusqueda_),(query)=>{
            console.log(query);
            props.setSearchEntry(localStorage.getItem(CriterioBusqueda_));
            const data = {
                criterioBusqueda: query,
                idIdioma: localStorage.getItem(idLan_),
                idEmpresa: localStorage.getItem(idEmpresa_)
            }
            makeRequest('application/json',data,'GET','masterdata/busquedaInteligente/WPA',false,false,(dataRes,success) => handleResSubmenus(dataRes,success))
        })
    },[])

    return(
        <div className={sty.root}>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Avatar onClick={props.back} className={sty.avatar} style={{color: localStorage.getItem(color_accent_)}} alt="Add icon">
                        <ArrowBackIcon className={sty.img}/>
                    </Avatar>
                </Grid>
                <Grid item xs={10}>
                    <p className={sty.nameSub} style={{color: localStorage.getItem(color_secondary_)}}>{<TranslateStr token="titleSearch"/>}</p>
                </Grid>
                <Grid container >
                <Typography className={sty.modalright}>{props.showInformationOn ? <TranslateStr token="showInfoSwipeLeft"/> : ''}</Typography>
                <Typography className={sty.modalleft}>{props.showInformationOn ? <TranslateStr token="showInfoSwipeRight"/> : ''}</Typography>
            </Grid>
            </Grid>
            <Grid>
                <SwipeableList>
                    {
                        arraySubs.map(sub => (
                            <SubMenu key={arraySubs.indexOf(sub)} dataItem={sub} idTipoMenu={props.idTipoMenu} showInformationOn={props.showInformationOn} setconfigCarrito={props.setconfigCarrito}/>
                        ))
                    }
                   
                </SwipeableList>
            </Grid>
            {/* <Grid container >
                <Typography className={sty.modalright}>{props.showInformationOn ? <TranslateStr token="showInfoSwipeLeft"/> : ''}</Typography>
                <Typography className={sty.modalleft}>{props.showInformationOn ? <TranslateStr token="showInfoSwipeRight"/> : ''}</Typography>
            </Grid> */}
            {/* <Grid>
                <SwipeableList>
                    {
                        arraySubs.map(sub => (
                            <SubMenu key={arraySubs.indexOf(sub)} dataItem={sub} idTipoMenu={props.idTipoMenu} showInformationOn={props.showInformationOn}/>
                        ))
                    }
                </SwipeableList>
            </Grid> */}
        </div>
    )
}