//import React, {useState,useEffect} from "react";
import React, {useEffect} from "react";
//Styles
//import {MainColors} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
//import {Grid,Fab} from '@material-ui/core';
//import {Grid} from '@material-ui/core';
import Menus from '../Menu/Menus';
import Servicios from '../Servicio/Servicios';
//import Formsearch from "../Search/Formsearch";
import SearchForm from "../Search/SearchForm";

//import Favoritos from '../Favoritos/Favoritos';
//import StarsIcon from '@material-ui/icons/Stars';
//import RoomServiceIcon from '@material-ui/icons/RoomService';
//import ScanQR from '../QR/ScanQR';
//import TranslateStr from "../ConfigPage/HelperLanguage/TranslateStr";
//Apis
//import {makeRequest} from '../../../service/ManagerRequest';
//import {idLan_, idEmpresa_, DosCarritos_} from '../../Constants';
import {DosCarritos_} from '../../Constants';
import { getBoolStorage } from "../Carrito/HelperArray";
const useStyles = makeStyles(() => ({
  inline: {
    display: 'inline',
  },
  description: {
    textAlign: 'left',
    fontSize: '4vh',
    color: '#fff',
    margin: 'auto auto auto 5vw',
  },
  container : {
      width : '95vw',
      margin : '0.5vh 2vw',
      //backgroundColor: MainColors.secondary,
      borderRadius: '0.5vw',
  },
  avatar:{
      margin: 'auto auto',
  },
  oculto:{
    visibility: 'hidden',
  },
}));

export default function TipoDeMenu(props) {
  //const [page,setPage] = useState(0);
  const sty = useStyles();
  const [show, setShow] = React.useState(true);
  //const[servicios,setArrayServicios] = useState([]);
  //const[menus,setArrayMenus] = useState([]);
  const isDosMenus = getBoolStorage(DosCarritos_) ;
  // const handleResponse = (dataServcio,status) => {
  //   if(status){
  //     setArrayServicios(dataServcio);
  //   }
  // }

// const handleResponseMenu = (dataMenu,status) => {
//   if(status){
//     setArrayMenus(dataMenu);
//   }
// }
useEffect(() => {   
  //const abortController = new AbortController();
  //const signal = abortController.signal;
  
  console.log('isDosMenus: ' + isDosMenus);
  // const dataGetMenus = {
  //   idRestaurant : localStorage.getItem(idEmpresa_),
  //   idIdioma : localStorage.getItem(idLan_)
  // }

  // const dataGetServicios = {
  //   idRestaurant : localStorage.getItem(idEmpresa_),
  //   idIdioma : localStorage.getItem(idLan_)
  // }

  // makeRequest('application/json',dataGetMenus,'GET','masterdata/getMenu',false,false,(data,success) => {
  //    handleResponseMenu(data,success);
  // });
  // makeRequest('application/json',dataGetServicios,'GET','masterdata/getMenu/Servicios',false,false,(data,success) => {
  //   handleResponse(data,success);
  // });

  

  // setTimeout(() => {
  //   makeRequest('application/json',dataGetServicios,'GET','masterdata/getMenu/Servicios',false,false,(data,success) => handleResponse(data,success));
  // },1000);
  // return function cleanup(){
  //   abortController.abort();
  // }
  
},[isDosMenus]);
// useEffect(() => {   
  
//   const dataGetMenus = {
//     idRestaurant : localStorage.getItem(idEmpresa_),
//     idIdioma : localStorage.getItem(idLan_)
//   }
// makeRequest('application/json',dataGetMenus,'GET','masterdata/getMenu',false,false,(data,success) => handleResponseMenu(data,success));
// },[]);

// const onBackPress = (index) => {
//   if(index === -1){
//       setPage(0);
//   }
// }

  return (
    <div className={sty.root}>
    <SearchForm show={show} setShow={setShow}  idTipoMenu={0} showInformationOn={props.showInformationOn} setconfigCarrito={props.setconfigCarrito}></SearchForm>
    {
  show ?  
    <div className={sty.root}>
        
        
        {/* <SearchForm setShow={setShow}></SearchForm> 
        servicios.length==0 && !menus.length==0*/}
        {/* {servicios.length==0 && !menus.length==0 ?  */}
        {/* hayServicios={servicios.length} hayMenus={menus.length} */}
        <Servicios showInformationOn={props.showInformationOn} setconfigCarrito={props.setconfigCarrito}/>
        {!isDosMenus ? 
         <Menus idMenu={1} showInformationOn={props.showInformationOn} setconfigCarrito={props.setconfigCarrito}/> 
         :
         <></>
        }
    </div>
    :
    <></>}
    </div>
  );
  
}