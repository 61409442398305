import React,{useState, useEffect} from 'react';
//Components
import {Grid} from '@material-ui/core';
import ItemDate from './ItemDate';
//Apis
import {getSummaryDate} from '../../../../service/ManagerApis';
//Style 
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
         margin: '2vh 1vh 0 1vh'
    },
}));

export default function ListDates(){

    const sty = useStyles();
    const [arrayDates,setArrayDates] = useState([]);

    useEffect(() =>{
        getSummaryDate(array =>{
            setArrayDates(array);
        });
    },[]);

    return(
        <Grid className={sty.root} container direction="column" justify="center" alignItems="center">
            {
                arrayDates.map((item,index) => (
                    <ItemDate date={item} key={index}/>
                ))
            }
        </Grid>
    )
}