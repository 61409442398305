/* eslint-disable default-case */
import React, {useState,useEffect} from "react";
//Components
import ItemMenu from './ItemMenu';
import {List,Fab,Avatar} from '@material-ui/core';
import ListSubmenus from '../Submenus/ListSubmenus';
import Favoritos from '../Favoritos/Favoritos';
//import StarsIcon from '@material-ui/icons/Stars';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import ScanQR from '../QR/ScanQR';
import TranslateStr from "../ConfigPage/HelperLanguage/TranslateStr";
//Apis
import {API_BASE_IMG,makeRequest} from '../../../service/ManagerRequest';
import {idLan_, idEmpresa_,DosCarritos_, color_accent_, color_main_, color_secondary_} from '../../Constants';

//Styles
import {MainSize} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import { FormatColorResetOutlined } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    root: {
      width: MainSize.wFull,
      position: 'relative'
    },
    title:{
        //color: MainColors.secondary,
        padding: '2vh 0 0 5vw',
        margin: '0',
        fontSize: '5vh',
    },
    contFavorite:{
        margin: '1vh',
    },
    labelFavorite:{
        //color: MainColors.accent,
        padding: '0',
        margin: '0',
        fontSize: '2vh',
    },
    favorite: {
        width: '100%',
        height: '100%',
        padding: '0',
        //color: MainColors.main,
        backgroundColor: '#fff',
    },
    img:{
        margin: '0',
        width: '6vh',
        height: '6vh',
    },
    fab: {
        position: "fixed",
        zIndex: '10',
        bottom: '4vh',
        right: '4vh',
        backgroundColor: localStorage.getItem(color_main_),
        "&:hover, &.Mui-focusVisible": { backgroundColor: localStorage.getItem(color_main_) }
    },
    modal: {
        position: "absolute",
        zIndex: '16',
        bottom: '0vh',
        right: '6vh',
        fontSize: '9pt',
        fontWeight: 'bold',
        color: '#CB4335',
        opacity: '100%',
        background:'transparent',
        width:'137px',
        height: 'auto',
        textAlign:'left',
        //left: '50%',
        //transform: 'translate(-50%, -50%)',
    },
    avatar: {
        width: '100%',
        height: '100%',
        padding: 'auto',
        //color: MainColors.accent,
        backgroundColor: '#fff',
    },
    nameSub:{
        fontSize: '5vh',
        fontWeight: '150',
        //color: MainColors.secondary,
        margin: '0',
    },
    container : {
        width : '95vw',
        margin : '0.5vh 2vw',
       //backgroundColor: MainColors.secondary,
        borderRadius: '0.5vw',
    },
    description: {
        textAlign: 'left',
        fontSize: '4vh',
        color: '#fff',
        margin: 'auto auto auto 5vw',
    },
    avatarbak:{
        margin: 'auto auto',
        //color: MainColors.accent,
       
    },
    iconNav: {
        height : '7vh',
        width: '7vh',
        color: '#fff',
        //backgroundColor: MainColors.secondary,
        marginBottom: '1vh',
      },
}));

export default function Menus(props){
    const[menus,setArrayMenus] = useState([]);
    const[arrayShowMenus,setArrayShowMenus] =  useState({
        idMenu: 0,
        status:0,
        dataMenu_: null
    });
    const[configSubMenu,setConfigSub] = useState({nombreMenu:"",idMenu:0,showSubmenu:false});
    const [page,setPage] = useState(0);
    const classes = useStyles();
    //const [blnShowMenu,SetblnShowMenu] = useState(false);
    const getBoolStorage = (key) => {
        const storage = localStorage.getItem(key);
        return storage === null || storage === 'false' ? false : true;
    }
    const verDosCarritos = getBoolStorage(DosCarritos_);
  
    
    // var arrayShowMenu = [];
    
    // var ver =false;
    
    const handleResponse = async (dataMenu,status) => {
        //var arrayFinal=[];
        if(status){
            if(dataMenu.length !== 0){
               await dataMenu.forEach(element => {
                   
                    //element['visible'] = true;
                    

                    const data = {
                        idMenu: element.idMenu,
                        idIdioma: localStorage.getItem(idLan_)
                    }
                    
                    makeRequest('application/json',data,'GET','masterdata/getAllProductosSubMenu/WPA',true,true,(dataRes,success) => {
                        if(success && dataRes.data.length!=0){
                            //console.log('idMenu..' + element.idMenu);
                            //console.log('ver menu: ' + success);
                            //console.log(item);
                            //Object.assign(item,{children:[item]});
                            //Object.assign(item,{children:[dataRes.data]});
                            //var arrayFinal = parseMenuItem(element);
                            //if(dataRes.data[0].idMenu===element.idMenu){

                                //arrayFinal.push(element);
                            //}
                            
                            //const newMenu = dataMenu.filter((dataMenu) => dataMenu.idMenu === element.idMenu);
                            // console.log({newMenu});
                            // setArrayShowMenus({
                            //     idMenu:element.idMenu,
                            //     status:1,
                            //     dataMenu_:dataMenu
                            // });
                            //arrayFinal.push(dataMenu.filter((dataMenu) => dataMenu.idMenu === element.idMenu));
                            //console.log(arrayFinal);
                            // newMenu.forEach(itemMenu => {
                            //     arrayFinal.prototype.push(itemMenu);
                            // });
                            //setArrayMenus(newMenu);
                            //element['visible'] = true;
                            
                        }
                        else{
                            // console.log('idMenu..' + element.idMenu);
                            // console.log(element);
                            


                    
                            //element['visible'] = false;
                            dataMenu.splice(dataMenu.indexOf(element), 1);
                            //arrayFinal.splice(arrayFinal.indexOf(element), 1);  
                            setArrayShowMenus({
                                idMenu:element.idMenu,
                                status:1,
                                dataMenu_:dataMenu
                            });
                            //dataMenu.length=dataMenu.length-1;
                        }
                    });
                    //arrayFinal=dataMenu;
                });
                //setArrayMenus(dataMenu.filter((m) => m.visible==true));
            }
            
            //setArrayMenus(arrayShowMenu);
            //console.log('menus restaurate...');
            //console.log(dataMenu);
            //setArrayMenus(dataMenu.filter((m) => m.visible=='true').sort());
            setArrayMenus(dataMenu.sort());
            //console.log(arrayFinal);
            //setArrayMenus(arrayFinal);
            
        }
    }

    
    const changeShowSub = (idMenu_,nombreMenu_) => {
        setConfigSub({nombreMenu:nombreMenu_,idMenu:idMenu_,showSubmenu:!configSubMenu.showSubmenu})
    }

    useEffect(() => {   
        //const abortController = new AbortController();
        //const signal = abortController.signal;

        if(verDosCarritos){
            localStorage.setItem('idTipoOrden',1);
        }
        const dataGetMenus = {
            idRestaurant : localStorage.getItem(idEmpresa_),
            idIdioma : localStorage.getItem(idLan_)
        }
        makeRequest('application/json',dataGetMenus,'GET','masterdata/getMenu',false,false,async (data,success) => {
            await handleResponse(data,success);
        });

        // return function cleanup(){
        //     abortController.abort();
        // }
    
    },[]);

    const onBackPress = (index) => {
        if(index === -1){
            setPage(0);
        }
    }
    
    // const OnShowMenu = (idMenu_,setMainResponse) => {
    //     //var Show_=false;

    //     const data = {
    //         idMenu: idMenu_,
    //         idIdioma: localStorage.getItem(idLan_)
    //     }
        
    //     makeRequest('application/json',data,'GET','masterdata/getAllProductosSubMenu',true,true,(dataRes,success) => {
    //         if(success && dataRes.data.length!=0){
    //             console.log('idMenu..' + idMenu_);
    //             console.log(success);
    //             //console.log(dataRes);
    //             //arrayFinal[0].push( ItemMenu_);
    //         }
    //         setMainResponse(dataRes.data);
    //     });
        
    // }

    return(
        <div className={classes.root}>
            {
                page === 0 &&
                <Fab className={classes.fab} style={{backgroundColor: localStorage.getItem(color_main_)}} color="primary" onClick={() => setPage(-1)}>
                    <RoomServiceIcon />
                    {<p className={classes.modal}>{props.showInformationOn ? <TranslateStr token="showInfoWaiter"/> : ''}</p>}
                </Fab>
            }
            {props.idMenu == 0 ?
            <div className={classes.container} style={{backgroundColor: localStorage.getItem(color_secondary_)}} onClick={props.back}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar className={classes.avatarbak} style={{color: localStorage.getItem(color_accent_)}}>
                        <Avatar className={classes.iconNav} style={{backgroundColor: localStorage.getItem(color_secondary_)}}>
                            <ArrowBackIcon className={classes.img}/>
                        </Avatar> 
                    </ListItemAvatar>
                        <ListItemText primary={<p className={classes.description}>{<TranslateStr token="titleTipoMenu" />}</p>} />
                </ListItem>
            </div>
            :
            <></>
            }
            {
                (function(){
                    switch(page){
                    case 0:
                        return !configSubMenu.showSubmenu ?
                        <List>
                            {
                                menus.map( item => (
                                   <ItemMenu 
                                        key={menus.indexOf(item)} 
                                        url={API_BASE_IMG + item.imgUri}
                                        title={item.nombreMenu}
                                        idTipoMenu={1}
                                        showSub={changeShowSub}
                                        idMenu={item.idMenu}
                                        setconfigCarrito={props.setconfigCarrito}
                                    />
                                ))
                            }
                        </List>
                        :
                        <ListSubmenus nombreMenu={configSubMenu.nombreMenu} idMenu={configSubMenu.idMenu} idTipoMenu={1} back={changeShowSub} showInformationOn={props.showInformationOn} setconfigCarrito={props.setconfigCarrito}/>
                    case 1: 
                        return <Favoritos changePage={setPage}/>
                    case -1:
                        return <ScanQR callwaiter={true} idTipoMenu={1} back={onBackPress} changeEmpresa={false} sendOrden={false} sendQueja={false}/>
                    }
                })()
            }
        </div>
    );
}