
import React, { useEffect, useState } from 'react';
//Styles
//import {MainColors} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';

//import {getCustomTextField} from '../Generics/TextField';
//import {getCustomButton} from '../Generics/CustomButton';
//import SearchBar from 'material-ui-search-bar';
import SearchList from './SearchList';
import SearchBar from './SearchBar';

//import {makeNormalDialog} from '../../utilscomponents/DialogAsk';
//import {getBoolStorage} from '../../../service/HelperStorage';
import {CriterioBusqueda_} from '../../Constants';

//import {statusDeliveryOrder,refreshTokenUser } from '../../../service/ManagerApis';

import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';

const useStyles = makeStyles(() => ({
  inline: {
    display: 'inline',
  },
  description: {
    textAlign: 'left',
    fontSize: '4vh',
    color: '#fff',
    margin: 'auto auto auto 5vw',
  },
  container : {
      width : '95vw',
      margin : '0.5vh 2vw',
      backgroundColor: 'transparent',// MainColors.secondary,
      borderRadius: '0.5vw',
  },
  textField: {
    width: '70vw',
    //height: '80vw',
    height: 'auto',
    margin: '6vh',
  },
  avatar:{
      margin: 'auto auto',
  },
  oculto:{
    visibility: 'hidden',
  },
}));

//const CommentTextField = getCustomTextField(MainColors.secondary);

const SearchForm = (props) => {
    //const [openBtn,setOpenBtn] = useState(false);
    const [indexAction,setIndexAction] = useState(0);
    const sty = useStyles();
    //const [infoForm,setInfoForm] = useState({comment:"",rating:0});

    
    const labelSearch = TranslateStr({token:"searchlabel"});
    const [searchEntry, setSearchEntry] = useState("");
    //const [searchHistory, setSearchHistory] = useState("");
    const searchHistory="";
    // update search text state
    // const updateSearchInput = e => {
    //     setSearchEntry(e.target.value);
    //     //setSearchHistory(e.target.value);
    // };

    const onInputComment = e => {
        //e.persist();
        //setSearchEntry(e.value);
        setSearchEntry(localStorage.getItem(CriterioBusqueda_));
        //setSearchHistory(e.value);
        console.log(localStorage.getItem(CriterioBusqueda_));
        //setInfoForm({comment:e.target.value,rating:infoForm.rating});
    };

    const handleClickOption = (index) => {
        //const history=searchEntry;
        
        if(indexAction===0){
            //setSearchEntry(localStorage.getItem(CriterioBusqueda_));
            if(searchEntry !== "" && localStorage.getItem(CriterioBusqueda_)!==""){
                //setSearchHistory(searchEntry);
                //console.log(searchHistory.toString());
                //if(index === 0 ){
                    changePage(index);
                    props.setShow(false);
                //}
            }
        }else{
            //setSearchEntry(localStorage.getItem(CriterioBusqueda_));
            changePage(-1);
            props.setShow(true);
        }
        // else{
        //     makeNormalDialog(<TranslateStr token="emptyfields"/>, <TranslateStr token="requiredComplaints"/>,null);
        //     setOpenBtn(false);
        // }
    }

    const changePage = (index) => {
        setIndexAction(index+1);
        if(index === 0){
            //setOpenBtn(false);
        }
    };

    // const handleOpen = () => {
    //     //Solo la opcion de restaurante por mientras
    //     handleClickOption(0);
    // };

    // const handleClose = () => {
    //     setOpenBtn(false);
    // }

    useEffect(() => {
        // if(getBoolStorage(login_)){
        //     refreshTokenUser(() => {});
        // }
    },[]);

    return ( 
        <div className={sty.root}>
        {
            (function() {
                switch(indexAction){
                    case 0:
                        return <div className={sty.container}> 
                                    <SearchBar
                                        onChange={onInputComment}
                                        onRequestSearch={() => handleClickOption(0)}
                                        style={{
                                            margin: '0 auto',
                                            // maxWidth: 800
                                        }}
                                        placeholder={labelSearch}
                                        value={searchEntry}
                                        cancelOnEscape
                                    />
                                </div>;
                    case 1:
                        return <SearchList idTipoMenu={props.idTipoMenu} showInformationOn={props.showInformationOn} show={true} setShow={props.setShow} setSearchEntry={setSearchEntry} search={searchHistory} back={handleClickOption} setconfigCarrito={props.setconfigCarrito}/>
                    default:
                        return;
                }
            })()
        }
        </div>
    )
};
export default SearchForm;
