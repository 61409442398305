import React,{useState, useEffect} from 'react';
//Components
import ItemOrder from './ItemOrder';
import {Grid} from '@material-ui/core';
//Apis
import {getAllOrderRestaurant} from '../../../../service/ManagerApis';
//Styles
import {MainColors} from '../../Sources/SourcesConst';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    titleStatus:{
        fontSize: '3vh',
        color: MainColors.secondary,
        textAlign: 'center',
        margin: '0.5vh',
        fontWeight: '450'
    },
    msgStatus:{
        color: '#273746',
        fontSize: '3vh',
        fontWeight: 500,
        margin: '0',
        textAlign: 'center',
        padding: '0.5vh',
    },
    containerTitle: {
        margin: '0 0 1.5vh 0'
    }
}));

export default function SelectAddress(){

    const classes =useStyles();
    const [listOrders,setListOrders] = useState([]);

    useEffect(() => {
        getAllOrderRestaurant(null,array => {
            setListOrders(array);
        });
    },[]);

    const getTotal = () => {
        var total = 0;
        listOrders.forEach(order => {
            total += order.precio;
        });
        return total;
    }

    return(
        <Grid container direction="column" justify="center" alignItems="center">
            <Grid className={classes.containerTitle} item container direction="row" justify="center" alignItems="center">
                <Grid item xs={3}>
                    <p className={classes.titleStatus}>Total</p>
                </Grid>
                <Grid item xs={9}>
                    <p className={classes.msgStatus}>{'$' + getTotal()}</p>
                </Grid>
            </Grid>
            {
                listOrders.map((order,index) => (
                    <ItemOrder key={index} dataOrder={order}/>
                ))
            }
        </Grid>
    )
}