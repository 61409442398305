import React, { useState } from 'react';
//Styles
import { makeStyles } from '@material-ui/core/styles';
import {MainColors} from '../Sources/SourcesConst';
//Components
import { Typography, Grid, Paper, Avatar, Snackbar } from '@material-ui/core';
import DetailDish from './DetailSubmenu/DetailDish';
import Alert from '@material-ui/lab/Alert';
//import AddIcon from '@material-ui/icons/Add';
//import PlusOne from '@material-ui/icons/PlusOne';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
//DB
import {formatoMexico,saveItemCarrito,deleteItemCarritomaxId,getInfoCarrito} from '../../../service/ManagerCarrito';
import {makeRequest} from '../../../service/ManagerRequest';
import {idLan_} from '../../Constants';
//Modal
import {useModal} from '../Generics/useModal';
import {useModalTopping} from '../Generics/useModalTopping';
import AddTopping from './Toppings/AddTopping';
//Swipe
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import ItemSwipeRight from './ItemSwipeRight';
import ItemSwipeLeft from './ItemSwipeLeft';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
    root: {
         flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
      //height: '100vh'
      //elevation: '3'
    },
    image: {
      width: '8vh',
      height: '8vh',
      margin: 'auto',
    },
    modalleft: {
      position: "absolute",
      zIndex: '16',
      bottom: '0vh',
      right: '3vh',
      fontSize: '9pt',
      fontWeight: 'bold',
      color: '#CB4335',
      opacity: '100%',
      background:'transparent',
      width:'137px',
      height: 'auto',
      textAlign:'left',
      //left: '50%',
      //transform: 'translate(-50%, -50%)',
    },
    modalright: {
      position: "absolute",
      zIndex: '16',
      bottom: '0vh',
      //right: '6vh',
      fontSize: '9pt',
      fontWeight: 'bold',
      color: '#CB4335',
      opacity: '100%',
      background:'transparent',
      width:'137px',
      height: 'auto',
      textAlign:'right',
      left: '3vh',
      //transform: 'translate(-50%, -50%)',
    },
    buttonTopping: {
        zIndex: '4',
        lineheight: '12px',
        width: '20px',
        fontSize: '1rem',
        fontFamily: 'tahoma',
        //margintop: '1px',
        // marginright: '2px',
        position: 'absolute',
        //top: theme.spacing(0),
        bottom: theme.spacing(5),
        //top: theme.spacing(1),
        left: theme.spacing(4),
        color: '#fff',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconComment: {
      fontSize: '1rem',
      fontFamily: 'tahoma',
      color: '#fff',
      backgroundColor: MainColors.main,
      '&:hover': {
        backgroundColor: MainColors.main,
      },
    },
  }));

export default function SubMenu(props) {
  const classes = useStyles();
  const {show,RenderModal} = useModal();
  const { hideTopping,showTopping,RenderModalTopping } = useModalTopping();
  const [expandedTopping, setExpandedTopping] = useState(false);
  const dataSub = props.dataItem;
  const idTipoMenu = props.idTipoMenu;
  const idIdioma = localStorage.getItem(idLan_);
  const [arrayTopping,setToppings] = useState([]);
  const [IDTopping,setidToppings] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [totalToppings, setTotaltoppings] = useState(0);
  
  const getTotales = () =>{
    var montoToppings = 0;
    setTotaltoppings(montoToppings);
  }

  const handleResTopping = (data,success,_id) =>{
    console.log('success: ' + success);
    if(success){
      // console.log(data);
      setToppings(data);
    }
  }

  const handleTopping = () => {
    makeRequest('application/json',null,'GET','masterdata/Productos/MisProductos/' + dataSub.idProducto + '/Topping?idIdioma=' + idIdioma + '&EsActivo=1',false,false,(dataRes,success) => handleResTopping(dataRes,success))

    setExpandedTopping(showTopping);
    return expandedTopping;
  }

  const handleCloseDelete = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenDelete(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const saveItem = () => {
    localStorage.setItem('idTipoOrden',props.idTipoMenu);
    const tieneTopping = dataSub.tieneTopping;
    saveItemCarrito(dataSub.idProducto,dataSub,false,(result) => {
      getInfoCarrito((totalR,countR) => {
        props.setconfigCarrito(countR);
      });
      setOpenSuccess(true);
      if(tieneTopping)
      {
        console.log('Tiene topping: ' + tieneTopping);
        setidToppings(result);
        handleTopping();
      }
    });
  }

  const deleteItem = () => {
    deleteItemCarritomaxId(dataSub.idProducto,(result) => {
      getInfoCarrito((totalR,countR) => {
        props.setconfigCarrito(countR);
      });
      setOpenDelete(true);
    });
  }

  return (
    <SwipeableListItem
        className={classes.container}
        swipeLeft={{
        content: <ItemSwipeLeft/>,
        action: () => deleteItem()
        }}
        swipeRight={{
        content: <ItemSwipeRight/>,
        action: () => saveItem()
        }}
    >

      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid onClick={show} item xs={3}>
              <Avatar className={classes.image} alt="Menu Photo" src={dataSub.imagenUri} />
            </Grid>
            <Grid onClick={show} item xs={9} sm container>
              <Grid item xs container direction="column" spacing={1}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1">
                    {dataSub.nombreSubMenu}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {dataSub.nombreProducto}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">{'$' + formatoMexico(dataSub.precio)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <RenderModalTopping>
          <AddTopping
            dataSub={dataSub}
            idTipoMenu={1}
            arrayTopping={arrayTopping}
            ID={IDTopping}
            total={parseFloat(dataSub.precio)}
            totalToppings={parseFloat(totalToppings)}
            getTotales = {getTotales}
            setTotaltoppings = {setTotaltoppings} 
            origen = {1}           
          />
        </RenderModalTopping>
        <RenderModal >
          <DetailDish dataSub={dataSub} idTipoMenu={idTipoMenu} setconfigCarrito={props.setconfigCarrito} isTopping={false}/>
        </RenderModal>
        <Snackbar open={openSuccess} autoHideDuration={1500} onClose={handleCloseSuccess}>
          <Alert onClose={handleCloseSuccess} severity="success">
            <TranslateStr token="addorder"/>
          </Alert>
        </Snackbar>
        <Snackbar open={openDelete} autoHideDuration={1500} onClose={handleCloseDelete}>
          <Alert onClose={handleCloseDelete} severity="warning">
            <TranslateStr token="removeorder"/>
          </Alert>
        </Snackbar>
      </div>
    </SwipeableListItem>
  );
}