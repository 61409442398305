import {getStringStorage, getBoolStorage} from '../../../../service/HelperStorage';
import {lan_,idLan_, isBilingual_} from '../../../Constants';
import {dictionary} from './dictionary';

export default function TranslateStr({token}) {
    //console.log(token);
    var selectedLanguage = getStringStorage(lan_);
    var Isbilingual = getBoolStorage(isBilingual_); 
    if(selectedLanguage === ""){
        selectedLanguage = "ES";
        localStorage.setItem(idLan_,1);
        localStorage.setItem(lan_,"ES");
    }

    if(!Isbilingual){
        selectedLanguage = "ES";
        localStorage.setItem(idLan_,1);
        localStorage.setItem(lan_,"ES");
    }
    
    const translatedToke = dictionary[selectedLanguage][token] || dictionary["ES"][token];
    //console.log(translatedToke);
    return translatedToke;
}