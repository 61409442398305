import React,{useState, useEffect} from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import TranslateStr from '../../ConfigPage/HelperLanguage/TranslateStr';
import {idLan_} from '../../../Constants';
import ListComments from './ListComments';

import {useModalTopping} from '../../Generics/useModalTopping';
import AddTopping from '../Toppings/AddTopping';
import {formatoMexico,saveItemCarrito,deleteItemCarrito,getInfoCarrito,itemCarritobyIdProd,
   itemCarritoById} from '../../../../service/ManagerCarrito';
import {Service} from '../../../../service/DBService';
import {makeRequest} from '../../../../service/ManagerRequest';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90vw',
    height: 'auto',
    maxWidth: '60vh',
    padding: '5px 0 0 0',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  alerta: {
    //zIndex: 10,
    //color: '#fff', // '#000', //'#fff',
    //backgroundColor:MainColors.secondary, // '#fff', //'transparent',
    //padding: '5px 0 0 5px',
    //padding: '4vh 0 0 5vw',
    //top: '14vh',
    bottom:'12vh',
    //left: '53vh',
    //right: '3vh',
    position: 'relative',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  comment: {
    margin: '0 0 0 2vh',
  },
  listcomment: {
    padding: '1vh',
  },
  cantidad: {
    width: '100%',
    height: '100%',
    fontSize: '3vh',
    fontWeight:'450',
    textAlign: 'center',
    padding: '1vh',
    fontStyle: 'bold',
    color: '#117A65',
  },
  des: {
    margin: '2vh 0',
  },
  price:{
    color: '#1ABC9C',
    fontWeight: '500',
    fontSize: '5vh',
  },
  total:{
    marginTop: '2vh',
    width: '100%',
    color: '#1ABC9C',
    fontWeight: '450',
    fontSize: '3vh',
    alignment: 'center',
    textAlign: 'center',
  },
  toppingBtn: {
    //zIndex: 10,
    color: '#fff',
    fontSize: '10pt',
    //fontWeight: 'bold',
    //color: '#CB4335',
    //opacity: '100%',
    //background:'transparent',
    //width:'137px',
    //height: 'auto',
    textAlign: 'center',
    outline: 'none',
  },
}));

const DeleteBtn = withStyles(() => ({
  root: {
    color: '#E74C3C',
    backgroundColor: '#FBFCFC',
    '&:hover': {
      backgroundColor: '#FBFCFC',
    },
    //left: '2vh',
  },
}))(Button);

const AddBtn = withStyles(() => ({
  root: {
    color: '#5499C7',
    backgroundColor: '#FBFCFC',
    '&:hover': {
      backgroundColor: '#FBFCFC',
    },
  },
}))(Button);

const AddBtnToppingd = withStyles(() => ({
  root: {
    color: '#5499C7',
    backgroundColor: '#FBFCFC',
    '&:hover': {
      backgroundColor: '#FBFCFC',
    },
  },
}))(Button);

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [cost,setCost] = useState({total:0,cantidad:0});
  const dataSub = props.dataSub;
  const price = dataSub.precio;
  
  const [openDelete, setOpenDelete] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);

  const { hideTopping,showTopping,RenderModalTopping } = useModalTopping();
  const [expandedTopping, setExpandedTopping] = useState(false);
  const [IDTopping,setidToppings] = useState(0);
  const [arrayTopping,setToppings] = useState([]);
  const [totalToppings, setTotaltoppings] = useState(0);
  const idIdioma = localStorage.getItem(idLan_);

  const viewToppings = () => {
    //console.log('Tiene topping... ');
    //console.log (dataDish.id);
    //etidToppings(id_);
    //setidToppings(dataDish.id);
    itemCarritobyIdProd(dataSub.idProducto, (itemCarrito) => {
      if(itemCarrito.length>0){
        const itemorder = itemCarrito.sort(((a, b) => {
          return b.id - a.id;
        }));
          
        const ind = itemorder[0].id;
        setidToppings(ind);
        handleTopping();
      }
    });
  }

  const handleResTopping = (data,success,_id) => {
    //console.log('success: ' + success);
    if(success){
      setToppings(data);
    }
  }

  const handleTopping = () => {
    makeRequest('application/json',null,'GET','masterdata/Productos/MisProductos/' + dataSub.idProducto + '/Topping?idIdioma=' + idIdioma + '&EsActivo=1',false,false,(dataRes,success) => handleResTopping(dataRes,success))

    setExpandedTopping(showTopping);
    return expandedTopping;
  }

  const handleCloseDelete = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenDelete(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleErrorSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSuccess(false);
  };

  const getTotales = () =>{
    var montoToppings = 0;
    Service.getidx('ItemCarrito','idProducto',dataSub.idProducto).then(item=>{
      console.log('busca idx'); 
      console.log(item);
      if(item !== undefined){
        setCost({total:item.length * price,cantidad:item.length});
        if(item.length>0){
          montoToppings = item.map(t => t.totalToppings).reduce((a, c) => { return a + c });
        }
        setTotaltoppings(montoToppings);
      }
    });
  }

  useEffect(() => {
    //console.log(dataSub.tieneTopping);
    getTotales();
    
  },[]);

  const saveItem = () => {
    //const idTipoMenu = props.idTipoMenu;
    const tieneTopping = dataSub.tieneTopping;
  
    localStorage.setItem('idTipoOrden',props.idTipoMenu);
    saveItemCarrito(dataSub.idProducto,dataSub,false,(result) => {
      getInfoCarrito((totalR,countR) => {
        //console.log('Cont Carrito:' + countR);
        props.setconfigCarrito(countR);
      });

      if(tieneTopping)
      {
        //console.log('Tiene topping: ' + tieneTopping);
        setidToppings(result);
      }

    });
    
    setOpenSuccess(true);
    
  }

  const deleteItem = () => {
    Service.getidx('ItemCarrito','idProducto',(dataSub.nombreMenu == 'Toppings'? dataSub.idProductoTopping : dataSub.idProducto)).then(item=>{
      const itemorder = item.sort(((a, b) => {
        return b.id - a.id;
      } ));
      console.log('Elimina último registro del producto...');
      console.log(itemorder);
      deleteItemCarrito(itemorder[0].id,(result) => {
        getInfoCarrito((totalR,countR) => {
          //console.log('Cont Carrito:' + countR);
          props.setconfigCarrito(countR);
        });
      });
    });
    setOpenDelete(true);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePlus = () => {
    if(!props.isTopping){
      const cantiad_ = cost.cantidad + 1;
      saveItem();
      setCost({ total: cantiad_ * price, cantidad: cantiad_ });
    }
    else{
      console.log('Es topping: ' + props.isTopping);
      itemCarritoById(dataSub.idProducto,(item) => {
        if(item !== undefined){
          const cantiad_ = cost.cantidad + 1;
          saveItem();
          setCost({ total: cantiad_ * price, cantidad: cantiad_ });
        }
        else{
          console.log('¡Debe agregar producto correspondiente!');
          //setCost({ total: cost.cantidad * price, cantidad: cost.cantidad });
          setErrorSuccess(true);
        }
      });
    }
  }

  const handleLess = () => {
    if(cost.cantidad > 0){
      deleteItem();
      const cantiad_ = cost.cantidad-1;
      setCost({total:cantiad_*price,cantidad:cantiad_});
      
    }
  }

  return (
    
    <Card className={classes.root}>
        <CardHeader
          title={dataSub.nombreSubMenu}
          subheader={dataSub.nombreMenu}>
        </CardHeader>
        <RenderModalTopping>
          <AddTopping
            dataSub={dataSub}
            idTipoMenu={1}
            arrayTopping={arrayTopping}
            ID={IDTopping}
            total={parseFloat(cost.total)}
            totalToppings={parseFloat(totalToppings)} 
            getTotales = {getTotales}
            setTotaltoppings = {setTotaltoppings}
            origen = {2}  
          />
        </RenderModalTopping>
        <CardMedia className={classes.media} image={dataSub.imagenUri} title="dish">
        </CardMedia>
        <CardContent className={classes.cardcont}>
          <Typography className={classes.des} variant="body2" color="textPrimary" component="p">
            {dataSub.nombreProducto}
          </Typography>
          <Grid container direction="row" justify="center" alignItems="center">
            <p className={classes.price}>{'$' + formatoMexico(price)}</p>
          </Grid>
          {/* variant="outlined"  */}
          <Grid container justify="space-around" alignItems="center" direction="row">
            <Grid item xs={5}>
              <DeleteBtn
                onClick={handleLess}
                variant="contained"
                color="secondary"
                startIcon={<ArrowBackIosIcon />}
              >
                <TranslateStr token="remove" />
              </DeleteBtn>
            </Grid>
            <Grid item xs={2}>
              <div className={classes.contcantidad}>
                <Typography className={classes.cantidad} color="textPrimary" component="p">
                  {cost.cantidad}
                </Typography>
              </div>
              <Snackbar open={errorSuccess} autoHideDuration={1500} onClose={handleErrorSuccess}>
                <Alert className={classes.alerta} onClose={handleErrorSuccess} severity="warning">
                  <TranslateStr token="errorTopping" />
                </Alert>
              </Snackbar>
              <Snackbar open={openSuccess} autoHideDuration={1500} onClose={handleCloseSuccess}>
                <Alert className={classes.alerta} onClose={handleCloseSuccess} severity="success">
                  <TranslateStr token="addorder" />
                </Alert>
              </Snackbar>
              <Snackbar open={openDelete} autoHideDuration={1500} onClose={handleCloseDelete}>
                <Alert className={classes.alerta} onClose={handleCloseDelete} severity="warning">
                  <TranslateStr token="removeorder" />
                </Alert>
              </Snackbar>
            </Grid>
            <Grid item xs={5}>
              <AddBtn
                onClick={handlePlus}
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIosIcon />}
              >
                <TranslateStr token="add" />
              </AddBtn>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid justify="center" alignItems="center">
              <AddBtnToppingd hidden={!dataSub.tieneTopping || cost.cantidad == 0}
                onClick={viewToppings}
                variant="contained"
                color="primary"
                className={classes.toppingBtn}
              >
                {totalToppings == 0 ? '+ Toppings' : '+ Toppings $' + formatoMexico(totalToppings)}
              </AddBtnToppingd>
              <p className={classes.total}>{'Total $' + formatoMexico((cost.total + totalToppings))}</p>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions disableSpacing>

          <Typography className={classes.comment} variant="body2" color="textPrimary" component="p">
            <TranslateStr token="comments" />
          </Typography>

          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Comentarios"
          >
            <ExpandMoreIcon />
          </IconButton>

        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={classes.listcomment}>
            <ListComments idProducto={dataSub.idProducto} />
          </CardContent>
        </Collapse>
      </Card>
  );
}