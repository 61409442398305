import React,{ useEffect, useState }from 'react';
//Components
//import AddComent from './Comments/AddComment';
import {
    Collapse,
    Chip,Typography,
    Grid,
    Paper,
    Avatar,
    IconButton,
    Snackbar,
    Divider
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

//DB
import {formatoMexico,decimalAdjust,deleteComment,saveItemTopping, getInfoTopping, 
    deleteItemTopping,getInfoCarrito} from '../../../../../service/ManagerCarrito';
import {Service} from '../../../../../service/DBService';
//API
//import {makeRequest} from '../../../../service/ManagerRequest';
//Styles
import {MainColors} from '../../../Sources/SourcesConst';
import {makeStyles} from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
//Modal
//import {useModal} from '../Generics/useModal';
//import {useModalTopping} from '../Generics/useModalTopping';
///import AddTopping from './Toppings/AddTopping';
//Swipe
//import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
//import ItemSwipeRight from '../Submenus/ItemSwipeRight';
//import ItemSwipeLeft from '../Submenus/ItemSwipeLeft';

//import TranslateStr from '../../../ConfigPage/HelperLanguage/TranslateStr';
import {idLan_, color_main_, color_secondary_} from '../../../../Constants';

export default function ItemTopping(props){
    //const Idioma = localStorage.getItem(idLan_);
    const dataDish = props.data;
    //const ID = props.ID;
    const sty = useStyles();
    //const { hide,show,RenderModal } = useModal();
    //const { hideTopping,showTopping,RenderModalTopping } = useModalTopping();
    const [expanded, setExpanded] = useState(false);
    const [expandedTopping, setExpandedTopping] = useState(false);
    const [openRemoveSwipe, setOpenRemoveSwipe] = useState(false);
    const [openAddSwipe, setOpenAddSwipe] = useState(false);
    const [dataComment,setDataComment] = useState({index:0,dataDish: []});
    const [arrayTopping,setToppings] = useState([]);
    const [IDTopping,setidToppings] = useState(0);
    const [costItem,setCostItem] = useState({total:0,cantidad:0});
    const [subTotalCar, setSubtotalCar] = useState(props.subTotal);

    // const handlePlus = () => {
    //     saveDish();
    //     const cantad_ = costItem.cantidad+1;
    //     //const subtotal = subTotalCar;
    //     //console.log('Agrega topping...');
    //     //console.log(dataDish);
    //     //console.log('index:' + index);
    //     //console.log ('Cant. total: ' + cantad_);
    //     setCostItem({total:cantad_*dataDish.precioDescuento,cantidad: cantad_});
    //     props.setCostTotal(props.subTotal + dataDish.precioDescuento);

        
    //     //saveDish();
    //     //console.log('Total producto: ' + (subTotalCar ));
    //     //console.log('Total item: ' + (costItem.total));
    //     //console.log('Subtotal: ' + (subTotalCar + (cantad_*dataDish.precioDescuento)));
    //     //props.setSubtotal( subTotalCar + (cantad_*dataDish.precioDescuento));
    //     //setCost({total:cantiad_*price,cantidad:cantiad_})
    //     //props.getToppings();
    //     //props.reload();
    // }
    
    // const handleLess = () => {
    //     //props.reloadTopping();
    //     if(costItem.cantidad > 0){
    //         deleteDish();
    //         const cantad_ = costItem.cantidad-1;
    //         setCostItem({total:cantad_*dataDish.precioDescuento,cantidad: cantad_});
    //         props.setCostTotal(props.subTotal - dataDish.precioDescuento);
           
    //       //props.setCostTotal(props.costTotal - dataDish.precioDescuento);
    //       //props.setSubtotal(props.costTotal - dataDish.precioDescuento);
    //       //props.reloadTopping();
    //     }
    //     //console.log('index:' + props.key);
    //     //console.log ('Cant. total: ' + dataDish[props.key].cantidad);
    // }

    //topping handlers
    // const handleResTopping = (data,success,_id) =>{
        
    //     console.log('success: ' + success);
    //     if(success){
    //         //for (var i = 0; i < data.length; i++) {
    //         data.forEach(element => {
    //             element.checked = false;
    //             element.cantidad = 0;
    //             Service.getidx('ItemTopping','idTopping',_id).then(item=>{
    //                 console.log('busca idx'); 
    //                 console.log(item);
    //                 element.cantidad = item.length;
    //                 if(item !== undefined && item.length!=0){
    //                     element.checked = true;
    //                 }
    //                 else{
    //                     element.checked = false;
    //                 }
    //             });
    //         });
    //         console.log(data);
    //         setToppings(data);
    //         //RenderModal(true);
    //     }
    // }

    
    
    //swipe handlers
    const handleCloseSwipeAdd = (event,reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAddSwipe(false);
    }
    const handleCloseSwipeRemove = (event,reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenRemoveSwipe(false);
    }

    //Collapse
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    //chip handlers
    const handleDeleteChip = (index) => {
        deleteComment(index,dataDish.id,() => {
            props.reload();
        });
    };

    const handleClickChip = (index) => {
        //show();
        //setDataComment({index:index,dataDish:dataDish});
    };

    const saveCommentChip = () => {
        props.reload();
    }

    //handlers db
    // const saveDish = () => {
    //     // console.log('saveTopping...');
    //     // console.log(dataDish);
    //     //const tieneTopping = dataDish.tieneTopping;

    //     saveItemTopping(ID,dataDish,false,(result) => {
    //         console.log('Topping agregado...');
    //         // setOpenAddSwipe(true);
    //         // getInfoCarrito((totalR,countR) => {
    //         //     props.setconfigCarrito(countR);
    //         // });
    //         //props.reloadTopping();
    //         //props.reload();
    //         // if(tieneTopping)
    //         // {
    //         //     console.log('Tiene topping: ' + tieneTopping);
    //         //     setidToppings(result);
    //         //     handleTopping(result);
    //         // }
    //         getInfoCarrito((totalR,countR,toppingsR) => {
    //             //localStorage.setItem(countCarrito_,countR);
    //             //setInfoOrder({total:totalR,count: countR,readyLogin:true});
                
    //             props.setTotaltoppings({monto:toppingsR});
    //             props.reload();
    //             //setmontoToppings({monto: toppingsR});
             
    //         });
    //     });
    // }

    // const deleteDish = () => {
    //     deleteItemTopping(ID,dataDish.idProductoTopping,(result) => {
    //         console.log('Topping eliminado...');
    //         getInfoCarrito((totalR,countR,toppingsR) => {
    //             //localStorage.setItem(countCarrito_,countR);
    //             //setInfoOrder({total:totalR,count: countR,readyLogin:true});
                
    //             props.setTotaltoppings({monto:toppingsR});
    //             props.reload();
    //             //setmontoToppings({monto: toppingsR});
             
    //         });
    //         // setOpenRemoveSwipe(true);
    //         // getInfoCarrito((totalR,countR) => {
    //         //     props.setconfigCarrito(countR);
    //         // });
    //         //props.reload();
    //         //props.reloadTopping();
    //     });
    //     //props.reloadTopping();
    // }

    useEffect(() => {
        //props.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
        //setCost({total:dataDish.cantidad * dataDish.precioDescuento,cantidad:dataDish.cantidad});
        // getInfoTopping(ID,dataDish.idProductoTopping,(totalR,countR) => {
        //     //console.log('getInfoTopping...');
        //     //console.log('ID: ' + ID);
        //     //console.log('PRODUCTO: ' + dataDish.idProductoTopping);
        //     setCostItem({total:countR*dataDish.precioDescuento,cantidad: countR});
        // });
    },[]);

    return(
        // <SwipeableListItem
        //     className={sty.container}
        //     swipeLeft={{
        //     content: <ItemSwipeLeft/>,
        //     action: () => deleteDish()
        //     }}
        //     swipeRight={{
        //     content: <ItemSwipeRight/>,
        //     action: () => saveDish()
        //     }}
        // >
            <div className={sty.root}>
                <Paper className={sty.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Avatar className={sty.image} alt="Menu Photo" src={dataDish.imageUri} />
                        </Grid>
                        <Grid item xs={9} container direction="column">
                            <Grid item xs container direction="row">
                                <Grid item xs={9}>
                                    <Typography className={sty.title}>
                                        {dataDish.subMenu}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography className={sty.price}  >{'$' + formatoMexico(dataDish.precio)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs container direction="row" justify="space-between" alignItems="center">
                                <Grid item>
                                    <p className={sty.cantidad} style={{color: localStorage.getItem(color_secondary_)}}>{dataDish.cantidad}</p>
                                </Grid>
                                <Grid item>
                                    <p className={sty.total} style={{color: localStorage.getItem(color_main_)}}>{'+'}</p>
                                </Grid>
                                <Grid item>
                                    <p className={sty.total} style={{color: localStorage.getItem(color_main_)}}>{"$" + formatoMexico(decimalAdjust('round',parseFloat(dataDish.cantidad*dataDish.precio),-2))}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid container direction="column">
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <Divider className={sty.divider} variant="middle" />
                                <Grid item xs container direction="row" justify="space-between" alignItems="center" >
                                    <Grid item xs={6}>
                                        <p className={sty.indicaciones} style={{color: localStorage.getItem(color_secondary_)}}><TranslateStr token="indications"/></p>
                                    </Grid>
                                    <Grid item sx={2}>
                                        {
                                            dataDish.comments.length < dataDish.cantidad &&
                                            <IconButton onClick={() => handleClickChip(-1)} size="small">
                                                <AddIcon />
                                            </IconButton>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <div className={sty.chipContainer}>
                                        {
                                            dataDish.comments.map((comment,index) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        className={sty.chip}
                                                        style={{color: localStorage.getItem(color_secondary_)}}
                                                        variant="outlined"
                                                        size="small"
                                                        label={comment}
                                                        onClick={() => handleClickChip(index)}
                                                        onDelete={() => handleDeleteChip(index)}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </Grid>
                            </Collapse>
                        </Grid> */}
                    </Grid>
                </Paper>

                {/* <RenderModalTopping>
                    <AddTopping 
                    dataSub={dataDish} 
                    idTipoMenu={1}
                    arrayTopping={arrayTopping}
                    ID = {IDTopping}
                    total = {parseFloat(dataDish.cantidad*dataDish.price)}
                    />
                </RenderModalTopping> */}

                {/* <RenderModal>
                    <AddComent hide={hide} save={saveCommentChip} index={dataComment.index} dataDish={dataComment.dataDish}/>
                </RenderModal>

                <Snackbar open={openAddSwipe} autoHideDuration={1500} onClose={handleCloseSwipeAdd}>
                        <Alert onClose={handleCloseSwipeAdd} severity="success">
                            <TranslateStr token="addorder"/>
                        </Alert>
                </Snackbar>
                <Snackbar open={openRemoveSwipe} autoHideDuration={1500} onClose={handleCloseSwipeRemove}>
                        <Alert onClose={handleCloseSwipeRemove} severity="warning">
                            <TranslateStr token="removeorder"/>
                        </Alert>
                </Snackbar> */}
            </div>
        // </SwipeableListItem>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100% !important',
    },
    root: {
         flexGrow: 1,
         margin: '0.4vh 0.4vh 0 0.4vh'
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
      border: '0.2vh solid #EBEDEF',
    },
    image: {
      width: '8vh',
      height: '8vh',
      margin: 'auto',
    },
    iconComment: {
        //color: MainColors.secondary,
        backgroundColor: '#fff',
    },
    iconBorder: {
        border: '0.2vh solid #EBEDEF',
    },
    toppingBtn: {
        backgroundColor: MainColors.main,
        color: '#fff',
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(7),
        zIndex: '16',
        //fontSize: '9pt',
        //fontWeight: 'bold',
        //color: '#CB4335',
        //opacity: '100%',
        //background:'transparent',
        //width:'137px',
        //height: 'auto',
        textAlign:'left',
        bordercolor: '#fff',
    },
    chip: {
        color: MainColors.secondary,
    },
    cantidad: {
        //color: MainColors.secondary,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    total: {
        //color: MainColors.main,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2vh',
        marginBottom: '0',
    },
    price: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2.5vh',
        marginBottom: '0',
        textAlign: 'right',
    },
    divider: {
        margin: '1vh',
    },
    chipContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
        margin: theme.spacing(0.5),
        },
    },
    indicaciones: {
        //color: MainColors.secondary,
        fontSize: '2.5vh',
        fontWeight: 'light',
        textAlign: 'left',
        marginBottom: '0',
    },
}));