import React, { useEffect, useState } from 'react';
//DB
import {allItemCarrito} from '../../../../service/ManagerCarrito';
//Components
import ItemToSend from './ItemToSend';
import {List} from '@material-ui/core';
//Styles
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height:'100%',
        overflow: 'hidden', 
        'overflow-y' : 'scroll',
    },
});

export default function ListToSend(props){

    const sty = useStyles();
    const [arrayCarrito,setArrayCarrito] = useState([])

    useEffect(() => {
        allItemCarrito((array) => {
            setArrayCarrito(array);
        });
    },[]);

    return(
        <List className={sty.root}>
            {
                arrayCarrito.map((item,index) => (
                    <ItemToSend
                        key={index}
                        data={item}
                    />
                ))
            }
        </List>
    )
}