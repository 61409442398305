import React,{ useState,useEffect }  from 'react';
import List from '@material-ui/core/List';
import CommentDish from './CommentDish';
import {makeRequest} from '../../../../service/ManagerRequest';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1vh',
        width: '100%',
        background: theme.palette.white,
        overflow: 'auto',
        maxHeight: 300,
        position: 'relative',
    }
}));

export default function ListComments(props){
    const [arrayCommets,setCommets] = useState([]);
    const sty = useStyles();

    const handleResponse = (data,success) => {
        if(success){
            console.log(data);
            setCommets(data.comentarios);
        }
    }

    useEffect(() => {
        makeRequest(
            'application/json',
            {idProducto:props.idProducto},
            'GET',
            'masterdata/getProducto',
            false,
            false,
            (data,success) => handleResponse(data,success));
    },[]);

    return(
        <List className={sty.root}>
            {
                arrayCommets.map(comment => (
                    <CommentDish
                        key={arrayCommets.indexOf(comment)}
                        dataComment={comment}
                    />
                ))
            }
        </List>
    )
}
