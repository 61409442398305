import React, { useState } from 'react';
//Apis
import {searchAddress} from '../../../../service/ManagerApis';
//Styles
import { makeStyles,withStyles } from '@material-ui/core/styles';
import {MainColors} from '../../Sources/SourcesConst';
//Components
import { confirmAlert } from 'react-confirm-alert';
import {Grid,Button,TextField,CircularProgress} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const usestyles = makeStyles(() => ({
    container: {
        width: '90vw',
        height: '60vh',
        backgroundColor: '#fff',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        margin: '3vh',
        textAlign: 'center',
    },
    textContent:{
        width: '80%',
        margin: '2vh 0',
    },
}))


const CssTextField = withStyles({
    root: {
        width: '80%',
        margin: '2vh',
        '& label.Mui-focused': {
        color: MainColors.secondary,
     },
      '& .MuiInput-underline:after': {
        color: MainColors.secondary,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: MainColors.secondary,
        },
        '&:hover fieldset': {
          borderColor: MainColors.secondary,
        },
        '&.Mui-focused fieldset': {
          borderColor: MainColors.secondary,
        },
      },
    },
})(TextField);

const ColorButton = withStyles((theme) => ({
    root: {
      color: MainColors.secondary,
      fontSize: '3vh',
      fontWeight: 'bold',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
}))(Button);

export default function AddAddress(props){

    const sty = usestyles();

    const [street,setStreet] = useState("");
    const [nota,setNota] = useState("");
    const [idCiudad,setIdCiudad] = useState("");

    const [openSearchBox, setOpenSearchBox] = useState(false);
    const [settingsSearch,setSettingsSearch] = useState({loading:false,options:[]});

    const showDialog = (title, subTitle) => {
        confirmAlert({
            title: title,
            message: subTitle,
            buttons: [],
    })};

    const onInputStreet = (e) => {
        e.persist();
        setStreet(e.target.value);
    }

    const onInputNota = (e) => {
        e.persist();
        setNota(e.target.value);
    }

    const onInputSearch = (e) => {
        console.log('entra');
        e.persist();
        setSettingsSearch({loading:true,options:[]});
        searchAddress(e.target.value,(array) => {
            setSettingsSearch({loading:false,options:array});
        })
    }

    const setSelected = (e,value,reason) => {
        e.persist();
        var idDomi = '';
        settingsSearch.options.forEach(element => {
            const itemSub = element.substring(8,element.length-1);
            console.log(itemSub);
            if(itemSub === value){
                var arraySplit = element.split(',');
                idDomi = arraySplit[0];
            }
        });
        console.log(idDomi);
        setIdCiudad(idDomi);
    }

    const addDomi = () => {
        if(street !== "" && nota !== "" && idCiudad !== ''){
            props.save(idCiudad,street,nota);
        }else{
            showDialog('Campos faltantes','Por favor verifique que su información esté completa')
        }
    }

    return(
        <Grid className={sty.container} container direction="column" alignItems="center" justify="center">
                
                <p className={sty.title}>Agregue su nueva dirección</p>

                <CssTextField value={street} onChange={onInputStreet} id="outlined-basic" label="Calle y número" variant="outlined" size="small"/>

                <Autocomplete
                    id="asynchronous-demo"
                    className={sty.textContent}
                    open={openSearchBox}
                    onOpen={() => {
                        setOpenSearchBox(true);
                    }}
                    onClose={() => {
                        setOpenSearchBox(false);
                    }}
                    onInputChange={setSelected}
                    getOptionSelected={(option, value) => value === option}
                    getOptionLabel={(option) => option === undefined ? "" : option.substring(8,option.length-1)}
                    options={settingsSearch.options}
                    loading={settingsSearch.loading}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Busque su colonia"
                        variant="outlined"
                        size="small"
                        onChange={onInputSearch}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                            <React.Fragment>
                                {settingsSearch.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                            ),
                        }}
                        />
                    )}
                />

                <CssTextField value={nota} onChange={onInputNota} id="outlined-basic2" label="Referencia" variant="outlined" size="small"/>

                <ColorButton onClick={addDomi} variant="contained">
                    Guardar
                </ColorButton>
        </Grid>
    )
}