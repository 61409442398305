import React,{useState, useEffect} from 'react';
//Components
import ItemNoOrder from './ItemNoOrder';
//import StatusOrden  from '../StatusOrden';
import { isDelivery_, isIdOrden_ , nameUser_, login_, idEmpresa_, ValidacionPorUsuario_, color_accent_, color_main_} from '../../../Constants';
import {List,Grid} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
//import CachedIcon from '@material-ui/icons/Cached';
import {allItemOrders} from '../../../../service/ManagerOrders';
//import {makeRequest } from '../../../../service/ManagerRequest';
import {
    signalRConnectionBuild, 
    notifyCallCheck } from '../../../../service/ManagerApis';

import TranslateStr from '../../ConfigPage/HelperLanguage/TranslateStr';
//import Translator from '../../ConfigPage/HelperLanguage/Translator';
//import {MainColors} from '../../Sources/SourcesConst';
import {getBoolStorage} from './HelperArray';
//import { array } from 'prop-types';
const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: 'transparent',
      width: '100vw',
    },
    title: {
        fontSize: '3.5vh',
        padding: '1vh',
        textAlign: 'center',
        margin: '0', //1rem',
        //color: MainColors.main,
    },
    tab: {
        //color: MainColors.accent,
        //Cambiar con base en la cantidad de pestañas
        width: '50vw',
        textDecoration: 'none !important',
        '&hover' : {
          textDecoration: 'none',
        }
    },
    msgStatus:{
        color: '#273746',
        fontSize: '3vh',
        fontWeight: 350,
        margin: '0',
        textAlign: 'center',
        padding: '0',
    },
    createImg:{
        margin: '0 1vh 0 0',
        width: '5vh',
        height: '5.3vh',
    },
    createImgAvatar:{
        width: '100%',
        height: '100%',
        padding: '0vh',
        //color: MainColors.secondary,
        backgroundColor: '#fff',
        textAlign: 'center',
    },
    img:{
        margin: '0',
        width: '4vh',
        height: '4vh',
    },
    labelOption:{
        textAlign: 'start',
        //color: MainColors.accent,
        fontSize: '3vh',
        margin: '0',
        padding: '0',
    },
    modal: {
        fontSize: '9pt',
        fontWeight: 'bold',
        color: '#CB4335',
        zIndex: 3,
        opacity: '100%',
        background:'transparent',
        width:'90px',
        height: 'auto',
        position: 'fixed',
        //top: '12%',
        textAlign:'match-parent',
        //left: '27%',
        //transform: 'translate(-50%, -50%)',
      },
      iconNav: {
        height : '5vh',
        width: '5vh',
        color: '#fff',
        //backgroundColor: MainColors.secondary,
        marginBottom: '1vh',
      },
  }));

export default function ListNoOrders(props){
    const classes = useStyles();
    const isDelivery = getBoolStorage(isDelivery_);
    const isOrder = getBoolStorage(isIdOrden_);
    
    const validarUser = getBoolStorage(ValidacionPorUsuario_);
    const isLogin = getBoolStorage(login_);
    const UserName = (!validarUser && !isLogin) ? 'Mesa General': localStorage.getItem(nameUser_) ;
    //const UserName = localStorage.getItem(nameUser_) ;
    //const [seconds,setSeconds] = useState(30);
    const [settingsOrder,setSettingsOrder] = useState({
        idOrder: 0,
        status:0,
        dataOrder: null,
        arrayOrders:[],
        totalOrder:0.0,
        totalUser:-1
    });

    //const ArrayOrdenes = props.ArrayOrdenes;
    const [ArrayOrdenes,setArrayOrdenes]=useState([]);
    //const [ArrayOrdenesDB,setArrayOrdenesDB]=useState([]);
    //var DiffArray=0

    const loadOrdenes = () => {
        props.getOrdenesDB();
        getOrdenes();
        setSettingsOrder({
            //idOrder: 0,
            status:0,
            dataOrder: null,
            arrayOrders: [] ,
            totalOrder:0.0,
            totalUser:-2
        });
        //props.setIndexAction(1);
    }

    const getOrdenes = () => {
        console.log('isOrder');
        console.log(isOrder);
        // if(!validarUser){
        //     UserName='Mesa General';
        // }
        if(isOrder && !isDelivery ){
            

            allItemOrders( array => {
                setArrayOrdenes(array);
            });
            console.log('TOTAL ORDENES');
            console.log(ArrayOrdenes.length);

            
            
        }
    }

    
    // const getOrdenesDB = () => {
    //         allItemOrdersDB( arrayDB => {
    //             setArrayOrdenesDB(arrayDB);
    //         });
    //     console.log('OrdenesDB ' + ArrayOrdenesDB.length)
    //     return ArrayOrdenesDB.length;
    // }
    
   
    useEffect(() => {

        // signalRConnectionBuild(async _hubConnection => {
        //     await _hubConnection.start(); 
        //     console.assert(_hubConnection.state === "Connected");
        //     console.log("SignalR Connected.");

        //     // // Escuchar eventos del websocket
        //     // _hubConnection.on("notifywpatowaiter", function (result) {

        //     //     const notif = result[0];
        //     //     if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
        //     //     if (parseInt(localStorage.getItem(idMesa_)) !== notif.idMesa) return;

        //     //     if (notif.comando === 'solicitud-cuenta'){
        //     //         console.log('notif', notif);
        //     //     }

        //     // });

        //     _hubConnection.on("notifytouser", function (result) {

        //         const notif = result[0];
        //         console.log('notif', notif);
        //         if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
        //         if(notif.origen === 8) return;

        //         //setId(notif.idOrden);
        //         //setStart(false);
        //         //if (parseInt(localStorage.getItem(idMesa_)) !== notif.idMesa) return;

        //         if (notif.comando === 'update-estatus' || notif.comando === 'nueva-orden' || notif.comando === 'delete-orden' 
        //         || notif.comando === 'delete-producto' || notif.comando === 'todos-productos-terminados'){
        //             //console.log('notif', notif);
        //             if(notif.origen!==8){
        //                 loadOrdenes();
        //             }
                        
        //         }

        //     });

        //     _hubConnection.on("notifyproductotouser", function (result) {

        //         const notif = result[0];
        //         console.log('notif', notif);
        //         if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
        //         if(notif.origen === 8) return;
        //         //setId(notif.idOrden);
        //         //setStart(false);
        //         if(notif.origen!==8)
        //         loadOrdenes();

        //     });

        //     _hubConnection.on("notifyordentouser", function (result) {

        //         const notif = result[0];
        //         console.log('notif', notif);
        //         if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
        //         if(notif.origen === 8) return;
        //         //setId(notif.idOrden);
        //         if(notif.origen!==8)
        //         loadOrdenes();

        //     });

        //     _hubConnection.on("notifyentregatouser", function (result) {

        //         const notif = result[0];
        //         console.log('notif', notif);

        //         if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
        //         if(notif.origen === 8) return;
        //         //setId(notif.idOrden);
        //         //if (parseInt(localStorage.getItem(idMesa_)) !== notif.idMesa) return;
        //         //setStart(false);
        //         if (notif.comando === 'platillo-para-entrega' || notif.comando === 'platillo-entregado'){
                    
        //             if(notif.origen!==8)
        //                 loadOrdenes();

        //         }

        //     });
           
        // });

        getOrdenes();

    },[]);

    return(
        <div className={classes.root}>
            <Grid item container direction="row" justify="center" alignItems="center">
                <p className={classes.title} style={{color: localStorage.getItem(color_main_)}}><TranslateStr token="titleStatusOrder"/></p>
                
            </Grid>
            {/* <Grid item container direction="row" justify="center" alignItems="center">
                <Grid item>
                    <p className={classes.msgStatus}>{UserName}</p>
                </Grid>
            </Grid> */}
            {/* { isLogin  || !validarUser ? */}
            { isLogin  && isOrder && !isDelivery  ?
            
             <Grid item container direction="row" justify="center" alignItems="flex-start">
                    
                    <Grid
                        onClick={() =>loadOrdenes()} 
                        item 
                        container 
                        justify="center" 
                        alignItems="flex-start"
                    >
                        {/* <Grid item>
                            <Avatar className= {classes.createImgAvatar} alt="Cached Icon">
                                <CachedIcon className={classes.createImg}/>
                            </Avatar>
                        </Grid> */}
                        <Grid item>
                            {/* <p className={classes.modal}>{props.showInformationOn ? <TranslateStr token="refreshOrders"/> : ''}</p> */}
                            <p className={classes.labelOption} style={{color: localStorage.getItem(color_accent_)}}>{UserName}</p>
                        </Grid>
                    </Grid>
            </Grid>
             :
            <></>} 
           
            {isOrder && !isDelivery ?
            <List>
            {
                ArrayOrdenes.map( item => (
                    <ItemNoOrder
                    key={ArrayOrdenes.indexOf(item)} 
                    //url={API_BASE_IMG + item.imgUri}
                    NumOrden={item.id}
                    //NumOrden={settingsOrder.idOrder}
                    idTipoOrden={item.idTipoOrden}
                    contOrden={ArrayOrdenes.length}
                    reload={getOrdenes}
                    loadOrdenes={loadOrdenes}
                    ArrayOrdenes={ArrayOrdenes}
                    setSettingsOrder={setSettingsOrder}
                    settingsOrder={settingsOrder}
                    />
                ))
            }
        </List>
        :
        <Grid item container direction="row" justify="center" alignItems="center">
            <Grid item>
                <p className={classes.msgNoOrder}><TranslateStr token="noOrder"/></p>
            </Grid>
        </Grid>
        }
      </div>  
    )
}