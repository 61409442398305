import React from 'react';
//Components
import {Grid} from '@material-ui/core';
import ListDates from './ListDates';
import TitleBack from '../../../utilscomponents/TitleBack';
import TranslateStr from '../HelperLanguage/TranslateStr';

export default function HistorialUser(props){
    
    return(
        <Grid container direction="column" justify="center" alignItems="center">
            <TitleBack msg={<TranslateStr token="titleLongRecord"/>} back={props.changePage}/>
            <ListDates/>
        </Grid>
    )
}