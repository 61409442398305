import React, { useEffect, useState } from 'react';
//import React,{useState} from 'react';
import {formatoMexico} from '../../../../service/ManagerCarrito';
//Components
import {Grid,Paper,Checkbox,Typography,Avatar,IconButton} from '@material-ui/core';
//import AddTopping from './Toppings/AddTopping';
//Styles
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {MainColors} from '../../Sources/SourcesConst';
//Apis
//import {makeRequest} from '../../../../service/ManagerRequest';
import { color_secondary_, idLan_} from '../../../Constants';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
//Modal
import {useModalTopping} from '../../Generics/useModalTopping';
import AddTopping from './Toppings/AddTopping';
//import { SwapVertOutlined } from '@material-ui/icons';

const AddBtn = withStyles(() => ({
    root: {
      color: '#5499C7',
      backgroundColor: '#FBFCFC',
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(1),
      margin: 'auto',
      maxWidth: 500,
      border: '0.2vh solid #EBEDEF',
    },
    image: {
      width: '8vh',
      height: '8vh',
      margin: 'auto',
    },
    indication: {
        //color: MainColors.secondary,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2.5vh',
        marginBottom: '0',
    },
    subTitle:{
        fontSize: '2vh',
        color: '#5D6D7E',
        textAlign: 'left',
        margin: '0',
    },
    subTitleTopping:{
        fontSize: '2vh',
        color: '#5499C7',
        textAlign: 'left',
        margin: '0',
    },
    price: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2.5vh',
        marginBottom: '0',
        textAlign: 'right',
    },
    toppingBtn: {
        // backgroundColor: MainColors.main,
        // "&:hover, &.Mui-focusVisible": { backgroundColor: MainColors.main },
        // color: '#fff',
        // position: 'absolute',
        // bottom: theme.spacing(5),
        // left: theme.spacing(10),
        // zIndex: '2',
        //fontSize: '9pt',
        //fontWeight: 'bold',
        //color: '#CB4335',
        //opacity: '100%',
        //background:'transparent',
        //width:'137px',
        //height: 'auto',
        //textAlign:'left',
        //bordercolor: 'transparent',
        
        backgroundColor: MainColors.main,
        color: '#fff',
        position: 'relative',
        top: theme.spacing(1),
        left: theme.spacing(7),
        zIndex: '16',
        //fontSize: '9pt',
        //fontWeight: 'bold',
        //color: '#CB4335',
        //opacity: '100%',
        //background:'transparent',
        //width:'137px',
        //height: 'auto',
        textAlign:'left',
        bordercolor: '#fff',
    },
    toppingBtn: {
        //backgroundColor: MainColors.main,
        //color: '#fff',
        position: 'absolute',
        top: theme.spacing(1),
        //left: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: '16',
        fontSize: '7pt',
        //fontWeight: 'bold',
        //color: '#CB4335',
        //opacity: '100%',
        //background:'transparent',
        //width:'137px',
        //height: 'auto',
        textAlign:'center',
        outline: 'none',
        //bordercolor: '#fff',
    },
    buttonTopping: {
        zIndex: '16',
        lineheight: '12px',
        width: '20px',
        fontSize: '7pt',
        fontFamily: 'tahoma',
        //margintop: '1px',
        // marginright: '2px',
        position: 'absolute',
        //top: theme.spacing(0),
        //bottom: theme.spacing(5),
        top: theme.spacing(1),
        left: theme.spacing(2),
        color: '#5499C7',
        backgroundColor: '#FBFCFC',
            '&:hover': {
                backgroundColor: '#FBFCFC',
            },
    },
    customHoverFocus: {
        //marginRight: '10px',
        //width: '4vh',
        //height: '4vh',
        color: '#fff',
        backgroundColor: "transparent",
        "&:hover, &.Mui-focusVisible": { backgroundColor: "transparent" }
    },
}));

export default function ItemOrder(props){

    const {dataOrder,selectChild,isSelected,dataUser} = props;
    const sty = useStyles();
    /*const [isChecked,setIsChecked] = useState(isSelected);*/
    //const [arrayTopping,setToppings] = useState([]);
    const { hideTopping,showTopping,RenderModalTopping } = useModalTopping();
    const [expandedTopping, setExpandedTopping] = useState(false);
    //const [expanded, setExpanded] = useState(false);
    //const [dataComment,setDataComment] = useState({index:0,dataDish: []});
    //const idIdioma =  localStorage.getItem(idLan_);

    const changeStatus = () => {
        //setIsChecked(!isChecked);
        //selectChild(dataOrder.index);
        selectChild(dataUser,dataOrder.index);
    }

    //topping handlers
    // const handleResTopping = (data,success) =>{
        
    //     console.log('success: ' + success);
    //     if(success){
    //         for (var i = 0; i < data.length; i++) {
    //             data[i].checked=false;
    //         }
    //         console.log(data);
    //         setToppings(data);
    //         //RenderModal(true);
    //     }
    // }

    const handleTopping = () => {
        if(dataOrder.tieneTopping && dataOrder.arrayTopping.length>0){
            console.log('Tiene topping');
            console.log(dataOrder);
            setExpandedTopping(showTopping);
            return expandedTopping;
            //makeRequest('application/json',null,'GET','masterdata/Productos/MisProductos/' + dataOrder.idProduct + '/Topping?idIdioma=' + idIdioma + '&EsActivo=1',false,false,(dataRes,success) => handleResTopping(dataRes,success))
        
            //setExpanded(show);
            //return expanded;
        }

        // if(dataOrder.esTopping){
        //     console.log('Es topping');
        //     console.log(dataOrder);
        //     //makeRequest('application/json',null,'GET','masterdata/Productos/MisProductos/' + dataOrder.idProduct + '/Topping?idIdioma=' + idIdioma + '&EsActivo=1',false,false,(dataRes,success) => handleResTopping(dataRes,success))
        
        //     //setExpanded(show);
        //     //return expanded;
        // }
        
    }

    // const saveCommentChip = () => {
    //     props.reload();
    // }

    return(
        <><Paper className={sty.paper}>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Checkbox
                        checked={isSelected}
                        onChange={() => changeStatus()} />

                </Grid>
                <Grid item xs={3} container direction="column">
                    <Grid item xs container direction="row">
                        <Grid item xs={9} onClick={handleTopping}>
                            <Avatar className={sty.image} alt="Menu Photo" src={dataOrder.url} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={7} container direction="column">
                    <Grid item xs container direction="row" onClick={handleTopping}>
                        <Grid item xs={9} >
                            <Typography className={sty.title}>
                                {dataOrder.name}
                            </Typography>
                            <Typography hidden={!dataOrder.tieneTopping} className={sty.subTitleTopping}>
                                {'+ Toppings'}
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography className={sty.price}>{'$' + formatoMexico(dataOrder.price + dataOrder.montoToppings)}</Typography>
                            
                        </Grid>
                    </Grid>
                    {/* <Grid item xs container direction="row" justify="center" alignItems="center">
                    <Grid item >
                    <Typography className={sty.subTitle}>
                        {dataOrder.url}
                    </Typography>
                    </Grid>
                    </Grid> */}
                    <Grid item xs container direction="row" justify="center" alignItems="center">
                        <Grid item>
                            <p className={sty.indication} style={{ color: localStorage.getItem(color_secondary_) }}>{dataOrder.indication}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
        <RenderModalTopping>
            <AddTopping
                dataSub={dataOrder}
                idTipoMenu={1}
                arrayTopping={dataOrder.arrayTopping}
                ID={1}
                total={parseFloat(dataOrder.price)}
                totalToppings={parseFloat(dataOrder.montoToppings)}
                //getToppings={getToppings}
                //setTotaltoppings={props.setTotaltoppings}
                //reload={props.reload}
            />
        </RenderModalTopping>
    </>
    )
}