import React from 'react';
//Components
import {Grid,Paper,Typography,Avatar} from '@material-ui/core';
//Styles
import { makeStyles } from '@material-ui/core/styles';
import {MainColors} from '../../Sources/SourcesConst';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(1),
      margin: 'auto',
      maxWidth: 500,
      width: '100%',
      border: '0.2vh solid #EBEDEF',
    },
    image: {
      width: '8vh',
      height: '8vh',
      margin: 'auto',
    },
    indication: {
        color: MainColors.secondary,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        marginBottom: '0',
    },
    price: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        marginBottom: '0',
        textAlign: 'right',
    },
}));

export default function ItemOrder(props){

    const {dataOrder} = props;
    const sty = useStyles();

    return(
        <Paper className={sty.paper}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Avatar className={sty.image} alt="Menu Photo" src={dataOrder.imageUri} />
                </Grid>
                <Grid item xs={7} container direction="column">
                    <Grid item xs container direction="row">
                        <Grid item xs={9}>
                            <Typography className={sty.title}>
                            {dataOrder.producto}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={sty.price} >{'$' + dataOrder.precio}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="row" justify="center" alignItems="center">
                        <Grid item>
                            <p className={sty.indication}>{dataOrder.indicaciones}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}