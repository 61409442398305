import React,{ useEffect, useState }from 'react';
//Components
//import AddComent from './Comments/AddComment';
import {
    Typography,
    Grid,
    Paper,
    Avatar,
    IconButton
} from '@material-ui/core';
//import Alert from '@material-ui/lab/Alert';

//DB
import {formatoMexico,decimalAdjust,saveItemTopping, getInfoTopping, 
    deleteItemTopping,getInfoCarrito} from '../../../../service/ManagerCarrito';
//import {Service} from '../../../../service/DBService';
//API
//import {makeRequest} from '../../../../service/ManagerRequest';
//Styles
import {MainColors} from '../../Sources/SourcesConst';
import {makeStyles} from '@material-ui/core/styles';
//import CommentIcon from '@material-ui/icons/Comment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
//Modal
//import {useModal} from '../Generics/useModal';
//import {useModalTopping} from '../Generics/useModalTopping';
///import AddTopping from './Toppings/AddTopping';
//Swipe
//import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
//import ItemSwipeRight from '../Submenus/ItemSwipeRight';
//import ItemSwipeLeft from '../Submenus/ItemSwipeLeft';

//import TranslateStr from '../../ConfigPage/HelperLanguage/TranslateStr';
import {color_main_, color_secondary_} from '../../../Constants';

export default function ItemTopping(props){
    //const Idioma = localStorage.getItem(idLan_);
    const dataDish = props.data;
    const ID = props.ID;
    const sty = useStyles();
    const [costItem,setCostItem] = useState({total:0,cantidad:0});

    const handlePlus = () => {
        saveDish();
        // const cantad_ = costItem.cantidad+1;
        // setCostItem({total:cantad_*dataDish.precioDescuento,cantidad: cantad_});
        // props.setCostTotal(props.subTotal + dataDish.precioDescuento);
    }
    
    const handleLess = () => {
        //props.reloadTopping();
        if(costItem.cantidad > 0){
            deleteDish();
            // const cantad_ = costItem.cantidad-1;
            // setCostItem({total:cantad_*dataDish.precioDescuento,cantidad: cantad_});
            // props.setCostTotal(props.subTotal - dataDish.precioDescuento);
        }
    }

    //handlers db
    const saveDish = () => {
        // console.log('saveTopping...');
        // console.log(dataDish);
        saveItemTopping(ID,dataDish,false,(result) => {
            //props.setOrigen(2);
            console.log('Topping agregado...');
            const cantad_ = costItem.cantidad+1;
            setCostItem({total:cantad_*dataDish.precioDescuento,cantidad: cantad_});
            
            props.setCostTotal(props.ItemTotal + dataDish.precioDescuento);
           
            
            
            getInfoCarrito((totalR,countR,toppingsR) => {
                
                //props.setTotaltoppings({monto:toppingsR});
                //props.setTotaltoppings({monto:toppingsR});
                props.reload();
             
            });
        });
    }

    const deleteDish = () => {
        deleteItemTopping(ID,dataDish.idProductoTopping,(result) => {
            console.log('Topping eliminado...');
            const cantad_ = costItem.cantidad-1;
            setCostItem({total:cantad_*dataDish.precioDescuento,cantidad: cantad_});
            props.setCostTotal(props.ItemTotal - dataDish.precioDescuento);

            getInfoCarrito((totalR,countR,toppingsR) => {
                //localStorage.setItem(countCarrito_,countR);
                //setInfoOrder({total:totalR,count: countR,readyLogin:true});
                
                //props.setTotaltoppings({monto:toppingsR});
                //props.setTotaltoppings(toppingsR);
                props.reload();
                //setmontoToppings({monto: toppingsR});
             
            });
            
        });
        //props.reloadTopping();
    }

    useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        getInfoTopping(ID,dataDish.idProductoTopping,(totalR,countR) => {
            //console.log('getInfoTopping...');
            //console.log('ID: ' + ID);
            //console.log('PRODUCTO: ' + dataDish.idProductoTopping);
            setCostItem({total:countR*dataDish.precioDescuento,cantidad: countR});
        });
    },[]);

    return(
        <div className={sty.root}>
            <Paper className={sty.paper}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Avatar className={sty.image} alt="Menu Photo" src={dataDish.imagenUri} />
                    </Grid>
                    <Grid item xs={9} container direction="column">
                        <Grid item xs container direction="row">
                            <Grid item xs={9}>
                                <Typography className={sty.title}>
                                    {/* {Idioma==1 ? dataDish.nameESP : dataDish.nameENG} */}
                                    {dataDish.nombreSubMenu}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={sty.price}  >{'$' + formatoMexico(dataDish.precioDescuento)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <p className={sty.cantidad} style={{ color: localStorage.getItem(color_secondary_) }}>{costItem.cantidad}</p>
                            </Grid>
                            <Grid item>
                                <p className={sty.total} style={{ color: localStorage.getItem(color_main_) }}>{"$" + formatoMexico(decimalAdjust('round', parseFloat(costItem.total), -2))}</p>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => handleLess()} aria-label="delete" size="small">
                                    <RemoveIcon className={sty.iconComment} color="secondary" />
                                </IconButton>
                                <IconButton onClick={() => handlePlus()} aria-label="add" size="small">
                                    <AddIcon className={sty.iconComment} color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100% !important',
    },
    root: {
         flexGrow: 1,
         margin: '0.4vh 0.4vh 0 0.4vh'
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
      border: '0.2vh solid #EBEDEF',
    },
    image: {
      width: '8vh',
      height: '8vh',
      margin: 'auto',
    },
    iconComment: {
        //color: MainColors.secondary,
        backgroundColor: '#fff',
    },
    iconBorder: {
        border: '0.2vh solid #EBEDEF',
    },
    toppingBtn: {
        backgroundColor: MainColors.main,
        color: '#fff',
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(7),
        zIndex: '16',
        //fontSize: '9pt',
        //fontWeight: 'bold',
        //color: '#CB4335',
        //opacity: '100%',
        //background:'transparent',
        //width:'137px',
        //height: 'auto',
        textAlign:'left',
        bordercolor: '#fff',
    },
    chip: {
        color: MainColors.secondary,
    },
    cantidad: {
        //color: MainColors.secondary,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    total: {
        //color: MainColors.main,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2vh',
        marginBottom: '0',
    },
    price: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2.5vh',
        marginBottom: '0',
        textAlign: 'right',
    },
    divider: {
        margin: '1vh',
    },
    chipContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
        margin: theme.spacing(0.5),
        },
    },
    indicaciones: {
        //color: MainColors.secondary,
        fontSize: '2.5vh',
        fontWeight: 'light',
        textAlign: 'left',
        marginBottom: '0',
    },
}));