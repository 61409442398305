import React, { useState } from 'react';
//Componentes
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {Grid,Avatar,TextField,Button,Radio,RadioGroup,FormControlLabel,FormControl,Select,MenuItem,InputLabel} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
//styles
import visa from '../../../img/visa.png';
import master from '../../../img/master.png';
import american from '../../../img/american.png';
import carnet from '../../../img/carnet.png';
import bancomer from '../../../img/bancomer.png';
import santander from '../../../img/santander.png';
import hsbc from '../../../img/hsbc.png';
import scotia from '../../../img/scotia.png';
import inbursa from '../../../img/inbursa.jpg';
import openpay from '../../../img/openpay.png';
import securecard from '../../../img/securecard.png';
import ixe from '../../../img/ixe.png';
import {makeStyles,withStyles} from '@material-ui/core/styles';
//import {MainColors} from '../Sources/SourcesConst';
//Apis
import {notifyPayment,makeDeliveryOrder,getAllOrderRestaurant,makePayment} from '../../../service/ManagerApis';
//Dialog
import { confirmAlert } from 'react-confirm-alert';
//Openpay
import {getInstanceOpenpay,getDeviceId} from './SettingOpenpay';
//Api
import { getRestaPorPagar,getArrayIdProductOrder, getProductOrderFromResponse,getTotalFromResponse } from '../StatusOrden/Restaurant/HelperArray';
//Other
import { deleteAllCarrito } from '../../../service/ManagerCarrito';
import {errorApi_, color_accent_, color_main_, color_secondary_ } from '../../Constants';

const useStyles = makeStyles((theme) => ({
   back: {
      width: '100%',
      height: '100%',
      padding: '1vh',
      //color: MainColors.accent,
      backgroundColor: '#fff',
  },
  img:{
      margin: '0',
      width: '6vh',
      height: '6vh',
  },
   title: {
      //color: MainColors.main,
      fontSize: '4vh',
      fontWeight: '300',
      margin: '1vh',
      padding: '0',
   },
   gridW: {
      width: '100%',
   },
   containerCards: {
      marginBottom: '2vh',
   },
   labelCards: {
       //color: MainColors.accent,
       textAlign: 'center',
       fontSize: '2vh',
       fontWeight: '200',
       margin: '0 0 1.5vh 1vh',
       padding: 0,
   },
   labelTotal: {
      //color: MainColors.main,
      fontWeight: '250',
      fontSize: '6vh',
      margin: '2vh 0 1vh 3vh',
   },
   labelCounts: {
      //color: MainColors.accent,
      fontWeight: '250',
      fontSize: '4vh',
      margin: '2vh 0 2vh 3vh',
   },
   labelInfo:{
      //color: MainColors.accent,
      fontWeight: '200',
      fontSize: '2vh',
      margin: '0',
      padding: '0',
      textAlign: 'center',
   },
   labelOpenpay:{
      //color: MainColors.accent,
      fontWeight: '200',
      fontSize: '2vh',
      margin: '0',
      padding: '0'
   },
   imgOpenpay: {
      width: '12vh',
      height: '7vh',
      margin: '0',
   },
   imgSecureCard: {
      width: '5vh',
      height: '5vh',
   },
   containerInfo: {
      margin: '2vh 0',
   },
   labelTip:{
      //color: MainColors.main,
      fontWeight: '200',
      fontSize: '3vh',
      margin: '0 0 0 2vh',
      padding: '0',
      textAlign: 'left',
   },
   formControl: {
      margin: '1vh',
      width: '90%',
      height: '100%'
   },
}));

const CssTextField = withStyles({
    root: {
       height: '100%',
       width: '90%',
       margin: '1vh',
      '& label.Mui-focused': {
         color: localStorage.getItem(color_secondary_), //MainColors.secondary,
      },
      '& .MuiInput-underline:after': {
        color: localStorage.getItem(color_secondary_), //MainColors.secondary,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: localStorage.getItem(color_secondary_), //MainColors.secondary,
        },
        '&:hover fieldset': {
          borderColor: localStorage.getItem(color_secondary_), //MainColors.secondary,
        },
        '&.Mui-focused fieldset': {
          borderColor: localStorage.getItem(color_secondary_), //MainColors.secondary,
        },
      },
    },
})(TextField);


const ColorButton = withStyles(() => ({
   root: {
     color: localStorage.getItem(color_secondary_), //MainColors.secondary,
     fontSize: '3vh',
     fontWeight: 'bold',
     backgroundColor: '#fff',
     '&:hover': {
       backgroundColor: '#fff',
     },
   },
}))(Button);

export default function Openpay(props){

   const sty = useStyles();
   const [cardNumber,setCardNumber] = useState("");
   const [holderName,setHolderName] = useState("");
   const [expYear,setExpYear] = useState("");
   const [expMonth,setExpMonth] = useState("");
   const [cvv,setCvv] = useState("");
   const [config,setConfig] = useState({total:props.total,tip:0,tipP:'0'});
   const openPayI = getInstanceOpenpay();

   const roundFloat = (number) =>{
      return Number(Math.round(number + "e2") + "e-2");
   }

   const handleChangeTip = (event) => {
      const tipSelected = props.total/100 * parseInt( event.target.value);
      const newTip = roundFloat(tipSelected);
      const newTotal = roundFloat(props.total + newTip);
      setConfig({total: newTotal ,tip: newTip,tipP:event.target.value});
   };

   const onSuccessCard = (response) => {
      if(props.isDelivery){
         makeSendOrderPayment(response.data.id);
      }else{
         makeFinalPayment(response.data.id);
      }
   }

   const onFaildCard = (response) => {
      errorMsgPayment(false,response.data.description);
   }

   const makeFinalPayment = (token) => {
      const totalFinal = props.total;
      const arrayToPayFinal = props.arrayToPay;
      makePayment(
         holderName,
         "default",
         token,
         totalFinal,
         config.tip,
         "Cargo vía openpay",
         getDeviceId(openPayI),
         "DefaultEmail@gmail.com",
         getArrayIdProductOrder(props.arrayToPay),
         (successPayment) => {
            errorMsgPayment(successPayment,localStorage.getItem(errorApi_));
            if(successPayment){
               notifyPayment(totalFinal,getRestaPorPagar(arrayToPayFinal),(successNotify) => {
                  errorMsgPayment(successNotify,localStorage.getItem(errorApi_));
                  if(successNotify){
                     successDialog();
                  }
               });
            }
         });
   } 

   const makeSendOrderPayment = (token) => {
      makeDeliveryOrder(okCreate => {
         errorMsgPayment(okCreate,<TranslateStr token="errorCreateDelOrder"/>);
         if(okCreate){
            getAllOrderRestaurant(null,orders => {
               const okGet = orders.length !== 0;
               errorMsgPayment(okGet,<TranslateStr token="errorBringDishData"/>);
               if(okGet){
                  makePayment(
                     holderName,"default",
                     token,
                     getTotalFromResponse(orders),
                     config.tip,
                     "Cargo vía openpay",
                     getDeviceId(openPayI),
                     "DefaultEmail@gmail.com",
                     getProductOrderFromResponse(orders),
                     (successPayment) => {
                        errorMsgPayment(successPayment,localStorage.getItem(errorApi_));
                        if(successPayment){
                           notifyPayment(getTotalFromResponse(orders),0.0,(successNotify) => {
                              errorMsgPayment(successNotify,localStorage.getItem(errorApi_));
                              if(successNotify){
                                 successDialog();
                              }
                           });
                        }
                     }
                  );
               }
            });
         }
      })
   }

   const successDialog = () => {
      confirmAlert({
         title: <TranslateStr token="successfulAccion"/>,
         message: <TranslateStr token="okPaymentOrder"/>,
         onClickOutside: () => finishPayment(),
         buttons: [
            {
               label: <TranslateStr token="accept"/>,
               onClick: () => finishPayment()
            }
         ]
     });
   }

   const errorMsgPayment = (ok,msgError) => {
      if(!ok){
         confirmAlert({
            title: <TranslateStr token="errorPayment"/>,
            message: msgError,
            buttons: [
               {
                  label: <TranslateStr token="accept"/>
               }
            ]
        });
      }
   }

   const finishPayment = () => {
      deleteAllCarrito();
      props.changePage(true);
   }
   
   const onInputName = (e) => {
      e.persist();
      setHolderName(e.target.value);
   }

   const onInputCardNumber = (e) => {
      e.persist();
      setCardNumber(e.target.value);
   }

   const onInputExpYear = (e) => {
      e.persist();
      setExpYear(e.target.value);
   }

   const onInputExpMonth = (e) => {
      e.persist();
      setExpMonth(e.target.value);
   }

   const onInputCvv = (e) => {
      e.persist();
      setCvv(e.target.value);
   }

   const makeDialog = (title,msg) => {
      confirmAlert({
            title: title,
            message: msg,
            buttons: [
            {
               label: 'Ok',
            },
            ]
      });
   };

   const validateData = () => {
      if(cardNumber === "" || holderName === "" || expYear === "" || expMonth === "" || cvv === ""){
         makeDialog(<TranslateStr token="requiredFields"/>,<TranslateStr token="verifyAllComplete"/>);
      }else{
         const vNumber  = openPayI.card.validateCardNumber(cardNumber);
         const vCvv     = openPayI.card.validateCVC(cvv,cardNumber);
         const vDate    = openPayI.card.validateExpiry(expMonth,expYear);
         var msgError   = "";

         if(!vNumber){
            msgError = <TranslateStr token="invalidCard"/>;
         }
         if(!vCvv){
            msgError = <TranslateStr token="invalidCvv"/>;
         }
         if(!vDate){
            msgError = <TranslateStr token="invalidExpDate"/>;
         }
         if(vNumber && vCvv && vDate){
            openPayI.token.create({
               "card_number" : cardNumber,
               "holder_name" : holderName,
               "expiration_year" : expYear,
               "expiration_month" : expMonth,
               "cvv2" : cvv
            },onSuccessCard,onFaildCard)
         }else{
            errorMsgPayment(false,msgError);
         }
      }
   }

   return(
       <div>
          <Grid item container alignItems="flex-start">
               <Grid item>
                  <Avatar onClick={() => props.changePage(false)} className= {sty.back} style={{color: localStorage.getItem(color_accent_)}} alt="Add icon">
                     <ArrowBackIcon className={sty.img}/>
                  </Avatar>
               </Grid>
               <Grid item>
                  <p className={sty.title} style={{color: localStorage.getItem(color_main_)}}><TranslateStr token="titlePayment"/></p>
               </Grid>
         </Grid>

         <Grid container direction="center" justify="flex-start" alignItems="center">
             <p className={sty.labelTotal} style={{color: localStorage.getItem(color_main_)}}>{config.total}</p>
             <p className={sty.labelCounts} style={{color: localStorage.getItem(color_accent_)}}>{props.countItems} <TranslateStr token="products"/></p>
         </Grid>

         <Grid container direction="row" justify="center" alignItems="flex-start">
             <Grid className={sty.containerCards} item xs={5} container direction="column" justify="center">
                 <Grid item>
                     <p className={sty.labelCards} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="credirCards"/></p>
                 </Grid>
                 <Grid item container justify="center" direction="row">
                     <AvatarGroup max={4}>
                        <Avatar alt="Visa" src={visa} />
                        <Avatar alt="Master" src={master} />
                        <Avatar alt="American" src={american} />
                        <Avatar alt="Carnet" src={carnet} />
                    </AvatarGroup>
                 </Grid>
             </Grid>
             <Grid item xs={7} container direction="column" justify="center">
                 <Grid item>
                     <p className={sty.labelCards} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="debitcards"/></p>
                 </Grid>
                 <Grid item  container justify="center" direction="row">
                     <AvatarGroup max={6}>
                        <Avatar alt="Bancomer" src={bancomer} />
                        <Avatar alt="Santander" src={santander} />
                        <Avatar alt="HSBC" src={hsbc} />
                        <Avatar alt="Scotiabank" src={scotia} />
                        <Avatar alt="Inbursa" src={inbursa} />
                        <Avatar alt="IXE" src={ixe} />
                    </AvatarGroup>
                 </Grid>
             </Grid>

         </Grid>

          <Grid item container direction="column" justify="center" alignItems="center">
             
            <CssTextField 
               label={<TranslateStr token="fullName"/>} 
               value={holderName} 
               onChange={onInputName}
               id="outlined-basic" 
               variant="outlined"
               type="text"
            />
             
            <CssTextField 
               label={<TranslateStr token="cardNumber"/>} 
               value={cardNumber} 
               onChange={onInputCardNumber}
               id="outlined-basic2" 
               variant="outlined"
               type="number"
               InputProps={{
                  inputProps: { 
                      max: 16
                  }
              }}
            />

            <Grid item container direction="row" justify="center">
               <Grid item xs={4} >
                  {/*<CssTextField 
                     label="Año de expiración" 
                     value={expYear} 
                     onChange={onInputExpYear}
                     id="outlined-basic" 
                     variant="outlined"
                  />*/}
                  <FormControl variant="outlined" className={sty.formControl}>
                     <InputLabel id="demo-simple-select-outlined-label"><TranslateStr token="year"/></InputLabel>
                     <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={expYear}
                        onChange={onInputExpYear}
                        label={<TranslateStr token="year"/>}
                     >
                        <MenuItem value={21}>2021</MenuItem>
                        <MenuItem value={22}>2022</MenuItem>
                        <MenuItem value={23}>2023</MenuItem>
                        <MenuItem value={24}>2024</MenuItem>
                        <MenuItem value={20}>2025</MenuItem>
                        <MenuItem value={20}>2026</MenuItem>
                     </Select>
                  </FormControl>
               </Grid>

               <Grid item xs={4}>
                  {/*<CssTextField 
                     label="Mes de expiración" 
                     value={expMonth} 
                     onChange={onInputExpMonth}
                     id="outlined-basic" 
                     variant="outlined"
                  />*/}
                  <FormControl variant="outlined" className={sty.formControl}>
                     <InputLabel id="select-month"><TranslateStr token="month"/></InputLabel>
                     <Select
                        labelId="select-month"
                        id="select-monthh"
                        value={expMonth}
                        onChange={onInputExpMonth}
                        label={<TranslateStr token="month"/>}
                     >
                        <MenuItem value={'01'}>01</MenuItem>
                        <MenuItem value={'02'}>02</MenuItem>
                        <MenuItem value={'03'}>03</MenuItem>
                        <MenuItem value={'04'}>04</MenuItem>
                        <MenuItem value={'05'}>05</MenuItem>
                        <MenuItem value={'06'}>06</MenuItem>
                        <MenuItem value={'07'}>07</MenuItem>
                        <MenuItem value={'08'}>08</MenuItem>
                        <MenuItem value={'09'}>09</MenuItem>
                        <MenuItem value={'10'}>10</MenuItem>
                        <MenuItem value={'11'}>11</MenuItem>
                        <MenuItem value={'12'}>12</MenuItem>
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={4}>
                  <CssTextField 
                     label={<TranslateStr token="cvv"/>} 
                     value={cvv} 
                     onChange={onInputCvv}
                     id="outlined-basiccvv" 
                     variant="outlined"
                  />
               </Grid>
            </Grid>

            <Grid className={sty.containerInfo} container direction="row" justify="center" alignItems="center">

               <Grid item xs={6} container direction="row" justify="center" alignItems="center">
                  <p className={sty.labelInfo} style={{color: localStorage.getItem(color_accent_)}} ><TranslateStr token="byOpenpay"/></p>
                  <img className={sty.imgOpenpay} alt="openpay" src={openpay}/>
               </Grid>

               <Grid item xs={6} container direction="row" justify="center" alignItems="center">
                  <Grid item xs={3}>
                     <img className={sty.imgSecureCard} alt="openpay" src={securecard}/>
                  </Grid>
                  <Grid item xs={9}>
                     <p className={sty.labelOpenpay} style={{color: localStorage.getItem(color_accent_)}} ><TranslateStr token="securePaymentByOpenpay"/></p>
                  </Grid>
               </Grid>
            </Grid>

            <Grid container direction="column" justify="center" alignItems="center">
               <Grid container direction="row" justify="center" alignItems="center">
                  <p className={sty.labelInfo} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="tip"/></p>
                  <p className={sty.labelTip} style={{color: localStorage.getItem(color_main_)}}>{config.tip !== 0 && " " + config.tip}</p>
               </Grid>
               <RadioGroup row aria-label="gender" name="gender1" value={config.tipP} onChange={handleChangeTip}>
                  <FormControlLabel value="0" control={<Radio />} label="0%"/>
                  <FormControlLabel value="5" control={<Radio />} label="5%"/>
                  <FormControlLabel value="10" control={<Radio />} label="10%"/>
                  <FormControlLabel value="15" control={<Radio />} label="15%"/>
               </RadioGroup>
            </Grid>
            
            <ColorButton onClick={validateData} variant="contained">
               <TranslateStr token="pay"/>
            </ColorButton>

          </Grid>
       </div>
   )
}