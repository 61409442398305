import React,{useState} from 'react';
//import React,{useState, useEffect} from 'react';
//DB
import {addComment,updateCommentDB, formatoMexico, decimalAdjust, getInfoToppings} from '../../../../service/ManagerCarrito';
//import {Service} from '../../../../service/DBService';
//Styles
import { makeStyles } from '@material-ui/core/styles';
//import { makeStyles,withStyles } from '@material-ui/core/styles';
//import {MainColors} from '../../Sources/SourcesConst';
//Apis
//import {makeRequest} from '../../../../service/ManagerRequest';
//import {color_main_, color_secondary_, idLan_} from '../../../Constants';
//Components
//import {Grid,Button,TextField,Checkbox} from '@material-ui/core';
import ItemTopping from './ItemTopping';
//import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
//import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
//import CardActions from '@material-ui/core/CardActions';
//import Collapse from '@material-ui/core/Collapse';
//import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
//import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
//import AddIcon from '@material-ui/icons/Add';
//import RemoveIcon from '@material-ui/icons/Remove';
import Grid from '@material-ui/core/Grid';
//import Paper from '@material-ui/core/Paper';
//import Checkbox from '@material-ui/core/Checkbox';
//import Avatar from '@material-ui/core/Avatar';
//import Snackbar from '@material-ui/core/Snackbar';
//import Alert from '@material-ui/lab/Alert';
import TranslateStr from '../../ConfigPage/HelperLanguage/TranslateStr';
import { List } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100% !important',
  },
  root: {
    width: '90vw',
    height: 'auto',
    maxWidth: '60vh',
    padding: '5px 0 0 0',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  paper: {
    padding: theme.spacing(0.5),
    margin: 0,
    width: '100%',
    border: '0.2vh solid #EBEDEF',
  },
  image: {
    width: '8vh',
    height: '8vh',
    margin: 'auto',
  },
  indication: {
      //color: MainColors.secondary,
      fontSize: '3vh',
      fontWeight: 'bold',
      textAlign: 'left',
      marginBottom: '0',
  },
  title: {
      color: '#5D6D7E',
      fontWeight: 'light',
      fontSize: '3vh',
      marginBottom: '0',
  },
  titleprod: {
    color: '#5D6D7E',
    fontWeight: 'light',
    fontSize: '2vh',
    marginBottom: '0',
  },
  subTitle:{
      fontSize: '2vh',
      color: '#5D6D7E',
      textAlign: 'left',
      margin: '0',
  },
  price: {
    color: '#5D6D7E',
    fontWeight: 'light',
    fontSize: '2.5vh',
    marginBottom: '0',
    textAlign: 'right',
  },
  total: {
    //color: MainColors.main,
    fontSize: '3vh',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '0',
  },
  cantidad: {
    //color: MainColors.secondary,
    fontSize: '3vh',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '0',
  },
  buttonTopping: {
      lineheight: '12px',
      width: '20px',
      fontSize: '1rem',
      fontFamily: 'tahoma',
      //margintop: '1px',
      // marginright: '2px',
      position: 'absolute',
      //top: theme.spacing(0),
      bottom: theme.spacing(5),
      //top: theme.spacing(1),
      left: theme.spacing(4),
      color: '#fff',
      backgroundColor: 'transparent',
          '&:hover': {
              backgroundColor: 'transparent',
          },
  },
  iconComment: {
    //color: MainColors.secondary,
    backgroundColor: '#fff',
  },
  customHoverFocus: {
      //marginRight: '10px',
      //width: '4vh',
      //height: '4vh',
      color: '#fff',
      backgroundColor: "transparent",
      "&:hover, &.Mui-focusVisible": { backgroundColor: "transparent" }
  },
    
}));

export default function AddTopping(props){
    const classes = useStyles();
    const [cost,setCost] = useState(props.total+props.ItemtotalToppings);
    const dataSub = props.dataSub;
    const dataTopping = props.arrayTopping;
    const IDTopping = props.ID;
    const [totalItemToppings, setTotalItemtoppings] = useState(0);

    const getToppings = () => {
      //var totalItemToppings = 0;
      //console.log('dataDish.id: ' + dataDish.id);
      //console.log('IDTopping: ' + IDTopping);
      getInfoToppings(IDTopping,(totalR,countR) => {
          //const sub = (dataDish.cantidad*dataDish.price) + totalR;
          setTotalItemtoppings(totalR);
          //totalItemToppings = totalR;
      });
      return decimalAdjust('round',parseFloat(props.total + totalItemToppings),-2);
    }

  return (
    <Card className={classes.root} >
      <CardHeader
        title={<TranslateStr token="addTopping"/>}
        subheader={dataSub.name} >
      </CardHeader>
      <CardContent className={classes.cardcont}>
        <Grid item xs container direction="row">
            <Grid item xs={9}>
              <Typography className={classes.total} >{' Total $' + (getToppings())}</Typography>
            </Grid>
        </Grid>
        <List>
          {
            dataTopping.map(dish => (
              <ItemTopping
                  key={dataTopping.indexOf(dish)}
                  setTotaltoppings = {props.setTotaltoppings}
                  setCostTotal = {setCost}
                  costTotal = {props.total}
                  data={dish}
                  ID = {props.ID}
                  ItemTotal = {cost}
                  //setSubtotal = {props.setSubtotal}
                  getToppings = {props.getToppings}
                  reload={props.reload}
              />
            ))
            
          }
        </List>
      </CardContent>
    </Card>
   
  );
}