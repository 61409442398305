import React,{useState, useEffect} from 'react';

//Styles
import { makeStyles} from '@material-ui/core/styles';
//import {MainColors} from '../../Sources/SourcesConst';

//Components
import ItemTopping from './ItemTopping';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TranslateStr from '../../ConfigPage/HelperLanguage/TranslateStr';
import { List } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100% !important',
  },
  root: {
    width: '90vw',
    height: 'auto',
    maxWidth: '60vh',
    padding: '0 0 0 0',
    top:0,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  paper: {
    padding: theme.spacing(0.5),
    margin: 0,
    width: '100%',
    border: '0.2vh solid #EBEDEF',
  },
  image: {
    width: '8vh',
    height: '8vh',
    margin: 'auto',
  },
  indication: {
      //color: MainColors.secondary,
      fontSize: '3vh',
      fontWeight: 'bold',
      textAlign: 'left',
      marginBottom: '0',
  },
  title: {
      color: '#5D6D7E',
      fontWeight: 'light',
      fontSize: '3vh',
      marginBottom: '0',
  },
  titleprod: {
    color: '#5D6D7E',
    fontWeight: 'light',
    fontSize: '2vh',
    marginBottom: '0',
  },
  subTitle:{
      fontSize: '2vh',
      color: '#5D6D7E',
      textAlign: 'left',
      margin: '0',
  },
  price: {
    color: '#5D6D7E',
    fontWeight: 'light',
    fontSize: '2.5vh',
    marginBottom: '0',
    textAlign: 'right',
  },
  total: {
    //color: MainColors.main,
    fontSize: '3vh',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '0',
  },
  cantidad: {
    //color: MainColors.secondary,
    fontSize: '3vh',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '0',
  },
  buttonTopping: {
      lineheight: '12px',
      width: '20px',
      fontSize: '1rem',
      fontFamily: 'tahoma',
      //margintop: '1px',
      // marginright: '2px',
      position: 'absolute',
      //top: theme.spacing(0),
      bottom: theme.spacing(5),
      //top: theme.spacing(1),
      left: theme.spacing(4),
      color: '#fff',
      backgroundColor: 'transparent',
          '&:hover': {
              backgroundColor: 'transparent',
          },
  },
  iconComment: {
    //color: MainColors.secondary,
    backgroundColor: '#fff',
  },
  customHoverFocus: {
      //marginRight: '10px',
      //width: '4vh',
      //height: '4vh',
      color: '#fff',
      backgroundColor: "transparent",
      "&:hover, &.Mui-focusVisible": { backgroundColor: "transparent" }
  },
    
  }));
  
  
export default function AddTopping(props){
  const classes = useStyles();
  const [cost,setCost] = useState(props.total+props.totalToppings);
  const dataSub = props.dataSub;
  const dataTopping = props.arrayTopping;

  return (
    <Card className={classes.root} >
      <CardHeader
        title={<TranslateStr token="addTopping"/>}
        subheader={dataSub.nombreSubMenu} >
      </CardHeader>
      <CardContent className={classes.cardcont}>
        <Grid item xs container direction="row">
            <Grid item xs={9}>
              <Typography className={classes.total} >{' Total $' + (cost)}</Typography>
            </Grid>
        </Grid>
        <List>
          {
            dataTopping.map(dish => (
              <ItemTopping
                  key={dataTopping.indexOf(dish)}
                  setCostTotal = {setCost}
                  costTotal = {props.total}
                  data={dish}
                  ID = {props.ID}
                  subTotal = {cost}
                  getTotales = {props.getTotales}
                  setTotaltoppings = {props.setTotaltoppings}
                  origen = {props.origen}
              />
            ))
          }
        </List>
      </CardContent>
    </Card>
  );
}