import React from 'react';
//Components
import ItemOrder from './ItemOrder';
import {Grid} from '@material-ui/core';

export default function ListOrders(props){

    return(
        <Grid container justify="center" alignItems="center">
            {
                props.orders !== undefined &&
                props.orders.map((order,index) => (
                    <ItemOrder key={index} changePage={props.changePage} dataOrder={order}/>
                ))
            }
        </Grid>
    )
}