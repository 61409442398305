import React,{useState, useEffect} from 'react';
//Components
import { confirmAlert } from 'react-confirm-alert';
import AddAddress from './AddAddress';
import ListAddress from './ListAddress';
import {Grid, IconButton, makeStyles} from '@material-ui/core';
//Apis
import {getAllAddressUser,saveDeliveryAddress,deleteDeliveryAddress} from '../../../../service/ManagerApis';
//Styles
import AddIcon from '@material-ui/icons/Add';
import {MainColors} from '../../Sources/SourcesConst';
//Modal
import {useModal} from '../../Generics/useModal';
//Others
import { desAddressDel_, indexAddressDel_, idAddressDel_} from '../../../Constants';

const useStyles = makeStyles({
    root: {

    },
    title:{
        color: MainColors.secondary,
        fontSize: '3vh',
        fontWeight: 250,
        margin: '0 0 1vh 0',
    },
    addressTitle:{
        color: MainColors.accent,
        fontSize: '2vh',
        fontWeight: 300,
        margin: '0',
    },
    imgAdd: {
        color: MainColors.main,
        backgroundColor: '#fff',
    }
});

export default function SelectAddress(){

    const { hide,show,RenderModal } = useModal();
    const sty = useStyles();
    const [arrayAddress,setArrayAddress] = useState([]);

    const showDialog = (title, subTitle) => {
        confirmAlert({
            title: title,
            message: subTitle,
            buttons: []
    })};

    const dialogDelete = (id) => {
        confirmAlert({
            title: 'Eliminar direeción',
            message: '¿Está seguro de esta acción?',
            buttons: [
            {
                label: 'Cancelar'
            },
            {
                label: 'Aceptar',
                onClick: () => deleteAddress(id)
            }
            ]
        });
    };

    const saveAddressUser = (idDomi,street,nota) => {
        saveDeliveryAddress(idDomi,street,nota,(successAdd) => {
            if(successAdd){
                showDialog('¡Dirección agregada!','Sus datos se guradaron correctamente');
            }else{
                showDialog('Ocurrió un error','No fue posible guardar su dirección intente de nuevo');
            }
            hide();
            getAddressArray();
        });
    }

    const deleteAddress = (idAddress) => {
        deleteDeliveryAddress(idAddress, (successDelete) => {
            if(successDelete){
                showDialog('¡Acción exitosa!','Su dirección ha sido borrada');
                getAddressArray();
            }else{
                showDialog('Ocurrió un error','No fue posible borrar su dirección intente de nuevo');
            }
        })
    }

    const getAddressArray = () => {
        getAllAddressUser((array) => {
            setArrayAddress(array);
        });
    }

    const onSelectAddress = (index) => {
        localStorage.setItem(idAddressDel_,arrayAddress[index].idDomicilio)
        localStorage.setItem(desAddressDel_,arrayAddress[index].domicilio);
        localStorage.setItem(indexAddressDel_,index);
    }

    useEffect(() => {
        getAddressArray();
    },[]);

    return(
        <Grid container direction="column" justify="center" alignItems="center">
            <p className={sty.title}>Seleccione el lugar de entrega</p>
            <Grid item container direction="row" alignItems="center" justify="center">
                <p className={sty.addressTitle}>Tus direcciones:</p>
                <IconButton onClick={() => show()} aria-label="delete">
                    <AddIcon className={sty.imgAdd}/>
                </IconButton>
            </Grid>
            <ListAddress onSelectAddress={onSelectAddress} deleteAddress={dialogDelete} addresses={arrayAddress}/>
            <RenderModal>
                <AddAddress hide={hide} save={saveAddressUser}/>
            </RenderModal>
        </Grid>
    )
}