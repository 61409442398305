import {openDB, deleteDB} from 'idb';

const DATABASE_NAME = 'SOURCES_ONTABLE';
const DATABASE_VERSION = 2;

// const dbPromise = openDB(DATABASE_NAME, DATABASE_VERSION,function (upgradeDb){
//   switch (upgradeDb.oldVersion) {
//     case 0:
//       // a placeholder case so that the switch block will 
//       // execute when the database is first created
//       // (oldVersion is 0)
//     case 1:
//       console.log('Creating the products object store');
//       upgradeDb.createObjectStore('SplashVideo', { keyPath: 'id' });
//     // TODO 4.1 - create 'name' index
//     // TODO 4.2 - create 'price' and 'description' indexes
//     // TODO 5.1 - create an 'orders' object store
//   }
// });

async function doDatabaseStuff(){
  return await openDB(DATABASE_NAME,DATABASE_VERSION, {
    upgrade(db, oldVersion, newVersion, transaction) {
      switch (oldVersion) {
        case 0:
          // a placeholder case so that the switch block will 
          // execute when the database is first created
          // (oldVersion is 0)
        case 2:
          //const carrito = db.createObjectStore('ItemCarrito', { keyPath: 'id', autoIncrement: true });
          const carrito = db.createObjectStore('ItemCarrito', { keyPath: 'id'});
          carrito.createIndex('idProducto', 'idProducto', { unique: false });
          const topping = db.createObjectStore('ItemTopping', { keyPath: 'id', autoIncrement: true });
          topping.createIndex('idTopping', 'idTopping', { unique: false });
          db.createObjectStore('ItemOrder', { keyPath: 'id' });
        default:
          return;
    
        // TODO 4.1 - create 'name' index
    
        // TODO 4.2 - create 'price' and 'description' indexes
    
        // TODO 5.1 - create an 'orders' object store
    
      }
    }
  });
}

async function deleteDatabase(){
  
  return await deleteDB(DATABASE_NAME);
}


class DBService {
    delDB(){
      deleteDatabase();
    }

    getidx(tablespace, idxname, idx){
      return doDatabaseStuff().then(db => {
        const tb = db.transaction(tablespace,'readonly').objectStore(tablespace);
        const  tbindex = tb.index(idxname);
        return tbindex.getAll(idx);
      }).catch(error => {
          console.log(error);
        // Do something?
      });
    }

    get(tablespace, key) {
      return doDatabaseStuff().then(db => {
        return db.transaction(tablespace).objectStore(tablespace).get(key);
      }).catch(error => {
          console.log(error);
        // Do something?
      });
    }
  
    getAll(tablespace) {
      return doDatabaseStuff().then(db => {
        return db.transaction(tablespace,'readonly').objectStore(tablespace).getAll();
      }).catch(error => {
          console.log(error);
        // Do something?
      });
    }

    add(tablespace,item) {
        return doDatabaseStuff().then(db => {
            var tx = db.transaction(tablespace, 'readwrite');
            var store = tx.objectStore(tablespace);
            store.add(item);
            return tx.complete;
        }).catch( error => {
            console.log(error);
        });
    }
  
    put(tablespace, object, key = null) {
      return doDatabaseStuff().then(db => {
        if (key) {
          return db.transaction(tablespace, 'readwrite').objectStore(tablespace).put(object, key);
        }
        return db.transaction(tablespace, 'readwrite').objectStore(tablespace).put(object);
      }).catch(error => {
          console.log(error);
        // Do something?
      });
    }
  
    delete(tablespace, key) {
      return doDatabaseStuff().then(db => {
        return db.transaction(tablespace, 'readwrite').objectStore(tablespace).delete(key);
      }).catch(error => {
          console.log(error);
        // Do something?
      });
    }
  
    deleteAll(tablespace) {
      return doDatabaseStuff().then(db => {
        return db.transaction(tablespace, 'readwrite').objectStore(tablespace).clear();
      }).catch(error => {
          console.log(error);
        // Do something?
      });
    }
  }
  
  export const Service = new DBService()