//Styles
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {color_secondary_} from '../../Constants';

const getCustomTextField = (mainColor) => {
    return withStyles({
        root: {
            '& label.Mui-focused': {
            color: localStorage.getItem(color_secondary_), // mainColor,
         },
          '& .MuiInput-underline:after': {
            color: localStorage.getItem(color_secondary_), // mainColor,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: localStorage.getItem(color_secondary_), //mainColor,
            },
            '&:hover fieldset': {
              borderColor: localStorage.getItem(color_secondary_), //mainColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: localStorage.getItem(color_secondary_), //mainColor,
            },
          },
        },
    })(TextField);
}

export {
    getCustomTextField
}