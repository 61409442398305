import React,{useState, useEffect} from 'react';
//Components
import {Grid,Avatar,TextField} from '@material-ui/core';
//import {Grid,Avatar,Radio,RadioGroup,FormControlLabel,TextField} from '@material-ui/core';
import {getCustomButton} from '../Generics/CustomButton';
import {makeNormalDialog} from '../../utilscomponents/DialogAsk';
import TextFieldPass from '../Generics/TextFieldPass';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BackDrop from '../../utilscomponents/BackDrop';
/*import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';*/
//Apis
import {getAlergenos, registroNormal,getMsgErrorApi} from '../../../service/ManagerApis';
//Styles
//import {MainColors} from '../Sources/SourcesConst';
import { makeStyles} from '@material-ui/core/styles';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
import {color_accent_, color_main_, color_secondary_} from '../../Constants';

const useStyles = makeStyles(({
    back: {
       width: '100%',
       height: '100%',
       padding: '1vh',
       //color: MainColors.accent,
       backgroundColor: '#fff',
   },
   img:{
       margin: '0',
       width: '6vh',
       height: '6vh',
   },
    title: {
       //color: MainColors.main,
       fontSize: '4vh',
       fontWeight: '300',
       margin: '1vh',
       padding: '0',
    },
    textField: {
        width: '70vw',
        margin: '0.5vh'
    },
    contBtn: {
        margin: '1.5vh 0'
    }
 }));

const ValidateButton = getCustomButton(localStorage.getItem(color_secondary_),'2vh','bold','#fff','#fff');

export default function Registro(props){

    const sty = useStyles();
    const [showLoad,setShowLoad] = useState(false);
    const [name,setName] = useState("");
    const [apeMa,setApeMa] = useState("");
    const [apePa,setApePa] = useState("");
    const [userName,setUserName] = useState("");
    //const [genero,setGenero] = useState("");
    //const [alergenos,setAlergenos] = useState([]);
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [pass,setPass] = useState("");
    const [rPass,setRPass] = useState("");
    //const [selectedDate, setSelectedDate] = useState(new Date('2020-10-01T21:11:54'));

    const backPress = () => {
        props.changePage(false);
    }

    const validateData = () => {
        if(name === "" || apePa === "" || userName === "" || phone === "" || email === "" || pass === "" || rPass === ""){
            makeNormalDialog(<TranslateStr token="requiredFields"/>,null);
        }else{
            if(pass !== rPass){
                makeNormalDialog(<TranslateStr token="passDiff"/>,'',null);
            }else{
                setShowLoad(true);
                registroNormal(name,apePa,apeMa,userName,"M","",email,phone,pass, status => {
                    setShowLoad(false);
                    if(status){
                        makeNormalDialog(<TranslateStr token="okSignUp"/>,<TranslateStr token="verifyAccount"/>,backPress);
                    }else{
                        makeNormalDialog(<TranslateStr token="badSignUp"/>,getMsgErrorApi(<TranslateStr token="tryagain"/>),null);
                    }
                });
            }
        }
    }

    const onInputName = (e) => {
        e.persist();
        setName(e.target.value);
    }

    const onInputApePa = (e) => {
        e.persist();
        setApePa(e.target.value);
    }

    const onInputApeMa = (e) => {
        e.persist();
        setApeMa(e.target.value);
    }

    const onInputUserName = (e) => {
        e.persist();
        setUserName(e.target.value);
    }

    const onInputEmail = (e) => {
        e.persist();
        setEmail(e.target.value);
    }

    const onInputPhone = (e) => {
        e.persist();
        setPhone(e.target.value);
    }

    /* const handleChangeAlergenos = (selectedOption) => {
        setAlergenos(selectedOption);
    }; */

    /*const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleChangeGenero = (event) => {
        setGenero(event.target.value);
     };*/

    useEffect(() => {
        getAlergenos(array => {
            //setAlergenos(array);
        });
    },[]);

    return(
        <Grid container direction="column" justify="center" alignItems="center">
            {
                showLoad === true ?
                    <BackDrop show={showLoad}/> 
                :
                <div>
                    <Grid item container alignItems="flex-start">
                        <Grid item>
                            <Avatar onClick={() => props.changePage(false)} className= {sty.back} style={{color: localStorage.getItem(color_accent_)}} alt="Add icon">
                                <ArrowBackIcon className={sty.img}/>
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <p className={sty.title} style={{color: localStorage.getItem(color_main_)}}><TranslateStr token="titleSignUp"/></p>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <TextField 
                            className={sty.textField}
                            value={name} 
                            onChange={onInputName}
                            label={<TranslateStr token="name"/>} 
                        />
                    </Grid>

                    <Grid item>
                        <TextField 
                            className={sty.textField}
                            value={apePa} 
                            onChange={onInputApePa}
                            label={<TranslateStr token="lastnameOne"/>}  
                        />
                    </Grid>

                    <Grid item>
                        <TextField 
                            className={sty.textField}
                            value={apeMa} 
                            onChange={onInputApeMa}
                            label={<TranslateStr token="lastnameTwo"/>}  
                        />
                    </Grid>

                    <Grid item>
                        <TextField 
                            className={sty.textField}
                            value={userName} 
                            onChange={onInputUserName}
                            label={<TranslateStr token="username"/>}  
                        />
                    </Grid>

                    <Grid item>
                        <TextField 
                            className={sty.textField}
                            value={email} 
                            onChange={onInputEmail}
                            label={<TranslateStr token="email"/>}  
                        />
                    </Grid>

                    <Grid item>
                        <TextField 
                            className={sty.textField}
                            value={phone} 
                            onChange={onInputPhone}
                            label={<TranslateStr token="phone"/>}  
                        />
                    </Grid>

                    <Grid item>
                        <TextFieldPass className={sty.textField} title={<TranslateStr token="password"/>} onChangePass={setPass}/>
                    </Grid>

                    <Grid item>
                        <TextFieldPass className={sty.textField} title={<TranslateStr token="rpassword"/>} onChangePass={setRPass}/>
                    </Grid>

                    {/*<Grid item>
                        <RadioGroup row aria-label="gender" name="gender1" value={genero} onChange={handleChangeGenero}>
                            <FormControlLabel value="M" control={<Radio />} label="Mujer"/>
                            <FormControlLabel value="H" control={<Radio />} label="Hombre"/>
                            <FormControlLabel value="O" control={<Radio />} label="Otro"/>
                        </RadioGroup>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Fecha de nacimiento"
                                format="dd/MM/yyyy"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'Cambiar fecha',
                                }}
                            />
                            </MuiPickersUtilsProvider>
                    </Grid>
                    <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                    />*/}
                    <Grid className={sty.contBtn} item>
                        <ValidateButton onClick={validateData} variant="contained" style={{color: localStorage.getItem(color_secondary_)}}>
                            <TranslateStr token="createAcc"/>
                        </ValidateButton>
                    </Grid>
                </div>
            }
        </Grid>
    )
}