import {getStringStorage, getBoolStorage} from '../../../../service/HelperStorage';
import {lan_,idLan_, isBilingual_} from '../../../Constants';

export const initialState = {
    defaultLanguage : 'ES',
    selectedLanguage : getLanguage()
}

function getLanguage(){
    var selectedLanguage = getStringStorage(lan_);
    var Isbilingual = getBoolStorage(isBilingual_); 
    console.log('Isbilingual: ' + Isbilingual);
    if(selectedLanguage === ""){
        selectedLanguage = "ES";
        localStorage.setItem(idLan_,1);
        localStorage.setItem(lan_,"ES");
        localStorage.setItem('color_main','#516ac5');
        localStorage.setItem('color_secondary','#829af2');
        localStorage.setItem('color_accent','#808598');
    }
    if(!Isbilingual){
        selectedLanguage = "ES";
        localStorage.setItem(idLan_,1);
        localStorage.setItem(lan_,"ES");
    }
    return selectedLanguage;
}

// function getInformationOn(){
//     var selectedInfo = getStringStorage(InfoOn_);
//     if(selectedInfo === ""){
//         selectedInfo = "false";
//         localStorage.setItem(InfoOn_,false);
//     }
//     return selectedInfo;
// }

export function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case 'LANGUAGE_UPDATE' : {
            return { ...state, selectedLanguage: payload };
        }
        default: return state;
    }
}