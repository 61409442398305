import React from 'react';
//Components
import { CardMedia, Grid} from '@material-ui/core';
//Styles
import {makeStyles} from '@material-ui/styles';
import logoApp from '../../img/logoapp.png';
import './css/MainStyles.css';

const useStyles = makeStyles(() => ({
    root: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#000',
        flexGrow: 1,
    },
    logo: {
        width: '30vw',
        height: '30vw',
    },
}));

export default function Splash(){
    const sty = useStyles();
    return(
        <Grid className={sty.root} container direction="column" alignItems="center" justify="center">
                <Grid item>
                    <CardMedia className={sty.logo} image={logoApp}/>
                </Grid>
                <Grid item>
                    <p style={{fontSize:'6vh',fontWeight:'lighter',color:'#fff'}}>OnTable</p>
                </Grid>
        </Grid>
    );
}