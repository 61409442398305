import React from 'react';
//Styles
import {makeStyles} from '@material-ui/core/styles';
//Components
import TranslateStr from '../mainpages/ConfigPage/HelperLanguage/TranslateStr';
import {Backdrop,CircularProgress} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backDrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titleLoading: {
        margin: '0',
        fontSize: '3vh',
        fontWeight: '350',
        padding: '0 0 0 2vh',
    }
}));

export default function BackDrop (props){
    const sty = useStyles();
    return(
        <Backdrop className={sty.backDrop} open={props.show}>
            <CircularProgress color="inherit"/>
            <p className={sty.titleLoading}><TranslateStr token="loading"/></p>
        </Backdrop>
    )
}