import React, {useState,useEffect} from "react";
//Components
import Menus from '../Menu/Menus';
import {List,Grid,Fab} from '@material-ui/core';
import ListSubmenus from '../Submenus/ListSubmenus';
import Favoritos from '../Favoritos/Favoritos';
//import StarsIcon from '@material-ui/icons/Stars';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import ScanQR from '../QR/ScanQR';
import TranslateStr from "../ConfigPage/HelperLanguage/TranslateStr";
//Apis
import {API_BASE_IMG,makeRequest} from '../../../service/ManagerRequest';
import {idLan_, idEmpresa_,DosCarritos_, color_main_} from '../../Constants';
//Styles
//import {MainSize,MainColors} from '../Sources/SourcesConst';
import {MainSize} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
import ItemServicio from "./ItemServicio";

const useStyles = makeStyles(() => ({
    root: {
      width: MainSize.wFull,
      position: 'relative'
    },
    title:{
        //color: MainColors.secondary,
        padding: '2vh 0 0 5vw',
        margin: '0',
        fontSize: '5vh',
    },
    contFavorite:{
        margin: '1vh',
    },
    labelFavorite:{
        //color: MainColors.accent,
        padding: '0',
        margin: '0',
        fontSize: '2vh',
    },
    favorite: {
        width: '100%',
        height: '100%',
        padding: '0',
        //color: MainColors.main,
        backgroundColor: '#fff',
    },
    img:{
        margin: '0',
        width: '6vh',
        height: '6vh',
    },
    fab: {
        position: "fixed",
        zIndex: '10',
        bottom: '4vh',
        right: '4vh',
        //backgroundColor: MainColors.main,
        "&:hover, &.Mui-focusVisible": { backgroundColor: localStorage.getItem(color_main_) }
    },
    modal: {
        position: "absolute",
        zIndex: '16',
        bottom: '0vh',
        right: '6vh',
        fontSize: '9pt',
        fontWeight: 'bold',
        color: '#CB4335',
        opacity: '100%',
        background:'transparent',
        width:'137px',
        height: 'auto',
        textAlign:'left',
        //left: '50%',
        //transform: 'translate(-50%, -50%)',
      },
}));

export default function Servicios(props){
    const[servicios,setArrayServicios] = useState([]);
    const[arrayShowMenus,setArrayShowMenus] =  useState({
        idMenu: 0,
        status:0,
        dataMenu_: null
    });
    const[configSubMenu,setConfigSub] = useState({nombreMenu:"",idMenu:0,showSubmenu:false});
    const [page,setPage] = useState(0);
    const classes = useStyles();
    

    const getBoolStorage = (key) => {
        const storage = localStorage.getItem(key);
        return storage === null || storage === 'false' ? false : true;
    }
    const verDosCarritos = getBoolStorage(DosCarritos_);

    const handleResponse = async (dataMenu,status) => {
        if(status){
            if(dataMenu.length !== 0){
                await dataMenu.forEach(element => {

                    //element['visible'] = true;

                    const data = {
                        idMenu: element.idMenu,
                        idIdioma: localStorage.getItem(idLan_)
                    }
                    
                    makeRequest('application/json',data,'GET','masterdata/getAllProductosSubMenu/WPA',true,true,(dataRes,success) => {
                        if(success && dataRes.data.length!=0){
                            
                            // const newMenu = dataMenu.filter((dataMenu) => dataMenu.idMenu === element.idMenu);
                            // console.log({newMenu});
                            // arrayFinal.push(newMenu);

                            // newMenu.forEach(itemMenu => {
                            //     arrayFinal.prototype.push(itemMenu);
                            // });
                            //setArrayMenus(newMenu);
                           
                        }
                        else{
                            // console.log('idMenu..' + element.idMenu);
                            // console.log(element);
                            dataMenu.splice(dataMenu.indexOf(element), 1); 
                            //element['visible'] = false;

                            setArrayShowMenus({
                                idMenu:element.idMenu,
                                status:1,
                                dataMenu_:dataMenu
                            });
                        }
                    });
                   
                });
            }
            //console.log('menus servicios...');
            //console.log(dataMenu.sort());
            setArrayServicios(dataMenu.sort());
            //console.log(status);
            //console.log(dataMenu);
        }
    }

    const changeShowSub = (idMenu_,nombreMenu_) => {
        setConfigSub({nombreMenu:nombreMenu_,idMenu:idMenu_,showSubmenu:!configSubMenu.showSubmenu})
    }

    useEffect(() => {  
        const dataGetServicios = {
            idRestaurant : localStorage.getItem(idEmpresa_),
            idIdioma : localStorage.getItem(idLan_)
        }
        makeRequest('application/json',dataGetServicios,'GET','masterdata/getMenu/Servicios',false,false,async (data,success) => await handleResponse(data,success));
        if(verDosCarritos){
            localStorage.setItem('idTipoOrden',2);
        }
        
    },[]);

    const onBackPress = (index) => {
        if(index === -1){
            setPage(0);
        }
    }
    
    return(
        <div className={classes.root}>
            {
                page === 0 &&
                <Fab className={classes.fab} style={{backgroundColor: localStorage.getItem(color_main_)}} color="primary" onClick={() => setPage(-1)}>
                    <RoomServiceIcon />
                    {<p className={classes.modal}>{props.showInformationOn ? <TranslateStr token="showInfoWaiter"/> : ''}</p>}
                </Fab>
            }
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                    {/* <p className={classes.title}><TranslateStr token="titleMenus"/></p> */}
                </Grid>
                {/* <Grid className={classes.contFavorite} item>
                    <Grid container direction="column" justify="space-between" alignItems="center">
                        <Avatar onClick={() => setPage(1)} className= {classes.favorite} alt="Add icon">
                            <StarsIcon className={classes.img}/>
                        </Avatar>
                        <p className={classes.labelFavorite}><TranslateStr token="titleFavorite"/></p>
                    </Grid>
                </Grid> */}
            </Grid>
            {
                (function(){
                    switch(page){
                    case 0:
                        return !configSubMenu.showSubmenu ?
                        <List>
                            {
                                servicios.map( item => (
                                    <ItemServicio 
                                        key={servicios.indexOf(item)} 
                                        url={API_BASE_IMG + item.imgUri}
                                        title={item.nombreMenu}
                                        idTipoMenu={2}
                                        showSub={changeShowSub}
                                        idMenu={item.idMenu}
                                        setconfigCarrito={props.setconfigCarrito}
                                    />
                                ))
                                
                            }
                            {
                            verDosCarritos ?
                                <ItemServicio 
                                    key={servicios.length} 
                                    url={API_BASE_IMG + 'icon/IconosMenu/star32P.png'}
                                    title={<TranslateStr token="titleTipoMenu" />}
                                    idTipoMenu={2}
                                    showSub={changeShowSub}
                                    idMenu={0}
                                    setconfigCarrito={props.setconfigCarrito}
                                />
                            :
                            <></>
                            }
                        </List>
                        :
                        (!configSubMenu.idMenu==0 ?
                            <ListSubmenus nombreMenu={configSubMenu.nombreMenu} idMenu={configSubMenu.idMenu} idTipoMenu={2} back={changeShowSub} showInformationOn={props.showInformationOn} setconfigCarrito={props.setconfigCarrito}/>
                            :
                            <Menus nombreMenu={configSubMenu.nombreMenu} idMenu={0} back={changeShowSub} showInformationOn={props.showInformationOn} setconfigCarrito={props.setconfigCarrito}/>
                        )
                        case 1: 
                        return <Favoritos changePage={setPage}/>
                    case -1:
                        return <ScanQR callwaiter={true} idTipoMenu={2} back={onBackPress} changeEmpresa={false} sendOrden={false} sendQueja={false}/>
                    default:
                        return;
                    }
                })()
            }
            
        </div>
    );
}