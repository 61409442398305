import React,{useState} from 'react';
//Components
import {
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    ListItemIcon
} from '@material-ui/core';
//Styles
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ApartmentIcon from '@material-ui/icons/Apartment';
//Others
import { indexAddressDel_ } from '../../../Constants';

export default function ListAddress(props){

    const [selectedIndex, setSelectedIndex] = useState(localStorage.getItem(indexAddressDel_) !== null ? parseInt(localStorage.getItem(indexAddressDel_)) : 0);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        props.onSelectAddress(index);
    };

    return(
        <Grid container justify="center" alignItems="center">
            <List component="nav" aria-label="main mailbox folders">
                {
                    props.addresses.map((item,index) => (
                        <ListItem
                            role={undefined} 
                            dense
                            key={index}
                            button
                            selected={selectedIndex === index}
                            onClick={(event) => handleListItemClick(event, index)}
                        >
                            <ListItemIcon>
                                <ApartmentIcon />
                            </ListItemIcon>

                            <ListItemText primary={item.domicilio} />

                            <ListItemSecondaryAction>
                                <IconButton onClick={() => props.deleteAddress(item.idDomicilio)} edge="end" aria-label="delete">
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                            
                        </ListItem>
                    ))
                }
            </List>
        </Grid>
    )
}