import {getBoolStorage,getStringStorage} from '../../../../service/HelperStorage';
//import ConfigPage from '../ConfigPage';
import {InfoOn_} from '../../../Constants';

export default function InformationOn() {
    var selectedInfo = getStringStorage(InfoOn_);
    if(selectedInfo === ""){
        selectedInfo=true;
        localStorage.setItem(InfoOn_,true);
    }
    else{
        selectedInfo = !getBoolStorage(InfoOn_);
        localStorage.setItem(InfoOn_,selectedInfo);
    }
    //console.log('Show Info ' + selectedInfo);
    return selectedInfo;
}