import {Service} from './DBService';
import {idEmpresa_,idTipoOrden_} from '../components/Constants';
import {getNameProducto} from './ManagerApis';
//import Topping from '../components/mainpages/Submenus/Topping';
const tableMain = "ItemCarrito";
const tableTopping = "ItemTopping";

// const updateCarrito = (setResultDDB) => {
//     const idEmpresa = localStorage.getItem(idEmpresa_);

//     Service.getAll(tableMain).then(array => {
//         if(array.length !== 0){
//             array.map(function(item){
//                 getNameProducto(item.id,1,(nombreProducto,desMenu) => {
//                     console.log(nombreProducto);
//                     item.nameESP = nombreProducto;
//                     item.desESP = desMenu;
//                     Service.put(tableMain,item,null);
//                 });
//                 getNameProducto(item.id,2,(nombreProducto,desMenu) => {
//                     console.log(nombreProducto);
//                     item.nameENG = nombreProducto;
//                     item.desENG = desMenu;
//                     Service.put(tableMain,item,null);
//                 });    
//             })
//         }
//     });
    
//     Service.getAll(tableMain).then(array => {
//         var arrayFinal = [];
//             if(array.length !== 0){
//                 array.map(function(item){
//                     if(item.idEmpresa === parseInt(idEmpresa) ){
//                         // getNameProducto(item.id,(nombreProducto,desMenu) => {
//                         //     console.log(nombreProducto);
//                         //     item.name = nombreProducto;
//                         //     item.des = desMenu;
//                         //     Service.put(tableMain,item,null);
//                         // });
//                         arrayFinal.push(item);
//                     }
//                 })
//             }
            
//             //console.log(arrayFinal);
//             setResultDDB(arrayFinal);
//     });
// }

const allItemToppingbyIdProd = async (IdCarrito, idProduct,setResultDDB) => {
    await Service.getidx(tableTopping,'idTopping',IdCarrito).then(array => {
        var arrayFinal = [];
            if(array.length !== 0){
                array.map(function(item){
                    if(parseInt(item.idProducto) === parseInt(idProduct) ){
                        arrayFinal.push(item);
                    }
                })
            }
            
            //console.log(arrayFinal);
            setResultDDB(arrayFinal);
    });
}

const allItemToppingbyIdCar = async (IdCarrito, setResultDDB) => {
    await Service.getidx(tableTopping,'idTopping',IdCarrito).then(array => {
        var arrayFinal = [];
        if (array.length !== 0) {
            array.map(function (item) {
                arrayFinal.push(item);
            })
        }
        //console.log(arrayFinal);
        setResultDDB(arrayFinal);
    });
}

const allItemToppings =async (setResultDDB) => {
    const idEmpresa = localStorage.getItem(idEmpresa_);
    //console.log();
    await Service.getAll(tableTopping).then(array => {
        var arrayFinal = [];
            if(array.length !== 0){
                array.map(function(item){
                    if(item.idEmpresa === parseInt(idEmpresa) ){
                        arrayFinal.push(item);
                    }
                })
            }
            
            //console.log(arrayFinal);
            setResultDDB(arrayFinal);
    });
    
}

const allItemCarrito = async (setResultDDB) => {
    const idEmpresa = localStorage.getItem(idEmpresa_);
    //console.log();
    await Service.getAll(tableMain).then(array => {
        var arrayFinal = [];
            if(array.length !== 0){
                array.map(function(item){
                    if(item.idEmpresa === parseInt(idEmpresa) ){
                        arrayFinal.push(item);
                    }
                })
            }
            
            //console.log(arrayFinal);
            setResultDDB(arrayFinal);
    });
    
}

const allItemCarritoTipo = (setResultDDB) => {
    //Función para saber el numero de ordenes a generar por area de servicio
    const idEmpresa = localStorage.getItem(idEmpresa_);
    //const tipo= localStorage.getItem(idTipoOrden_);
    var tipo=0;
    var area=0;
    //console.log('AREAS DE SERVICIO');
    Service.getAll(tableMain).then(array => {
        var arrayFinal = [];
        if(array.length !== 0){
            array.forEach(element => {
                if(parseInt(element.idTipoMenu)===2){
                    if(parseInt(element.idEmpresa) === parseInt(idEmpresa) && parseInt(element.idAreaDeServicio)!==parseInt(area)){
                        arrayFinal.push(element);
                        area=element.idAreaDeServicio;
                    }
                }
                else{
                    if(parseInt(element.idEmpresa) === parseInt(idEmpresa) && parseInt(element.idTipoMenu)!==parseInt(tipo)){
                        arrayFinal.push(element);
                        tipo=element.idTipoMenu;
                    }
                }
                
            });
        }
        console.log(arrayFinal);
        setResultDDB(arrayFinal);
    });
}

const allItemCarritobyTipo = (tipo,area,setResultDDB) => {
    //Función para crear carrito por area de servicio y generar orden
    const idEmpresa = localStorage.getItem(idEmpresa_);
    //var arrayFinal = [];
    //const tipo= localStorage.getItem(idTipoOrden_);
    Service.getAll(tableMain).then(array => {
        var arrayFinal = [];
        if(array.length !== 0){
            array.map(function(item){
                //if(parseInt(item.idEmpresa) === parseInt(idEmpresa) && parseInt(item.idTipoMenu)===parseInt(tipo) && parseInt(item.idAreaDeServicio)===parseInt(area)){
                if(parseInt(item.idEmpresa) === parseInt(idEmpresa) &&  parseInt(item.idAreaDeServicio)===parseInt(area)){
                    arrayFinal.push(item);
                }
            });
        }
        //console.log('Carrito tipo ' + tipo);
        //console.log('Carrito area ' + area);
        //console.log(arrayFinal);
        setResultDDB(arrayFinal);
    });
}

const deleteAllCarrito = () => {
    Service.deleteAll(tableMain).then(() => {
        deleteAllToppings();
    }).catch(err => {
        console.log(err);
    });
}

const deleteAllToppings = () => {
    Service.deleteAll(tableTopping).then(() => {
        
    }).catch(err => {
        console.log(err);
    });
}

const deleteCarritoByTipo = (tipo) => {
    allItemCarrito(array => {
        array.forEach(element => {
            if( parseInt(element.idTipoMenu)===parseInt(tipo)){
                Service.delete(tableMain,element.id);
            }

        });
    });
}


const deleteToppingsByidTopping = (idtopping, Eliminados) => {
    let eliminados = 0;
    allItemToppingbyIdCar(idtopping, array => {
        
        array.forEach(element => {
            if( parseInt(element.idTopping)===parseInt(idtopping)){
                Service.delete(tableTopping,element.id);
                eliminados = eliminados + 1;
            }
        });
        Eliminados(eliminados);
    });
    //Eliminados(eliminados);
}

const itemCarritobyIdProd = async (idProduct,setResultDDB) => {
    const idEmpresa = localStorage.getItem(idEmpresa_);
    await Service.getidx(tableMain,'idProducto',idProduct).then(array => {
        var arrayFinal = [];
        if(array.length !== 0){
            array.map(function(item){
                if(parseInt(item.idEmpresa) === parseInt(idEmpresa) ){
                    arrayFinal.push(item);
                }
            })
        }
            
        //console.log(arrayFinal);
        setResultDDB(arrayFinal);
    });
}

const itemCarritoById = (id,setResultDB) => {
    Service.get(tableMain,id).then(item => {
        setResultDB(item);
    });
}

const ItemToppingById = (id,setResultDB) => {
    Service.get(tableTopping,id).then(item => {
        setResultDB(item);
    });
}

const deleteItemTopping = (id, idprod, onResult) =>{
    //console.log('id Carrito: ' + id);
    //console.log('id topping: ' + idprod);

    allItemToppingbyIdProd(id, idprod, (item) => {
        console.log('item topping a eliminar...');
        console.log(item);
        
        if (item.length > 0) {
            const precio = item[0].price;
            ItemToppingById(item[0].id, (itemT) => {
                console.log('itemT...');
                console.log(itemT);
                if (itemT.cantidad > 1) {
                    itemT.cantidad = itemT.cantidad - 1;
                    Service.put(tableTopping, itemT, null);
                } else {
                    Service.delete(tableTopping, itemT.id);
                }
            });

            itemCarritoById(id,(itemcarrito) => {
                if (itemcarrito !== undefined) {
                    //console.log('idtopping: ' + item.idTopping);
                    //console.log('idProducto: ' + data.idProducto);
                    itemcarrito.totalToppings = itemcarrito.totalToppings - precio;
                    Service.put(tableMain, itemcarrito, null);
                }
            });
        }

        onResult();
    });
}

const deleteItemCarritomaxId = (idProduct,onResult) => {
    itemCarritobyIdProd(idProduct, (itemCarrito) => {
        if(itemCarrito.length>0){
            const itemorder = itemCarrito.sort(((a, b) => {
                return b.id - a.id;
            }));
            
            const ind = itemorder[0].id;

            deleteItemCarrito(ind,(result) => {
                onResult(result);
            
            });
        }
    });

}
const deleteItemCarrito = (id,onResult) => {
    itemCarritoById(id,(item) => {
        if(item !== undefined){
            const isTopping = item.esTopping;

            if(item.cantidad > 1){
              item.cantidad = item.cantidad-1;
              Service.put(tableMain,item,null);
              onResult(0);
            }else{
                Service.delete(tableMain,id);
                if(!isTopping){
                    deleteToppingsByidTopping(id, (Eliminados) => {
                        //console.log('topings eliminados: ' + Eliminados);
                        onResult(Eliminados);
                    }) ;
                }
                else{
                    onResult(0);
                }
            }
        }
        //onResult();
    });
}

const saveItemTopping = (id,data,isItemFromDB,onResult) => {
    allItemToppingbyIdProd(id,data.idProductoTopping, item => {
        //console.log('Topping encontrado...');
        //console.log(item);
        if(item.length > 0){
            console.log('item.id...');
            console.log(item[0].id);
            ItemToppingById(item[0].id,(itemT) => {
                console.log('itemT...');
                console.log(itemT);
                itemT.cantidad =itemT.cantidad+1;
                Service.put(tableTopping,itemT,null);
                
                onResult();
            });
        }
        else{
            const dataSave = {
                //id: ind,
                idProducto: data.idProductoTopping,
                idEmpresa:data.idEmpresa,
                //name: isItemFromDB ? data.name : data.nombreSubMenu,
                //des: isItemFromDB ? data.des : data.nombreMenu,
                //url: isItemFromDB ? data.url : data.imagenUri,
                price: data.precioDescuento,
                cantidad: 1,
                //idTipoMenu: TipoMenu,
                //idAreaDeServicio: area ,
                //nameENG:'',
                //nameESP: '',
                //desMenuESP:'',
                //desMenuENG:'',
                //tieneTopping: data.tieneTopping,
                esTopping: true, //isItemFromDB ? data.esTopping : (data.nombreMenu == 'Toppings'? true : false),
                idTopping: id,
                ///comments:[]
            }
            if(!isItemFromDB){
                Service.add(tableTopping,dataSave);
            } 
            onResult();
        }

        itemCarritoById(id,(itemcarrito) => {
            if (itemcarrito !== undefined) {
                //console.log('idtopping: ' + item.idTopping);
                //console.log('idProducto: ' + data.idProducto);
                itemcarrito.totalToppings = itemcarrito.totalToppings + data.precioDescuento;
                Service.put(tableMain, itemcarrito, null);
            }
        });
    });
}

const saveItemCarrito = (id,data,isItemFromDB,onResult) => {
    var TipoMenu= parseInt(localStorage.getItem(idTipoOrden_));
    var area=1;
    var ind = 1;

    //console.log('TipoMenu: ' + TipoMenu);
    //console.log(data);
    //itemCarritoById(id,(item) => {
    allItemCarrito(item => {
       
        const itemorder = item.sort(((a, b) => {
            return b.id - a.id;
        }));
        //console.log('itemorder...');
        //console.log(itemorder);
        if (itemorder.length>0)
            ind = itemorder[0].id + 1;
        else
            ind = 1;
        
        //console.log('id item:' + ind);
        // if(item !== undefined){
        //     //console.log('idtopping: ' + item.idTopping);
        //     //console.log('idProducto: ' + data.idProducto);
        //     item.cantidad = item.cantidad+1;
        //     Service.put(tableMain,item,null);
        // }else{
            //if(data.idAreaDeServicio===1) { TipoMenu=1 }
            if(parseInt(TipoMenu)===0 ) { TipoMenu=parseInt(data.idTipoMenu) }
            if(parseInt(TipoMenu)===2){ area=data.idAreaDeServicio }
            const dataSave = {
                id: ind,
                idProducto: data.nombreMenu == 'Toppings'? data.idProductoTopping : data.idProducto,
                idEmpresa:data.idEmpresa,
                name: isItemFromDB ? data.name : data.nombreSubMenu,
                des: isItemFromDB ? data.des : data.nombreMenu,
                url: isItemFromDB ? data.url : data.imagenUri,
                price: isItemFromDB ? data.price : data.precio,
                cantidad: 1,
                idTipoMenu: TipoMenu,
                idAreaDeServicio: area ,
                nameENG:'',
                nameESP: '',
                desMenuESP:'',
                desMenuENG:'',
                tieneTopping: data.tieneTopping,
                esTopping: isItemFromDB ? data.esTopping : (data.nombreMenu == 'Toppings'? true : false),
                idTopping: isItemFromDB ? data.idTopping : data.idProducto,
                totalToppings: 0,
                comments:[]
            }
            //if(!isItemFromDB){
               
                Service.add(tableMain,dataSave);
            //}
        //}
        //onResult();
        //data.idProducto
        itemCarritoById(ind,(item) => {
            if(item !== undefined){
                getNameProducto(item.idProducto,1,(NombreIdioma,nombreMenuIdioma) => {
                    item.nameESP=NombreIdioma;
                    item.desMenuESP=nombreMenuIdioma;
                    //console.log('ESPAÑOL');
                    //console.log(NombreIdioma);
                    Service.put(tableMain,item,null);
                });
    
                getNameProducto(item.idProducto,2,(NombreIdioma,nombreMenuIdioma) => {
                    item.nameENG=NombreIdioma;
                    item.desMenuENG=nombreMenuIdioma;
                    //console.log('ENGLISH');
                    //console.log(NombreIdioma); 
                    Service.put(tableMain,item,null);
                });
               
            }
            //onResult(ind);
        });
        onResult(ind);
    });
}

const deleteComment = (indexComment,idItem,onResult) => {
    itemCarritoById(idItem,(item) => {
        var arrayComments = item.comments;
        var newarray = arrayComments.filter((value) => value !== arrayComments[indexComment]);
        item.comments = newarray;
        Service.put(tableMain,item,null);
        onResult();
    });
}

const addComment = (comment,idItem,onResult) => {
    itemCarritoById(idItem,(item) => {
        item.comments.push(comment);
        Service.put(tableMain,item,null);
        onResult();
    });
}

const updateCommentDB = (comment,indexComment,idItem,onResult) => {
    itemCarritoById(idItem,(item) => {
        item.comments[indexComment] = comment;
        Service.put(tableMain,item,null);
        onResult();
    });
}

const getInfoCarrito = (setResult) => {
    var total = 0.0;
    var count = 0;
    var totalToppings = 0.0;

    allItemCarrito(array => {
        array.forEach(element => {
            count += parseInt(element.cantidad);
            total += parseInt(element.cantidad) * parseFloat(element.price);
            totalToppings += parseInt(element.totalToppings);
        });
        setResult(total,count,totalToppings);
    });
}

const getInfoToppings = async (idCar, setResult) => {
    var total = 0.0;
    var count = 0;
    await allItemToppingbyIdCar(idCar,array => {
        array.forEach(element => {
            count += parseInt(element.cantidad);
            total += parseInt(element.cantidad) * parseFloat(element.price);
        });
        setResult(total,count);
    });
}

const getInfoTopping =  (idCar,idprod,setResult) => {
    var total = 0.0;
    var count = 0;
    allItemToppingbyIdProd(idCar,idprod,array => {
        array.forEach(element => {
            count += parseInt(element.cantidad);
            total += parseInt(element.cantidad) * parseFloat(element.price);
        });
        setResult(total,count);
    });
}

const decimalAdjust = (type, value, exp) => {
    // Si el exp no está definido o es cero...
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
      }
      value = +value;
      exp = +exp;
      // Si el valor no es un número o el exp no es un entero...
      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
      }
      // Shift
      value = value.toString().split('e');
      value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
      // Shift back
      value = value.toString().split('e');
      return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));

}

const formatoMexico = (valor) => {

    // {
   //     locale: "en-US"
   //     maximumFractionDigits: 3
   //     minimumFractionDigits: 0
   //     minimumIntegerDigits: 1
   //     notation: "standard"
   //     numberingSystem: "latn"
   //     signDisplay: "auto"
   //     style: "decimal"
   //     useGrouping: true
   //     currency: "MXN",
   //     }
   return (new Intl.NumberFormat("en-US", {style: "decimal",  maximumFractionDigits: 2,
   minimumFractionDigits: 0}).format(valor));
}

// const DescribeProducto = (idProducto) => {
    
//     getNameProducto(idProducto,(dataProducto) => {
//         console.log(dataProducto);
//         nombre= dataProducto;
//         return nombre;
        
//     });
    
// }
export {formatoMexico,decimalAdjust,getInfoCarrito,deleteAllCarrito,deleteCarritoByTipo,
    allItemCarrito,allItemCarritoTipo,allItemCarritobyTipo,itemCarritoById,deleteItemCarrito,
    saveItemCarrito,deleteComment,addComment,updateCommentDB,saveItemTopping,getInfoTopping,
    allItemToppingbyIdCar, getInfoToppings, allItemToppings, deleteItemTopping, deleteItemCarritomaxId,
    itemCarritobyIdProd }
