export const dictionary = {
    ES: {
        changeAccount: 'Cambiar cuenta',
        menu: 'Menú',
        searchlabel: 'Buscar...',
        titleSearch: 'Resultados de la búsqueda',
        avisoPrivacidad: 'Aviso de privacidad',
        //Scan
        wrongQr: '¡QR incorrecto!',
        waiterComing: '¡Personal en camino!',
        waiterWillBeThere: 'Personal en camino, pronto se presentará en su ubicación', //'Pronto un mesero se presentará en su mesa',
        verifyQrRestaurant: 'Por favor verifique que el QR sea del negocio en donde está',
        scanToChange: 'Escanee el QR más cercano para cambiar de negocio',
        scanToSend: 'Escanee el QR más cercano para enviar su orden',
        scanLocation: 'Escanee el QR más cercano para actualizar su ubicación',
        scanToCall: 'Escanee el QR más cercano para llamar a un mesero/camarero',
        scanToComplaint: 'Escanee el QR más cercano para enviar su comentario',
        outOfService: 'Fuera de servicio',
        //Openpay
        titlePayment: 'Pago en línea',
        errorCreateDelOrder: 'La orden a domicilio no fue creada',
        okPaymentOrder: 'Sus platillos han sido pagados correctamente, gracias por su preferencia',
        errorPayment: 'El pago no pudo completarse',
        invalidCard: 'Número de tarjeta inválido',
        invalidCvv: 'Cvv inválido',
        invalidExpDate: 'Fecha de expiración inválida',
        products: 'producto(s)',
        credirCards: 'Tarjetas de crédito',
        debitcards: 'Tarjetas de debito',
        byOpenpay: 'Transacción realizada vía:',
        securePaymentByOpenpay: 'Tus pagos se realizan de forma segura con encriptación',
        tip: 'Propina:',
        pay: 'Pagar',
        //Carrito
        titleCart: 'Carrito',
        optionDel: 'Orden a domicilio',
        optionRes: 'Ordenar en restaurante',
        emptyCart: 'Carrito vacío',
        emptyCartDes: 'Seleccione algunos platillos para poder ordenar',
        //Favorite
        emptyFavorite: 'Por el momento no hay sugerencias de platillos',
        titleFavorite: 'Favoritos del público',
        //Menus
        titleMenus: 'Menús',
        titleTipoMenu: 'Restaurante',
        //Order
        sendOrderTitle:'Orden enviada',
        finishedOrder: '¡Orden finalizada!',
        lastOrderFinish: 'La orden que realizó anteriormente ha concluido, gracias por su preferencia',
        canceledOrder: 'Orden cancelada.',
        lastOrderCenceled: 'Por favor contactenos para más información',
        sendOrderOk: 'Su pedido se creó correctamente',
        sendOrderError: 'No fue posible enviar su orden, intente de nuevo por favor',
        untilOpen: 'La orden que hizo anteriormente sigue abierta',
        errorBringDishData: 'No se pudo traer datos de los platillos, intente nuevamente',
        //Status orden
        refreshOrders: 'Actualizar lista de ordenes',
        titleStatusOrder: 'Estado de orden',
        status: 'Estado',
        numOrder: 'Orden',
        validatingOrder: 'Su orden esta en espera',
        makingOder: 'Su orden esta en proceso',
        partialOrder: 'Orden entregada, algunos productos ya han sido pagados',
        assignedOrder: 'Su orden ha sido asignada',
        payOrder: 'Pagar orden',
        callCheck: 'Pedir cuenta',
        uSureCallCheck: '¿Esta seguro de pedir la cuenta?',
        okCallCheck: 'Se ha pedido la cuenta correctamente',
        comingCheck: 'Un mesero/camarero vendrá en breve',
        faildCallCheck: 'No fue posible pedir la cuenta',
        totalOrder: 'Total orden:',
        selectedTotal: 'Total a pagar:',
        noOrder: 'Por el momento no tiene una orden abierta',
        itemsOrder: 'Platillos de orden',
        selectedItemsOrder: 'Platillos a pagar seleccionados',
        //Record
        titleRecord: 'Historial (califica tu consumo)',
        titleLongRecord: 'Historial de órdenes',
        typeDelivOrder: 'Delivery order',
        typeRestOrder: 'Ordenes',
        comment: 'Comentario',
        comments: 'Comentarios',
        rateDish: 'Califique el platillo',
        doneComment: 'Comentario hecho',
        thanksForRate: 'Gracias por tu opinión!',
        errorRate: 'Debe asignar una calificación',
        //Login
        login: 'Iniciar sesión',
        rusurelogout: "¿Está seguro de cerrar sesión?",
        thkslogin: 'Gracias por iniciar sesión',
        createAcc: 'Crear cuenta',
        passUserWrong: 'Tu contraseña o nombre de usuario es incorrecto',
        orLogin:'O',
        typePassUser: 'Ingresa tus credenciales para iniciar sesión',
        noAccount: '¿Aún no tienes una cuenta?',
        signUpHere: 'Registrate aquí',
        buttonTextGoogle: 'Inicia sesión con Google',
        buttonTextFB: 'Inicia sesión con Facebook',
        //Sign up
        titleSignUp: 'Registro',
        passDiff: 'Las contraseñas no coinciden',
        okSignUp: 'Registro exitoso',
        badSignUp: 'Registro fallido',
        verifyAccount: 'Verifique su cuenta con el enlace que enviamos a su correo',
        //Dish
        indications: 'Indicaciones',
        addorder: 'Orden agregada',
        removeorder: 'Orden eliminada',
        add: 'Agregar',
        addTopping: 'Agregar Toppings',
        errorTopping : 'Primero agregue un producto en la orden',
        remove: 'Quitar',
        //Generic messages
        goMenus: 'Ir al menú',
        welcomeApp: '¡Bienvenido a OnTable!',
        welcomeTo: 'Bienvenido a',
        sorry: 'Lo sentimos',
        welcome: '¡Bienvenido!',
        total: 'Total',
        save: 'Guardar',
        emptyfields: 'Campos vacíos',
        requiredFields: 'Hay campos faltantes',
        saveddata: 'Datos guardados',
        updateddata: 'Su información se actualizó correctamente',
        faildEdit: 'Edición fallida',
        tryagain: 'Intente de nuevo',
        update: 'Actualizar',
        updateProfile: 'Editar perfil',
        continue: 'Continuar',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        alsoScanTable: 'También puedes escanear el QR mas cercano',
        changeRestaurant: 'Recargar App',
        errorSetRest: 'Ocurrió un error al tratar de configurar la empresa',
        successfulAccion: '¡Acción exitosa!',
        accept: 'Aceptar',
        verifyAllComplete: 'Verifique que toda la información esté completa',
        loading: 'Procesando...',
        //Generics fields
        name: 'Name',
        lastnameOne: 'Apellido paterno',
        lastnameTwo: 'Apellido materno',
        username: 'Nombre de usuario',
        phone: 'Teléfono',
        email: 'Correo',
        emailUsername: 'Correo o cuenta de usuario',
        password: 'Contraseña',
        rpassword: 'Repita la contraseña',
        fullName: 'Nombre completo',
        cardNumber: 'Número de tarjeta',
        year: 'Año',
        month: 'Mes',
        cvv: 'cvv',
        //Language
        language: 'Idioma',
        //Errors
        ups: 'Ups',
        somewrong: 'Ocurrió un error',
        sorryTryAgain: 'Disculpe las molestias, intente de nuevo',
        nocheckorder: 'No se pudo verificar el estado de su orden',
        requiredLogin: 'Se requiere iniciar sesión para la acción que desea realizar',
        //Information
        showInformation: 'Información',
        showInfoConfig: 'Configura y califica',
        showInfoMenu: 'Menú y catálogo',
        showInfoCart: 'Carrito de órdenes',
        showInfoOrder: 'Estado de la orden',
        showInfoSwipeRight: 'Desliza y agrega ->',
        showInfoSwipeLeft: '<- Desliza y elimina', 
        showInfoWaiter: 'Llamar mesero/camarero',
        showInfoSendOrder: 'Enviar orden',
        //Quejas y sugerencias
        titleComplaints: 'Quejas y sugerencias',
        showInfoComplaints: 'Enviar comentario',
        showInfoComplaintsBox: 'Buzón',
        requiredComplaints: 'Escriba su comentario para poder enviar',
        sendComplaintTitle:'Comentario enviado',
        sendComplaintOk: 'Su comentario se envió correctamente',
    },
    EN: {
        changeAccount: 'Change user',
        menu: 'Menu',
        searchlabel: 'Search...',
        titleSearch: 'Search results',
        avisoPrivacidad: 'Privacy notice',
        //Scan
        wrongQr: 'Invalid QR!',
        waiterComing: 'Staff on the way!',
        waiterWillBeThere: 'Staff on route, soon will come to your location', //'Soon a waiter will come to your table',
        verifyQrRestaurant: 'Please verify that the QR is from the place where you are',
        scanToChange: 'Scan the nearest QR to change company',
        scanToSend: 'Scan the nearest QR to create an order',
        scanLocation: 'Scan the nearest QR to send your location',
        scanToCall: 'Scan the nearest QR to call a waiter',
        scanToComplaint: 'Scan the nearest QR to send your comment',
        outOfService: 'Out of service',
        //Open-pay
        titlePayment: 'Online payment',
        errorCreateDelOrder: 'Delivery order was not created',
        okPaymentOrder: 'Your dishes have been paid correctly, thank you very much',
        errorPayment: 'Payment could not be completed',
        invalidCard: 'Invalid number card',
        invalidCvv: 'Invalid cvv',
        invalidExpDate: 'Invalid expiration date',
        products: 'product(s)',
        credirCards: 'Credit cards',
        debitcards: 'Debit cards',
        byOpenpay: 'Payments work by:',
        securePaymentByOpenpay: 'Your payments are made securely with 256-bit encryption',
        tip: 'Tip:',
        pay: 'Pay',
        //Cart
        titleCart: 'Cart',
        optionDel: 'Delivery order',
        optionRes: 'On site order',
        emptyCart: 'Empty cart',
        emptyCartDes: 'Select some items to order',
        //Favorite
        emptyFavorite: 'No favorite dishes',
        titleFavorite: 'Favorites',
        //Menus
        titleMenus: 'Menus',
        titleTipoMenu: 'Restaurant',
        //Order
        sendOrderTitle:'Order is done',
        finishedOrder: 'Order complete!',
        lastOrderFinish: 'The previous order has concluded, thank you for your preference',
        canceledOrder: 'The order has been canceled.',
        lastOrderCenceled: 'Please contact us for further information',
        sendOrderOk: 'Order created successfully',
        sendOrderError: 'Your order could not be created, please try again',
        untilOpen: 'Your last order is still open',
        errorBringDishData: "Error retrieving dishes's data",
        //Status orden
        refreshOrders: 'Update order list',
        titleStatusOrder: 'Order status',
        status: 'Status',
        numOrder: 'Order',
        validatingOrder: 'Your order is on hold',
        makingOder: 'Your order is in process',
        partialOrder: 'Your food is being delivered, some of it has already been paid for',
        assignedOrder: 'Your order has been assigned',
        payOrder: 'Pay order',
        callCheck: 'Check please',
        uSureCallCheck: 'Are you sure to ask for the bill?',
        okCallCheck: 'Your bill is on the way',
        comingCheck: 'A waiter will come shortly',
        faildCallCheck: 'It was not possible to request the bill',
        totalOrder: 'Total order:',
        selectedTotal: 'Total to pay:',
        noOrder: "At the moment you don't have an open order",
        itemsOrder: 'Order dishes',
        selectedItemsOrder: 'Selected dishes to pay',
        //Record
        titleRecord: 'Record (rate your consumption)',
        titleLongRecord: 'Order history',
        typeDelivOrder: 'Delivery order',
        typeRestOrder: 'Orders',
        comment: 'Comment',
        comments: 'Comments',
        rateDish: 'Rate this dish',
        doneComment: 'Thank you for your comment',
        thanksForRate: 'Thanks for rate us!',
        errorRate: 'At least one field must be supplied',
        //Login
        login: 'Log in',
        rusurelogout: "Are you sure to log out?",
        thkslogin: 'Thank you for logging in.',
        createAcc: 'Create account',
        passUserWrong: 'Your password or username is wrong',
        orLogin:'Or',
        typePassUser: 'Enter your credentials to login',
        noAccount: "Don't you have an account yet?",
        signUpHere: 'Sign up here',
        buttonTextGoogle: 'Login with Google',
        buttonTextFB: 'Login with Facebook',
        //Sign up
        titleSignUp: 'Sign up',
        passDiff: 'Passwords do not match',
        okSignUp: 'Successful sign up',
        badSignUp: 'Sign up failed',
        verifyAccount: 'Verify your account with the link we send to your email',
        //Dish
        indications: 'Instructions',
        addorder: 'Aggregated order',
        removeorder: 'Order removed',
        add: 'Add',
        addTopping: 'Add Toppings',
        errorTopping : 'First add one product one the order',
        remove: 'Remove',
        //Generic messages
        goMenus: 'Go to menu',
        welcomeApp: 'Welcome to OnTable!',
        welcomeTo: 'Welcome to',
        sorry: 'Sorry',
        welcome: '¡Welcome!',
        total: 'Total',
        save: 'Save',
        emptyfields: 'Empty fields',
        requiredFields: 'There are required fields',
        saveddata: 'Saved data',
        updateddata: 'Your information was successfully updated',
        faildEdit: 'Failed edit',
        tryagain: 'Try again',
        update: 'Update',
        updateProfile: 'Edit profile',
        continue: 'Continue',
        cancel: 'Cancel',
        confirm: 'Confirm',
        alsoScanTable: 'You can also scan the nearest QR',
        changeRestaurant: 'Refresh App',
        errorSetRest: 'Error trying to set up the company',
        successfulAccion: 'Succesfull accion',
        accept: 'Accept',
        verifyAllComplete: 'Verify that all information is complete',
        loading: 'Loading...',
        //Generics fields
        name: 'Name(s)',
        lastnameOne: 'Lastname',
        lastnameTwo: 'Second lastname(Optional)',
        username: 'Username',
        phone: 'Phone',
        email: 'Email',
        emailUsername: 'Email or username',
        password: 'Password',
        rpassword: 'Repeat password',
        fullName: 'Full name',
        cardNumber: 'Card number',
        year: 'Year',
        month: 'Month',
        cvv: 'cvv',
        //Language
        language: 'Language',
        //Errors
        ups: 'Ups',
        somewrong: 'There is a problem',
        sorryTryAgain: 'Sorry, try again please',
        nocheckorder: 'Could not verify the status of your order',
        requiredLogin: 'Login is required for this action ',
        //Information
        showInformation: 'Information',
        showInfoConfig: 'Set up & rate',
        showInfoMenu: 'Menu & catalog',
        showInfoCart: 'Order cart',
        showInfoOrder: 'Order status',
        showInfoSwipeRight: 'Swipe and add ->',
        showInfoSwipeLeft: '<- Swipe and delete', 
        showInfoWaiter: 'Request a waiter',
        showInfoSendOrder: 'Send order',
        //Quejas y sugerencias
        titleComplaints: 'Complaints and suggestions',
        showInfoComplaints: 'Send comment',
        showInfoComplaintsBox: 'Mailbox',
        requiredComplaints: 'Write your comment to send',
        sendComplaintTitle:'Comment sent',
        sendComplaintOk: 'Your comment sent successfully',
    }
}
