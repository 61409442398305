import React,{useState} from 'react';
//Components
import {Grid,Typography,Paper,Collapse,Avatar} from '@material-ui/core';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import ListRecords from './ListRecords';
import TranslateStr from '../HelperLanguage/TranslateStr';
//Styles
//import {MainColors} from '../../Sources/SourcesConst';
import {color_accent_, color_main_} from '../../../Constants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100% !important',
    },
    root: {
         flexGrow: 1,
         margin: '0.4vh 0.4vh 0 0.4vh'
    },
    paper: {
      padding: theme.spacing(0.5),
      margin: 0,
      width: '100%',
      border: '0.2vh solid #EBEDEF',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2.5vh',
        marginBottom: '0',
    },
    type: {
        //color: MainColors.main,
        fontWeight: 'light',
        fontSize: '2vh',
        marginBottom: '0',
    },
    contImg: {
        width: '100%',
        height: '100%',
        padding: 'auto',
        //color: MainColors.accent,
        backgroundColor: '#fff',
    },
    img:{
        margin: 'auto',
        width: '4vh',
        height: '4vh',
    },
}));

export default function ItemDate(props){

    const sty = useStyles();
    const {date} = props;
    const dateRecord = date.fecha.split("T")[0];
    const [expanded, setExpanded] = useState(false);

    return(
        <Grid container direction="row" justify="center" alignItems="center">
            <Paper className={sty.paper}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Avatar className={sty.contImg} style={{color: localStorage.getItem(color_accent_)}} alt="Add icon">
                            { date.esDelivery === true ?
                                <EmojiTransportationIcon className={sty.img}/>
                            :
                                <FastfoodIcon className={sty.img}/>
                            }
                        </Avatar>
                    </Grid>
                    <Grid item xs={10} container direction="column" onClick={() => setExpanded(!expanded)}>
                        <Grid item>
                            <Typography className={sty.title}>
                            {dateRecord}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={sty.type} style={{color: localStorage.getItem(color_main_)}}>{ date.esDelivery === true ? <TranslateStr token="typeDelivOrder"/> : <TranslateStr token="typeRestOrder"/> }</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <ListRecords date={dateRecord}/>
                </Collapse>
            </Paper>
        </Grid>
    )
}