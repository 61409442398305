import {initializeApp} from 'firebase/app';
import {getMessaging,getToken,onMessage} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAW_0Kz78vye8mM1JPEV0WyDvJaBTK8ZN0",
  authDomain: "ontablev1-7c693.firebaseapp.com",
  databaseURL: "https://ontablev1-7c693.firebaseio.com",
  projectId: "ontablev1-7c693",
  storageBucket: "ontablev1-7c693.appspot.com",
  messagingSenderId: "474793117806",
  appId: "1:474793117806:web:b9d5d7234edc139ccbfe7f"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getTokenFire = () => {
  return getToken(messaging,{vapidKey: 'BNJ_q1igBMBVmgNxZ_lYCnKvttd6HhRdiF8Lj0m1B3PnhXB4dZVUcrTDqHaLIZ2PbQgfxSJTqpvhCU5Mz5snyIw'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging,(payload) => {
      resolve(payload);
    });
});
