import React from 'react';
//Components
import {List,Grid} from '@material-ui/core';
import ItemUser from './ItemUser';

export default function ListUsers(props){

    const {arrayUsers,selectParent,selectChild,idStatus,totalUser,totalOrder} = props;
    return(
        <Grid container justify="center" alignItems="center">
            <List>
                {
                    arrayUsers.map((user,index) => (
                        <ItemUser
                            key={index}
                            dataUser={user}
                            selectChild={selectChild}
                            selectParent={selectParent}
                            idStatus={idStatus}
                            totalUser={totalUser}
                            reload={props.reload}
                            totalOrder={totalOrder}
                        />
                    ))
                }
            </List>
        </Grid>
    )
}