/* eslint-disable eqeqeq */
import jwt_decode from "jwt-decode";
import { makeRequest, makeRequestAuth, makeRequestKPI, makeRequestSignalR, API_signalr } from './ManagerRequest';
//import {makeRefreshToken} from './MakeRefreshToken';
//import { allItemCarrito,allItemCarritoTipo,allItemCarritobyTipo,deleteAllCarrito } from './ManagerCarrito';
import {allItemCarritobyTipo,deleteAllCarrito, getInfoCarrito, allItemToppingbyIdCar } from './ManagerCarrito';
import {allItemOrders, deleteDbOrder,saveDbOrder, setCurrentDbOrder } from './ManagerOrders';
import {getBoolStorage, logOut} from './HelperStorage';
import { isForOrder_,desAddressDel_,desOptDel_,isDelivery_,emailUser_,isIdOrden_,errorApi_,
    idUser_,idOrden_,idMesaDel_,idAddressDel_,idOptDel_,idEmpresa_,nameUser_, token_, 
    login_, ValidacionPorUsuario_, tagMesa_, rolUser_, idMesa_, ubicacion_, esHabitacion_ ,
     firstError_, refreshToken_, indexAddressDel_, indexOptionDel_, idLan_, lan_, InfoOn_, 
     IsQueja_} from '../components/Constants';
//import { LocalLaundryService } from "@material-ui/icons";
import * as signalR from "@microsoft/signalr";
//import { LocalGasStationRounded } from '@material-ui/icons';
//import { createNamedExports } from 'typescript';
/*Status
idEstatusOrden	EstatusOrden
1	Abierta   
2	Cancelada 
3	Pagada    
4	Parcial   
5	PorEntrega
7	Validacion

idEstatusEntrega	EstatusEntrega
1	Nuevo
2	En Proceso
3	Rechazado
4	Lista
5	En tránsito
6	Entregado
7	Rechazado por Cliente
8	Rechazado Por Repartidor
9	Asignado
*/

//Complaints
const CreateComplaint = (Queja,setMainResponse) => {
    const data = {
        idEmpresa : localStorage.getItem(idEmpresa_),
        idUsuario: getBoolStorage(ValidacionPorUsuario_) ? localStorage.getItem(idUser_) : 0,
        idUbicacion: parseInt(localStorage.getItem(idMesa_)),
        comentario: Queja
    }
    console.log('Ejecuta makeRequest POST Empresa/Queja');
    makeRequest('application/json',data,'POST','Empresa/Queja',true,true,(dataRes,success) => {
        //console.log(dataRes);
        var itsOk = validateResponse(success,dataRes,"Add Queja",false);
        //console.log(itsOk);
        if(success){
            if(dataRes.success){
                console.log("Queja creada!!!");
                itsOk = true;
                // localStorage.setItem(idOrden_,0);
                // localStorage.setItem(isIdOrden_,true);
                // localStorage.setItem(isDelivery_,false);
                // localStorage.setItem(IsQueja_,false);
            }
        }
        setMainResponse(dataRes,itsOk);
    });
}
//Dishes
const searchDishes = (stringSearch,setMainResponse) => {

    var urlSearch = 'masterdata/busquedainteligente';
    
    const arraySearch = stringSearch.split(' ');

    arraySearch.forEach((itemString,index) => {
        if(index === 0){
            urlSearch += '?criteriobusqueda' + itemString;
        }else{
            urlSearch += '&criteriobusqueda' + itemString;
        }
    });

    const data = {
        idEmpresa : localStorage.getItem(idEmpresa_),
        idIdioma : localStorage.getItem(idLan_) 
    }

    makeRequest('application/json',data,'GET',urlSearch,true,false,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Get search dishes:',true);
        var arrayOrders = [];
        if(status){
            arrayOrders = dataRes.data;
        }
        setMainResponse(arrayOrders);
    });
}

//User
const updateUser = (name, pat, mat, userName, phone, mail, setMainResponse) => {
    // const data = {
    //     idUsuario : localStorage.getItem(idUser_),
    //     cuentaDeUsuario : userName,
    //     nombreUsuario : name,
    //     apellidoPat : pat,
    //     apellidoMat : mat,
    //     celular : phone,
    //     idRol : 6,
    //     genero: ""
    // }

    const data = {
        username: userName,
        email: mail,
        idCompany: 0,
        nombre: name,
        apellidoPaterno: pat,
        apellidoMaterno: mat,
        genero: "M",
        telefono: phone,
        idCaja: 0,
        pin: 0,
        rol: "comensal",
        OldRol: "comensal"
    }

    makeRequestAuth('application/json',data,'PUT','Authenticate/Users/Change',true,true,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Update info user:',true);
        setMainResponse(status);
    });
}

const getInfoUser = (setMainResponse) => {
    makeRequest('application/json',null,'GET','Usuario/ApplicationUser/' + localStorage.getItem(idUser_),true,true,(dataRes,success) => {
        var dataUser = null;
        if(validateResponse(success,dataRes,'Get info user:',true)){
            dataUser = dataRes.data;
        }
        setMainResponse(dataUser);
    });
}

const myCurrentOrders = (setMainResponse) => {
    var idSearch = getBoolStorage(ValidacionPorUsuario_) ? localStorage.getItem(idUser_) : localStorage.getItem(idMesa_);
    var conf =  getBoolStorage(ValidacionPorUsuario_) ? 1 : 0;

    if(idSearch===0){
        idSearch=localStorage.getItem(idMesa_);
        //conf=0;
    }
    

    console.log('id: ' + idSearch);
    console.log('conf: ' + conf);


    //makeRequest('application/json',null,'GET','Orden/MisOrdenesDelivery/' + localStorage.getItem(idUser_),true,true,(dataRes,success) => {
    //makeRequest('application/json',null,'GET','Orden/MisOrdenesDeUsuario/' + localStorage.getItem(idUser_),true,true,(dataRes,success) => {
    makeRequest('application/json',null,'GET','Orden/MisOrdenesDeUsuario/' + idSearch + '/' + conf,true,true,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Get mis ordenes:',false);
        var arrayOrders = [];
        if(status){
            arrayOrders = dataRes.data;
            console.log('MisOrdenesDeUsuario');
            console.log(arrayOrders);
        }
        
        setMainResponse(arrayOrders);
    });
}

//User//Historial
const getSummaryDate = (setMainResponse) => {

    const data = {
        idUsuario : localStorage.getItem(idUser_), 
        idEmpresa : localStorage.getItem(idEmpresa_)
    }

    makeRequest('application/json',data,'GET','Orden/ProductosOrden/MiHistoria/Resumen',true,true,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Get summary dates:',true);
        var arrayRecords = [];
        if(status){
            arrayRecords = dataRes.data;
        }
        setMainResponse(arrayRecords);
    });
} 

const getRecordsByDate = (date,setMainResponse) => {

    const data = {
        idUsuario : localStorage.getItem(idUser_), 
        idEmpresa : localStorage.getItem(idEmpresa_),
        Fecha : date,
        idIdioma : localStorage.getItem(idLan_)
    }

    makeRequest('application/json',data,'GET','Orden/ProductosOrden/MiHistoria',true,true,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Get orders by date:',true);
        var arrayRecords = [];
        if(status){
            arrayRecords = dataRes.data;
        }
        setMainResponse(arrayRecords);
    });

}

const rateDish = (idProduct, text, rateNumber, idProductOrder, setMainResponse) => {
    const data = {
        idUsuario  : localStorage.getItem(idUser_),
        idProducto : idProduct,
        Texto : text,
        Calificacion : rateNumber,
        idProductoOrden : idProductOrder
    }
    makeRequest('application/json',data,'POST','masterdata/productos/Comentario',true,true,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Guardando comentario de platillo:',true);
        setMainResponse(status);
    });
}

//Alergenos
const getAlergenos = (setMainResponse) => {
    makeRequest('application/json',null,'GET','masterdata/Alergenos',true,false,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Get alergenos:',true);
        var arrayAler = [];
        if(status){
            arrayAler = dataRes.data;
        }
        setMainResponse(arrayAler);
    });
}

const saveAlergeno = (id,setMainResponse) => {
    const data = {
        idUsuario  : localStorage.getItem(idUser_),
        idAlergeno : id
    }
    makeRequest('application/json',data,'POST','Usuario/Conf/Alergenos',true,false,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Guardando alergeno de usuario:',true);
        setMainResponse(status);
    });
}

//Registro
const registroNormal = (name, pat, mat, userName, genero, date, email, phone, pass, setMainResponse) => {
    const data = {
        username: userName,
        email: email,
        password: pass,
        idCompany:0, // parseInt(localStorage.getItem(idEmpresa_)),
        nombre: name,
        apellidoPaterno: pat,
        apellidoMaterno: mat,
        genero: "M", //genero,
        telefono: phone,
        idCaja: 0,
        pin: 0,
        rol: "comensal"
    }

    // const data = {
    //     idRol : 6,
    //     CuentaDeUsuario : userName,
    //     NombreUsuario : name,
    //     ApellidoPat : pat,
    //     ApellidoMat : mat,
    //     Genero : genero,
    //     Direccion : "",
    //     FechaNacimiento : date,
    //     CorreoElectronico : email,
    //     Celular : phone,
    //     Contrasenia : pass,
    //     IMEI : ""
    // }
    // makeRequest('application/json',data,'POST','usuario/comensal',true,false,(dataRes,success) => {
    //     const status = validateResponse(success,dataRes,'Registrando:',true);
    //     setMainResponse(status);
    // });
    makeRequestAuth('application/json',data,'POST','Authenticate/register',true,false,(dataRes,success) => {
        //console.log('register dataRes...');
        //console.log(dataRes);
        //console.log('register success...');
        //console.log(success);
        const status = validateResponseAuth(success,dataRes,'Registrando:',true);
        setMainResponse(status);
    });
}

const registroSocialNetwork = (name,email,userName,uriImg,token, sub, setMainResponse) => {
    const data = {
        provider: "Google",
        idToken: sub,
        userName: userName,
        email:  email,
        nombre: name,
        photoUrl: uriImg,
        token: token
    }
    
    //console.log(data);
    // makeRequest('application/json',data,'POST','Usuario/Google',true,false,(dataRes,success) => {
    //     const status = validateResponse(success,dataRes,'Registrando via google/face:',true);
    //     if(status){
    //         console.log("Todo bien");
    //     }
    //     setMainResponse(status);
    // });
    makeRequestAuth('application/json',data,'POST','Authenticate/External/Register',true,false,(dataRes,success) => {
        console.log('dataRes register external');
        console.log(dataRes);
        const status = validateResponseAuth(success,dataRes,'Registrando via google/face:',true);
        if(status){
            console.log("Todo bien");
            //var itsOk = false;
            //const TOKEN_DECODED = {};
            //if(dataRes.isAuthSuccessful){
            if(dataRes.token !== undefined && dataRes.token !== ''){
                //Object.assign(TOKEN_DECODED, jwt_decode(dataRes.token));
                var decoded = dataRes.token.split('}.'); 
                //Object.assign(TOKEN_DECODED, jwt_decode(decoded[1]));
                //{"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name":"curry.lsr.74@gmail.com","jti":"5d137342-d129-4e15-b6d8-8512a0d67cf5","http://schemas.microsoft.com/ws/2008/06/identity/claims/role":"comensal","IdEmployee":"2264","exp":1682477462,"iss":"https://localhost:3000/,https://localhost:3001/, https://api-auth-dev.ontable.app","aud":"https://localhost/"}
                //jwt_decode(dataRes.token);
                console.log('decoded...');
                console.log(decoded);
                console.log('TOKEN_DECODED');
                console.log(decoded[1]);
                const TOKEN_DECODED = JSON.parse(decoded[1]); //.split(','); //jwt_decode(decoded[1]);
                console.log(TOKEN_DECODED);
                // console.log("Pasa?");
                // // Object.assign(TOKEN_DECODED, jwt_decode(dataRes.token));
                // // localStorage.setItem(
                // //     nameUser_,
                // //     TOKEN_DECODED['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
                // // );
                localStorage.setItem(
                    rolUser_,
                    TOKEN_DECODED['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                );
                localStorage.setItem(refreshToken_, TOKEN_DECODED['jti']);
                localStorage.setItem(idUser_, TOKEN_DECODED['IdEmployee']);
                // // //localStorage.setItem(nameUser_,dataRes.fullName);
                //localStorage.setItem(token_,JSON.stringify(TOKEN_DECODED));
                // // //localStorage.setItem(refreshToken_,dataRes.refresh_token);
                // // //localStorage.setItem(idUser_,dataRes.idUsuario);
                // // //localStorage.setItem(rolUser_,dataRes.usertypeid);
                // // //localStorage.setItem(emailUser_,dataRes.email);
                localStorage.setItem(login_,true);
                localStorage.setItem(IsQueja_,false);
                
                // //itsOk = true;
                if(getBoolStorage(isForOrder_)){
                     localStorage.setItem(isForOrder_,false);
                }else{
                     deleteAllCarrito();
                }
                setCurrentDbOrder();
            }
                
        }
        setMainResponse(status);
    });
}

//Repartidor
const getOrdersAsignadas = (setMainResponse) => {
    makeRequest('application/json',null,'GET','Entrega/All/Asignadas/' + localStorage.getItem(idEmpresa_),true,true,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Get Asignadas:',true);
        var arrayAsig = [];
        if(status){
            arrayAsig = dataRes.data;
        }
        setMainResponse(arrayAsig);
    });
}

const getOrdersEntransito = (setMainResponse) => {
    makeRequest('application/json',null,'GET','Entrega/All/EnTransito/' + localStorage.getItem(idEmpresa_),true,true,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Get Entransito:',true);
        var arrayAsig = [];
        if(status){
            arrayAsig = dataRes.data;
        }
        setMainResponse(arrayAsig);
    });
}

const aceptarEntrega = (idOrden,setMainResponse) => {
    makeRequest('application/json',null,'GET','Entrega/AceptarEntrega/' + idOrden,true,true,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Aceptando entrega:',true);
        setMainResponse(status);
    });
}

const cancelarEntrega = (idOrdenC,motivo,setMainResponse) => {

    const data = {
        idOrden : idOrdenC,
        idMotivo : 0,
        Motivo: motivo
    }
    
    makeRequest('application/json',data,'GET','Entrega/SetRechazadoRepartidor',true,true,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Cancelando orden:',true);
        setMainResponse(status);
    });
}

const setOrdenEntregada = (idOrden,setMainResponse) => {
    makeRequest('application/json',null,'GET','Entrega/SetEntregadoAlCliente/' + idOrden,true,true,(dataRes,success) => {
        const status = validateResponse(success,dataRes,'Marcando como entregada:',true);
        setMainResponse(status);
    });
}

//Favorite Dishes
const getTopDishes = (setMainRes) => {
    makeRequest(
        'applicacion/json',
        null,
        'GET',
        'masterdata/productos/top10/' + localStorage.getItem(idEmpresa_),true,false,(dataRes,success) => {
            const status = validateResponse(success,dataRes,'Get top dishes',true);
            var arrayTop = [];
            if(status){
                arrayTop = dataRes.data;
                console.log(arrayTop);
            }
            setMainRes(arrayTop);
        });
}

//Address
const getAllAddressUser = (setMainRes) => {
    makeRequest(
        'applicacion/json',
        null,
        'GET',
        'Entrega/Domicilio/' + localStorage.getItem(idUser_) + '/All',true,true,(dataRes,success) => {

            if(validateResponse(success,dataRes,"Domicilios usuario",true)){
                setMainRes(dataRes.data);
            }
        });
}

const saveDeliveryAddress = (idCiudad,street,nota,setMainRes) => {

    const data = {
        idDomicilio: 0,
        idUsuario: localStorage.getItem(idUser_),
        idCiudad: idCiudad,
        domicilio: street,
        nota: nota
    }

    makeRequest(
        'application/json;charset=utf-8',
        data,
        'POST',
        'Entrega/Domicilio',true,true,(dataRes,success) => {
            setMainRes(validateResponse(success,dataRes,"Save delivery address",false));
        });
}

const deleteDeliveryAddress = (idDomicilioDeEntrega,setMainRes) => {
    
    makeRequest(
        'applicacion/json',
        null,
        'DELETE',
        'Entrega/Domicilio?idDomicilioDeEntrega=' + idDomicilioDeEntrega,true,true,(dataRes,success) => {
            setMainRes(validateResponse(success,dataRes,"Delete delivery address",false));
        });
}

const searchAddress = (criterio,setMainRes) => {

    var urlSearch = 'Entrega/Domicilio/busquedainteligente?idIdioma=1&idEmpresa=' + localStorage.getItem(idEmpresa_);
    var arrayCriterio = criterio.split(" ");
    if(arrayCriterio.length > 1) {
        arrayCriterio.forEach(element => {
            urlSearch = urlSearch + '&criterioBusqueda=' + element;
        });
    }else{
        urlSearch = urlSearch + '&criterioBusqueda=' + criterio;
    }

    makeRequest(
        'applicacion/json',
        null,
        'GET',
        urlSearch,
        true,
        false,
        (dataRes,success) => {
            if( validateResponse(success,dataRes,"Search",true) ){
                setMainRes(dataRes.data);
            }
        }
    );
}

//Orders restaurant

const callWaiter = (setMainRes) => {
    //console.log('callWaiter es habitacion');
    //console.log(localStorage.getItem(esHabitacion_));
    var bolHabitacion=getBoolStorage(esHabitacion_);
    //console.log('bolHabitacion ' + bolHabitacion);
    const data = {
        idEmpresa : parseInt(localStorage.getItem(idEmpresa_)),
        EtiquetaMesa : localStorage.getItem(tagMesa_),
        UbicacionMesa : localStorage.getItem(ubicacion_),
        idMesa : parseInt(localStorage.getItem(idMesa_)),
        esHabitacion: bolHabitacion
    }
    makeRequestKPI('applicacion/json',data,'POST','KPI/LlamarMesero',true,true,(dataRes,success) => {
        setMainRes(validateResponse(success,dataRes,"Call waiter",false));
    });
}

var successSendMulti = false;

const callCheck = (setMainResponse) => {
    var bolHabitacion=getBoolStorage(esHabitacion_);
    const data = {
        idEmpresa :  localStorage.getItem(idEmpresa_),
        EtiquetaMesa : localStorage.getItem(tagMesa_),
        UbicacionMesa : localStorage.getItem(ubicacion_),
        idMesa : localStorage.getItem(idMesa_),
        esHabitacion: bolHabitacion
    }

    makeRequestKPI('application/json',data,'POST','KPI/SolicitarCuenta',true,true,(dataRes,success) => {
        setMainResponse(validateResponse(success,dataRes,"LLamando cuenta de orden",false));
    });
}

const checkStatusOrder = (idOrden,setMainResponse) => {
    //var id = (idOrden === null ? localStorage.getItem(idOrden_) : idOrden);
    var id = (idOrden === null ? 0 : idOrden);
    //var id = idOrden;
   
    //makeRequest('application/json',null,'GET','Orden/' + localStorage.getItem(idOrden_),true,true,(dataRes,success) => {
    makeRequest('application/json',null,'GET','Orden/' + id,true,true,(dataRes,success) => {

        var itsOk = validateResponse(success,dataRes,"Status orden",true);
        var itsOkToAdd = false;
        var stOrder=1;
        if(itsOk){
            const st = parseInt(dataRes.data.idEstatusOrden);
            //if(st ===  2 || st === 3 || st === 5 || st === 9 )
            //Lis: 2022-10-28 st=5 por entregar aún no está libre la mesa/habitación
            if(st ===  2 || st === 3  || st === 9 ){
                deleteInfoOrder(id);
            }else{
                itsOkToAdd = true;
            }
            stOrder=st;
        }
        setMainResponse(itsOk,itsOkToAdd,stOrder);
    });
}

const getStatusOrderRest = (idOrden,setMainResponse) => {
    //makeRequest('application/json',null,'GET','Orden/' + localStorage.getItem(idOrden_),true,true,(dataRes,success) => {
    makeRequest('application/json',null,'GET','Orden/' + idOrden,true,true,(dataRes,success) => {

        var itsOk = validateResponse(success,dataRes,"Status orden",false);
        var status = null;
        if(itsOk){
            const st = dataRes.data;
            if(st !== undefined && st !== null){
                status = dataRes.data;
            }
        }
        setMainResponse(status);
    });
}

const sendAllOrdenes = (idOrder,TipoOrden,AreaOrden,setMainResponse) => {
    allItemCarritobyTipo(TipoOrden,AreaOrden,arrayTipo => {
        if(arrayTipo.length > 0){
            sendOrder(idOrder,0,0,arrayTipo,(itsOk) => {
                setMainResponse(itsOk);
            });
        }
    });
}

const sendToppings = (idOrder, IdCarrito, idProductoOrden) => {
    var idUser = getBoolStorage(ValidacionPorUsuario_) ? localStorage.getItem(idUser_) : 0;
    allItemToppingbyIdCar(IdCarrito, itemToppings => {
        if(itemToppings.length>0){
            itemToppings.forEach(element => {
                const maxElemtos = element.cantidad
                for (let i = 0; i < maxElemtos; i++) {
                    const data = {
                        idOrden : idOrder, //localStorage.getItem(idOrden_),
                        idUsuario : idUser,
                        idProducto : element.idProducto, //.id,
                        idTopping:  idProductoOrden, //,
                        Indicaciones : '' //commentSend
                    }
                    
                    makeRequest('application/json',data,'POST','Orden/ProductoOrden',true,true,(dataRes,success) => {
                        if(success){
                            console.log('topping enviado correctamente');
                            //successSendMulti = dataRes.success;
                        }
                        //if(isLastMainItem && isLastCuantityItem){
                            //setResponse(successSendMulti);
                        //}else {
                        //    sendOrder(idOrder,nextIndexMain, nextIndexCuantity,listCarrito,setResponse);
                        //}
                    });
                }
            });
        }
    });
}

const sendOrder = (idOrder,indexMainItem,indexCuantityItem,listCarrito,setResponse) => {
    const mainItem = listCarrito[indexMainItem];
    const isLastMainItem     = indexMainItem     === listCarrito.length -1;
    const isLastCuantityItem = indexCuantityItem === parseInt(mainItem.cantidad) - 1;
    const mainItemComments   = mainItem.comments;
    var commentSend          = "";
    //var withToken            = true; //getBoolStorage(ValidacionPorUsuario_);
    var idUser               = getBoolStorage(ValidacionPorUsuario_) ? localStorage.getItem(idUser_) : 0;

    if(mainItemComments.length !== 0) {
        if(indexCuantityItem <= mainItemComments.length - 1) commentSend = mainItemComments[indexCuantityItem];
    }

    var nextIndexMain = indexMainItem;
    var nextIndexCuantity = indexCuantityItem;
    if(!isLastCuantityItem){
        nextIndexCuantity++;
    }else {
        nextIndexCuantity = 0;
        nextIndexMain++;
    }

    const data = {
        idOrden : idOrder, //localStorage.getItem(idOrden_),
        idUsuario : idUser,
        idProducto : mainItem.idProducto, //.id,
        idTopping:  0, //mainItem.idProducto,
        Indicaciones : commentSend
    }

    console.log('mainItem', mainItem);
    
    makeRequest('application/json',data,'POST','Orden/ProductoOrden',true,true,(dataRes,success) => {
        if(success){
            console.log('platillo enviado correctamente');
            console.log(dataRes);
            successSendMulti = dataRes.success;
            sendToppings(idOrder,mainItem.id,dataRes.id);
            // notifyUpdateOrden(mainItem.idAreaDeServicio,dataRes.data.idOrden,mainItem.idProducto,mainItem.name,(res,success) => {
            //     console.log('notify', res);
            // });
        }
        if(isLastMainItem && isLastCuantityItem){
            setResponse(successSendMulti);
        }else {
            sendOrder(idOrder,nextIndexMain, nextIndexCuantity,listCarrito,setResponse);
        }
    });
}

const createOrderRestaurant = (idArea,tipoMenu,setMainResponse) => {
    //console.log("Valida tipo orden");
    var idUser = getBoolStorage(ValidacionPorUsuario_) ? localStorage.getItem(idUser_) : 0;
    //var withToken = true; // getBoolStorage(ValidacionPorUsuario_);

    if(parseInt(tipoMenu)===1){ //Si es restaurante
        const data = {
            idMesa : localStorage.getItem(idMesa_),
            idUsuario: idUser
            //localStorage.getItem(idUser_)
        }
        console.log("Orden tipo 1");
        console.log('Area ' + idArea);
        //console.log(data);
        //console.log('Ejecuta makeRequest POST Orden');
        makeRequest('application/json',data,'POST','Orden',true,true,(dataRes,success) => {
            console.log(dataRes);
            var itsOk = validateResponse(success,dataRes,"Create order",false);
            console.log(itsOk);
            if(success && itsOk){
                if(dataRes.success && dataRes.data.idOrden!==undefined){
                    console.log("Orden creada!!!");
                    itsOk = true;
                    localStorage.setItem(idOrden_,dataRes.data.idOrden);
                    localStorage.setItem(isIdOrden_,true);
                    localStorage.setItem(isDelivery_,false);
                    localStorage.setItem(IsQueja_,false);
                    saveDbOrder(1,dataRes.data.idOrden,idArea,false,() => {})
                }
            }
            var _idOrden = 0;

            if(itsOk){
                _idOrden = dataRes.data.idOrden;
            }
            setMainResponse(_idOrden,itsOk);
        });
    }
    else //2 Si es hotel
    { 
        const data = {
            idMesa : localStorage.getItem(idMesa_),
            idUsuario: idUser,
            idAreaServicio : idArea
        }
        console.log("Orden tipo 2");
        console.log('Area ' + idArea);
        console.log('Id Usuario: ' + idUser);
        //console.log(idArea);
        //console.log(data);
        //console.log('Ejecuta makeRequest POST Orden/Servicio');
        makeRequest('application/json',data,'POST','Orden/Servicio',true,true,(dataRes,success) => {
            console.log(success);
            var itsOk = validateResponse(success,dataRes,"Create order",false);
            console.log(itsOk);
            if(success){
                if(dataRes.success){
                    console.log("Orden creada!!!");
                    itsOk = true;
                    localStorage.setItem(idOrden_,dataRes.data.idOrden);
                    localStorage.setItem(isIdOrden_,true);
                    localStorage.setItem(isDelivery_,false);
                    localStorage.setItem(IsQueja_,false);
                    saveDbOrder(2,dataRes.data.idOrden,idArea,false,() => {})
                }
            }
            var _idOrden = 0;

            if(itsOk){
                _idOrden = dataRes.data.idOrden;
            }
            setMainResponse(_idOrden,itsOk);
        });
    }
}

const getNumOrdenesAbierta = (setMainResponse) => {
    const data = {
        id: localStorage.getItem(idMesa_)
    }
    makeRequest('application/json',data,'GET','Mesa/NoOrdenAbierta/',true,true,(dataRes,success) => {
        var arrayOrder = [];
        if(dataRes.data.length !== 0) arrayOrder = dataRes.data;
        setMainResponse(arrayOrder);
    });
}

const checkStatusOrderRestaurant = (idArea,idTipo,setMainResponse) => {
    var itsOk = false;
    var itsOtroTipo = false;
    var idOrden = 0;
    var statusOrden = 0;
    var IDTipoOrden =0;// parseInt(localStorage.getItem(idTipoOrden_));
    var IDarea=0;
    //onst idTipo = parseInt(localStorage.getItem(idTipoOrden_));
    const isIdOrden = getBoolStorage(isIdOrden_);
    // const data = {
    //     id: localStorage.getItem(idMesa_)
    // }
    makeRequest('application/json',null,'GET','Mesa/NoDeOrdenAbierta/' + localStorage.getItem(idMesa_),true,true,(dataRes,success) => {
        var arrayOrder = [];
        console.log('NoDeOrdenAbierta',dataRes.data);
        var arrayOrdenAbierta =  dataRes.data ? dataRes.data.filter((orden) => orden.idTipoOrden!==4) : dataRes.data;
        //if(dataRes.data.length != 0 && success == true)
        console.log('GET Mesa/NoDeOrdenAbierta/' +  localStorage.getItem(idMesa_));
        console.log(arrayOrdenAbierta);
        if(success === true && dataRes.success === true && arrayOrdenAbierta.length!=0){
            //arrayOrder = dataRes.data;
            //var arrayFinal = [];
            //if(arrayOrder.length !== 0){
            dataRes.data.map(function(item){
                if(item.idEstatusOrden!=5 && item.idTipoOrden!=4){
                    if(parseInt(item.idTipoOrden) === 1 && parseInt(idArea) ===1 ){
                        arrayOrder.push(item);
                    }
                    else{
                        if(parseInt(item.idareaServicio) === parseInt(idArea) ){
                            arrayOrder.push(item);
                        }
                    }
                }
            });
            //}
            console.log(arrayOrder);
            //Recorre arreglo para revisar posibles órdenes tipo 1 ó tipo 2
            if(arrayOrder.length!=0){
                arrayOrder.forEach(element => {
                    idOrden = element.idOrden;
                    console.log('IDORDEN');
                    console.log(idOrden);

                    statusOrden = parseInt(element.idEstatusOrden);
                    IDTipoOrden = parseInt(element.idTipoOrden);
                    if(parseInt(IDTipoOrden) === 1 ){
                        IDarea=1;
                    }
                    else{
                        IDarea = parseInt(element.idareaServicio);
                    }
                    
            
                    console.log('STATUS');
                    console.log(statusOrden);
                    console.log('AREA');
                    console.log(idArea);
                    console.log('TipoOrdenGuardar');
                    console.log(idTipo);

                    console.log('ISID');
                    console.log(isIdOrden);

                    if (idOrden !== 0 && statusOrden != 0 && !itsOk){
                        console.log('son diferentes de 0');
                        if(statusOrden !== 5 && statusOrden !== 3 && statusOrden !== 2 && statusOrden!==9 && IDarea===idArea){
                            console.log('esta disponible para agregar');
                            itsOtroTipo=false;
                            console.log('Otro tipo?');
                            console.log(itsOtroTipo);
                            if(isIdOrden){
                                localStorage.setItem(idOrden_,idOrden);
                                if(idOrden == localStorage.getItem(idOrden_)){
                                    console.log('es la misma orden solo se manda a agregar');
                                    itsOk = true;
                                }
                            }
                            else{
                                console.log('no hay orden y se manda para agregar con estos datos de orden');
                                localStorage.setItem(idOrden_,idOrden);
                                localStorage.setItem(isIdOrden_,true);
                                localStorage.setItem(isDelivery_,false);
                                localStorage.setItem(IsQueja_,false);
                                saveDbOrder(IDTipoOrden,idOrden,IDarea,false,() => {});
                                itsOk = true;
                            }
                        }
                        else{
                            localStorage.setItem(idOrden_,idOrden);
                            console.log('Esta cancelada, pagada, por entregar, es de otro tipo o diferente area de servicio. Se crea otra orden');
                            //if(IDTipoOrden!==idTipo || IDarea!==idArea){
                            //if(IDarea!==idArea){
                                itsOtroTipo = true;
                            //}
                            console.log('Otro tipo?');
                            console.log(itsOtroTipo);
                            itsOk = true;
                        }
                        setMainResponse(idOrden,itsOtroTipo,itsOk);
                    }
                    else {
                        if(!itsOk) {
                            //No hay nada en la mesa se checa status de orden
                            localStorage.setItem(idOrden_,idOrden);
                            console.log('Se checa orden');
                            if(isIdOrden){
                                checkStatusOrder(idOrden,(okRes,okconsult) => {
                                    if(okRes && !okconsult){
                                        itsOk = true;    
                                    }
                                    setMainResponse(idOrden,itsOtroTipo,itsOk);
                                })
                            }
                            else{
                                itsOk = true;
                                setMainResponse(idOrden,itsOtroTipo,itsOk);
                            }
                        }
                    }
                });
            }
            else{
                //No hay nada en el area de servivio
                //localStorage.setItem(idOrden_,idOrden);
                //console.log('No hay nada en el area de servicio');
                console.log('No hay nada en el area de servicio, por entregar');
                itsOtroTipo = true;
                itsOk = true;
                console.log('itsOk');
                console.log(itsOk);
                setMainResponse(idOrden,itsOtroTipo,itsOk);
            }
        }
        else{
            if(success === true){
                //No hay nada en la mesa
                //localStorage.setItem(idOrden_,idOrden);
                console.log('No hay nada en la mesa');
                itsOtroTipo = true;
                console.log('Otro tipo');
                console.log(itsOtroTipo);
                itsOk = true;
                setMainResponse(idOrden,itsOtroTipo,itsOk);
            }
            else{
                console.log('No hay nada en la mesa fuera de servicio');
                itsOtroTipo = false;
                itsOk = false;
                console.log('itsOk');
                console.log(itsOk);
                setMainResponse(idOrden,itsOtroTipo,itsOk);
            }
        }
    });
}

const getAllOrderRestaurant = (idOrden,setMainResponse) => {
    //var withToken = true; // getBoolStorage(ValidacionPorUsuario_);
    console.log('id orden all ' + idOrden);
    const data = {
        id : idOrden,
        idIdioma: localStorage.getItem(idLan_),
        idEmpresa : localStorage.getItem(idEmpresa_)
    }

    makeRequest('application/json',data,'GET','Orden/ProductosOrden/All',true,true,(dataRes,success) => {
        var arrayOrder = [];
        if(validateResponse(success,dataRes,"Platillos de orden en restaurante (getAllOrderRestaurant)",true)){
            if(dataRes.data.length !== 0) arrayOrder = dataRes.data;
        }
        //console.log(arrayOrder);
        setMainResponse(arrayOrder);
    });
}

//Payment
const makePayment = (userName,last,token,amount,tip,des,deviceSessionId,email,arrayProducts,setMainRes) => {

    var data = {
        name : userName,
        lastName : last,
        idComensal : localStorage.getItem(idUser_),
        idOrden : localStorage.getItem(idOrden_),
        tokenid : token,
        amount : amount,
        tip : tip,
        description : des,
        devicesessionid : deviceSessionId,
        email : email
    } 

    var url = 'Orden/PagoEnLinea';
    arrayProducts.map((product,index) => {
        if(index === 0){
            url = url + "?idProductoOrden=" + product
        }else{
            url = url + "&idProductoOrden=" + product
        }
    })
    
    console.log(data);

    makeRequest('application/json',data,'POST',url,true,true,(dataRes,success) => {
        setMainRes(validateResponse(success,dataRes,'Payment',false));
    });

}

const notifyPayment = (total,restaPagar,setMainRes) => {

    const isDelivery = getBoolStorage(isIdOrden_);
    const tag = isDelivery ? localStorage.getItem(nameUser_) : localStorage.getItem(tagMesa_);
    const data = {
        idPuntoDeVenta: 0,
        idOrden : localStorage.getItem(idOrden_) ,
        EtiquetaMesa : tag ,
        MontoPago : total ,
        RestaPorPagar : restaPagar ,
        idEmpresa : localStorage.getItem(idEmpresa_),
    }
    
    console.log(data);

    makeRequestKPI('application/json',data,'POST','KPI/NotificarPago',true,true,(dataRes,success) => {
        setMainRes(validateResponse(success,dataRes,'Notify payment',false));
    });

}

//Orden delivery
const optionsDelivery = (setMainRes) => {
    makeRequest('application/json',null,'GET','Entrega/OpcionDeEntrega',true,false,(dataRes,success) => {
        var array = [];
        if(validateResponse(success,dataRes,'Delivery options',false)){
            array = dataRes.data;
        }
        setMainRes(array);
    });
}

const makeDeliveryOrder = (setMainRes) => {
    const data = {
        idMesa: localStorage.getItem(idMesaDel_),
        idUsuario: localStorage.getItem(idUser_),
        idDomicilio: localStorage.getItem(idAddressDel_),
        idOpcionEntrega: localStorage.getItem(idOptDel_),
        esDelivery: 1,
        nota: '',
    }
    console.log(data);

    makeRequest('application/json',data,'POST','Orden/Delivery',true,true,(dataRes,success) => {
        if(validateResponse(success,dataRes,'Make delivery order:',false)){
            localStorage.setItem(idOrden_,dataRes.id);
            localStorage.setItem(isIdOrden_,true);
            localStorage.setItem(isDelivery_,true);
            localStorage.setItem(IsQueja_,false);
            sendAllOrdenes(dataRes.id,3,0,(successSend) => {
                saveDbOrder(3,dataRes.id,0,true,() => {});
                setMainRes(successSend);
            });
        }else{
            setMainRes(false);
        }
    });

}

const statusDeliveryOrder = (setMainRes) => {
    var isActive = false;
    var itsOk = false;
    makeRequest('application/json',null,'GET','Entrega/'+localStorage.getItem(idOrden_),true,true,(dataRes,success) => {
        console.log("Status delivery");
        console.log(dataRes);
        if(success){
            if(dataRes.success){
                const status = dataRes.data.idEstatusEntrega;
                if(status === 3 || status === 6 || status === 7 || status === 8){
                    deleteInfoOrder(localStorage.getItem(idOrden_));
                }else{
                    isActive = true;
                }
            }
            itsOk = true;
        }
        setMainRes(itsOk,isActive);
    });
}

const detailDeliveryOrder = (id,setMainRes) => {
    const idOrdenUrl = id === null ? localStorage.getItem(idOrden_) : id;
    makeRequest('application/json',null,'GET','Entrega/'+ idOrdenUrl,true,true,(dataRes,success) => {
        console.log("Data delivery");
        console.log(dataRes);
        var dataOrder = null;
        if(success){
            if(dataRes.success){
                dataOrder = dataRes.data;
            }
        }
        setMainRes(dataOrder);
    });
}

//Reset settings order
const deleteInfoOrder = (id) => {
    deleteDbOrder(id);
    resetStorageOrder();
}

const resetInfoDelivery = () => {
    localStorage.setItem(idAddressDel_,null)
    localStorage.setItem(desAddressDel_,null);
    localStorage.setItem(indexAddressDel_,null);
    localStorage.setItem(idOptDel_,null);
    localStorage.setItem(desOptDel_,null);
    localStorage.setItem(indexOptionDel_,null);
}

const resetAllOrder = () => {
    deleteAllCarrito();
    resetStorageOrder();
}

const resetStorageOrder = () => {
    allItemOrders( array => {
        console.log(array.length);
        if( array===undefined || array===null || array.length==0){
            //console.log('SIN ORDENES: resetStorageOrder');
            localStorage.setItem(idOrden_,0);
            localStorage.setItem(isIdOrden_,false);
            localStorage.setItem(isDelivery_,false);
            localStorage.setItem(IsQueja_,false);
        }
        // else
        // {
        //     localStorage.setItem(idOrden_,array[0].id);
        //     localStorage.setItem(isIdOrden_,true);
        //     localStorage.setItem(isDelivery_,false);
        // }
    });
}
//Login sin validar
const signInGeneral = (userName,pass,setMainResponse) => {

    const data = {
        //grant_type : 'password',
        username : userName,
        password : pass
    }

    //makeRequest('application/json;charset=UTF-8',data,'POST','Token',true,false,(dataRes,success) => {
    makeRequestAuth('application/json;charset=UTF-8',data,'POST','Authenticate/login', true,false,(dataRes,success) => {
        //console.log(dataRes);
        var itsOk = false;
        const TOKEN_DECODED = {};
        if(dataRes.isAuthSuccessful){
            if(dataRes.token !== undefined && dataRes.token !== ''){
                Object.assign(TOKEN_DECODED, jwt_decode(dataRes.token));
                //var decoded = jwt_decode(dataRes.token);
                //console.log('decoded...');
                //console.log(TOKEN_DECODED);
                console.log("Pasa?");
                // Object.assign(TOKEN_DECODED, jwt_decode(dataRes.token));
                localStorage.setItem(
                    nameUser_,
                    TOKEN_DECODED['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
                );
                localStorage.setItem(
                    rolUser_,
                    TOKEN_DECODED['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                );
                localStorage.setItem(refreshToken_, TOKEN_DECODED['jti']);
                localStorage.setItem(idUser_, TOKEN_DECODED['IdEmployee']);
                // //localStorage.setItem(nameUser_,dataRes.fullName);
                localStorage.setItem(token_,dataRes.token);
                // //localStorage.setItem(refreshToken_,dataRes.refresh_token);
                // //localStorage.setItem(idUser_,dataRes.idUsuario);
                //localStorage.setItem(rolUser_,'comensal');
                // //localStorage.setItem(emailUser_,dataRes.email);
                localStorage.setItem(login_,true);
                //localStorage.setItem(IsQueja_,false);
                
                itsOk = true;
                
            }
        }
        // if(success){
        //     if(dataRes.access_token !== undefined && dataRes.access_token !== ''){
        //         console.log("Pasa?");
        //         localStorage.setItem(nameUser_,dataRes.fullName);
        //         localStorage.setItem(token_,dataRes.access_token);
        //         localStorage.setItem(refreshToken_,dataRes.refresh_token);
        //         localStorage.setItem(idUser_,dataRes.idUsuario);
        //         localStorage.setItem(rolUser_,dataRes.usertypeid);
        //         localStorage.setItem(emailUser_,dataRes.email);
        //         localStorage.setItem(login_,true);
        //         //localStorage.setItem(IsQueja_,false);
        //         itsOk = true;
        //         // if(getBoolStorage(isForOrder_)){
        //         //     localStorage.setItem(isForOrder_,false);
        //         // }else{
        //         //     deleteAllCarrito();
        //         // }
        //         // setCurrentDbOrder();
        //     }
        // }
        setMainResponse(itsOk);
    })
}

//Login external
const signInExternal = (dataRes,setMainResponse) => {

    
    //makeRequestAuth('application/json;charset=UTF-8',data,'POST','Authenticate/External/login',true,false, (dataRes,success) => {
        //console.log('Authenticate/login');
        //console.log(dataRes);
        //console.log('isAuthSuccessful...');
        //console.log(dataRes.isAuthSuccessful);
        var itsOk = false;
        const TOKEN_DECODED = {};
        if(dataRes.isAuthSuccessful){
            if(dataRes.token !== undefined && dataRes.token !== ''){
                Object.assign(TOKEN_DECODED, jwt_decode(dataRes.token));
                //var decoded = jwt_decode(dataRes.token);
                //console.log('decoded...');
                //console.log(TOKEN_DECODED);
                console.log("Pasa?");
                // Object.assign(TOKEN_DECODED, jwt_decode(dataRes.token));
                // localStorage.setItem(
                //     nameUser_,
                //     TOKEN_DECODED['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
                // );
                localStorage.setItem(
                    rolUser_,
                    TOKEN_DECODED['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                );
                localStorage.setItem(refreshToken_, TOKEN_DECODED['jti']);
                localStorage.setItem(idUser_, TOKEN_DECODED['IdEmployee']);
                // //localStorage.setItem(nameUser_,dataRes.fullName);
                localStorage.setItem(token_,dataRes.token);
                // //localStorage.setItem(refreshToken_,dataRes.refresh_token);
                // //localStorage.setItem(idUser_,dataRes.idUsuario);
                // //localStorage.setItem(rolUser_,dataRes.usertypeid);
                // //localStorage.setItem(emailUser_,dataRes.email);
                localStorage.setItem(login_,true);
                localStorage.setItem(IsQueja_,false);
                
                itsOk = true;
                if(getBoolStorage(isForOrder_)){
                    localStorage.setItem(isForOrder_,false);
                }else{
                    deleteAllCarrito();
                }
                setCurrentDbOrder();
            }
        }
        setMainResponse(itsOk);
    //})
}
//Login
const signIn = (userName,pass,setMainResponse) => {

    const data = {
        //grant_type : 'password',
        username: userName,
        password: pass,
    }

    makeRequestAuth('application/json;charset=UTF-8',data,'POST','Authenticate/login',true,false, (dataRes,success) => {
        //console.log('Authenticate/login');
        //console.log(dataRes);
        //console.log('isAuthSuccessful...');
        //console.log(dataRes.isAuthSuccessful);
        var itsOk = false;
        const TOKEN_DECODED = {};
        if(dataRes.isAuthSuccessful){
            if(dataRes.token !== undefined && dataRes.token !== ''){
                Object.assign(TOKEN_DECODED, jwt_decode(dataRes.token));
                //var decoded = jwt_decode(dataRes.token);
                //console.log('decoded...');
                //console.log(TOKEN_DECODED);
                console.log("Pasa?");
                // Object.assign(TOKEN_DECODED, jwt_decode(dataRes.token));
                // localStorage.setItem(
                //     nameUser_,
                //     TOKEN_DECODED['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
                // );
                localStorage.setItem(
                    rolUser_,
                    TOKEN_DECODED['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                );
                localStorage.setItem(refreshToken_, TOKEN_DECODED['jti']);
                localStorage.setItem(idUser_, TOKEN_DECODED['IdEmployee']);
                // //localStorage.setItem(nameUser_,dataRes.fullName);
                localStorage.setItem(token_,dataRes.token);
                // //localStorage.setItem(refreshToken_,dataRes.refresh_token);
                // //localStorage.setItem(idUser_,dataRes.idUsuario);
                // //localStorage.setItem(rolUser_,dataRes.usertypeid);
                // //localStorage.setItem(emailUser_,dataRes.email);
                localStorage.setItem(login_,true);
                localStorage.setItem(IsQueja_,false);
                
                itsOk = true;
                if(getBoolStorage(isForOrder_)){
                    localStorage.setItem(isForOrder_,false);
                }else{
                    deleteAllCarrito();
                }
                setCurrentDbOrder();
            }
        }
        setMainResponse(itsOk);
    })
}

const refreshTokenUser = (setMainResponse) => {
    
    //const data = "grant_type=refresh_token&refresh_token=" + localStorage.getItem(refreshToken_) ;
    const data = localStorage.getItem(token_);

    //makeRefreshToken('application/json;charset=UTF-8',data,'POST','Token',true,(dataRes,success) => {
        let itsOk = false;
        const success = true;
        if(success){
            //if(dataRes.access_token !== undefined && dataRes.access_token !== ''){
            if(data !== undefined && data !== ''){
                //localStorage.setItem(token_,dataRes.access_token);
                //localStorage.setItem(refreshToken_,dataRes.refresh_token);
                itsOk = true;
                setCurrentDbOrder();
            }
        }else{
            logOut();
        }
        setMainResponse(itsOk);
    //})
}

//Empresa
const getInfoEmpresa = (idEmpresa,setMainRes) => {
    makeRequest('application/json',null,'GET','Empresa/'+idEmpresa,true,false,(dataRes,success) => {
        var itsOk = false;
        if(success){
            //Guardar empresa
            if(dataRes.success){
                itsOk = true;
                console.log(dataRes.data);
                localStorage.setItem('idEmpresa',dataRes.data.idEmpresa);
                localStorage.setItem('nameEmpresa',dataRes.data.nombreComercial);
                localStorage.setItem('urlEmpresaLogo',dataRes.data.imagenUri);
                localStorage.setItem('idDeliveryMesa',dataRes.data.configuracion.mesaDelivery);
                localStorage.setItem('ValidacionPorUsuario',dataRes.data.configuracion.requireValidarUsuario);
                localStorage.setItem('isBilingual',dataRes.data.configuracion.idiomasWPA);
                if(!dataRes.data.configuracion.idiomasWPA){
                    localStorage.setItem(idLan_,1);
                    localStorage.setItem(lan_,"ES");
                }
                //localStorage.setItem('ValidacionPorUsuario',true);
                localStorage.setItem('color_main','#516ac5');
                localStorage.setItem('color_secondary','#829af2');
                localStorage.setItem('color_accent','#808598');
                if(dataRes.data.configuracion.colorMain!==undefined && dataRes.data.configuracion.colorSecundary!==undefined && dataRes.data.configuracion.colorAccent!==undefined){
                    dataRes.data.configuracion.colorMain!==null && dataRes.data.configuracion.colorMain!==""? localStorage.setItem('color_main',dataRes.data.configuracion.colorMain): localStorage.setItem('color_main','#516ac5');//'#1bd13d');//dataRes.data.configuracion.colorMain);
                    dataRes.data.configuracion.colorSecundary!==null && dataRes.data.configuracion.colorSecundary!=="" ? localStorage.setItem('color_secondary',dataRes.data.configuracion.colorSecundary) : localStorage.setItem('color_secondary','#829af2');//'#d11bad'); //dataRes.data.configuracion.colorSecundary);
                    dataRes.data.configuracion.colorAccent!==null && dataRes.data.configuracion.colorAccent!=="" ? localStorage.setItem('color_accent',dataRes.data.configuracion.colorAccent) : localStorage.setItem('color_accent','#808598');//'#00a3f5');//dataRes.data.configuracion.colorAccent);
                }
                else{
                    localStorage.setItem('color_main','#516ac5');
                    localStorage.setItem('color_secondary','#829af2');
                    localStorage.setItem('color_accent','#808598');
                }
            }
        }
        setMainRes(itsOk);
    });
}

const getDataEmpresa = (idEmpresa,setMainRes) => {
    console.log('GET idEmpresa: ' + idEmpresa);
    makeRequest('application/json',null,'GET','Empresa/'+idEmpresa,true,false,(dataRes,success) => {
        
        var dataEmpresa = null;
        var countCarrito = 0;
        if(success){
            console.log(dataRes.data);
            //Guardar empresa
            localStorage.setItem(InfoOn_,true);
            if(dataRes.success){
                dataEmpresa = dataRes.data;
                localStorage.setItem('idEmpresa',dataRes.data.idEmpresa);
                localStorage.setItem('nameEmpresa',dataRes.data.nombreComercial);
                localStorage.setItem('urlEmpresaLogo',dataRes.data.imagenUri);
                localStorage.setItem('idDeliveryMesa',dataRes.data.configuracion.mesaDelivery);
                localStorage.setItem('ValidacionPorUsuario',dataRes.data.configuracion.requireValidarUsuario);
                localStorage.setItem('isBilingual',dataRes.data.configuracion.idiomasWPA);
                if(!dataRes.data.configuracion.idiomasWPA){
                    localStorage.setItem(idLan_,1);
                    localStorage.setItem(lan_,"ES");
                }
                //localStorage.setItem('ValidacionPorUsuario',true);
                localStorage.setItem('color_main','#516ac5');
                localStorage.setItem('color_secondary','#829af2');
                localStorage.setItem('color_accent','#808598');
                if(dataRes.data.configuracion.colorMain!==undefined && dataRes.data.configuracion.colorSecundary!==undefined && dataRes.data.configuracion.colorAccent!==undefined){

                    dataRes.data.configuracion.colorMain!==null && dataRes.data.configuracion.colorMain!==""? localStorage.setItem('color_main',dataRes.data.configuracion.colorMain): localStorage.setItem('color_main','#516ac5');//'#1bd13d');//dataRes.data.configuracion.colorMain);
                    dataRes.data.configuracion.colorSecundary!==null && dataRes.data.configuracion.colorSecundary!=="" ? localStorage.setItem('color_secondary',dataRes.data.configuracion.colorSecundary) : localStorage.setItem('color_secondary','#829af2');//'#d11bad'); //dataRes.data.configuracion.colorSecundary);
                    dataRes.data.configuracion.colorAccent!==null && dataRes.data.configuracion.colorAccent!=="" ? localStorage.setItem('color_accent',dataRes.data.configuracion.colorAccent) : localStorage.setItem('color_accent','#808598');//'#00a3f5');//dataRes.data.configuracion.colorAccent);
                }
                else{
                    localStorage.setItem('color_main','#516ac5');
                    localStorage.setItem('color_secondary','#829af2');
                    localStorage.setItem('color_accent','#808598');
                }
               
                // console.log('color_main: ' + dataRes.data.configuracion.colorMain);
                // console.log('color_secondary: ' + dataRes.data.configuracion.colorSecundary);
                // console.log('color_accent: ' + dataRes.data.configuracion.colorAccent);
                if(parseInt(dataRes.data.idTipo)===7 || parseInt(dataRes.data.idTipo)===10)
                {
                    localStorage.setItem('IsRestaurant',false);
                    localStorage.setItem('idTipoOrden',2);
                    if (dataRes.data.idTipo==10){
                        localStorage.setItem('DosCarritos',true);
                    }
                    else{
                        localStorage.setItem('DosCarritos',false);
                        
                    }
                    
                }
                else{
                    localStorage.setItem('idTipoOrden',1);
                    localStorage.setItem('DosCarritos',false);
                    localStorage.setItem('IsRestaurant',true);
                }

                getInfoCarrito((totalR,countR) => {
                    //localStorage.setItem(countCarrito_,countR);
                    //setInfoOrder({total:totalR,count: countR,readyLogin:true});
                    countCarrito = countR;
                });
                
            }
            
              
        }
        setMainRes(dataEmpresa,countCarrito);
    });
}

//Utils
const validateResponseAuth = (statusCallApi,data,tagApi,allInfo) => {

    var status = false;

    localStorage.setItem(errorApi_,null);
    localStorage.setItem(firstError_,false);

    if(statusCallApi){
        if(data.isAuthSuccessful){
            if(allInfo) {
                console.log('Ok ' + tagApi);
                console.log(data);
            }
            status = true;
        }else{
            console.log("Error ejecutando api: "+tagApi);
            console.log(data.errorList[0]);
            localStorage.setItem(firstError_,true);
            if(data.errorList[0]){
                localStorage.setItem(errorApi_,data.errorList[0]);
            }
        }
    }else{
        localStorage.setItem(errorApi_,data);
        console.log("Error ejecutando api: "+tagApi);
        console.log(data);
    }
    return status;
}
const validateResponse = (statusCallApi,data,tagApi,allInfo) => {

    var status = false;

    localStorage.setItem(errorApi_,null);
    localStorage.setItem(firstError_,false);

    if(statusCallApi){
        if(data.success){
            if(allInfo) {
                console.log('Ok ' + tagApi);
                console.log(data);
            }
            status = true;
        }else{
            console.log("Error ejecutando api: "+tagApi);
            console.log(data.firstError);
            localStorage.setItem(firstError_,true);
            localStorage.setItem(errorApi_,data.firstError);
        }
    }else{
        localStorage.setItem(errorApi_,data);
        console.log("Error ejecutando api: "+tagApi);
        console.log(data);
    }
    return status;
}

const getMsgErrorApi = (defaultMsg) => {
    const error   = localStorage.getItem(errorApi_);
    const isError = localStorage.getItem(firstError_);
    const isError1 = isError === null ? false : isError;
    console.log('defaultMsg: ' + defaultMsg);
    var msg = defaultMsg;
    if(isError1){
        msg = error;
    }
    localStorage.setItem(errorApi_,null);
    localStorage.setItem(firstError_,false);
    return msg;
}

const getNameProducto = (idproducto,IDIdioma,setMainRes) =>{

    var NombreIdioma = "";
    var nombreMenuIdioma="";

    const dataEsp = {
        idProducto: idproducto,
        idIdioma : IDIdioma
    }
   
    //console.log('masterdata/getProducto');
    makeRequest(
        'applicacion/json',dataEsp,'GET','masterdata/getProducto' ,true,false,(dataRes,success) => {
           
            if(success==true && dataRes.data!=null){
                //console.log(dataRes.data);
                NombreIdioma=dataRes.data.nombreSubMenu;
                nombreMenuIdioma=dataRes.data.nombreMenu;
                setMainRes(NombreIdioma,nombreMenuIdioma);
            }
        }
    );
}

//SignalR
const signalRConnectionBuild  = (_hubConnection) => {
    const URL = API_signalr;//"https://signalrdev.ontable.app"; //or whatever your backend port is
    const urlHub = `${URL}/notify`;
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken },
        withCredentials: false
    };

    const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(urlHub, httpOptions)
    .withAutomaticReconnect({
        // Configure the reconnection options here
        // For example, you can specify the interval between reconnect attempts
        // and the maximum number of reconnect attempts
        nextRetryDelayInMilliseconds: retryContext => {
            // Define your custom retry delay logic here
            // retryContext.previousRetryCount contains the number of previous retries
            // retryContext.elapsedMilliseconds contains the elapsed time in milliseconds
            // Return the delay in milliseconds for the next reconnect attempt
            // or null to stop the reconnection process
        },
        maxRetryDelayInMilliseconds: 600000, // Maximum delay between reconnect attempts
        reconnectDelayInMilliseconds: 60000, // Delay between each reconnect attempt
        maxReconnectAttempts: null, // Maximum number of reconnect attempts (null for unlimited)
    })
    .configureLogging(signalR.LogLevel.Information)
    .build();  
    _hubConnection(hubConnection);
}

const notifyCallWaiter = (setMainRes) => {
    var bolHabitacion=getBoolStorage(esHabitacion_);
    //console.log('bolHabitacion ' + bolHabitacion);
    const data = {
        idEmpresa : parseInt(localStorage.getItem(idEmpresa_)),
        origen : 8,
        comando : 'llamada-mesero',
        idMesa : parseInt(localStorage.getItem(idMesa_)),
        EtiquetaMesa : localStorage.getItem(tagMesa_),
        UbicacionMesa : localStorage.getItem(ubicacion_),
        esHabitacion: bolHabitacion
    }
    makeRequestSignalR('applicacion/json',data,'POST','api/v0/Notificaciones/Enviar/LlamarMesero',true,true,(res,success) => {
        if(success==true && res!=null){
            setMainRes(res,success);
        }
    });
}

const notifyCallCheck = (setMainResponse) => {
    var bolHabitacion=getBoolStorage(esHabitacion_);
    //console.log('bolHabitacion ' + bolHabitacion);
    const data = {
        idEmpresa : parseInt(localStorage.getItem(idEmpresa_)),
        origen : 8,
        comando : 'solicitud-cuenta',
        idMesa : parseInt(localStorage.getItem(idMesa_)),
        EtiquetaMesa : localStorage.getItem(tagMesa_),
        UbicacionMesa : localStorage.getItem(ubicacion_),
        esHabitacion: bolHabitacion
    }
    makeRequestSignalR('applicacion/json',data,'POST','api/v0/Notificaciones/Enviar/LlamarMesero',true,true,(res,success) => {
        if(success==true && res!=null){
            setMainResponse(res,success);
        }
    });
}

const notifyNewOrden = (idOrder,TipoOrden,setMainResponse) => {
    //var bolHabitacion=getBoolStorage(esHabitacion_);
    //console.log('bolHabitacion ' + bolHabitacion);
    const data = {
        idEmpresa : parseInt(localStorage.getItem(idEmpresa_)),
        idOrden: idOrder,
        tipoOrden: TipoOrden,
        comando: 'nueva-orden',
        origen: 8,
    }
    makeRequestSignalR('applicacion/json',data,'POST','api/v0/Notificaciones/Enviar/Orden',true,true,(res,success) => {
        if(success==true && res!=null){
            setMainResponse(res,success);
        }
    });
}

const notifyUpdateOrden = (_idArea, _idOrden, _idProductoOrden, _producto, setMainResponse) => {
    const data = {
        idEmpresa : parseInt(localStorage.getItem(idEmpresa_)),
        idArea: _idArea,
        idOrden: _idOrden,
        idProductoOrden: _idProductoOrden,
        producto: null,
        // {
        //     idTopping: $scope.idProductoOrdenActivo,
        //     idAreaDeServicio: productoPadre.idAreaDeServicio,
        //     idTiempoMenu: productoPadre.idTiempoMenu,
        //     idEstatusServicio: 1,
        //     menu: productoAgregado.nombreMenu,
        //     producto: productoAgregado.nombreProducto,
        //     subMenu: productoAgregado.nombreSubMenu,
        //     disponible: productoAgregado.disponible,
        //     esActivo: productoAgregado.esActivo,
        //     esTopping: true
        // },
        idOrigen: 8,
        comando: 'nuevo-producto',
    }
    makeRequestSignalR('applicacion/json',data,'POST','api/v0/Notificaciones/Enviar/producto',true,true,(res,success) => {
        if(success==true && res!=null){
            setMainResponse(res,success);
        }
    });
}

export {
    getDataEmpresa,
    setOrdenEntregada,
    getOrdersEntransito,
    getOrdersAsignadas,
    notifyPayment,
    detailDeliveryOrder,
    resetStorageOrder,
    deleteInfoOrder,
    statusDeliveryOrder,
    resetInfoDelivery,
    checkStatusOrder,
    getAllOrderRestaurant,
    resetAllOrder,
    makeDeliveryOrder,
    optionsDelivery,
    deleteDeliveryAddress,
    saveDeliveryAddress,
    searchAddress,
    getAllAddressUser,
    getInfoEmpresa,
    createOrderRestaurant,
    sendAllOrdenes,
    checkStatusOrderRestaurant,
    signIn,
    signInGeneral,
    makePayment,
    getTopDishes,
    aceptarEntrega,
    cancelarEntrega,
    getAlergenos,
    registroNormal,
    saveAlergeno,
    registroSocialNetwork,
    callCheck,
    getMsgErrorApi,
    myCurrentOrders,
    updateUser,
    searchDishes,
    getInfoUser,
    getSummaryDate,
    getRecordsByDate,
    rateDish,
    callWaiter,
    refreshTokenUser,
    getStatusOrderRest,
    getNumOrdenesAbierta,
    getNameProducto,
    CreateComplaint,
    signalRConnectionBuild,
    notifyCallWaiter,
    notifyCallCheck,
    notifyNewOrden,
    notifyUpdateOrden
}