import React, { useState } from 'react';
//DB
import {addComment,updateCommentDB} from '../../../../service/ManagerCarrito';
//Styles
import { makeStyles,withStyles } from '@material-ui/core/styles';
//import {MainColors} from '../../Sources/SourcesConst';
import { color_secondary_} from '../../../Constants';
//Components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TranslateStr from '../../ConfigPage/HelperLanguage/TranslateStr';

const usestyles = makeStyles(() => ({
    container: {
        width: '80vw',
        height: '40vh',
        backgroundColor: '#fff',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        margin: '3vh',
        textAlign: 'center',
    }
}))


const CssTextField = withStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
        color: localStorage.getItem(color_secondary_), //MainColors.secondary,
     },
      '& .MuiInput-underline:after': {
        color: localStorage.getItem(color_secondary_),
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: localStorage.getItem(color_secondary_),
        },
        '&:hover fieldset': {
          borderColor: localStorage.getItem(color_secondary_),
        },
        '&.Mui-focused fieldset': {
          borderColor: localStorage.getItem(color_secondary_),
        },
      },
    },
}))(TextField);

const ColorButton = withStyles((theme) => ({
    root: {
      color: localStorage.getItem(color_secondary_), // MainColors.secondary,
      fontSize: '3vh',
      fontWeight: 'bold',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
}))(Button);

export default function AddComment(props){
    const sty = usestyles();
    const indexCo = props.index;
    const dataDish = props.dataDish;
    const [comment,setComment] = useState(indexCo === -1 ? "" : dataDish.comments[indexCo]);
    const updateComment = props.index !== -1;

    const onInputComment = (e) => {
        e.persist();
        setComment(e.target.value);
    }

    const addCommentM = () => {
        if(comment !== ""){
            if(updateComment){
                updateCommentDB(comment,indexCo,dataDish.id,() => {
                    props.hide();
                    props.save();
                });
            }else{
                addComment(comment,dataDish.id,() => {
                    props.hide();
                    props.save();
                });
            }
        }else{
            props.hide();
        }
    }

    return(
        <Grid className={sty.container} container direction="column" alignItems="center" justify="space-between">
                
                <Grid item xs>
                    <p className={sty.title}></p>
                </Grid>
                <Grid item xs>
                    <form noValidate autoComplete="off">
                        <CssTextField value={comment} onChange={onInputComment} id="outlined-basic" label="Indicación" variant="outlined" />
                    </form>
                </Grid>
                <Grid item xs>
                    <ColorButton onClick={addCommentM} variant="contained">
                        <TranslateStr token="save"/>
                    </ColorButton>
                </Grid>
        </Grid>
    )
}