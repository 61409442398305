import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/ListItemAvatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

//import { color_accent_, color_main_, color_secondary_} from '../../../Constants';
import {  color_main_} from '../../../Constants';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0.5vh',
    },
}));

export default function CommentDish(props){
    const data = props.dataComment;
    const sty = useStyles();
    return(
        <ListItem className={sty.root}>
            <ListItemAvatar>
                <Avatar style={{color: localStorage.getItem(color_main_)}}>
                    <AccountCircleIcon/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText 
                primary={data.usuario}
                secondary={data.texto}/>
            <Box component="fieldset" mb={1} borderColor="transparent">
                <Rating name="read-only" value={data.calificacion} readOnly />
            </Box>
        </ListItem>
    )
}