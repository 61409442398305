import React, { useState } from 'react';
//Styles
import { makeStyles } from '@material-ui/core/styles';
import {MainColors} from '../../Sources/SourcesConst';
import {color_secondary_} from '../../../Constants';
//Apis
import {rateDish} from '../../../../service/ManagerApis'
//Components
import {makeNormalDialog} from '../../../utilscomponents/DialogAsk';
import Rating from '@material-ui/lab/Rating';
import {
    Grid,
    Box
} from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {getCustomTextField} from '../../Generics/TextField';
import {getCustomButton} from '../../Generics/CustomButton';
import TranslateStr from '../HelperLanguage/TranslateStr';

const CommentTextField = getCustomTextField(MainColors.secondary);

const AcceptButton = getCustomButton(MainColors.secondary,'3vh','bold','#fff','#fff');

const usestyles = makeStyles(() => ({
    container: {
        width: '80vw',
        height: '40vh',
        backgroundColor: '#fff',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        margin: '3vh',
        textAlign: 'center',
    },
}))

export default function RateOrder(props){

    const sty = usestyles();
    const {dataOrder} = props;
    const [infoForm,setInfoForm] = useState({comment:"",rating:0});

    const onInputComment = (e) => {
        e.persist();
        setInfoForm({comment:e.target.value,rating:infoForm.rating});
    }

    const onInputRating = (e) => {
        e.persist();
        setInfoForm({comment:infoForm.comment,rating:e.target.value});
    }

    const validateFields = () => {
        if(infoForm.comment !== "" || infoForm.rating !== 0){
            rateDish(dataOrder.idProducto,infoForm.comment,infoForm.rating,dataOrder.idProductoOrden, status => {
                if(status){
                    makeNormalDialog(<TranslateStr token="doneComment"/>,<TranslateStr token="thanksForRate"/>,() =>{
                        props.hideDialog();
                        props.onUpdateComment(infoForm.comment,infoForm.rating);
                    });
                }else{
                    makeNormalDialog(<TranslateStr token="somewrong"/>,<TranslateStr token="sorryTryAgain"/>, null);
                }
            });
        }else{
            makeNormalDialog(<TranslateStr token="ups"/>,<TranslateStr token="errorRate"/>,null);
        }
    }

    return(
        <Grid className={sty.container} container direction="column" alignItems="center" justify="space-between">
                
                <Grid item xs>
                    <p className={sty.title}><TranslateStr token="rateDish"/></p>
                </Grid>
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Rating
                        onChange={onInputRating}
                        name="customized-empty"
                        defaultValue={0}
                        precision={1}
                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    />
                </Box>
                <Grid item xs>
                    <CommentTextField value={infoForm.comment} onChange={onInputComment} id="outlined-basic" label={<TranslateStr token="comment"/>} variant="outlined" />
                </Grid>
                <Grid item xs>
                    <AcceptButton style={{color: localStorage.getItem(color_secondary_)}} onClick={validateFields} variant="contained">
                        <TranslateStr token="continue"/>
                    </AcceptButton>
                </Grid>
        </Grid>
    )
}