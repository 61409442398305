import React,{useState, useEffect} from 'react';
import PropTypes from 'prop-types';
//Components
import FormOpenpay from '../Openpay/FormOpenpay';
import Login from '../Login/Login';
import SelectAddress from './Address/SelectAddress';
import SelectOption from './SelectOption';
import ConfirmOrder from './Confirm/ConfirmOrder';
import {Grid,Stepper,Step,StepLabel,StepConnector,Fab,Avatar} from '@material-ui/core';
//Dialog
import { confirmAlert } from 'react-confirm-alert';
//Apis
import {makeDeliveryOrder,resetInfoDelivery} from '../../../service/ManagerApis'; 
//Styles
import {MainColors} from '../Sources/SourcesConst';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import SendIcon from '@material-ui/icons/Send';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//
import { deleteAllCarrito,getInfoCarrito } from '../../../service/ManagerCarrito';
import { login_, idAddressDel_, idOptDel_ } from '../../Constants';
import {getBoolStorage} from '../../../service/HelperStorage';

export default function OrdenDelivery(props){

    const login = getBoolStorage(login_);
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [indexPag,setIndexPage] = useState(0);
    const [infoOrder,setInfoOrder] = useState({total:0.0,count:0,readyLogin:false});

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep === 2 ? 0 : prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const validateOrder = () => {
        var addreess = localStorage.getItem(idAddressDel_);
        var option = localStorage.getItem(idOptDel_);
        if(option === null || addreess === null){
            makeDialog('Campos faltante','Es necesario seleccionar una dirección y una opción de entrega');
        }else{
            dialogSend();
        }
    }

    const sendOrder = () => {
        makeDeliveryOrder(successSend => {
            if(successSend){
                resetInfoDelivery();
                deleteAllCarrito();
                okSendDialog();
            }else{
                makeDialog('Ocurrió un error','Su pedido no puse ser completado, intente de nuevo')
            }
        });
    }

    const dialogSend = () => {
        confirmAlert({
            title: 'Seleccione su método de pago',
            message: 'Si es efectivo la orden se creará al oprimir esa opción',
            buttons: [
            {
                label: 'Pago en línea',
                onClick: () => setIndexPage(1)
            },
            {
                label: 'Efectivo',
                onClick: () => sendOrder()
            }
            ]
        });
    };

    const okSendDialog = () => {
        confirmAlert({
            title: '¡Orden enviada!',
            message: 'Su pedido a domicilio se creó exitosamente',
            onClickOutside: () => {
                props.back(-1);
            },
            buttons: [
            {
                label: 'Aceptar',
                onClick: () => {
                    props.back(-1);
                }
            }
            ]
        });
    };

    const faildLogin = () => {
        confirmAlert({
            title: 'Acción necesaria',
            message: 'Para enviar una orden se requiere iniciar sesión',
            onClickOutside: () => {
                props.back(-1);
            },
            buttons: [
            {
                label: 'Aceptar',
                onClick: () => {
                    props.back(-1);
                }
            }
            ]
        });
    };

    const statusLogin = (status) => {
        if(status === false){
            faildLogin();
        }else{
            getInfoCarritoMain();
        }
    }

    const onBackPayment = (successPayment) => {
        if(successPayment){
            props.back(-1)
        }else{
            setIndexPage(0);
        }
    }

    const getInfoCarritoMain = () => {
        getInfoCarrito((totalR,countR) => {
            setInfoOrder({total:totalR,count: countR,readyLogin:true});
        });
    }

    useEffect(() => {
       if(login){
           getInfoCarritoMain();
       } 
    },[])

    return(
        login ?
            infoOrder.readyLogin &&
            (function(){
                switch (indexPag) {
                    case 0:
                        return <div className={classes.root} >
                            <Grid item container alignItems="center" justify="flex-start">
                                <Grid item>
                                    <Avatar onClick={() => props.back(-1)} className={classes.back} alt="Add icon">
                                        <ArrowBackIcon className={classes.img}/>
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <p className={classes.titlePage}>Orden a domicilio</p>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                    {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel className={classes.labelStep} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                            <div>
                                {getStepContent(activeStep)}
                                {
                                    activeStep === 2 && 
                                        <Fab onClick={() => validateOrder()} className={classes.btnSend} variant="extended">
                                            <SendIcon />
                                            Enviar
                                        </Fab>
                                }
                                <Fab disabled={activeStep === 0} onClick={handleBack} className={classes.backBtn} aria-label="back">
                                    <KeyboardArrowLeftIcon/>
                                </Fab>
                                <Fab className={classes.button} onClick={handleNext} aria-label="next">
                                    <KeyboardArrowRightIcon />
                                </Fab>
                            </div>
                        </div>
                    case 1:
                        return <FormOpenpay
                            isDelivery={true}
                            total={infoOrder.total} 
                            countItems={infoOrder.count}
                            arrayToPay={null} 
                            changePage={onBackPayment}/>
                    default:
                        break;
                }
            })()
        :
            <Login changePage={statusLogin}/>
    )
}

const makeDialog = (title,sub) => {
    confirmAlert({
        title: title,
        message: sub,
        buttons: [
        {
            label: 'Aceptar',
        }
        ]
    });
};

function getSteps() {
    return ['Dirección', 'Opciones de entrega', 'Confirmar orden'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return <SelectAddress/>;
        case 1:
            return <SelectOption/>;
        case 2:
            return <ConfirmOrder/>;
        default:
        return 'Unknown step';
    }
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <HomeIcon />,
        2: <FormatListBulletedIcon />,
        3: <AssignmentTurnedInIcon />,
    };

    return (
        <div
        className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
        })}
        >
        {icons[String(props.icon)]}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        height: '100%'
    },
    back: {
        width: '100%',
        height: '100%',
        padding: '1.5vh',
        color: MainColors.accent,
        backgroundColor: '#fff',
    },
    img:{
        margin: '0',
        width: '6vh',
        height: '6vh',
    },
    titlePage:{
        padding: '0',
        margin: '0',
        fontSize: '4vh',
        color: MainColors.main,
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepper: {
        padding: '1.5vh',
    },
    button: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: '#fff',
        backgroundColor: MainColors.main,
        '&:hover': {
        backgroundColor: MainColors.main,
        },
    },
    resetBtn: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: '#fff',
        backgroundColor: MainColors.main,
    },
    backBtn: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(10),
        color: '#808B96',
        backgroundColor: '#fff',
    },
    btnSend:{
        position: 'absolute',
        bottom: theme.spacing(10),
        right: theme.spacing(2),
        color: '#fff',
        backgroundColor: MainColors.main,
        '&:hover': {
            backgroundColor: MainColors.main,
        },
    }
}));

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: '7vh',
        height: '7vh',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
        'linear-gradient( 95deg,rgb(174, 214, 241) 0%,rgb(36, 113, 163 ) 50%,rgb(92, 107, 192) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
        'linear-gradient( 95deg,rgb(174, 214, 241) 0%,rgb(36, 113, 163 ) 50%,rgb(92, 107, 192) 100%)',
    },
});

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
            'linear-gradient( 95deg,rgb(174, 214, 241) 0%,rgb(36, 113, 163 ) 50%,rgb(92, 107, 192) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
            'linear-gradient( 95deg,rgb(174, 214, 241) 0%,rgb(36, 113, 163 ) 50%,rgb(92, 107, 192) 100%)',
        },
    },
    line: {
        height: '0.5vh',
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);