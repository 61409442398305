import React,{useState, useEffect} from 'react';
//Components
import {Grid} from '@material-ui/core';
import ItemOrder from './ItemOrder';
//Apis
import {getRecordsByDate} from '../../../../service/ManagerApis';
//Styles
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles( () => ({
    root:{
        marginTop: '0.7vh',
    },
}));

export default function ListRecords(props){

    const sty = useStyles();
    const [arrayOrders,setArrayOrders] = useState([]);

    useEffect(() =>{
        getRecordsByDate(props.date,array =>{
            setArrayOrders(array);
        });
    },[]);

    return(
        <Grid className={sty.root} container direction="column" justify="center" alignItems="center">
            {
                arrayOrders.map((item,index) => (
                    <ItemOrder data={item} key={index}/>
                ))
            }
        </Grid>
    )
}