import React from 'react';
//Styles
import {MainColors} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
//Components
import FavoriteList from './FavoriteList';
import {Grid,Avatar} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';

const useStyles = makeStyles(({
	root: {
		flex: 0,
    },
    back: {
        width: '100%',
        height: '100%',
        padding: '1vh',
        color: MainColors.accent,
        backgroundColor: '#fff',
    },
    img:{
        margin: '0',
        width: '6vh',
        height: '6vh',
    },
    title: {
        color: MainColors.main,
        fontSize: '4vh',
        fontWeight: '300',
        margin: '1vh',
        padding: '0',
     },
}));

export default function Favoritos(props) {
	const sty = useStyles();
	return(
		<Grid className={sty.root} container direction="column" justify="center" alignItems="center">
            
            <Grid item container alignItems="flex-start">
                <Grid item>
                    <Avatar onClick={() => props.changePage(0)} className= {sty.back} alt="Add icon">
                        <ArrowBackIcon className={sty.img}/>
                    </Avatar>
                </Grid>
                <Grid item>
                    <p className={sty.title}><TranslateStr token="titleFavorite"/></p>
                </Grid>
            </Grid>
			<FavoriteList/>
		</Grid>
	)
}
