import {dictionary} from './dictionary';
import { initialState, reducer} from './LocalizationReducer';
import React,{ useReducer,createContext } from 'react';

export const LocalizationContext = createContext();

export function LanguageProvider({children}){
    const localizationStore = useReducer(reducer,initialState);
    return (
        <LocalizationContext.Provider value={{localizationStore,dictionary}}>
            {children}
        </LocalizationContext.Provider>
    )
}