import {Service} from './DBService';
import {resetStorageOrder,resetInfoDelivery,myCurrentOrders} from './ManagerApis';
import {idEmpresa_,idUser_,idMesa_, idOrden_,isIdOrden_,isDelivery_} from '../components/Constants';

const tableMain = "ItemOrder";

const allItemOrders = (setResultDDB) => {
    const idEmpresa = localStorage.getItem(idEmpresa_);
    const idMesa = parseInt(localStorage.getItem(idMesa_));
    console.log();
    Service.getAll(tableMain).then(array => {
        var arrayFinal = [];
            if(array.length !== 0){
                array.map(function(item){
                    if(item.idEmpresa === parseInt(idEmpresa) && parseInt(item.idMesa) === idMesa ){
                        arrayFinal.push(item);
                    }
                })
            }
            setResultDDB(arrayFinal);
    });
}

const allItemOrdersDB = (setResultDDB) => {
    const idEmpresa = localStorage.getItem(idEmpresa_);
    const idMesa = parseInt(localStorage.getItem(idMesa_));
    console.log();
    //Se traen las del server
    myCurrentOrders(arrayOrders => {
        console.log("Entra myCurrentOrders?")
        console.log(arrayOrders)
        var arrayFinal = [];
        if(arrayOrders.length !== 0){
            arrayOrders.map(function(item){
                if(item.idEmpresa === parseInt(idEmpresa) && parseInt(item.idMesa) === idMesa){
                    arrayFinal.push(item);
                }
            })
        }
        setResultDDB(arrayFinal);
    });
}

//Solo a un usuario le apareceria si fuese que una orden esta relacionada a un usuario IMPORTANTE
//Condicionando a que hay una sesion de usuario
const setCurrentDbOrder = () => {

    const idEmpresa = parseInt(localStorage.getItem(idEmpresa_));
    const idMesa = parseInt(localStorage.getItem(idMesa_));

    Service.getAll(tableMain).then(arrayDB => {
        //Se borran ordenes de DB local e informacion de las ordenes anteriores
        arrayDB.forEach(itemOrderDB => {
            deleteDbOrder(itemOrderDB.id);
        });
        resetInfoDelivery();
        resetStorageOrder();
    });

    //Se traen las del server
    myCurrentOrders(arrayOrders => {
        console.log("Entra?")
        console.log(arrayOrders)
        if(arrayOrders.length !== 0){
            arrayOrders.forEach(orderServer => {
                if(parseInt(orderServer.idEmpresa) === idEmpresa && parseInt(orderServer.idMesa) === idMesa ){
                    saveDbOrder(orderServer.idTipoOrden,orderServer.idOrden,0,orderServer.esDelivery,() =>{});
                } 
            });
        }
    });
}
const setCurrentDbOrders = () => {

    const idEmpresa = parseInt(localStorage.getItem(idEmpresa_));
    const idMesa = parseInt(localStorage.getItem(idMesa_));
    var contOrders=false;
    var arrayLocal=[];
    //Carga tabla local ItemOrder
    allItemOrders(array => {
        arrayLocal=array;
        console.log(tableMain + " Entra??");
        console.log(array);
        // array.forEach(element => {
        //     if( parseInt(element.id)===parseInt(orderServer.idOrden)){
        //         contOrders=true;
        //         console.log( "contOrders?? " + contOrders);
        //     }
        // });
    });

    //Se traen las del server
    myCurrentOrders(arrayOrders => {
        console.log("myCurrentOrders Entra??");
        console.log(arrayOrders);
        if(arrayOrders.length !== 0){
            arrayOrders.forEach(orderServer => {
                if(parseInt(orderServer.idEmpresa) === idEmpresa && parseInt(orderServer.idMesa) === idMesa){
                    contOrders=false;
                    console.log(tableMain + "arrayLocal Entra??");
                    console.log(arrayLocal);
                    //Compara si existe orden en ItemOrder
                    arrayLocal.forEach(element => {
                        if( parseInt(element.id)===parseInt(orderServer.idOrden)){
                            contOrders=true;
                            console.log( "contOrders?? " + contOrders);
                        }
                    });
                    //Si no exite orden del server la almacena en ItemOrder
                    if(contOrders==false){
                        console.log('add orden ' + orderServer.idOrden);
                        saveDbOrder(orderServer.idTipoOrden,orderServer.idOrden,0,orderServer.esDelivery,() =>{});
                    }
                }
            });
        }
    });
    
}

const deleteDbOrder = (id) => {
    Service.delete(tableMain,id);
}

const deleteAllDbOrder = () => {
    Service.deleteAll(tableMain);
}

const itemDbOrderById = (id,setResultDB) => {
    Service.get(tableMain,id).then(item => {
        setResultDB(item);
    });
}


const saveDbOrder = (idTipoOrden,idOrder,idArea,isDelivery,onResult) => {
    //const idTipoOrden= localStorage.getItem(idTipoOrden_);
    const dataSave = {
        id : idOrder,
        idEmpresa : parseInt(localStorage.getItem(idEmpresa_)),
        idUser : parseInt(localStorage.getItem(idUser_)),
        idMesa:  parseInt(localStorage.getItem(idMesa_)), 
        isDelivery : isDelivery,
        idTipoOrden : idTipoOrden,
        idAreaDeServicio: idArea
    }
    console.log('orden guardada');
    console.log(dataSave);
    
    localStorage.setItem(isIdOrden_,true);
    localStorage.setItem(idOrden_,idOrder);
    localStorage.setItem(isDelivery_,isDelivery);
    //localStorage.setItem(IsQueja_,false);

    Service.add(tableMain,dataSave).then(() => {
        onResult();
    }).catch(error => {
        console.log("Que pedo we?")
        console.log(error);
        onResult();
    });
}
  
export {saveDbOrder,deleteAllDbOrder,deleteDbOrder,itemDbOrderById,allItemOrders,setCurrentDbOrder,setCurrentDbOrders,allItemOrdersDB}
  