import React from 'react';
//Components
import {
    Chip,
    Typography,
    Grid,
    Paper,
    Avatar,
    Divider,
    ListItem
} from '@material-ui/core';
//Styles
import {MainColors} from '../../Sources/SourcesConst';
import {makeStyles} from '@material-ui/core/styles';

export default function ItemToSend(props){

    const dataDish = props.data;
    const sty = useStyles();
    
    return(
        <ListItem className={sty.root}>
            <Paper className={sty.paper}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Avatar className={sty.image} alt="Menu Photo" src={dataDish.url} />
                    </Grid>
                    <Grid item xs={9} container direction="column">
                        <Grid item xs container direction="row">
                            <Grid item xs={9}>
                                <Typography className={sty.title}>
                                {dataDish.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={sty.price} >{'$' + dataDish.price}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <p className={sty.cantidad}>{dataDish.cantidad}</p>
                            </Grid>
                            <Grid item>
                                <p className={sty.total} >{"$" + dataDish.cantidad*dataDish.price}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        dataDish.comments.lenght > 0 &&
                        <Grid container direction="column">
                            <Divider className={sty.divider} variant="middle" />
                            <Grid item xs container direction="row" justify="space-between" alignItems="center" >
                                <Grid item >
                                    <p className={sty.indicaciones}>Indicaciones</p>
                                </Grid>
                            </Grid>
                            <Grid>
                                <div className={sty.chipContainer}>
                                    {
                                        dataDish.comments.map((comment,index) => {
                                            return (
                                                <Chip
                                                    key={index}
                                                    className={sty.chip}
                                                    variant="outlined"
                                                    size="small"
                                                    label={comment}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </ListItem>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100% !important',
    },
    root: {
        padding: '0',
         flexGrow: 1,
         margin: '0',
         width: '100%',
    },
    paper: {
      width: '100%',
      padding: theme.spacing(1),
      margin: '0',
      maxWidth: 500,
      border: '0.2vh solid #EBEDEF',
    },
    image: {
      width: '8vh',
      height: '8vh',
      margin: 'auto',
    },
    iconComment: {
        color: MainColors.secondary,
        backgroundColor: '#fff',
    },
    chip: {
        color: MainColors.secondary,
    },
    cantidad: {
        color: MainColors.secondary,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    total: {
        color: MainColors.main,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        marginBottom: '0',
    },
    price: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        marginBottom: '0',
        textAlign: 'right',
    },
    divider: {
        margin: '1vh',
    },
    chipContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
        margin: theme.spacing(0.5),
        },
    },
    indicaciones: {
        color: MainColors.secondary,
        fontSize: '2.5vh',
        fontWeight: 'light',
        textAlign: 'left',
        marginBottom: '0',
    },
}));