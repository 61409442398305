import {LocalizationContext} from './LanguageProvider';
import { useContext } from 'react';

export default function Translator({token}) {

    const {
        localizationStore : [state], dictionary
    } = useContext(LocalizationContext);

    const { 
        selectedLanguage, defaultLanguage
    } = state;

    const translatedToke = dictionary[selectedLanguage][token] || dictionary[defaultLanguage][token];

    return translatedToke;
}