import axios from 'axios';
//import * as signalR from "@microsoft/signalr";

//import {refreshTokenUser} from './ManagerApis';

//const API_BASE = 'https://ontable.app/api/v0'; //PRODUCTIVO
//const API_BASE = 'http://gcaseqa-001-site23.atempurl.com/api/v0'; //DESARROLLO
//const API_BASE = 'http://gcaseqa-001-site36.itempurl.com/api/v0'; //CALIDAD

//Desarrollo NETCORE
// const API_BASE = 'https://dev-api.ontable.app/restaurante/api/v0'; //NETCORE restaurante
// const API_BASE_AUT = 'https://dev-api.ontable.app/auth/api'; //NETCORE auth
// const API_BASE_KPI = 'https://dev-api.ontable.app/KPI/api/v0'; //NETCORE KPI
// const API_BASE_IMG = 'https://dev.ontable.app/'; 
// const API_signalr = 'https://signalrdev.ontable.app';

//local NETCORE
// const API_signalr = 'https://localhost:3008';
// const API_BASE = 'https://localhost:3000/restaurante/api/v0'; //NETCORE restaurante local
// const API_BASE_AUT = 'https://localhost:3000/auth/api'; //NETCORE auth local
// const API_BASE_KPI = 'https://localhost:3000/KPI/api/v0'; //NETCORE KPI local
// const API_BASE_IMG = 'https://dev.ontable.app/'; 

//Productivo NETCORE
const API_BASE = 'https://prd-api.ontable.app/restaurante/api/v0'; //NETCORE restaurante
const API_BASE_AUT = 'https://prd-api.ontable.app/auth/api'; //NETCORE auth
const API_BASE_KPI = 'https://prd-api.ontable.app/KPI/api/v0'; //NETCORE KPI
const API_BASE_IMG = 'https://app.ontable.app/'; 
const API_signalr = 'https://signalr.ontable.app';

async function makeRequest(contentType, data, methodName, pathUrl,getAllResponse,isWithToken, setResponse){

    const token = localStorage.getItem('token');
    const inst = axios.create();

    inst.interceptors.request.use(
    async config => {
        config.headers = {
        'Authorization': `Bearer ${ token === null ? "" : token}`,
        'Accept': contentType,
        //'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json'
        }
        return config
    },
    error => {
        Promise.reject(error)
    });

    /* inst.interceptors.response.use((response) => {
        return response;
    }, async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await refreshTokenUser(() => {});
            axios.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('token') }`;
            return inst(originalRequest);
        }
    }); */

    let baseURL = pathUrl === 'Token' ? `${API_BASE_IMG}${pathUrl}` : `${API_BASE}/${pathUrl}`;

    //console.log('baseURL:' + baseURL);

    if(methodName === 'GET'){
        if(data !== null){
            let count = 0;
            Object.entries(data).forEach(([key, value]) => {
                if(count === 0){
                    baseURL += '?' + key + '=' + value;
                }else{
                    baseURL += '&' + key + '=' + value;
                }
                count ++;
            });
        }
        await inst.get(baseURL).then((response) => {
            //console.log(response);
            setResponse(getAllResponse ? response.data : response.data.data, true)
        }).catch((error) => {
            console.log(error);
            setResponse(error,false)
        });
    }else{

        let dataPost = data;

        // if(data !== null){
        //     let count = 0;
        //     Object.entries(data).forEach(([key, value]) => {
        //         if(count === 0){
        //             dataPost += key + '=' + value;
        //         }else{
        //             dataPost += '&' + key + '=' + value;
        //         }
        //         count ++;
        //     });
        // }

        inst.post(baseURL,dataPost).then((response) => {
            console.log('devuelve response...');
            console.log(response);
            setResponse(getAllResponse ? response.data : response.data.data, true)
        }).catch((error) => {
            console.log('error response...');
            console.log(error);
            setResponse(error,false)
        });
    }

    //console.log('pathUrl: ' + pathUrl);
}

async function makeRequestAuth(contentType, data, methodName, pathUrl,getAllResponse,isWithToken, setResponse){

    const token = localStorage.getItem('token');
    const inst = axios.create();

    inst.interceptors.request.use(
    async config => {
        config.headers = {
        'Authorization': `Bearer ${ token === null ? "" : token}`,
        'Accept': contentType,
        'Content-Type': 'application/json'
        }
        return config
    },
    error => {
        Promise.reject(error)
    });

    /* inst.interceptors.response.use((response) => {
        return response;
    }, async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await refreshTokenUser(() => {});
            axios.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('token') }`;
            return inst(originalRequest);
        }
    }); */

    //let baseURL = pathUrl === 'Token' ? `${API_BASE_IMG}${pathUrl}` : `${API_BASE_AUT}/${pathUrl}`;
    let baseURL = pathUrl === 'Token' ? `${API_BASE_IMG}${pathUrl}` : `${API_BASE_AUT}/${pathUrl}`;

    console.log('baseURL:' + baseURL);

    if(methodName === 'GET'){
        if(data !== null){
            let count = 0;
            Object.entries(data).forEach(([key, value]) => {
                if(count === 0){
                    baseURL += '?' + key + '=' + value;
                }else{
                    baseURL += '&' + key + '=' + value;
                }
                count ++;
            });
        }
        await inst.get(baseURL).then((response) => {
            //console.log(response);
            setResponse(getAllResponse ? response.data : response.data.data, true)
        }).catch((error) => {
            console.log(error);
            setResponse(error,false)
        });
    }else if (methodName === 'PUT'){

        // let dataPost = '';

        // if(data !== null){
        //     let count = 0;
        //     Object.entries(data).forEach(([key, value]) => {
        //         if(count === 0){
        //             dataPost += key + '=' + value;
        //         }else{
        //             dataPost += '&' + key + '=' + value;
        //         }
        //         count ++;
        //     });
        // }

        console.log('datapost...');
        console.log(data);
        inst.put(baseURL,data).then((response) => {
            console.log('put response...');
            console.log(response);
            setResponse(getAllResponse ? response.data : response.data.data, true)
        }).catch((error) => {
            console.log(error);
            setResponse(error,false)
        });
    }
    else{

        // let dataPost = '';

        // if(data !== null){
        //     let count = 0;
        //     Object.entries(data).forEach(([key, value]) => {
        //         if(count === 0){
        //             dataPost += key + '=' + value;
        //         }else{
        //             dataPost += '&' + key + '=' + value;
        //         }
        //         count ++;
        //     });
        // }

        console.log('datapost...');
        console.log(data.username);
        inst.post(baseURL,data).then((response) => {
            //console.log('post response...');
            //console.log(response);
            setResponse(getAllResponse ? response.data : response.data.data, true)
        }).catch((error) => {
            console.log(error);
            setResponse(error,false)
        });
    }

    //console.log('pathUrl: ' + pathUrl);
}

async function makeRequestKPI(contentType, data, methodName, pathUrl,getAllResponse,isWithToken, setResponse){

    const token = localStorage.getItem('token');
    const inst = axios.create();

    inst.interceptors.request.use(
    async config => {
        config.headers = {
        'Authorization': `Bearer ${ token === null ? "" : token}`,
        'Accept': contentType,
        //'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json'
        }
        return config
    },
    error => {
        Promise.reject(error)
    });

    /* inst.interceptors.response.use((response) => {
        return response;
    }, async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await refreshTokenUser(() => {});
            axios.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('token') }`;
            return inst(originalRequest);
        }
    }); */

    let baseURL = pathUrl === 'Token' ? `${API_BASE_IMG}${pathUrl}` : `${API_BASE_KPI}/${pathUrl}`;

    //console.log('baseURL:' + baseURL);

    if(methodName === 'GET'){
        if(data !== null){
            let count = 0;
            Object.entries(data).forEach(([key, value]) => {
                if(count === 0){
                    baseURL += '?' + key + '=' + value;
                }else{
                    baseURL += '&' + key + '=' + value;
                }
                count ++;
            });
        }
        await inst.get(baseURL).then((response) => {
            //console.log(response);
            setResponse(getAllResponse ? response.data : response.data.data, true)
        }).catch((error) => {
            console.log(error);
            setResponse(error,false)
        });
    }else{

        let dataPost = data;

        // if(data !== null){
        //     let count = 0;
        //     Object.entries(data).forEach(([key, value]) => {
        //         if(count === 0){
        //             dataPost += key + '=' + value;
        //         }else{
        //             dataPost += '&' + key + '=' + value;
        //         }
        //         count ++;
        //     });
        // }

        inst.post(baseURL,dataPost).then((response) => {
            console.log('devuelve response...');
            console.log(response);
            setResponse(getAllResponse ? response.data : response.data.data, true)
        }).catch((error) => {
            console.log('error response...');
            console.log(error);
            setResponse(error,false)
        });
    }

    //console.log('pathUrl: ' + pathUrl);
}

async function makeRequestSignalR(contentType, data, methodName, pathUrl,getAllResponse,isWithToken, setResponse){
    const token = localStorage.getItem('token');
    const inst = axios.create();

    inst.interceptors.request.use(
    async config => {
        config.headers = {
        'Authorization': `Bearer ${ token === null ? "" : token}`,
        'Accept': contentType,
        //'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json'
        }
        return config
    },
    error => {
        Promise.reject(error)
    });

    let baseURL = pathUrl === 'Token' ? `${API_BASE_IMG}${pathUrl}` : `${API_signalr}/${pathUrl}`;

    //console.log('baseURL:' + baseURL);

    if(methodName === 'GET'){
        if(data !== null){
            let count = 0;
            Object.entries(data).forEach(([key, value]) => {
                if(count === 0){
                    baseURL += '?' + key + '=' + value;
                }else{
                    baseURL += '&' + key + '=' + value;
                }
                count ++;
            });
        }
        await inst.get(baseURL).then((response) => {
            //console.log(response);
            setResponse(getAllResponse ? response : response, true)
        }).catch((error) => {
            console.log(error);
            setResponse(error,false)
        });
    }else{

        let dataPost = data;

        inst.post(baseURL,dataPost).then((response) => {
            //console.log('devuelve response...');
            //console.log(response);
            setResponse(getAllResponse ? response : response, true)
        }).catch((error) => {
            console.log('error response...');
            console.log(error);
            setResponse(error,false)
        });
    }

    //console.log('pathUrl: ' + pathUrl);
}
export{
    makeRequest, makeRequestAuth, makeRequestKPI, makeRequestSignalR, API_BASE, API_BASE_IMG, API_BASE_AUT, API_BASE_KPI, API_signalr
}
