import React, { useState,useEffect } from 'react';
//DB
//import {Service} from '../../service/DBService';
//Components
import { Avatar, Grid} from '@material-ui/core';
import Translator from '../mainpages/ConfigPage/HelperLanguage/Translator';
import TranslateStr from '../mainpages/ConfigPage/HelperLanguage/TranslateStr';
import ChangeLanBoxSimple from '../mainpages/ConfigPage/HelperLanguage/ChangeLanBoxSimple';
import {getCustomButton} from '../mainpages/Generics/CustomButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logo from '../../img/logo.jpeg';
//Styles
import {makeStyles} from '@material-ui/styles';
//import {MainColors} from '../mainpages/Sources/SourcesConst';
//Apis
import {getDataEmpresa} from '../../service/ManagerApis';
import {getIntStorage, getBoolStorage} from '../../service/HelperStorage';
import {idForChangeEmpresa_, color_accent_, color_main_, idLan_, lan_, isBilingual_} from '../Constants';

const useStyles = makeStyles(() => ({
    root: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#fff',
    },
    img:{
        margin: '0.5rem 0 0 0.5rem',
        width: '6vh',
        height: '6vh',
    },
    back: {
        width: '100%',
        height: '100%',
        padding: '0',
        //color: MainColors.accent,
        backgroundColor: '#fff',
    },
    imgOntable: {
        margin: '1rem',
        width: '20vh',
        height: '8vh',
        borderRadius: '0'
    },
    logo: {
        //width: '25vw',
        width: '26vh',
        //height: '25vw',
        height: 'auto',
        borderRadius: '0'
    },
    titleWelcome: {
        fontSize:'6vh',
        fontWeight:'lighter',
        //color: MainColors.accent,
        textAlign: 'center',
        margin: '0',
        padding: '0'
    },
    titleEmpresa: {
        fontSize:'6vh',
        fontWeight:'lighter',
        //color: MainColors.accent,
        textAlign: 'center',
        margin: '0',
        padding: '0'
    },
    error: {
        fontSize:'3vh',
        fontWeight:'lighter',
        //color: MainColors.accent,
        textAlign: 'Center',
        margin: '0',
        padding: '0'
    }
}));

const ValidateButton = getCustomButton(localStorage.getItem(color_main_),'2.5vh','bold','#fff','#fff');

export default function LandingPage(props){
    const sty = useStyles();
    const [empresa,setEmpresa] = useState(null);
    const [error,setError] = useState(false);
    const showBackBtn = props.isBtnBack !== undefined && props.isBtnBack === true;
    //const [bilingual,setBilingual] = useState(getBoolStorage(isBilingual_));
    const isBilingual = getBoolStorage(isBilingual_); //props.bilingual; 
    useEffect(() => {
        var idEmpresa = null;
        if(showBackBtn){
            //Service.delDB();// borra base de datos local indexdb
            idEmpresa = getIntStorage(idForChangeEmpresa_);
        }else{
            idEmpresa = props.idEmpresa;
        }
        if(idEmpresa !== null){
            getDataEmpresa(idEmpresa,(info,numCarrito)=>{
                if(info){
                    setEmpresa(info);
                    console.log('Empresa info');
                    console.log(info);
                    // console.log('idiomasWPA:' + empresa.configuracion.idiomasWPA);
                    // if(empresa.configuracion.idiomasWPA){
                    //     localStorage.setItem(idLan_,1);
                    //     localStorage.setItem(lan_,"ES");
                    // }
                    props.setconfigCarrito(numCarrito);
                    // MainColors = { main: info.configuracion.colorMain, secondary: info.configuracion.colorSecundary, accent: info.configuracion.colorAccent }
                    // localStorage.setItem('color_main',info.configuracion.colorMain);
                    // localStorage.setItem('color_secondary',info.configuracion.colorSecundary);
                    // localStorage.setItem('color_accent',info.configuracion.colorAccent);
                }else{
                    setError(true);
                }
            });
        }
    }, []);



    return(
        <Grid className={sty.root} container direction="column" alignItems="center" justifyContent="center">
            {
                showBackBtn &&
                    <Grid item container direction="row" alignItems="center" justifyContent="flex-start">
                        <Avatar onClick={() => props.backIfBtn()} className={sty.img} alt="Add icon">
                            <ArrowBackIcon className={sty.back} style={{color: localStorage.getItem(color_accent_)}}/>
                        </Avatar>
                    </Grid>
            }
            <Grid item container direction="column" alignItems="center" justify="center" xs={showBackBtn ? 10 : 11}>
                {
                    empresa ? 
                        <Grid item container direction="column" alignItems="center" justify="center">
                            <Grid item>
                                <Avatar className={sty.logo}  alt="Menu Photo" src={empresa.imagenUri} />
                            </Grid>
                            <Grid item>
                                <p className={sty.titleWelcome} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="welcomeTo"/></p>
                            </Grid>
                            <Grid item>
                                <p className={sty.titleEmpresa} style={{color: localStorage.getItem(color_accent_)}}>{empresa.nombreComercial}</p>
                            </Grid>
                            <Grid item>
                            {isBilingual && 
                                <ChangeLanBoxSimple/>}
                            </Grid>
                            <ValidateButton style={{color: localStorage.getItem(color_main_)}} onClick={() => props.back()} variant="contained">
                                <TranslateStr token="goMenus"/>
                            </ValidateButton>
                            <Grid item>
                                <Avatar className={sty.imgOntable} alt="Add icon" src={Logo}/>
                            </Grid>
                        </Grid>
                    :
                        !error ?
                            <Grid item container direction="column" alignItems="center" justify="center">
                                <Grid item>
                                    <p className={sty.error} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="loading"/></p>
                                </Grid>
                            </Grid>
                        :
                            <Grid item container direction="column" alignItems="center" justify="center">
                                <Grid item>
                                    <p className={sty.error} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="errorSetRest"/></p>
                                </Grid>
                            </Grid>
                }
            </Grid>
            {/* <Grid item container direction="row-reverse" alignItems="center" justifyContent="flex-end">
                
            </Grid> */}
        </Grid>
    );
}