import React from 'react';
//import React,{useState, useEffect} from 'react';
//Components
import ItemOrder from './ItemOrder';
import {List} from '@material-ui/core';

export default function SelectAddress(props){

    const {orders,dataUser,selectChild,arraySelected} = props;

    return(
        <List>
            {
                orders.map((order,index) => (
                    !order.esTopping && <ItemOrder
                        isSelected={arraySelected[index]}
                        selectChild={selectChild}
                        dataOrder={order}
                        dataUser={dataUser}
                    />
                ))
            }
        </List>
    )
}