import React, { useState } from 'react';
//Components
import Registro from '../Registro/Registro';
import Logingoogle from './Logingoogle';

import {Button,TextField,Grid,FormControl,InputAdornment,Input,IconButton,InputLabel,Avatar} from '@material-ui/core';
import {AccountCircle,Visibility,VisibilityOff,ArrowBack, Info} from '@material-ui/icons';
//import GoogleIcon from '@material-ui/icons/GTranslate';
//import { FcGoogle } from 'react-icons/fc';
import {makeNormalDialog} from '../../utilscomponents/DialogAsk';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
import Translator from '../ConfigPage/HelperLanguage/Translator';
import {getInfoCarrito} from '../../../service/ManagerCarrito';
//import GoogleLogin from 'react-google-login';
import { GoogleOAuthProvider } from '@react-oauth/google';
//import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import FacebookLogin from 'react-facebook-login';

  
//Apis
import {signIn,registroSocialNetwork, getInfoUser} from '../../../service/ManagerApis';
//Styles
import {makeStyles,withStyles} from '@material-ui/core/styles';
//import {MainColors} from '../Sources/SourcesConst';
//Utils
import {logOut} from '../../../service/HelperStorage';
//import ConfirmOrder from '../Delivery/Confirm/ConfirmOrder';
import {color_accent_, color_main_, color_secondary_, nameUser_, emailUser_} from '../../Constants';


const useStyles = makeStyles(({
    root: {
        height: '100%',
        width: '100%',
        margin: '0',
        textAlign: 'center',
    },
    imgUser: {
        width: '4vh',
        height: '4vh',
        //color:  MainColors.main,
    },
    title: {
        padding: '0',
        margin: '0',
        fontSize: '3vh',
        //color: MainColors.main,
    },
    subTitle: {
        padding: '0',
        margin: '0',
        fontSize: '2vh',
        //color: MainColors.secondary,
    },
    textFieldUser: {
        width: '67vw',
    },
    textFieldPass: {
        width: '70vw',
    },
    back: {
        width: '100%',
        height: '100%',
        padding: 'auto',
        //color: MainColors.accent,
        backgroundColor: '#fff',
    },
    img:{
        margin: '0',
        width: '6vh',
        height: '6vh',
    },
    txtBtnSignUp: {
        padding: '0',
        margin: '0',
        fontSize: '2vh',
        fontWeight: '300',
        textAlign: 'center',
        //color: MainColors.accent,
    },
    facebookbutton: {
      width: '100%',
      //minwith: '200px',
      height: '30px',
      borderradius: '4px',
      background: '#3b5998',
      color: 'White',
      border: '0',
      bordercolor: '#3b5998',
      textAlign: 'center',
      verticalAlign: 'middle',
      //margin: '5px',
      padding: '0px 10px 10px 10px',
      fontfamily: 'Helvetica',
      fontsize: '12px',
    },
    createImgAvatar:{
        width: '100%',
        height: '100%',
        padding: '1vh',
        //color: MainColors.secondary,
        backgroundColor: '#fff',
    },
    createImg:{
        margin: '1vh',
        width: '5vh',
        height: '5vh',
    },
    labelOption:{
        textAlign: 'start',
        //color: MainColors.accent,
        fontSize: '2.5vh',
        margin: '0',
        padding: '0',
    },
}));

const ColorButton = withStyles((theme) => ({
    root: {
      color: localStorage.getItem(color_main_), //MainColors.main,
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
}))(Button);

export default function Login(props){

    // const [login, setLogin] = useState(false);
    // const [data, setData] = useState({});
    // const [picture, setPicture] = useState('');

    const [page,setPage] = useState(0);
    const sty = useStyles();
    const [values, setValues] = useState({
        password: '',
        showPassword: false,
    });
    const [userName,setUserName] = useState('');

    const responseSignUp = () => {
        
        setPage(0);
    }

    // const login = useGoogleLogin({
    //     onSuccess: async respose => {
    //         try {
    //             const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
    //                 headers: {
    //                     "Authorization": `Bearer ${respose.access_token}`
    //                 }
    //             })

    //             console.log(res.data)
    //         } catch (err) {
    //             console.log(err)

    //         }

    //     }
    // });
    //const authprovider = GoogleOAuthProvider('801840766851-toji7ug1o45anvt9tb6tds9v6odcqvvi.apps.googleusercontent.com');

    //const login = useGoogleLogin(authprovider,{
    //    onSuccess: codeResponse => console.log(codeResponse),
    //    flow: 'auth-code',
    //  });
    const responseGoogle_example = (response) => {
        //console.log(response);
         const userObject = jwt_decode(response.credential);
         //console.log(userObject);
         localStorage.setItem('user', JSON.stringify(userObject));
         const { name, sub, picture } = userObject;
         const doc = {
           _id: sub,
           _type: 'user',
           userName: name,
           image: picture,
         };
        //  client.createIfNotExists(doc).then(() => {
        //    navigate('/', { replace: true });
        //  });
     
    }

    const handleClickSignIn =  () => {
        if(values.password === '' || userName === ''){
            makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr token="requiredFields"/>,null);
        }else{
            signIn(userName,values.password,async (success) => {
                if(success){
                    await getInfoUser(dataUser => {
                        //console.log('dataUser');
                        //console.log(dataUser);
                        if(dataUser){
                            //console.log('dataUser');
                            //console.log(dataUser[0]);
                            localStorage.setItem(emailUser_,dataUser[0].email);
                            localStorage.setItem(nameUser_,dataUser[0].nombre + ' ' + dataUser[0].apellidoPat);
                            props.setemailuser(dataUser[0].email);
                            props.setfullUserName(dataUser[0].nombre + ' ' + dataUser[0].apellidoPat);
                            getInfoCarrito((totalR,countR,toppingsR) => {
                                //localStorage.setItem(countCarrito_,countR);
                                //setInfoOrder({total:totalR,count: countR,readyLogin:true});
                                
                                props.setconfigCarrito(countR);
                             
                            });
                            //props.setconfigCarrito(0);
                            //props.setConfigUser({ isLogin: props.isLogin, page: props.page, nombre: dataUser[0].nombre + ' ' + dataUser[0].apellidoPat});
                        }
                    });
                    props.changePage(true);
                }else{
                    makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr token="passUserWrong"/>,null);
                }
            });
        }
    }

    const handleChangeUser = (e) => {
        e.persist();
        setUserName(e.target.value);
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const responseFacebook = (response) => {
        //console.log('responseFacebook');
        //console.log(response.status);
        if(response.accessToken){
        //if(response !== null){
            if(response.length !==0 && response.status != 'unknown'){
                //console.log('sigue registroSocialNetwork');
                registroSocialNetwork(response.name,response.email,response.email,response.picture.data.url,response.accessToken,(okSignIn => {
                    //console.log('okSignIn');
                    console.log(okSignIn);
                    if(okSignIn){
                        signIn(response.email,response.accessToken,(success) => {
                            if(success){
                                props.changePage(true);
                            }else{
                                makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr token="passUserWrong"/>,null);
                            }
                        })
                    }
                }))
            }
        }
    }

    const responseGoogle = (response) => {
        console.log('response google...');
        console.log(response);
        // if(response !== null){
        //     if(response.length !== 0){
        //         registroSocialNetwork(response.profileObj.givenName,response.profileObj.email,response.profileObj.email,response.profileObj.imageUrl,response.accessToken,(okSignIn => {
        //             if(okSignIn){
        //                 signIn(response.profileObj.email,response.accessToken,(success) => {
        //                     if(success){
        //                         props.changePage(true);
        //                     }else{
        //                         makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr token="passUserWrong"/>,null);
        //                     }
        //                 })
        //             }
        //         }))
        //     }
        // }
    }
    //801840766851-toji7ug1o45anvt9tb6tds9v6odcqvvi.apps.googleusercontent.com
    //8140627796-paf871ov6o8ku7c5cli9aos8mkvqljph.apps.googleusercontent.com
    return(
        (function(){
            switch (page) {
                case 0:
                    return<div className={sty.root}> 
                    {/* <Grid className={sty.root} container spacing={1} direction="column" justify="center" alignItems="center"> */}
                    <Grid item container alignItems="flex-start">
                        <Grid item>
                            <Avatar onClick={() => props.changePage(false)} className={sty.back} style={{color: localStorage.getItem(color_accent_)}} alt="Add icon">
                                <ArrowBack className={sty.img}/>
                            </Avatar>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <p className={sty.title} style={{color: localStorage.getItem(color_main_)}}><TranslateStr token="welcomeApp"/></p>
                    </Grid>
                    {/* <Grid item>
                        <FacebookLogin
                            textButton={<TranslateStr token="buttonTextFB"/>}
                            cssClass={sty.facebookbutton}
                            appId="3110591309229987"
                            autoLoad={false}
                            fields="name,email,picture"
                            disableMobileRedirect={true}
                            onClick={logOut}
                            callback={responseFacebook}
                        />
                    </Grid> */}
                    <Grid item>
                        <GoogleOAuthProvider 
                           clientId="8140627796-paf871ov6o8ku7c5cli9aos8mkvqljph.apps.googleusercontent.com"
                            
                        >
                            <Logingoogle changePage={props.changePage} setemailuser={props.setemailuser} setfullUserName={props.setfullUserName} setconfigCarrito={props.setconfigCarrito}>

                            </Logingoogle>
                        </GoogleOAuthProvider>
                    </Grid>
                    {/* <Grid item>
                        <GoogleLogin
                            style={{  
                                width: 'auto',
                                height: '20px',
                                padding: '0 0 0 0'
                            }}
                            clientId="8140627796-paf871ov6o8ku7c5cli9aos8mkvqljph.apps.googleusercontent.com"
                            buttonText={<TranslateStr token="buttonTextGoogle"/>}
                            onClick={logOut}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />        
                    </Grid>  */}
                    <Grid item>
                        <p className={sty.subTitle} style={{color: localStorage.getItem(color_secondary_)}}></p>
                    </Grid>
                    <Grid item>
                        <p className={sty.subTitle} style={{color: localStorage.getItem(color_secondary_)}}><TranslateStr token="orLogin"/></p>
                    </Grid>
                    <Grid item>
                        <p className={sty.subTitle} style={{color: localStorage.getItem(color_secondary_)}}><TranslateStr token="typePassUser"/></p>
                    </Grid>
                    <Grid item container spacing={1} justify="center" alignItems="center">
                        <Grid item >
                            <AccountCircle className={sty.imgUser} style={{color: localStorage.getItem(color_main_)}}/>
                        </Grid>
                        <Grid item>
                            <TextField value={userName} onChange={handleChangeUser} className={sty.textFieldUser} id="input-with-icon-grid" label={<TranslateStr token="emailUsername"/>} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel htmlFor="standard-adornment-password"><TranslateStr token="password"/></InputLabel>
                            <Input
                                className={sty.textFieldPass}
                                id="standard-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <ColorButton onClick={() => handleClickSignIn()} variant="contained">
                                <TranslateStr token="login"/>
                        </ColorButton>
                    </Grid>
                    <Grid item>
                        <p className={sty.subTitle} style={{color: localStorage.getItem(color_secondary_)}}><TranslateStr token="noAccount"/></p>
                        <p className={sty.txtBtnSignUp} style={{color: localStorage.getItem(color_accent_)}} onClick={() => setPage(1)}><TranslateStr token="signUpHere"/></p>
                    </Grid>
                    <br/>
                    <Grid    
                        item
                        container
                        justify="center" 
                        alignItems="center"
                    >
                        {/* <Grid item>
                            <Avatar className= {sty.createImgAvatar} style={{color: localStorage.getItem(color_secondary_)}} alt="Add icon">
                                <Info className={sty.createImg}/>
                            </Avatar>
                        </Grid> */}
                        <Grid item>
                            <a href="https://ontable.app/aviso-privacidad.html" target="_blank" rel="noopener noreferrer">
                                <p className={sty.labelOption} style={{color: localStorage.getItem(color_accent_)}}><Translator token="avisoPrivacidad"/></p>
                            </a>
                        </Grid>
                    </Grid>
                {/* </Grid> */}
                </div>
                case 1:
                    return <Registro changePage={responseSignUp}/>
                default:
                    break;
            }
        })()
    )
}
