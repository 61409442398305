//Dialog
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import TranslateStr from '../mainpages/ConfigPage/HelperLanguage/TranslateStr';

const makeNormalDialog = (title,msg,action) => {
    confirmAlert({
        title: title,
        message: msg,
        onClickOutside: action,
        buttons: [
           {
              label: <TranslateStr token="accept"/>,
              onClick: action
           }
        ]
    });
}

const makeDialogOrderEnd = (title,msg,msgOrder,Orden,action) => {
    confirmAlert({
        title: title,
        message: <div>
                    <p>{msg}.</p> 
                    <p>{msgOrder}{' '}{Orden}</p>
                </div>,
        onClickOutside: action,
        buttons: [
           {
              label: <TranslateStr token="accept"/>,
              onClick: action
           }
        ]
    });
}

const makeAskDialog = (title,msg,actionOk) => {
    confirmAlert({
        title: title,
        message: msg,
        buttons: [
           {
              label: <TranslateStr token="confirm"/>,
              onClick: () => actionOk()
           },
           {
            label: <TranslateStr token="cancel"/>,
            }
        ]
    });
}

export {
    makeNormalDialog,makeAskDialog,makeDialogOrderEnd
}