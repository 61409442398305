import React,{useState,useEffect} from 'react';
//Components
import {Grid,Fab} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import ListOrders from './ListOrders';
import DetailOrder from './DetailOrder/DetailOrder';
//Styles
import {MainColors} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
//Apis
import {getOrdersAsignadas,getOrdersEntransito} from '../../../service/ManagerApis';
import { idUser_ } from '../../Constants';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        position: 'relative',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    title:{
        color: MainColors.main,
        padding: '1vh 1vh 0 2.5vw',
        margin: '0',
        fontSize: '4vh',
        fontWeight: '250',
        textAlign: 'center'
    },
}));

export default function SelectAddress(){

    const sty = useStyles();
    const [arrayOrders,setArrayOrders] = useState([]);
    const [page,setPage] = useState({index:0,dataSelectedOrder:null});

    useEffect(() => {
        getInfoRepartidor();
    },[]);

    const onChangePage = (index,data) => {
        setPage({index:index,dataSelectedOrder:data});
    }

    const reload = () => {
        setPage({index:0,dataSelectedOrder:null});
        getInfoRepartidor();
    }

    const getInfoRepartidor = () => {
        getOrdersAsignadas(array => {
            var arrayAsignadas = [];
            array.forEach(element => {
                if(parseInt(element.idRepartidor) === parseInt(localStorage.getItem(idUser_))){
                    arrayAsignadas.push(element);
                }
            });
            getEnTransito(arrayAsignadas);
        })
    }

    const getEnTransito = (arrayAsignadas) => {
        getOrdersEntransito(array => {
            array.forEach(element => {
                if(parseInt(element.idRepartidor) === parseInt(localStorage.getItem(idUser_))){
                    arrayAsignadas.push(element);
                }
            });
            setArrayOrders(arrayAsignadas);
        })
    }

    return(
        <div className={sty.root}>
            {
                (function(){
                    switch (page.index) {
                        case 0:
                            return <div>
                                <Grid container justify="center" alignItems="center">
                                    <p className={sty.title}>Ordenes asignadas para entrega</p> 
                                    <ListOrders changePage={onChangePage} orders={arrayOrders}/>
                                </Grid>
                                <Fab onClick={() => getInfoRepartidor()} className={sty.fab}>
                                    <CachedIcon />
                                </Fab>
                            </div>
                        case 1:
                            return <DetailOrder reload={reload} dataOrder={page.dataSelectedOrder} changePage={onChangePage}/>
                        default:
                            break;
                    }
                })()
            }
            
        </div>
    )
}