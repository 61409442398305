import React,{ useEffect, useState }from 'react';
//Components
import AddComent from './Comments/AddComment';
import {
    Collapse,
    Chip,Typography,
    Grid,
    Paper,
    Avatar,
    IconButton,
    Snackbar,
    Divider
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

//DB
import {formatoMexico,decimalAdjust,saveItemCarrito,deleteItemCarrito,deleteComment, 
    getInfoCarrito, getInfoToppings} from '../../../service/ManagerCarrito';
//import {Service} from '../../../service/DBService';
//API
import {makeRequest} from '../../../service/ManagerRequest';
//Styles
import {MainColors} from '../Sources/SourcesConst';
import {makeStyles} from '@material-ui/core/styles';
//import {makeStyles, withStyles} from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import AddIcon from '@material-ui/icons/Add';
//import Button from '@material-ui/core/Button';
//Modal
import {useModal} from '../Generics/useModal';
import {useModalTopping} from '../Generics/useModalTopping';
import AddTopping from './Toppings/AddTopping';
//Swipe
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import ItemSwipeRight from '../Submenus/ItemSwipeRight';
import ItemSwipeLeft from '../Submenus/ItemSwipeLeft';

import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
import {idLan_, color_main_, color_secondary_} from '../../Constants';

// const AddBtn = withStyles(() => ({
//     root: {
//       color: '#5499C7',
//       backgroundColor: '#FBFCFC',
//       '&:hover': {
//         backgroundColor: '#FBFCFC',
//       },
//     },
// }))(Button);

export default function ItemCarrito(props){
    const Idioma = localStorage.getItem(idLan_);
    const dataDish = props.data;
    const sty = useStyles();
    const { hide,show,RenderModal } = useModal();
    const { hideTopping,showTopping,RenderModalTopping } = useModalTopping();
    const [expanded, setExpanded] = useState(false);
    const [expandedTopping, setExpandedTopping] = useState(false);
    const [openRemoveSwipe, setOpenRemoveSwipe] = useState(false);
    const [openAddSwipe, setOpenAddSwipe] = useState(false);
    const [dataComment,setDataComment] = useState({index:0,dataDish: []});
    const [arrayTopping,setToppings] = useState([]);
    const [IDTopping,setidToppings] = useState(0);
    //const [origen,setOrigen] = useState(1);
    const [ItemtotalToppings, setItemTotaltoppings] = useState(0);
    //topping handlers

    const getToppings = () => {
        //console.log('dataDish.id: ' + dataDish.id);
        //console.log('IDTopping: ' + IDTopping);
        getInfoToppings(dataDish.id,(totalR,countR) => {
            //const sub = (dataDish.cantidad*dataDish.price) + totalR;
            setItemTotaltoppings(totalR);
        });
        return decimalAdjust('round',parseFloat(ItemtotalToppings),-2);
    }

    const handleResTopping = (data,success,_id) =>{
        
        console.log('success: ' + success);
        if(success){
            setToppings(data);
            // //RenderModal(true);
            
        }
    }

    const viewToppings = () => {
        if(dataDish.tieneTopping){
            //setOrigen(2);
            console.log('Tiene topping... ');
            console.log (dataDish.id);
            //etidToppings(id_);
            setidToppings(dataDish.id);
            handleTopping();
        }
    }

    const handleTopping = () => {
        makeRequest('application/json',null,'GET','masterdata/Productos/MisProductos/' + dataDish.idProducto + '/Topping?idIdioma=' + Idioma + '&EsActivo=1',false,false,(dataRes,success) => handleResTopping(dataRes,success))
   
        setExpandedTopping(showTopping);
        return expandedTopping;
    }
    
    //swipe handlers
    const handleCloseSwipeAdd = (event,reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAddSwipe(false);
    }
    const handleCloseSwipeRemove = (event,reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenRemoveSwipe(false);
    }

    //Collapse
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    //chip handlers
    const handleDeleteChip = (index) => {
        deleteComment(index,dataDish.id,() => {
            props.reload();
        });
    };

    const handleClickChip = (index) => {
        show();
        setDataComment({index:index,dataDish:dataDish});
    };

    const saveCommentChip = () => {
        props.reload();
    }

    //handlers db
    const saveDish = () => {
        //console.log('saveDish...');
        //console.log(dataDish);
        //setOrigen(1);
        const tieneTopping = dataDish.tieneTopping;

        saveItemCarrito(dataDish.idProducto,dataDish,true,(result) => {
            setOpenAddSwipe(true);
            getInfoCarrito((totalR,countR,totalT) => {
                props.setconfigCarrito(countR);
                //setItemTotaltoppings(totalT);
            });

            if(tieneTopping)
            {
                //setItemTotaltoppings(0);
                //console.log('Tiene topping: ' + tieneTopping);
                setidToppings(result);
                handleTopping();
                props.reload();
            }
            else{
                props.reload();
            }

            //props.reload();
            //setItemTotaltoppings(0);
        });
        
    }

    const deleteDish = () => {
        deleteItemCarrito(dataDish.id,(result) => {
            setOpenRemoveSwipe(true);
            getInfoCarrito((totalR,countR) => {
                props.setconfigCarrito(countR);
            });
            props.reload();
        });
    }

    useEffect(() => {
        props.reload();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <SwipeableListItem
            className={sty.container}
            swipeLeft={{
            content: <ItemSwipeLeft/>,
            action: () => deleteDish()
            }}
            swipeRight={{
            content: <ItemSwipeRight/>,
            action: () => saveDish()
            }}
        >
            <div className={sty.root}>
                <Paper className={sty.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Avatar className={sty.image} alt="Menu Photo" src={dataDish.url} onClick={viewToppings}/>
                        </Grid>
                        <Grid item xs={9} container direction="column">
                            <Grid item xs container direction="row" onClick={viewToppings}>
                                <Grid item xs={9}>
                                    <Typography className={sty.title}>
                                        {Idioma==1 ? dataDish.nameESP : dataDish.nameENG}
                                        {/* {dataDish.idTipoMenu} */}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography hidden={!dataDish.tieneTopping} className={sty.subTitleTopping}>
                                        {'+ Toppings'}
                                    </Typography>
                                    {/* <Typography className={sty.price}  >{'$' + formatoMexico(dataDish.price)}</Typography> */}
                                </Grid>
                            </Grid>
                            <Grid item xs container direction="row" justify="space-between" alignItems="center">
                                <Grid item>
                                    <p className={sty.cantidad} style={{color: localStorage.getItem(color_secondary_)}}>{dataDish.cantidad}</p>
                                </Grid>
                                <Grid item>
                                    <p className={sty.total} style={{color: localStorage.getItem(color_main_)}}>{"$" + formatoMexico(decimalAdjust('round',parseFloat((dataDish.cantidad*dataDish.price) + getToppings()),-2))}</p>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={handleExpandClick} aria-label="delete" size="small">
                                        <CommentIcon className={sty.iconComment} style={{color: localStorage.getItem(color_secondary_)}}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="column">
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <Divider className={sty.divider} variant="middle" />
                                <Grid item xs container direction="row" justify="space-between" alignItems="center" >
                                    <Grid item xs={6}>
                                        <p className={sty.indicaciones} style={{color: localStorage.getItem(color_secondary_)}}><TranslateStr token="indications"/></p>
                                    </Grid>
                                    <Grid item sx={2}>
                                        {
                                            dataDish.comments.length < dataDish.cantidad &&
                                            <IconButton onClick={() => handleClickChip(-1)} size="small">
                                                <AddIcon />
                                            </IconButton>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <div className={sty.chipContainer}>
                                        {
                                            dataDish.comments.map((comment,index) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        className={sty.chip}
                                                        style={{color: localStorage.getItem(color_secondary_)}}
                                                        variant="outlined"
                                                        size="small"
                                                        label={comment}
                                                        onClick={() => handleClickChip(index)}
                                                        onDelete={() => handleDeleteChip(index)}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Paper>

                <RenderModalTopping>
                    <AddTopping 
                    dataSub={dataDish} 
                    idTipoMenu={1}
                    arrayTopping={arrayTopping}
                    ID = {IDTopping}
                    total = {parseFloat(dataDish.cantidad*dataDish.price)}
                    ItemtotalToppings ={ parseFloat(ItemtotalToppings)}
                    getToppings={getToppings}
                    setTotaltoppings = {props.setTotaltoppings}
                    reload={props.reload}
                   
                    />
                </RenderModalTopping>

                <RenderModal>
                    <AddComent hide={hide} save={saveCommentChip} index={dataComment.index} dataDish={dataComment.dataDish}/>
                </RenderModal>

                <Snackbar open={openAddSwipe} autoHideDuration={1500} onClose={handleCloseSwipeAdd}>
                        <Alert onClose={handleCloseSwipeAdd} severity="success">
                            <TranslateStr token="addorder"/>
                        </Alert>
                </Snackbar>
                <Snackbar open={openRemoveSwipe} autoHideDuration={1500} onClose={handleCloseSwipeRemove}>
                        <Alert onClose={handleCloseSwipeRemove} severity="warning">
                            <TranslateStr token="removeorder"/>
                        </Alert>
                </Snackbar>
            </div>
        </SwipeableListItem>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100% !important',
    },
    root: {
         flexGrow: 1,
         margin: '0.4vh 0.4vh 0 0.4vh'
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
      border: '0.2vh solid #EBEDEF',
    },
    image: {
      width: '8vh',
      height: '8vh',
      margin: 'auto',
    },
    iconComment: {
        //color: MainColors.secondary,
        backgroundColor: '#fff',
    },
    iconBorder: {
        border: '0.2vh solid #EBEDEF',
    },
    toppingBtn: {
        //backgroundColor: MainColors.main,
        //color: '#fff',
        position: 'absolute',
        top: theme.spacing(1),
        //left: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: '16',
        fontSize: '7pt',
        //fontWeight: 'bold',
        //color: '#CB4335',
        //opacity: '100%',
        //background:'transparent',
        //width:'137px',
        //height: 'auto',
        textAlign:'center',
        outline: 'none',
        //bordercolor: '#fff',
    },
    chip: {
        color: MainColors.secondary,
    },
    cantidad: {
        //color: MainColors.secondary,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    total: {
        //color: MainColors.main,
        fontSize: '3vh',
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: '0',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2vh',
        marginBottom: '0',
    },
    subTitleTopping:{
        fontSize: '1.3vh',
        color: '#5499C7',
        textAlign: 'left',
        margin: '0',
    },
    price: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '2.5vh',
        marginBottom: '0',
        textAlign: 'right',
    },
    divider: {
        margin: '1vh',
    },
    chipContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
        margin: theme.spacing(0.5),
        },
    },
    indicaciones: {
        //color: MainColors.secondary,
        fontSize: '2.5vh',
        fontWeight: 'light',
        textAlign: 'left',
        marginBottom: '0',
    },
}));