import React,{useState, useEffect} from 'react';
//Components
import {Grid,Avatar,Button} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ListDetailOrders from './ListDetailOrders';
import AddCancelReason from './AddCancelReason';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
//Apis
import {detailDeliveryOrder,aceptarEntrega,cancelarEntrega, setOrdenEntregada} from '../../../../service/ManagerApis';
//Styles
import {MainColors} from '../../Sources/SourcesConst';
import { makeStyles,withStyles } from '@material-ui/core/styles';
//Modal
import {useModal} from '../../Generics/useModal';
//Others
import {makeNormalDialog,makeAskDialog} from '../../../utilscomponents/DialogAsk';

const useStyles = makeStyles(({
    back: {
        width: '100%',
        height: '100%',
        padding: '1vh',
        color: MainColors.accent,
        backgroundColor: '#fff',
    },
    img:{
        margin: '0',
        width: '6vh',
        height: '6vh',
    },
    imgMsg: {
        width: '100%',
        height: '100%',
        padding: '1vh',
        color: '#FFCA28',
        backgroundColor: '#fff',
    },
    imgSmall:{
        margin: '0',
        width: '3vh',
        height: '3vh',
    },
    subInfo:{
        color: MainColors.secondary,
        fontSize: '2vh',
        fontWeight: 300,
        margin: '0',
        textAlign: 'center',
        padding: '0.5vh',
    },
    subInfoDes:{
        color: MainColors.accent,
        fontSize: '2.5vh',
        fontWeight: 300,
        margin: '0',
        textAlign: 'center',
        padding: '0.5vh',
    },
    title:{
        color: MainColors.main,
        padding: '1vh 1vh 0 2.5vw',
        margin: '0',
        fontSize: '4vh',
        fontWeight: '250',
        textAlign: 'center'
    },
    msgAccepted:{
        color: '#FFCA28',
        padding: '0',
        margin: '0.5vh',
        fontSize: '2.8vh',
        fontWeight: '250',
        textAlign: 'center'
    },
    contBtns:{
        margin: '1.5vh 0 0 0',
    }
}));

const BtnAccept = withStyles(() => ({
    root: {
      color: '#F5F5F5',
      fontSize: '2vh',
      backgroundColor: '#48C9B0',
      '&:hover': {
        backgroundColor: '#48C9B0',
      },
    },
}))(Button);

const BtnCancel = withStyles((theme) => ({
    root: {
        color: '#F5F5F5',
        fontSize: '2vh',
        backgroundColor: '#E91E63',
        '&:hover': {
            backgroundColor: '#E91E63',
        },
    },
}))(Button);

const BtnDelivery = withStyles(() => ({
    root: {
        color: '#F5F5F5',
        fontSize: '2vh',
        backgroundColor: '#8BC34A',
        '&:hover': {
            backgroundColor: '#8BC34A',
        },
    },
}))(Button);

export default function DetailOrder(props){

    const [dataOrder,setDataOrder] = useState(null);
    const isAccepted = dataOrder !== null ? parseInt(dataOrder.idEstatusEntrega) === 5 : false
    const idOrden = props.dataOrder.idOrden;
    const sty = useStyles();
    const { hide,show,RenderModal } = useModal();
    //const idEntrega = props.dataOrder.idEntrega;

    const getDataDetail = () => {
        detailDeliveryOrder(idOrden,data => {
            if(data){
                console.log(data);
                setDataOrder(data);
            }
        })
    }

    useEffect(() => {
        getDataDetail();
    },[]);

    const processEntregada = () => {
        console.log(idOrden);
        setOrdenEntregada(idOrden, (status) => {
            if(status){
                makeNormalDialog(
                    '¡Orden entregada con éxito!',
                    'Gracias por su servicio',
                    props.reload
                    );
            }else{
                makeNormalDialog(
                    'Ocurrió un error',
                    'No fue posible marcar como entrega la orden, intente de nuevo',
                    getDataDetail
                    );
            }
        });
    }

    const confirmEntregada = () => {
        makeAskDialog(
            '¿Está seguro de dar por entregada la orden?',
            '',
            processEntregada
            );
    }

    const processAccept = () => {
        aceptarEntrega(idOrden,status => {
            if(status){
                makeNormalDialog(
                    'La orden ha sido aceptada exitosamente',
                    'Ahora estas a cargo',
                    getDataDetail
                    );
            }else{
                makeNormalDialog(
                    'Ocurrió un error',
                    'No fue posible aceptar la orden, intente de nuevo',
                    getDataDetail
                    );
            }
        })
    }

    const confirmAccept = () => {
        makeAskDialog(
            '¿Está seguro de aceptar esta orden?',
            'Estará a cargo de la entrega en caso de confirmar',
            processAccept
            );
    }

    const processCancel = (motivo) => {
        cancelarEntrega(idOrden,motivo,status => {
            if(status){
                makeNormalDialog(
                    'La entrega ha sido cancelada correctamente',
                    '',
                    props.reload
                    );
            }else{
                makeNormalDialog(
                    'Cancelación fallida',
                    'Intente de nuevo',
                    getDataDetail
                    );
            }
        });
    }

    const confirmCancel = () => {
        makeAskDialog(
            '¿Está seguro de cancelar la entrega?',
            'Esta acción es irreversible',
            show
        );
    }

    return(
        <Grid container justify="center" alignItems="center">
            <Grid item container alignItems="flex-start">
                <Grid item>
                    <Avatar onClick={() => props.changePage(0)} className= {sty.back} alt="Add icon">
                        <ArrowBackIcon className={sty.img}/>
                    </Avatar>
                </Grid>
                <Grid item>
                    <p className={sty.title}>Detalle de orden</p>
                </Grid>
            </Grid>
            {
                isAccepted && 
                <Grid item container direction="row" justify="center" alignItems="center">
                    <Grid item>
                        <Avatar className= {sty.imgMsg} alt="Add icon">
                            <MotorcycleIcon className={sty.imgSmall}/>
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <p className={sty.msgAccepted}>Estas a cargo de ésta entrega</p>
                    </Grid>
                </Grid>
            }
            {
                dataOrder !== null &&
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={6} container direction="column" justify="center" alignItems="center">
                            <p className={sty.subInfo} >Cliente:</p>
                            <p className={sty.subInfoDes}>{dataOrder.nombre}</p>
                        </Grid>
                        <Grid item xs={6} container direction="column" justify="center" alignItems="center">
                            <p className={sty.subInfo}>Teléfono:</p>
                            <p className={sty.subInfoDes}>{dataOrder.celular}</p>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={6} container direction="column" justify="center" alignItems="center">
                            <p className={sty.subInfo} >Dirección:</p>
                            <p className={sty.subInfoDes}>{dataOrder.domicilio + '/' + dataOrder.notaDomicilio}</p>
                        </Grid>
                        <Grid item xs={6} container direction="column" justify="center" alignItems="center">
                            <p className={sty.subInfo}>Indicaciones:</p>
                            <p className={sty.subInfoDes}>{dataOrder.opcionEntrega}</p>
                        </Grid>
                    </Grid>
                    <ListDetailOrders idOrden={idOrden}/>
                    {
                        !isAccepted && 
                        <Grid className={sty.contBtns} item xs={12}>
                            <BtnAccept onClick={() => confirmAccept()} variant="contained">
                                Aceptar orden
                            </BtnAccept>
                        </Grid>
                    }
                    {
                        isAccepted &&
                        <Grid className={sty.contBtns} item container direction="row" justify="space-around" alignItems="center">
                            <Grid>
                                <BtnCancel onClick={() => confirmCancel()} variant="contained">
                                    Cancelar
                                </BtnCancel>
                            </Grid>
                            <Grid>
                                <BtnDelivery onClick={() => confirmEntregada()} variant="contained">
                                    Entregar
                                </BtnDelivery>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            }
            <RenderModal>
                <AddCancelReason hide={hide} cancelOrder={processCancel} />
            </RenderModal>
        </Grid>
    )
}