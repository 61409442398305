import React, { useState } from 'react';
//Styles
import { makeStyles } from '@material-ui/core/styles';
import {MainColors} from '../../Sources/SourcesConst';
//Components
import Grid from '@material-ui/core/Grid';
import {getCustomTextField} from '../../Generics/TextField';
import {getCustomButton} from '../../Generics/CustomButton';

const usestyles = makeStyles(() => ({
    container: {
        width: '80vw',
        height: '40vh',
        backgroundColor: '#fff',
    },
    title: {
        color: '#5D6D7E',
        fontWeight: 'light',
        fontSize: '3vh',
        margin: '3vh',
        textAlign: 'center',
    },
    warningMsg: {
        color: '#FFD54F',
        fontWeight: 'light',
        fontSize: '1.5vh',
        margin: '0.75vh',
        textAlign: 'left',
    }
}))

const ReasonTextField = getCustomTextField(MainColors.secondary);

const AcceptButton = getCustomButton(MainColors.secondary,'3vh','bold','#fff','#fff');

export default function AddCancelReason(props){
    const sty = usestyles();
    const [infoForm,setInfoForm] = useState({reason:"",warningMsg:""});

    const onInputReason = (e) => {
        e.persist();
        setInfoForm({reason:e.target.value,warningMsg:""});
    }

    const validateReason = () => {
        if(infoForm.reason !== ""){
            if(infoForm.reason.length > 5){
                props.hide();
                props.cancelOrder(infoForm.reason);
            }else{
                setInfoForm({reason:infoForm.reason,warningMsg:"El motivo debe ser mas extenso"})
            }
        }else{
            setInfoForm({reason:infoForm.reason,warningMsg:"Debe describir el motivo"});
        }
    }

    return(
        <Grid className={sty.container} container direction="column" alignItems="center" justify="space-between">
                
                <Grid item xs>
                    <p className={sty.title}>Describa el motivo de cancelación</p>
                </Grid>
                <Grid item xs>
                    <form noValidate autoComplete="off">
                        <ReasonTextField value={infoForm.reason} onChange={onInputReason} id="outlined-basic" label="Motivo" variant="outlined" />
                    </form>
                </Grid>
                {
                    infoForm.warningMsg !== "" &&
                    <Grid item>
                        <p className={sty.warningMsg}>{infoForm.warningMsg}</p>
                    </Grid>
                }
                <Grid item xs>
                    <AcceptButton onClick={validateReason} variant="contained">
                        Cancelar orden
                    </AcceptButton>
                </Grid>
        </Grid>
    )
}