import React, {useEffect,useState } from 'react';
//Components
import SubMenu from './Submenu';
//import listToppings from './ListToppings'
import { Typography, Grid, Avatar, IconButton } from '@material-ui/core';
//import { Typography, Grid, Paper, Avatar, Snackbar, IconButton } from '@material-ui/core';
//Apis
import {makeRequest} from '../../../service/ManagerRequest';
import {idLan_, color_accent_, color_secondary_} from '../../Constants';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
//Styles
//import {MainColors} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import AddIcon from '@material-ui/icons/Add';
//Swipe List
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        padding: theme.spacing(1),
        margin: 'auto',
        maxWidth: 500,
    },
    avatar: {
        width: '100%',
        height: '100%',
        padding: 'auto',
        //color: MainColors.accent,
        backgroundColor: '#fff',
    },
    img:{
        margin: 'auto',
        width: '6vh',
        height: '6vh',
    },
    nameSub:{
        fontSize: '5vh',
        fontWeight: '150',
        //color: MainColors.secondary,
        margin: '0',
    },
    modalleft: {
        position: "absolute",
        zIndex: '3',
        //bottom: '0vh',
        //top: '10vh',
        right: '3vh',
        fontSize: '9pt',
        fontWeight: 'bold',
        color: '#CB4335',
        opacity: '100%',
        background:'transparent',
        width:'137px',
        height: 'auto',
        textAlign:'left',
        margin: '0px 0 0 -20px',
        //left: '50%',
        //transform: 'translate(-50%, -50%)',
      },
      modalright: {
        position: "absolute",
        zIndex: '3',
        //bottom: '0vh',
        //right: '6vh',
        fontSize: '9pt',
        fontWeight: 'bold',
        color: '#CB4335',
        opacity: '100%',
        background:'transparent',
        width:'137px',
        height: 'auto',
        textAlign:'right',
        left: '3vh',
        margin: '0px 0 0 -20px',
        //transform: 'translate(-50%, -50%)',
      },
      buttonTopping: {
        zIndex: '4',
        lineheight: '12px',
        width: '20px',
        fontSize: '1rem',
        fontFamily: 'tahoma',
        //margintop: '1px',
        // marginright: '2px',
        position: 'absolute',
        //top: theme.spacing(0),
        bottom: theme.spacing(5),
        //top: theme.spacing(1),
        left: theme.spacing(4),
        color: '#fff',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'transparent',
        },
      },
}));

export default function ListSubmenus(props){
    const [arraySubs,setSubs] = useState([]);
    const sty = useStyles();
    const idMenu_ = props.idMenu;
    const handleResSubmenus = (data,success) =>{
        //console.log('ListSubmenus...');
        //console.log(data);
        if(success){
            setSubs(data);
        }
    }
    useEffect(() => {
        const data = {
            idMenu: idMenu_, //props.idMenu,
            idIdioma: localStorage.getItem(idLan_)
        }
        makeRequest('application/json',data,'GET','masterdata/getAllProductosSubMenu/WPA',false,false,(dataRes,success) => handleResSubmenus(dataRes,success))
    },[])

    return(
        <div className={sty.root}>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Avatar onClick={props.back} className={sty.avatar} style={{color: localStorage.getItem(color_accent_)}} alt="Add icon">
                        <ArrowBackIcon className={sty.img}/>
                    </Avatar>
                </Grid>
                <Grid item xs={10}>
                    <p className={sty.nameSub} style={{color: localStorage.getItem(color_secondary_)}}>{props.nombreMenu}</p>
                </Grid>
            </Grid>
            <Grid container >
                <Typography className={sty.modalright}>{props.showInformationOn ? <TranslateStr token="showInfoSwipeLeft"/> : ''}</Typography>
                <Typography className={sty.modalleft}>{props.showInformationOn ? <TranslateStr token="showInfoSwipeRight"/> : ''}</Typography>
            </Grid>
            <Grid>
                <SwipeableList>
                    {
                        arraySubs.map(sub => (
                            <SubMenu key={arraySubs.indexOf(sub)} dataItem={sub} idTipoMenu={props.idTipoMenu} showInformationOn={props.showInformationOn} setconfigCarrito={props.setconfigCarrito} back={props.back}/>
                        ))
                    }
                </SwipeableList>
            </Grid>
            
        </div>
    )
}