import React, { useState,useEffect } from 'react';
//DB
import {deleteAllCarrito,allItemCarritoTipo} from '../../../service/ManagerCarrito';
//Styles
//import {MainColors} from '../Sources/SourcesConst';
import {makeStyles} from '@material-ui/core/styles';
//Apis
import {
    checkStatusOrderRestaurant,
    sendAllOrdenes,
    createOrderRestaurant,
    callWaiter,
    getMsgErrorApi,
    CreateComplaint,
    //signIn,
    signInGeneral,
    signalRConnectionBuild,
    notifyCallWaiter,
    notifyNuevaOrden,
    notifyNewOrden
} from '../../../service/ManagerApis';
//Components
import Login from '../Login/Login';
//import QrReader from 'react-qr-reader';
import QrReader from 'modern-react-qr-reader'
import {Grid} from '@material-ui/core';
import {makeNormalDialog} from '../../utilscomponents/DialogAsk';
import SubTitleBack from '../../utilscomponents/SubTitleBack';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
import BackDrop from '../../utilscomponents/BackDrop';
//Others
import {getBoolStorage} from '../../../service/HelperStorage';
import { ubicacion_, tagMesa_, isIdOrden_,login_,ValidacionPorUsuario_,idMesa_,esHabitacion_,isForOrder_,idForChangeEmpresa_, firstError_, idEmpresa_} from '../../Constants';
//import { ContactMailOutlined } from '@material-ui/icons';
//import { ubicacion_, tagMesa_, isIdOrden_,nameEmpresa_,login_,idMesa_,isForOrder_,idForChangeEmpresa_,isLandingForChangeEmpresa_, firstError_} from '../../Constants';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
        margin: '0',
    },
    msg:{
        fontSize: '3vh',
        fontWeight: '300',
        //color: MainColors.secondary,
        //color: '#CB4335',
        padding: '0',
        margin: '2vh 0 0 0',
    },
    qrContainer:{
        zIndex: theme.zIndex.drawer -1,
        width:'100vw',
        height:'70 vh',
        padding: '0',
        margin: '2vh 2vh 0 2vh',
       
    },
    back: {
        width: '100%',
        height: '100%',
        padding: 'auto',
        //color: MainColors.accent,
        backgroundColor: '#fff',
    },
    img:{
        margin: 'auto',
        width: '6vh',
        height: '6vh',
    },
    backDrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titleLoading: {
        margin: '0',
        fontSize: '3vh',
        fontWeight: '350',
        padding: '0 0 0 2vh',
    }
}));

export default function ScanQr(props){
    //const [conn, setConn] = React.useState(null);
    //const tipoOrden = localStorage.getItem(idTipoOrden_);
    const sty = useStyles();
    const [settingsPage,setSettingsPage] = useState({
        index: 0,
        showBackDrop: false,
    });
    const [arrayTipos, setarrayTipos]=useState([]);

    const getTiposCarrito = () => {
        allItemCarritoTipo( array => {
            setarrayTipos(array);
        });
    }

    const handleClickSignIn = () => {
        signInGeneral('MesaGeneral','Ribo1234',(success) => {
            if(success){
                //props.changePage(true);
                console.log('MesaGeneral: ' + success);
                
            }else{
                makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr token="passUserWrong"/>,null);
            }
        });
        
    }

    useEffect(() => {
        // signalRConnectionBuild(async _hubConnection => {
        //     await _hubConnection.start(); 
        //     console.assert(_hubConnection.state === "Connected");
        //     console.log("SignalR Connected.");

        //     // Escuchar eventos del websocket
        //     _hubConnection.on("notifywpatowaiter", function (result) {

        //         const notif = result[0];
        //         if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
        //         if (parseInt(localStorage.getItem(idMesa_)) !== notif.idMesa) return;


        //         if (notif.comando === 'llamada-mesero') {
        //             console.log('notif', notif);
        //         }

        //     });
        // });

        if(props.sendOrden){
            getTiposCarrito();
        }
       // onSuccessQR("https://ontable.app/App/1/5/Terraza/Int2");
        //onSuccessQR("http://OnTable.app/wpa/index.html?idempresa=1&idMesa=5&ubicacion=Terraza&tagMesa=Int2");
        //onSuccessQR('idMesa:5|Restaurant:TinoPanino|Sucursal:TinoPanino|Mesa:Int2|Ubicacion:Terraza|idEmpresa:1');
    }, []);

    const onSuccessQR = (dataQR) => {
        if(dataQR){
            showLoading();
            handleScan(dataQR,(ok,idEmpresa)=>{
                if(!ok){
                    reload();
                    makeNormalDialog(<TranslateStr token="wrongQr"/>,<TranslateStr token="verifyQrRestaurant"/>,null);
                }
                else{
                    if(props.changeEmpresa){
                        localStorage.setItem(idForChangeEmpresa_,idEmpresa);
                        props.back(2);
                        //Anterior
                        /*getInfoEmpresa(idEmpresa,(statusInfo) => {
                            if(statusInfo){
                                props.changeRootPage({index:1,isRecentOpenApp:false});
                            }else{
                                reload();
                                makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr token="errorSetRest"/>,null);
                            }
                        })*/
                    }else{
                        //Valida para mandar orden, mesero o queja
                        //&& getBoolStorage(ValidacionPorUsuario_)
                        if(!getBoolStorage(login_) && getBoolStorage(ValidacionPorUsuario_) ){
                            localStorage.setItem(isForOrder_,true);
                            setSettingsPage({index:-1,showBackDrop:false});
                        }else{
                            if(!getBoolStorage(ValidacionPorUsuario_) && !getBoolStorage(login_)){
                                localStorage.setItem(isForOrder_,true);
                                handleClickSignIn();
                            }
                            if(props.callwaiter && !props.sendQueja){
                                 validateToCall();
                            }else{
                                if(props.sendOrden && !props.sendQueja){
                                     //validateToSendOrder();
                                    ValidatebyTipo();
                                }
                                else{
                                    if(props.sendQueja){
                                        validateComplaint();
                                    }
                                    else{
                                        console.log("Login ok, status order...");
                                        handleStatusOrder();
                                    }
                                }
                            }
                        }
                    } 
                    
                }
            });
        }
    }

    const validateToCall = () => {

        callWaiter(status => {
            if (status) {
                //makeNormalDialog(<TranslateStr token="waiterComing"/>, <TranslateStr token="waiterWillBeThere"/>,() =>props.back(-1));
                notifyCallWaiter((res,status) => {
                    console.log(res.data);
                    if (status) {
                        makeNormalDialog(<TranslateStr token="waiterComing" />, <TranslateStr token="waiterWillBeThere" />, () => props.back(-1));
                    } else {
                        makeNormalDialog(<TranslateStr token="somewrong" />, <TranslateStr token="sorryTryAgain" />, null);
                    }
                    //reload();
                });
            } else {
                makeNormalDialog(<TranslateStr token="somewrong" />, <TranslateStr token="sorryTryAgain" />, null);
            }
            reload();
        });
    }

    const handleStatusOrder = () =>{
        //reload();
        props.back(-2);
    }
    const handleBackLogin = (success) => {
        console.log('handleBackLogin');
        console.log(success);
        console.log(props.callwaiter);
        if(!success){
            localStorage.setItem(isForOrder_,false);
            makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr token="requiredLogin"/>,null);
            reload();
        }else{
            showLoading();
            if(props.callwaiter && !props.sendQueja){
                validateToCall();
            }else {
                if(props.sendOrden && !props.sendQueja){
                    //validateToSendOrder();
                    ValidatebyTipo();
                }
                else{
                    if(props.sendQueja){
                        console.log("Login ok y validar queja");
                        console.log(props.comment);
                        validateComplaint();
                    }
                    else{
                        console.log("Login ok, status order...");
                        handleStatusOrder();
                    }
                }
            }
        }
    }

    const validateComplaint = () =>{
        CreateComplaint(props.comment,(dataQueja,statusCreate) => {
            if(statusCreate){
                console.log(dataQueja);
                props.setInfoForm({comment:"",rating:0});
                makeNormalDialog(<TranslateStr token="sendComplaintTitle"/>,<TranslateStr token="sendComplaintOk"/>,() =>  props.back(-1));
            }else{
                makeNormalDialog(<TranslateStr token="somewrong"/>, <TranslateStr token="sorryTryAgain"/>,null);
            }
            reload();
        });
    }

    const ValidatebyTipo = () =>{
        var tipoOrden=0;
        var areaServicio=0;
        var numTipos=arrayTipos.length;
        var iTipo=0;
        console.log('No. grupos carrito');
        console.log(numTipos);
        if(numTipos > 0){
            arrayTipos.forEach(element => {
                tipoOrden=parseInt(element.idTipoMenu);
                areaServicio=parseInt(element.idAreaDeServicio);
                iTipo = iTipo + 1;
                console.log('Tipo Orden ' + tipoOrden);
                console.log('Area de Servcio ' + areaServicio);
                console.log('Ejecuta validateToSendOrder');
                if(tipoOrden==1){
                    validateToSendOrder(1,tipoOrden,numTipos,iTipo);
                }
                else{
                    validateToSendOrder(areaServicio,tipoOrden,numTipos,iTipo);
                }
            })
        }
    }

    const validateToSendOrder = (AresServicio,TipoOrden,maxTipos,posTipos) => {
        //console.log('Ejecuta checkStatusOrderRestaurant');
        //console.log(TipoOrden);
        checkStatusOrderRestaurant(AresServicio,TipoOrden,(idOrden,statusTipo,statusTable) => {
            //handleErrorSend(statusTable);
            //console.log('Compara statusTable');
            //console.log(statusTable);
            if(statusTable === true ){
                if(getBoolStorage(isIdOrden_) && statusTipo===false){
                    //console.log('sendOrders ', idOrden);
                    sendOrders(idOrden,TipoOrden,AresServicio,maxTipos,posTipos);
                }
                else{
                    console.log('Nueva orden');
                    createOrderRestaurant(AresServicio,TipoOrden,(idOrden,statusCreate) => {
                        //handleErrorSend(statusCreate);
                        if(statusCreate === true){
                            notifyNewOrden(idOrden,TipoOrden,(res,success) =>{
                                console.log(res.data);
                            });
                            sendOrders(idOrden,TipoOrden,AresServicio,maxTipos,posTipos);
                        }
                        else{
                            handleErrorSend(statusCreate);
                        }
                    });
                }
            }
            else if(statusTipo === true){
                handleErrorSend(statusTipo);
            }
            else
            {
                // console.log('Nueva orden no hay registros activos en la mesa');
                // createOrderRestaurant(AresServicio,TipoOrden,(idOrden,statusCreate) => {
                // //handleErrorSend(statusCreate);
                // if(statusCreate === true){
                //     sendOrders(idOrden,TipoOrden,AresServicio,maxTipos,posTipos);
                // }
                // else{
                    handleErrorSend(statusTable);
                // }
                // });
            }
        });
    }

    const sendOrders = (idorden,TipoOrden,areaOrden,MaxOrder,PosOrder) => {
        sendAllOrdenes(idorden,TipoOrden,areaOrden,(statusSend) => {
            if(statusSend){
                if(parseInt(PosOrder)===parseInt(MaxOrder)){
                    //notifyNewOrden(idorden,TipoOrden);
                    handleOkSend(statusSend);
                }
            }
            else{
                handleErrorSend(statusSend);
            }
        });
    }

    const handleOkSend = (status) => {
        if(status){
            // if(props.sendOrden && !props.sendQueja){
                deleteAllCarrito();
                props.setconfigCarrito(0);
                //deleteCarritoByTipo(tipoOrden);
                reload();
                makeNormalDialog(<TranslateStr token="sendOrderTitle"/>,<TranslateStr token="sendOrderOk"/>,() =>  props.back(-1));
            // }
            // else{
            //     if(props.sendQueja){
            //         props.setInfoForm({comment:"",rating:0});
            //         reload();
            //         makeNormalDialog(<TranslateStr token="sendComplaintTitle"/>,<TranslateStr token="sendComplaintOk"/>,() =>  props.back(-1));
            //     }
            // }
        }
        else{
            makeNormalDialog(<TranslateStr token="somewrong"/>, <TranslateStr token="sorryTryAgain"/>,null);
        }
    }
    const showLoading = () => {
        setSettingsPage({index: 0,showBackDrop:true});
    }

    const reload = () => {
        setSettingsPage({index: 0,showBackDrop:false});
    }

    const handleErrorSend = (status) => {
        if(status === false){
            console.log('firstError: ' + getBoolStorage(firstError_));
            makeNormalDialog(getBoolStorage(firstError_) ? <TranslateStr token="outOfService"/> : <TranslateStr token="somewrong"/>,getMsgErrorApi(<TranslateStr token="sendOrderError"/>),null);
            //makeNormalDialog(getBoolStorage(firstError_) ? <TranslateStr token="outOfService"/> : <TranslateStr token="somewrong"/>,<TranslateStr token="sendOrderError"/>,null);
            //reload();
        }
        else{
            makeNormalDialog( <TranslateStr token="somewrong"/>, <TranslateStr token="untilOpen"/>,null);
            //reload();
        }
        reload();
    }

    const handleScan = (data,onResult) => {
        var ok = false;
        let idEmpresa = 0;
        //console.log(data);
        if(data){
            console.log('leer qr');
            console.log(data);
            //https://prd-api.ontable.app/restaurante/app/1/2365/Terraza/Terraza3/False
            var rawQR = data.split("/");
            console.log('rawQR', rawQR);
            if(rawQR.length === 10){
                ok = true;
                rawQR.forEach(function(element,index){
                    if(index === 5){
                        idEmpresa = element;
                    } else if (index === 6) {
                        localStorage.setItem(idMesa_,element);
                    } else if (index === 7){
                        localStorage.setItem(ubicacion_,element);
                    } else if (index === 8){
                        localStorage.setItem(tagMesa_,element);
                    } else if (index === 9){
                        localStorage.setItem(esHabitacion_,element.toLowerCase());
                    }
                })
            }
            else if(rawQR.length === 9){
                ok = true;
                rawQR.forEach(function(element,index){
                    if(index === 4){
                        idEmpresa = element;
                    } else if (index === 5) {
                        localStorage.setItem(idMesa_,element);
                    } else if (index === 6){
                        localStorage.setItem(ubicacion_,element);
                    } else if (index === 7){
                        localStorage.setItem(tagMesa_,element);
                    } else if (index === 8){
                        localStorage.setItem(esHabitacion_,element.toLowerCase());
                    }
                })
            }
        }
        onResult(ok,idEmpresa);
    }

    const handleErrorQR = (err) => {
        //console.error(err)
        console.error(err.name + ': ' + err.message)
    }

    const msgScan = props.changeEmpresa === true ? 
                        <TranslateStr token="scanToChange"/>
                    :
                    props.callwaiter === true ?
                        <TranslateStr token="scanToCall"/>
                        :
                        props.sendOrden === true ?
                            <TranslateStr token="scanToSend"/>
                            :
                            props.sendQueja === true ?
                                <TranslateStr token="scanToComplaint"/>
                                :
                                <TranslateStr token="scanLocation"/>

    return(
        (function(){
            switch(settingsPage.index){
                case 0:
                    return <Grid className={sty.root} container direction="column" justify="center" alignContent="center" spacing={1}>
                        {
                            settingsPage.showBackDrop === true ?
                            <BackDrop show={settingsPage.showBackDrop}/>
                            :
                            <div>
                                <SubTitleBack msg={msgScan} back={() => props.back(-1)}/>
                                {/* <QrReader
                                    className={sty.qrContainer}
                                    delay={1400}
                                    onError={handleErrorQR}
                                    onScan={onSuccessQR}
                                    
                                /> */}
                                <QrReader
                                    className={sty.qrContainer}
                                    delay={1400}
                                    facingMode={"environment"}
                                    onError={handleErrorQR}
                                    onScan={onSuccessQR}
                                />
                            </div>
                        }
                    </Grid>
                case -1 :
                    return <Login changePage={handleBackLogin} setfullUserName={props.setfullUserName} setemailuser={props.setemailuser} 
                            fulluserName={props.fulluserName} emailuser={props.emailuser} setconfigCarrito={props.setconfigCarrito}/>  
                default:
                    return;
            }
        })()
    )
}