import React,{useState} from 'react';
//Components
import Login from '../Login/Login';
import Registro from '../Registro/Registro';
import UpdateData from './UpdateUser/UpdateData';
import HistorialUser from './Historial/HistorialUser';
import {Grid,Avatar,Button} from '@material-ui/core';
import ChangeLanBox from './HelperLanguage/ChangeLanBox';
import Translator from './HelperLanguage/Translator';
import TranslateStr from './HelperLanguage/TranslateStr';
//import InformationOn from './HelperLanguage/InformationOn';
//Styles
import {AssignmentInd,Autorenew,ExitToApp,AccountCircle,PersonAdd,History, Info} from '@material-ui/icons';
//import InfoIcon from '@material-ui/icons/Info';
//import {MainColors} from '../Sources/SourcesConst';
import {makeStyles} from '@material-ui/core/styles';
//Dialog
import {makeAskDialog,makeNormalDialog} from '../../utilscomponents/DialogAsk';
//Others
import {getBoolStorage,logOut} from '../../../service/HelperStorage';
import {nameUser_,emailUser_,rolUser_,login_, ValidacionPorUsuario_, color_accent_, color_main_, color_secondary_} from '../../Constants';

//import { GoogleOAuthProvider } from '@react-oauth/google';

const useStyles = makeStyles(({
    avatar: {
        width: '100%',
        height: '100%',
        padding: '0.5vh',
        //color: MainColors.main,
        backgroundColor: '#fff',
    },
    img:{
        margin: '0',
        width: '4vh',
        height: '4vh',
    },
    createImgAvatar:{
        width: '100%',
        height: '100%',
        padding: '1vh',
        //color: MainColors.secondary,
        backgroundColor: '#fff',
    },
    createImg:{
        margin: '1vh',
        width: '5vh',
        height: '5vh',
    },
    labelOption:{
        textAlign: 'start',
        //color: MainColors.accent,
        fontSize: '2.5vh',
        margin: '0',
        padding: '0',
    },
    labelEmailUser: {
        textAlign: 'start',
        //color: MainColors.accent,
        fontSize: '1.5vh',
        margin: '0',
        padding: '0',
    },
    labelNameUser: {
        textAlign: 'start',
        //color: MainColors.secondary,
        fontSize: '2.5vh',
        margin: '0',
        padding: '0',
    },
    contInfo: {
        margin: '1.5vh 1vh 0 1vh',
        borderBottom: '3px solid #D5DBDB',
        paddingBottom: '1vh'
    },
    labelChangeSession:{
        textAlign: 'start',
        //color: MainColors.accent,
        fontSize: '3vh',
        margin: '0',
        padding: '0',
    },
    btnChange:{
        margin: '0.5vh 0'
    },
    contBtnChange:{
        margin: '0 0 0 1.5vh'
    },
    contSign: {
        margin: '0.5vh',
    }
}));

export default function SelectAddress(props){

    const sty = useStyles();
    const isLogin = getBoolStorage(login_);
    const ValidaLogin = getBoolStorage(ValidacionPorUsuario_);
    const [configUser,setConfigUser] = useState({isLogin:getBoolStorage(login_),page:0});
    //const [fulluserName,setfullUserName] = useState(localStorage.getItem(nameUser_));
    //const [emailuser, setemailuser] = useState(localStorage.getItem(emailUser_));
    const isBilingual = props.bilingual; 
    const statusLogin = (status) => {
        if(status){
            props.setConfigMailbox(
                {showMailbox:true,
                changeRestaurant: <TranslateStr token="changeRestaurant"/>
              });
            successLogin();
        }
        setConfigUser({ isLogin : status ? status : configUser.isLogin, page : 0});
      
    }

    const resetInfoUser = () => {
        logOut();
        setConfigUser({ isLogin: false, page: 0, nombre: ''});
        props.changePageOptions(true);
        props.setConfigMailbox({
            showMailbox:getBoolStorage(login_),
            changeRestaurant: <TranslateStr token="changeRestaurant"/>
        });
    }

    const askLogout = () => {
        makeAskDialog(<TranslateStr token="rusurelogout"/>,"",resetInfoUser);
    };

    const validateRol = () => {
        const rol = localStorage.getItem(rolUser_); // parseInt(localStorage.getItem(rolUser_));
        var isNormalClient = true;

        console.log('validateRol: ' + rol);
        if(rol === 'repartidor'){
            isNormalClient = false;
        }
        props.changePageOptions(isNormalClient);
        //props.setConfigMailbox(true);
    }

    const successLogin = () => {
        makeNormalDialog(<TranslateStr token="welcome"/>,<TranslateStr token="thkslogin"/>,validateRol());
    };

    const onBackPressSignUp = () =>{
        setConfigUser({ isLogin: configUser.isLogin, page: 0});
    }

    return(
        <Grid container direction="column" alignItems="center" justify="center">
            {
                (function(){
                    switch(configUser.page){
                      case 0:
                        return configUser.isLogin ? <Grid container direction="column" justify="center" alignItems="center">
        
                            <Grid className={sty.contInfo} container direction="column" justify="center" alignItems="flex-start">
                
                                <Grid item container direction="row" justify="center" alignItems="center">

                                    <Grid 
                                        onClick={() => setConfigUser({ isLogin: configUser.isLogin, page: 4})}  
                                        item 
                                        xs={10} container direction="row" justify="center" alignItems="center">

                                        <Grid item xs={2}>
                                            <Avatar className= {sty.avatar} style={{color: localStorage.getItem(color_main_)}} alt="User icon">
                                                <AccountCircle className={sty.img}/>
                                            </Avatar>
                                        </Grid>
                                        <Grid item xs={10} container direction="column" justify="center" alignItems="flex-start">
                                            <p className={sty.labelNameUser} style={{color: localStorage.getItem(color_secondary_)}}>{props.fulluserName}</p>
                                            <p className={sty.labelEmailUser} style={{color: localStorage.getItem(color_accent_)}}>{props.emailuser}</p>
                                        </Grid>

                                    </Grid>

                                    <Grid item xs={2}>
                                        <Avatar onClick={() => askLogout()} className= {sty.avatar} style={{color: localStorage.getItem(color_main_)}} alt="User icon">
                                            <ExitToApp className={sty.img}/>
                                        </Avatar>
                                    </Grid>
                                </Grid>
                
                                <Grid item className={sty.contBtnChange}>
                                    <Button
                                        onClick={() => setConfigUser({isLogin:configUser.isLogin,page:1})}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={sty.btnChange}
                                        style={{backgroundColor: localStorage.getItem(color_secondary_)}}
                                        startIcon={<Autorenew />}
                                    >
                                        <TranslateStr token="changeAccount"/>
                                    </Button>
                                </Grid>
                            </Grid>
                        
                        </Grid>
                        :
                        <Grid className={sty.contSign} container direccion="column" justify="center" alignItems="flex-start">
                            <Grid 
                                onClick={() => setConfigUser({isLogin:configUser.isLogin,page:1})}
                                item
                                container
                                justify="flex-start" 
                                alignItems="center"
                            >
                                <Grid item>
                                    <Avatar className= {sty.createImgAvatar} style={{color: localStorage.getItem(color_secondary_)}} alt="Add icon">
                                        <AssignmentInd className={sty.createImg}/>
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <p className={sty.labelOption} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="login"/></p>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    
                        case 1:
                            return <Login changePage={statusLogin} setfullUserName={props.setfullUserName} setemailuser={props.setemailuser} setconfigCarrito={props.setconfigCarrito}/>
                        case 2:
                            return <Registro changePage={onBackPressSignUp}/>
                        case 3:
                            return <HistorialUser changePage={onBackPressSignUp}/>
                        case 4:
                            return <UpdateData changePage={onBackPressSignUp}/>
                        default:
                            return; 
                    }
                })()
            }
            {
                configUser.page === 0 &&
                <Grid container direction="column" alignItems="flex-start" justify="center">
                    <Grid 
                        onClick={() => setConfigUser({ isLogin: configUser.isLogin, page: 2})} 
                        item 
                        container 
                        justify="flex-start" 
                        alignItems="center"
                    >
                        <Grid item>
                            <Avatar className= {sty.createImgAvatar} style={{color: localStorage.getItem(color_secondary_)}} alt="Add icon">
                                <PersonAdd className={sty.createImg}/>
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <p className={sty.labelOption} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="createAcc"/></p>
                        </Grid>
                    </Grid>

                    {
                        isLogin && ValidaLogin &&
                        <Grid 
                            onClick={() => setConfigUser({ isLogin: configUser.isLogin, page: 3})} 
                            item 
                            container 
                            justify="flex-start" 
                            alignItems="center"
                        >
                            <Grid item>
                                <Avatar className= {sty.createImgAvatar} style={{color: localStorage.getItem(color_secondary_)}} alt="Add icon">
                                    <History className={sty.createImg}/>
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <p className={sty.labelOption} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="titleRecord"/></p>
                            </Grid>
                        </Grid>
                    }
                    {/* <Grid 
                        onClick={() => InformationOn()}     
                        item
                        container
                        justify="flex-start" 
                        alignItems="center"
                    >
                        <Grid item>
                            <Avatar className= {sty.createImgAvatar} alt="Add icon">
                                <InfoIcon className={sty.createImg}/>
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <p className={sty.labelOption}><Translator token="showInformation"/></p>
                        </Grid>
                    </Grid> */}
                   {isBilingual && 
                   <ChangeLanBox setConfigMailbox={props.setConfigMailbox}/>}
                   <br/>
                   <Grid    
                        item
                        container
                        justify="flex-start" 
                        alignItems="center"
                    >
                        <Grid item>
                            <Avatar className= {sty.createImgAvatar} style={{color: localStorage.getItem(color_secondary_)}} alt="Add icon">
                                <Info className={sty.createImg}/>
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <a href="https://ontable.app/aviso-privacidad.html" target="_blank" rel="noopener noreferrer">
                                <p className={sty.labelOption} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="avisoPrivacidad"/></p>
                            </a>
                        </Grid>
                    </Grid> 
                </Grid>
            }
        </Grid>
        
    )
}