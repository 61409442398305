//Styles 
import {color_accent_, color_main_, color_secondary_} from '../../Constants';

const MainSize = {
    hBodyPage: '75vh',
    hNav: '10vh',
    hHeaderLogo: '13vh',
    wFull: '100vw',
    hFull: '100vh',
}

const MainColors = {
    main :  localStorage.getItem(color_main_)==null ? '#516ac5': localStorage.getItem(color_main_),
    secondary :  localStorage.getItem(color_secondary_)== null ? '#829af2' : localStorage.getItem(color_secondary_),
    accent : localStorage.getItem(color_accent_)==null ? '#808598' : localStorage.getItem(color_accent_),
}

export {MainSize, MainColors};