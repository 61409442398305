import React, { useState } from 'react';
//Styles
import { makeStyles } from '@material-ui/core/styles';
//Components
import {Typography,Grid,Paper,Avatar,Snackbar} from '@material-ui/core';
import DetailDish from '../Submenus/DetailSubmenu/DetailDish';
import Alert from '@material-ui/lab/Alert';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
//DB
import {saveItemCarrito,deleteItemCarrito, getInfoCarrito} from '../../../service/ManagerCarrito';
//Modal
import {useModal} from '../Generics/useModal';
//Swipe
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import ItemSwipeRight from '../Submenus/ItemSwipeRight';
import ItemSwipeLeft from '../Submenus/ItemSwipeLeft';
//import {countCarrito_} from '../../Constants';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
    root: {
         flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
    },
    image: {
      width: '8vh',
      height: '8vh',
      margin: 'auto',
    },
  }));

export default function SubMenu(props) {
  const classes = useStyles();
  const {show,RenderModal} = useModal();
  const dataSub = props.dataItem;

  const [openDelete, setOpenDelete] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleCloseDelete = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenDelete(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const saveItem = () => {
    saveItemCarrito(dataSub.idProducto,dataSub,false,() => {
      setOpenSuccess(true);
      getInfoCarrito((totalR,countR) => {
        props.setconfigCarrito(countR);
      });
    });
  }

  const deleteItem = () => {
    deleteItemCarrito(dataSub.idProducto,(result) => {
      getInfoCarrito((totalR,countR) => {
        props.setconfigCarrito(countR);
      });
      setOpenDelete(true);
    });
  }

  return (
    <SwipeableListItem
        className={classes.container}
        swipeLeft={{
        content: <ItemSwipeLeft/>,
        action: () => deleteItem()
        }}
        swipeRight={{
        content: <ItemSwipeRight/>,
        action: () => saveItem()
        }}
    >

        <div className={classes.root}>
            <Paper onClick={show} className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Avatar className={classes.image} alt="Menu Photo" src={dataSub.imagenUri} />
                    </Grid>
                    <Grid item xs={9} sm container>
                        <Grid item xs container direction="column" spacing={1}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                {dataSub.nombreSubMenu}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                {dataSub.nombreProducto}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1">{'$' + dataSub.precio}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            <RenderModal>
                <DetailDish dataSub={dataSub} setconfigCarrito={props.setconfigCarrito}/>
            </RenderModal>
            <Snackbar open={openSuccess} autoHideDuration={1500} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity="success">
                        <TranslateStr token="addorder"/>
                    </Alert>
            </Snackbar>
            <Snackbar open={openDelete} autoHideDuration={1500} onClose={handleCloseDelete}>
                    <Alert onClose={handleCloseDelete} severity="warning">
                        <TranslateStr token="removeorder"/>
                    </Alert>
            </Snackbar>
        </div>
    </SwipeableListItem>
  );
}