import React, { Fragment,useEffect,useState} from 'react';
//Views
import HeaderLogo from './Header/HeaderLogo';
import {IconButton,BottomNavigation, BottomNavigationAction, Avatar, Grid} from '@material-ui/core';
//Icons
import BuildIcon from '@material-ui/icons/Build';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListIcon from '@material-ui/icons/List';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
//import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import MailBoxIcon from '@material-ui/icons/Mail';
//Styles
import {makeStyles} from '@material-ui/core/styles';
//import { makeStyles, } from '@material-ui/core/styles';
import {MainSize} from './Sources/SourcesConst';
//Routes
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
//Pages
import OrdersRepartidor from './Repartidor/OrdersRepartidor';
import ConfigPage from './ConfigPage/ConfigPage';
import StatusOrden from './StatusOrden/StatusOrden';
//import Menus from './Menu/Menus';
//import Servicios from './Servicio/Servicios';
import TipoMenu from './TipoDeMenu/TipoDeMenu';
import Carrito from './Carrito/Carrito';
import Queja from './Queja/Queja';
//Apis
import { getInfoCarrito } from '../../service/ManagerCarrito';
import { refreshTokenUser, signalRConnectionBuild } from '../../service/ManagerApis';
import { getBoolStorage } from '../../service/HelperStorage';
import InformationOn from './ConfigPage/HelperLanguage/InformationOn';
import { rolUser_,login_ ,InfoOn_, color_accent_, color_secondary_, nameUser_, emailUser_, isBilingual_} from '../Constants';
import TranslateStr from './ConfigPage/HelperLanguage/TranslateStr';
//import { Button } from 'react-bootstrap';
//import zIndex from '@material-ui/core/styles/zIndex';
//BD
//import {allItemCarrito} from '../../service/ManagerCarrito';

const useStyles = makeStyles({
  root: {
    "&$selected": {
      color: '#fff',
    },
  },
  selected: {},
});

const otherStyles = makeStyles({
  mainContent: {
    width: MainSize.wFull,
    height: MainSize.hBodyPage,
    overflow: 'hidden', 
    'overflow-y' : 'scroll',
    maxHeight:  MainSize.hBodyPage,
  },
  root: {
    //backgroundColor: MainColors.accent,
    width: MainSize.wFull,
    height: MainSize.hNav,
    "&$selected": {
      color: '#fff',
    },
    selected: {},
  },
  iconNav: {
    height : '7vh',
    width: '7vh',
    color: '#fff',
    //backgroundColor: MainColors.secondary,
    marginBottom: '1vh',
  },
  subTitle: {
    padding: '0',
    margin: '0',
    fontSize: '2vh',
    fontWeight: 'normal',
    color: '#CB4335',
    backgroundColor: 'transparent',
  },
  modal: {
    fontSize: '9pt',
    fontWeight: 'bold',
    color: '#CB4335',
    zIndex: 3,
    opacity: '100%',
    background:'transparent',
    width:'90px',
    height: 'auto',
    position: 'fixed',
    top: '12%',
    textAlign:'match-parent',
    //left: '50%',
    //transform: 'translate(-50%, -50%)',
  },
  modalcarrito: {
    fontSize: '0.7em',
    fontWeight: 'bold',
    //paddingRight: '1vh',
    //paddingTop: '1vh',
    //paddingBottom: '2vh',
    //paddingLeft: '2vh',
    //color: '#CB4335',
    zIndex: 3,
    //opacity: '100%',
    //background:'transparent',
    //width:'90px',
    //height: 'auto',
    //marginBottom: '1vh',
    //position: 'fixed',
    //right: '0',
    // top: '0.4vh',
    //top: '12%',
    //textAlign:'match-parent',
    //top: '1vh',
    //transform: 'translate(-50%, -50%)',
    //maxWidth: "max-content",
  },
  modalbuzonInfo: {
    fontSize: '9pt',
    fontWeight: 'bold',
    color: '#CB4335',
    zIndex: 2,
    opacity: '100%',
    background:'transparent',
    width:'30px',
    height: 'auto',
    position: 'fixed',
    top: '0.8vh',
    textAlign:'left',
    right: '9.3vh',
    //transform: 'translate(-50%, -50%)',
  },
  buttonInfo: {
    //lineheight: '12px',
    width: '47px',
    fontSize: '1rem',
    fontFamily: 'tahoma',
    //margintop: '1px',
   // marginright: '2px',
    position: 'fixed',
    zIndex: '1',
    top: '0.8vh',
    right: '0.8vh',
    color: '#fff',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    //margin: '0.5rem 0.5rem 0.7rem 0.7rem',
    '&:focus': {
      outline: 'none',
    },
  },
  createImg:{
    outline: 'none',
    marginRight: '10px',
    width: '4vh',
    height: '4vh',
    color: '#fff',
    backgroundColor: "transparent",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "transparent" }
  },
  fab: {
    position: "fixed",
    zIndex: '10',
    bottom: '4vh',
    right: '4vh',
  },
  buttonMail: {
    //lineheight: '12px',
    width: '20px',
    fontSize: '1rem',
    fontFamily: 'tahoma',
    //margintop: '1px',
  // marginright: '2px',
    position: 'fixed',
    zIndex: '1',
    top: '0.8vh',
    right: '7.8vh',
    color: '#fff',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  customHoverFocus: {
    outline: 'none',
    marginRight: '10px',
    width: '4vh',
    height: '4vh',
    color: '#fff',
    backgroundColor: "transparent",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "transparent" }
  }
});



export default function MainContainer(props) {

  const classes = useStyles();
  const otherSty = otherStyles();
  const [value, setValue] = React.useState('/');
  const normalClient = parseInt(localStorage.getItem(rolUser_)) === 'repartidor' ? false : true;
  const [isNormalClient, setIsNormalClient] = React.useState(normalClient);
  const [settingsInformation,setSettingsInformation] = useState({
    showInformationOn: getBoolStorage(InfoOn_)
  });
  const [cantidad,setconfigCarrito] = useState(0);
  const [configMailbox,setConfigMailbox] = useState({
      showMailbox:getBoolStorage(login_),
      changeRestaurant: <TranslateStr token="changeRestaurant"/>
  });
  const [bilingual,setBilingual] = useState(getBoolStorage(isBilingual_));
  const [fulluserName,setfullUserName] = useState(localStorage.getItem(nameUser_));
  const [emailuser, setemailuser] = useState(localStorage.getItem(emailUser_));
  //const[NewhubConnectionBuilder, setHubConnectionBuilder] = useState(null);

  useEffect(() => {

    // signalRConnectionBuild(async _hubConnection => {
    //   await _hubConnection.start().then(setHubConnectionBuilder(_hubConnection)).then(console.log("Hub Connected"));
    // });

    if(getBoolStorage(login_)){
      refreshTokenUser(() => {});
    }

    getInfoCarrito((totalR,countR,toppingsR) => {
      //localStorage.setItem(countCarrito_,countR);
      //setInfoOrder({total:totalR,count: countR,readyLogin:true});
      
      setconfigCarrito(countR);
   
    });


    //console.log('Cantidad carrito: ' + cantidad);
  },[]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const handleInformation = () => {
    InformationOn();
    setSettingsInformation({
      showInformationOn:getBoolStorage(InfoOn_)
    });
  };

  return (
      <Router>
          <HeaderLogo changePage={props.changePage} changeRestaurant={configMailbox.changeRestaurant} setBilingual={setBilingual}/>
         
          <IconButton className={otherSty.buttonInfo}>
            <Grid  onClick={() =>handleInformation()}>
              <HelpIcon className={otherSty.customHoverFocus}/>
            </Grid> 
          </IconButton>
          {isNormalClient &&
            <IconButton className={otherSty.buttonMail} 
            component={Link}
            to='/Queja'> 
             <Grid>
              {<p className={otherSty.modalbuzonInfo}>{settingsInformation.showInformationOn ? <TranslateStr token="showInfoComplaintsBox"/> : ''}</p>} 
              <MailBoxIcon className={otherSty.customHoverFocus}/>
              </Grid>
            </IconButton>
          } 
          <Fragment>
            <BottomNavigation value={value} onChange={handleChange} className={otherSty.root} style={{backgroundColor: localStorage.getItem(color_accent_)}}>
              <BottomNavigationAction
                classes={classes} 
                value="/Config" icon={
                  <Grid item container alignItems="flex-start">
                    <Avatar className={otherSty.iconNav} style={{backgroundColor: localStorage.getItem(color_secondary_)}}>
                      <BuildIcon/>
                    </Avatar>
                    {<p className={otherSty.modal}>{settingsInformation.showInformationOn ? <TranslateStr token="showInfoConfig"/> : ''}</p>}
                  </Grid>
                } 
                component={Link} to='/Config'>
                </BottomNavigationAction>
               
                {isNormalClient &&
                  <BottomNavigationAction 
                    classes={classes} 
                    value="/Miorden" 
                    icon={
                    <Grid item container alignItems="flex-start">
                        <Avatar className={otherSty.iconNav} style={{backgroundColor: localStorage.getItem(color_secondary_)}}><AssignmentIcon/></Avatar>
                      {<p className={otherSty.modal}>{settingsInformation.showInformationOn ? <TranslateStr token="showInfoOrder"/> : ''}</p>}
                    </Grid>
                    } 
                    component={Link} 
                    to='/Miorden'
                  />
                }

                {isNormalClient && 
                  <BottomNavigationAction 
                    classes={classes} 
                    value="/" 
                    icon={
                      <Grid item container alignItems="flex-start">
                      <Avatar className={otherSty.iconNav} style={{backgroundColor: localStorage.getItem(color_secondary_)}}> <ListIcon/> </Avatar>
                      {<p className={otherSty.modal}>{settingsInformation.showInformationOn ? <TranslateStr token="showInfoMenu"/> : ''}</p>}
                    </Grid>
                    } 
                    component={Link} 
                    to='/'
                  />
                }
                {isNormalClient && 
                  <BottomNavigationAction
                    classes={classes} 
                    value="/Carrito" 
                    icon={ 
                      <Grid item container alignItems="flex-start">
                        <Avatar className={otherSty.iconNav} style={{backgroundColor: localStorage.getItem(color_secondary_)}}> 
                          <Badge badgeContent={cantidad} color="secondary" style={{maxWidth: "max-content", zIndex:"3",}}>
                            <ShoppingCartIcon/>
                          </Badge>
                          
                        </Avatar>
                        {<p className={otherSty.modal}>{settingsInformation.showInformationOn ? <TranslateStr token="showInfoCart"/> : ''}</p>}
                      </Grid>
                    } 
                    component={Link} 
                    to='/Carrito'
                  />
                }
              
                {!isNormalClient &&
                  <BottomNavigationAction
                    classes={classes} 
                    value="/OrdersRepartidor" 
                    icon={ <Avatar className={otherSty.iconNav} style={{backgroundColor: localStorage.getItem(color_secondary_)}}> <ListIcon/> </Avatar>} 
                    component={Link} 
                    to='/OrdersRepartidor'
                  />
                }
            </BottomNavigation>
            
            <div className={otherSty.mainContent}>
              <Switch>
                <Route path='/Queja'>
                  <Queja showMailbox={configMailbox.showMailbox} showInformationOn={settingsInformation.showInformationOn} 
                    setconfigCarrito={setconfigCarrito} setfullUserName={setfullUserName} setemailuser={setemailuser} 
                    fulluserName={fulluserName} emailuser={emailuser}
                  />
                </Route>
                <Route path='/Carrito'>
                  <Carrito showInformationOn={settingsInformation.showInformationOn} setconfigCarrito={setconfigCarrito} 
                    setfullUserName={setfullUserName} setemailuser={setemailuser} fulluserName={fulluserName} emailuser={emailuser}
                  />
                </Route>
                <Route path='/Miorden'>
                  <StatusOrden showInformationOn={settingsInformation.showInformationOn} changePage={props.changePage} setconfigCarrito={setconfigCarrito}/>
                </Route>
                <Route path='/Config'>
                  <ConfigPage  changePageOptions={setIsNormalClient} setConfigMailbox={setConfigMailbox} setconfigCarrito={setconfigCarrito} 
                    bilingual={bilingual} setfullUserName={setfullUserName} setemailuser={setemailuser} 
                    fulluserName={fulluserName} emailuser={emailuser}
                  />
                </Route>
                <Route path='/OrdersRepartidor'>
                  <OrdersRepartidor setconfigCarrito={setconfigCarrito}/>
                </Route>
                <Route path='/'>
                  <TipoMenu showInformationOn={settingsInformation.showInformationOn} setconfigCarrito={setconfigCarrito}/>
                </Route>
              </Switch>
            </div>
          </Fragment>
      </Router>
  );
}
