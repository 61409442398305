import { LocalizationContext } from './LanguageProvider';
import React, {useContext} from 'react';
import {lan_, idLan_, color_accent_} from '../../../Constants';

import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
//import {MainColors} from '../../Sources/SourcesConst';

const useStyles = makeStyles(() => ({
  root:{
    width: '100%',
    height: '100%',
  },
  select:{
    width: '45vw',
    height: '3rem',
    margin: '1rem 0 1rem 0',
    padding: '0.5rem',
    //color: MainColors.accent,
  },
}));

const langs = [
  { shortCode: 'ES', label: "Español" },
  { shortCode: 'EN', label: "English" },
];

export default function Dropdown() {

  const sty = useStyles();

  const {
    localizationStore: [ state, dispatch ]
  } = useContext(LocalizationContext);

  const { selectedLanguage } = state;

  const handleSelected = (e) => {
    const { target: { value } } = e;
    if(value === 'EN'){
        localStorage.setItem(lan_,value);
        localStorage.setItem(idLan_,2);
    }else{
        localStorage.setItem(lan_,value);
        localStorage.setItem(idLan_,1);
    }
    dispatch({ type: 'LANGUAGE_UPDATE', payload: value });
  }

  function getOptions(langs) {
    return langs.map(({ shortCode, label }) => {
      return <option value={shortCode} key={shortCode}>{label}</option>
    });
  }

  return (
    <Grid className={sty.root} item container direction="row" justify="flex-start" alignItems="center">
        <Grid item>
            <select className={sty.select} style={{color: localStorage.getItem(color_accent_)}} onChange={handleSelected} value={selectedLanguage}>
              {getOptions(langs)}
            </select>
        </Grid>
    </Grid>
  );

}