import React from 'react';
//import React,  { useEffect, useState } from 'react';
//Components
import ItemCarrito from './ItemCarrito';
//import {allItemCarrito} from '../../../service/ManagerCarrito';
//Swipe List
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
//Styles
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(({
    root: {
        height: '100%',
        width: '100%',
    },
}));


export default function ListCarrito(props){

    const sty = useStyles();
    //const [arrayCarrito,setArrayCarrito] = useState(props.arrayCarrito);
    const arrayCarrito = props.arrayCarrito;
    
    // const getCarrito = () => {
    //     allItemCarrito( array => {
    //         setArrayCarrito(array);
    //     });
    // }
    
    // useEffect(() => {
    //     getCarrito();
    // },[]);

    return(
        <SwipeableList scrollStartThreshold={10} className={sty.root}>
            {
                arrayCarrito.map(dish => (
                    <ItemCarrito 
                        key={arrayCarrito.indexOf(dish)}
                        reload={props.reload}
                        setTotaltoppings = {props.setTotaltoppings}
                        data={dish}
                        setconfigCarrito={props.setconfigCarrito}
                    />
                ))
            }
        </SwipeableList>
    )
}