import React from 'react';
//components
import {Avatar, Grid,Button} from '@material-ui/core';
//import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
//Styles 
import {MainSize} from '../Sources/SourcesConst';
import {color_accent_, color_main_} from '../../Constants';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';

const useStyles = makeStyles(({
    root:{
        height: MainSize.hHeaderLogo,
        //background: localStorage.getItem(color_accent_), // MainColors.accent,
        //backgroundColor: MainColors.accent,//MainColors.accent, // localStorage.getItem(color_accent_),
    },
    logo: {
        height:  '37%',
        width:  '37%',
        objectFit:'contain',
        //width: 'auto',
        minWidth: '12vh',
        minHeight: '12vh',
        left: '2vh',
        //borderRadius: '0'
    },
    title: {
        fontSize: '4vh',
        color: '#fff',
        margin: '0 0 0 1vh',
        padding: '0',
        left: '3vh'
    },
    version: {
        fontSize: '1rem',
        color: '#fff',
        margin: '0 0 0 0.5rem',
        padding: '0',
    },
    iconNav: {
        height : '7vh',
        width: '7vh',
        color: '#fff',
        //backgroundColor: MainColors.secondary,//localStorage.getItem(color_secondary_), //MainColors.secondary,
        marginBottom: '1vh',
      },
    
}));

const ColorButton = withStyles((theme) => ({
    root: {
        zIndex: 3,
        fontSize: '1.5vh',
        color: '#fff',
        padding: '0.3vh',
        margin: '0 0 0 1vh',
        left: '3vh',
        backgroundColor: localStorage.getItem(color_main_),//localStorage.getItem(color_main_), //MainColors.main,
        '&:hover': {
            backgroundColor: localStorage.getItem(color_main_),//localStorage.getItem(color_main_), //MainColors.main,
        },
    },
  }))(Button);

/**
 * AnoHana
 * Code Geass
 * Gurren Lagann
 * ajin
 * the beginning
 */

export default function HeaderLogo(props){
    const sty = useStyles();
    
    return(
        
        <Grid className={sty.root} container ba="true" justify="center" alignItems="center" style={{backgroundColor: localStorage.getItem(color_accent_)}} >
            <Grid item xs={3} container direction="row" justify="flex-end" alignItems="center">
            <Avatar src={localStorage.getItem('urlEmpresaLogo')} className={sty.logo}/>
            </Grid>
            
            <Grid item xs={9} container direction="column" justify="center" alignItems="flex-start">
                {/* <p className={sty.title}>{localStorage.getItem('nameEmpresa')}</p> */}
                <ColorButton
                    style={{backgroundColor: localStorage.getItem(color_main_)}}
                    onClick={() => props.changePage({index:0,isRecentOpenApp:false})}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<CachedIcon/>}
                >
                    {/* <TranslateStr token="changeRestaurant"/> */}
                    {props.changeRestaurant}
                </ColorButton>
                {/* <Grid onClick={() => props.changePage({index:0,isRecentOpenApp:false})} item xs={9} container direction="column" justify="flex-end" alignItems="flex-end">
                <Avatar className={sty.version}
                    
                    
                >
                  {<InfoIcon/>}
                </Avatar> */}
            {/* </Grid> */}

                
            </Grid>
           
            
        </Grid>
    )
}