import React, { useEffect, useState } from 'react';
//Components
import OrdenDelivery from '../Delivery/OrdenDelivery';
import ScanQR from '../QR/ScanQR';
import ListCarrito from './ListCarrito';
import {Backdrop, Grid, Avatar} from '@material-ui/core';
import { SpeedDial, SpeedDialAction}  from '@material-ui/lab';
import {makeNormalDialog} from '../../utilscomponents/DialogAsk';
//Styles
import SendIcon from '@material-ui/icons/Send';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import HomeIcon from '@material-ui/icons/Home';
//import {MainColors} from '../Sources/SourcesConst';
import {makeStyles} from '@material-ui/core/styles';

//BD
import {allItemCarrito,decimalAdjust,formatoMexico,getInfoCarrito} from '../../../service/ManagerCarrito';
//Utils
import {getBoolStorage} from '../../../service/HelperStorage';
import {isIdOrden_,isDelivery_,login_, ValidacionPorUsuario_, color_accent_, color_main_, color_secondary_} from '../../Constants';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
//Apis
//import { checkStatusOrder,statusDeliveryOrder,refreshTokenUser } from '../../../service/ManagerApis';
import {statusDeliveryOrder,refreshTokenUser } from '../../../service/ManagerApis';
//import { array } from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
    },
    speedDial: {
        position: 'absolute',
        //bottom: theme.spacing(10),
        bottom: theme.spacing(5),
        right: theme.spacing(7),
    },
    fab: {
        // zIndex: '2',
        // 'backgroundColor': localStorage.getItem(color_main_) + '!important',
        // color: '#fff',
        zIndex: '2',
        'backgroundColor': localStorage.getItem(color_main_) + '!important',// MainColors.main + '!important',
        "&:hover, &.Mui-focusVisible": { backgroundColor: localStorage.getItem(color_main_)  + '!important'},
        //color: '#fff',
       
    },
    fabicon: {
        height : '9vh',
        width: '9vh',
        color: '#fff',
        'backgroundColor': localStorage.getItem(color_main_) ,
    },
    speedDialAction: {
        //color: MainColors.secondary,
        background: '#fff',
    },
    list: {
        zIndex: '-1',
    },
    modalOptions:{
        zIndex: '3',
    },
    backdrop:{
        zIndex: '1',
    },
    title:{
        fontSize: '5vh',
        padding: '2vh 0 0 5vw',
        margin: '0',
        //color: MainColors.main,
    },
    totaltitle: {
        padding: '2vh 0 0 5vw',
        //color: MainColors.accent,
        fontSize: '3vh',
        margin: '0',
    },
    total: {
        padding: '2vh 0 0 2vw',
        //color: MainColors.secondary,
        fontWeight: '450',
        fontSize: '5vh',
        margin: '0 0 0 2vh',
    },
    modal: {
        position: 'absolute',
        bottom: theme.spacing(10),
        right: theme.spacing(2),
        //zIndex: '16',
        fontSize: '9pt',
        fontWeight: 'bold',
        color: '#CB4335',
        opacity: '100%',
        background:'transparent',
        width:'137px',
        height: 'auto',
        textAlign:'left',
        //left: '50%',
        //transform: 'translate(-50%, -50%)',
    },
}));

const actions = [
    { icon: <RestaurantIcon/>, name: <TranslateStr token="optionRes"/> },
    { icon: <HomeIcon/> , name: <TranslateStr token="optionDel"/>},
];

export default function Carrito(props){
    const [arrayCarrito,setArrayCarrito] = useState([]);
    const [openBtn,setOpenBtn] = useState(false);
    const [indexAction,setIndexAction] = useState(0);
    const sty = useStyles();
    const [TotalToppings, setTotaltoppings] = useState({monto:0});

    const handleClickOption = (index) => {
        if(arrayCarrito.length > 0){
            const isId = getBoolStorage(isIdOrden_);
            const isDelivery = getBoolStorage(isDelivery_);
            //console.log('isDelivery');
            //console.log(isDelivery);
            // console.log('isId');
            //console.log(index);

            if(index === -1 || !isId){
                changePage(index);
            }else{
                if(isDelivery){
                    statusDeliveryOrder((okRes,isActive) => {
                        validateStatusRes(okRes,isActive,index);
                    })
                }else{
                    // checkStatusOrder(null,(okRes,isActive) => {
                    //     validateStatusRes(okRes,isActive,index);
                    // })
                    validateStatusRes(true,true,index);
                }
            }
        }else{
            makeNormalDialog(<TranslateStr token="emptyCart"/>, <TranslateStr token="emptyCartDes"/>,null);
            setOpenBtn(false);
        }
    }

    const validateStatusRes = (okRes,isActive,indexPage) => {
        if(okRes){
            if(!isActive){
                setOpenBtn(false);
                makeNormalDialog(<TranslateStr token="sorry"/>,<TranslateStr tokne="untilOpen"/>,null);
            }else{
                changePage(indexPage);
            }
        }else{
            makeNormalDialog(<TranslateStr token="somewrong"/>,<TranslateStr token="nocheckorder"/>,null);
        }
    }

    const changePage = (index) => {
        setIndexAction(index+1);
        if(index === -1){
            setOpenBtn(false);
            getCarrito();
        }
    }

    const handleOpen = () => {
        //Solo la opcion de restaurante por mientras
        handleClickOption(0);
        //setOpenBtn(true);
    };

    const handleClose = () => {
        setOpenBtn(false);
    }

    

    const getCarrito = () => {
        allItemCarrito( array => {
            setArrayCarrito(array.sort(((a, b) => {
                const orden1 = b.idTipoMenu - a.idTipoMenu;
                const orden2 = a.id - b.id;
                const orden3 = a.idTopping - b.idTopping;
                return orden1 === 0 ? (orden2 ===0 ? orden3 : orden2) : orden1;
            } )));
        });

        //console.log(arrayCarrito);
       
        getInfoCarrito((totalR,countR,toppingsR) => {
                        
            setTotaltoppings({monto:toppingsR});;
         
        });
    }

    const getTotal = () => {
        var total = 0;
        arrayCarrito.forEach(function(item){
            total += item.price * item.cantidad;
        });
        
        return decimalAdjust('round',parseFloat((total)),-2);
    }

    

    useEffect(() => {
        getCarrito();
        if(getBoolStorage(login_) ){
            if(getBoolStorage(ValidacionPorUsuario_)){
                refreshTokenUser(() => {});
            }
        }
    },[]);

    return(
        // <div className={sty.root}>
            //{
                (function() {
                    switch(indexAction){
                        case 0:
                            return <>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <p className={sty.title} style={{color: localStorage.getItem(color_main_)}}><TranslateStr token="titleCart"/></p>
                                    <p className={sty.totaltitle} style={{color: localStorage.getItem(color_accent_)}}><TranslateStr token="total"/></p>
                                    <p className={sty.total} style={{color: localStorage.getItem(color_secondary_)}}>{"$" + formatoMexico((getTotal() + TotalToppings.monto))}</p>
                                </Grid>
                                <ListCarrito className={sty.list} reload={getCarrito} arrayCarrito={arrayCarrito} setconfigCarrito={props.setconfigCarrito} setTotaltoppings={setTotaltoppings}/>
                                <Backdrop className={sty.backdrop} open={openBtn}/>
                                {<p className={sty.modal}>{props.showInformationOn ? <TranslateStr token="showInfoSendOrder"/> : ''}</p>}
                                <SpeedDial
                                    ariaLabel="speed dial tooltip example"
                                    className={sty.speedDial}
                                    icon={
                                        <Avatar className={sty.fabicon} style={{backgroundColor: localStorage.getItem(color_main_)}}>
                                            <SendIcon/>
                                        </Avatar>
                                    }
                                    color={localStorage.getItem(color_main_)}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    open={openBtn}
                                    FabProps={{ className: sty.fab}} 
                                >
                                    {
                                        actions.map((action,index) => (
                                            <SpeedDialAction
                                                className={sty.speedDialAction}
                                                style={{color: localStorage.getItem(color_secondary_)}}
                                                key={index}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                                tooltipOpen
                                                onClick={() => handleClickOption(index)}
                                            />
                                        ))
                                    }
                                </SpeedDial>
                            </>;
                        case 1:
                            return <ScanQR callwaiter={false} changeEmpresa={false} sendOrden={true} sendQueja={false} back={handleClickOption} setconfigCarrito={props.setconfigCarrito}
                                    setfullUserName={props.setfullUserName} setemailuser={props.setemailuser} 
                                    fulluserName={props.fulluserName} emailuser={props.emailuser} />
                        case 2:
                            return <OrdenDelivery back={handleClickOption} setconfigCarrito={props.setconfigCarrito}/>
                        default:
                            return;
                    }
                })()
            //}
        // </div>
    )
}