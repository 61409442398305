import React from 'react';
//Styles
//import {MainColors} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import {color_secondary_} from '../../Constants';

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  description: {
    textAlign: 'left',
    fontSize: '4vh',
    color: '#fff',
    margin: 'auto auto auto 5vw',
  },
  container : {
      width : '95vw',
      margin : '0.5vh 2vw',
      //backgroundColor: MainColors.secondary,
      borderRadius: '0.5vw',
  },
  avatar: {
      margin: 'auto auto',
  }
}));

export default function ItemMenu(props) {
  const sty = useStyles();

  return (
    
    <div className={sty.container} style={{backgroundColor: localStorage.getItem(color_secondary_)}} onClick={() => props.showSub(props.idMenu,props.title)}>
        <ListItem alignItems="flex-start">
            <ListItemAvatar className={sty.avatar}>
                <Avatar  alt="Menu Photo" src={props.url} />
            </ListItemAvatar>
              <ListItemText primary={<p className={sty.description} >{props.title}</p>} />
        </ListItem>
    </div>
    
  );
}