import React, {useState,useEffect} from 'react';
//Components
import FavoriteItem from './FavoriteItem';
import {Grid} from '@material-ui/core';
import TranslateStr from '../ConfigPage/HelperLanguage/TranslateStr';
//Styles
import {MainColors} from '../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';

import {getTopDishes} from '../../../service/ManagerApis';

const useStyles = makeStyles(() => ({
    titleEmpty: {
        color: MainColors.accent,
        fontSize: '2vh',
        textAlign: 'center',
    },
}));

export default function FavoriteList() {

    const [arrayTop,setArrayTop] = useState([]);
    const sty = useStyles();
	
	useEffect(() =>{
		getTopDishes(array => {
			setArrayTop(array);
		})
	},[])
	
	return(
		<Grid>
			{
                arrayTop.length !== 0 ?
                arrayTop.forEach(item => (
					<FavoriteItem dataItem={item}/>
                ))
                :
                <p className={sty.titleEmpty}><TranslateStr token="emptyFavorite"/></p>
			}
		</Grid>
	)
}