import React,{useState, useEffect} from 'react';
//Apis
import {
    checkStatusOrder,
    getAllOrderRestaurant,
    callCheck,
    getStatusOrderRest,
    signalRConnectionBuild, 
    notifyCallCheck
} from '../../../../service/ManagerApis';
//import {setCurrentDbOrder,allItemOrdersDB,allItemOrders,setCurrentDbOrders} from '../../../../service/ManagerOrders';
import {decimalAdjust} from '../../../../service/ManagerCarrito';
//Components
import {makeNormalDialog, makeAskDialog, makeDialogOrderEnd} from '../../../utilscomponents/DialogAsk';
import ListUsers from './ListUsers';

import FormOpenpay from '../../Openpay/FormOpenpay';
import { Backdrop,CircularProgress} from '@material-ui/core';
import { SpeedDial, SpeedDialAction}  from '@material-ui/lab';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReceiptIcon from '@material-ui/icons/Receipt';
//import PaymentIcon from '@material-ui/icons/Payment';
//Styles
//import {MainColors} from '../../Sources/SourcesConst';
import { makeStyles } from '@material-ui/core/styles';
//Others
import { isDelivery_, isIdOrden_, color_main_, color_secondary_, idEmpresa_, idMesa_} from '../../../Constants';
import {makeArraysUserOrders,getBoolStorage, getTotalSelected,getTotalOrder,getCountSelected} from './HelperArray';

import TranslateStr from '../../ConfigPage/HelperLanguage/TranslateStr';
//import BackDropCircular from '../../../utilscomponents/BackDrop';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
    },
    msgNoOrder: {
        fontSize: '3vh',
        //color: MainColors.accent,
        textAlign: 'center',
        margin: '2vh',
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(2),
    },
    fab: {
        zIndex: '2',
        'backgroundColor': localStorage.getItem(color_main_) + '!important',
        color: '#fff',
    },
    speedDialAction: {
        //color: MainColors.secondary,
        background: '#fff',
    },
    backdrop:{
        zIndex: '1',
    },
    subTitle:{
        fontSize: '2vh',
        //color: MainColors.accent,
        textAlign: 'center',
        margin: '2vh 0 0 0',
    },
    labelCount:{
        fontSize: '4vh',
        //color: MainColors.secondary,
        textAlign: 'center',
        margin: '0 0 1.5vh 0',
    },
    msgStatus:{
        color: '#273746',
        fontSize: '3vh',
        fontWeight: 350,
        margin: '0',
        textAlign: 'center',
        padding: '0.5vh',
    },
}));

const actions = [
    // { icon: <PaymentIcon/>, name: <TranslateStr token="payOrder"/> },
    { icon: <ReceiptIcon/> , name: <TranslateStr token="callCheck"/>},
];

export default function StatusOrderRestaurant(props) {
    //const {setSettingsOrder,settingsOrder, reload} = props;
    const {reload} = props;
    //const UserName =localStorage.getItem(nameUser_);
    const classes = useStyles();
    const [seconds,setSeconds] = useState(30);
    const [showLoad,setShowLoad] = useState(false);
    const isDelivery = getBoolStorage(isDelivery_);
    const isOrder = getBoolStorage(isIdOrden_);
    const [page,setPage] = useState(0);
    const [openBtn,setOpenBtn] = useState(false);
    const [settingsOrder,setSettingsOrder] = useState({
        idOrder: 0,
        status:0,
        dataOrder: null,
        arrayOrders:[],
        totalOrder:0.0,
        totalUser:-1
    });

    const [isStart, setStart] = useState(true);
    //const [Id, setId] = useState(props.id);
    
    //const [ArrayOrdenesDB,setArrayOrdenesDB]=useState([]);
    
    
    useEffect(() => {
        //props.loadOrdenes();
        let interval = null;
        interval = setInterval(() => {
        if(seconds > 0){
            if(seconds === 30){
                //setShowLoad(true);
                // if(props.settingsOrder.totalUser==-2){
                //     setShowLoad(true);
                // }
                checkStatus();
            }
            setSeconds(seconds => seconds - 1);
        }else{
            setSeconds(30);
            //setShowLoad(false);
        }
        }, 1000);
        return () => clearInterval(interval);
    },[seconds]);

    // useEffect(() => {
    //     signalRConnectionBuild(async _hubConnection => {
    //         await _hubConnection.start(); 
    //         console.assert(_hubConnection.state === "Connected");
    //         console.log("SignalR Connected.");

    //         // // Escuchar eventos del websocket
    //         // _hubConnection.on("notifywpatowaiter", function (result) {

    //         //     const notif = result[0];
    //         //     if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
    //         //     if (parseInt(localStorage.getItem(idMesa_)) !== notif.idMesa) return;

    //         //     if (notif.comando === 'solicitud-cuenta'){
    //         //         console.log('notif', notif);
    //         //     }

    //         // });

    //         _hubConnection.on("notifytouser", function (result) {

    //             const notif = result[0];
    //             console.log('notif', notif);
    //             if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
    //             if(notif.origen === 8) return;

    //             //setId(notif.idOrden);
    //             //setStart(false);
    //             //if (parseInt(localStorage.getItem(idMesa_)) !== notif.idMesa) return;

    //             if (notif.comando === 'update-estatus' || notif.comando === 'nueva-orden' || notif.comando === 'delete-orden' 
    //             || notif.comando === 'delete-producto' || notif.comando === 'todos-productos-terminados'){
    //                 //console.log('notif', notif);
    //                 if(notif.origen!==8){
    //                     checkStatus(notif.idOrden);
    //                     //reload();
    //                 }
                        
    //             }

    //         });

    //         _hubConnection.on("notifyproductotouser", function (result) {

    //             const notif = result[0];
    //             console.log('notif', notif);
    //             if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
    //             if(notif.origen === 8) return;
    //             //setId(notif.idOrden);
    //             //setStart(false);
    //             if(notif.origen!==8)
    //                 checkStatus(notif.idOrden);

    //         });

    //         _hubConnection.on("notifyordentouser", function (result) {

    //             const notif = result[0];
    //             console.log('notif', notif);
    //             if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
    //             if(notif.origen === 8) return;
    //             //setId(notif.idOrden);
    //             if(notif.origen!==8)
    //                 checkStatus(notif.idOrden);

    //         });

    //         _hubConnection.on("notifyentregatouser", function (result) {

    //             const notif = result[0];
    //             console.log('notif', notif);

    //             if (parseInt(localStorage.getItem(idEmpresa_)) !== notif.idEmpresa) return;
    //             if(notif.origen === 8) return;
    //             //setId(notif.idOrden);
    //             //if (parseInt(localStorage.getItem(idMesa_)) !== notif.idMesa) return;
    //             //setStart(false);
    //             if (notif.comando === 'platillo-para-entrega' || notif.comando === 'platillo-entregado'){
                    
                    
    //                 if(notif.origen!==8)
    //                     checkStatus(notif.idOrden);
    //             }

    //         });
           
    //     });

    //     //if(isStart){
    //         //setId(props.id);
    //         //checkStatus();
    //         //setStart(false);
    //    // }
        

    // }, []);

    const handleOpenOptions = () => {
        setOpenBtn(true);
    };

    const handleCloseOptions = () => {
        setOpenBtn(false);
    }

    const handleClickOption = (index) => {
        if(index === 0){
            makeAskDialog(<TranslateStr token="uSureCallCheck"/>,"",callCheckApi);
        }
        // if(index === 0){
        //     setPage(1);
        // }else if(index === 1){
        //     makeAskDialog(<TranslateStr token="uSureCallCheck"/>,"",callCheckApi);
        // }
    }

    const backFromPayment = (successPayment) => {
        if(successPayment){
            checkStatus();
        }
        setPage(0);
    }

    //Apis
    const callCheckApi = () => {
        callCheck( status => {
            if(status){
                //makeNormalDialog(<TranslateStr token="okCallCheck"/>,<TranslateStr token="comingCheck"/>,null);
                notifyCallCheck(status => {
                    if(status){
                        makeNormalDialog(<TranslateStr token="okCallCheck"/>,<TranslateStr token="comingCheck"/>,null);
                    }else{
                        makeNormalDialog(<TranslateStr token="faildCallCheck"/>,<TranslateStr token="tryagain"/>,null);
                    }
                });
            }else{
                makeNormalDialog(<TranslateStr token="faildCallCheck"/>,<TranslateStr token="tryagain"/>,null);
            }
        });
    }

    // const getOrdenesDB = () => {
    //     allItemOrdersDB( arrayDB => {
    //         setArrayOrdenesDB(arrayDB);
    //     });
    //     console.log('OrdenesDB ' + ArrayOrdenesDB.length)
    //     if( ArrayOrdenesDB.length != props.contOrden){
    //         setCurrentDbOrders();
    //         props.reload();
    //         //allItemOrders( array => {
    //         //props.setArrayOrdenes(ArrayOrdenesDB);
    //         //});
            
    //     }
    // }

    const signalr_checkStatusEnd = (id) => {
        console.log('idOrden');
        console.log(id);
        checkStatusOrder(id,(okRes,isActive,stRes) => {
            if(okRes && !isActive){
                setSettingsOrder({
                        //idOrder:  0,
                        status: 0 ,
                        dataOrder:  null  ,
                        arrayOrders :[],
                        totalOrder : 0,
                        totalUser : -1
                    });
                if(stRes===2){
                    //makeNormalDialog(<TranslateStr token="canceledOrder"/>,<TranslateStr token="lastOrderCenceled"/>,null);
                    makeDialogOrderEnd(<TranslateStr token="canceledOrder"/>,<TranslateStr token="lastOrderCenceled"/>,<TranslateStr token="numOrder"/>,id,null);
                }
                else{
                    //makeNormalDialog(<TranslateStr token="finishedOrder"/>,<TranslateStr token="lastOrderFinish"/>,null);
                    makeDialogOrderEnd(<TranslateStr token="finishedOrder"/>,<TranslateStr token="lastOrderFinish"/>,<TranslateStr token="numOrder"/>,id,null);
                } 
                //setShowLoad(true);
                reload(); 
            }
        });
    }

    const checkStatusAll = () => {
        var id = props.id;
        if(isOrder && !isDelivery ){
            console.log('idOrden');
            console.log(id);
            checkStatusOrder(id,(okRes,isActive,stRes) => {
                if(okRes && isActive){
                    //console.log('Ejecuta getAllOrderRestaurant');
                    getAllOrderRestaurant(id,array => {
                        if(array.length !== 0){
                            //console.log('Ejecuta getStatusOrderRest');
                            getStatusOrderRest(id,(detailOrder) => {
                                //console.log('detailOrder');
                                //console.log(detailOrder);
                                //console.log('array');
                                //console.log(array[0]['idOrden']);
                                const arrayUsers = makeArraysUserOrders(id,array);
                                console.log('arrayUSERS');
                                console.log(arrayUsers);
                                //console.log('detailOrder');
                                //console.log(detailOrder);
                                //console.log(settingsOrder.totalUser);
                            
                                if(detailOrder !== null){
                                    //selectAll(arrayUsers);
                                    //idchecking=true;
                                    var userTotal=-1;
                                    var userArray=[];
                                    console.log('props.settingsOrder.totalOrder');
                                    console.log(props.settingsOrder.totalUser);
                                    if(props.settingsOrder.totalUser==-2){
                                        //setShowLoad(true);
                                        SetIni(arrayUsers,1,id);
                                        //setSettingsOrder({totalUser: -1})
                                        console.log('settingsOrder.totalUser');
                                        console.log(settingsOrder.totalUser);
                                        userTotal=decimalAdjust('round',parseFloat(arrayUsers[0].total),-2);
                                        userArray = arrayUsers;
                                        //selectAll(arrayUsers);
                                    }
                                    else{
                                        if(settingsOrder.totalUser==-1 ){
                                            userTotal=decimalAdjust('round',parseFloat(arrayUsers[0].total),-2);
                                            userArray = arrayUsers;
                                        }
                                        else {
                                            userTotal=settingsOrder.totalUser;
                                            userArray = settingsOrder.arrayOrders;
                                        }
                                    }
                                    
                                    setSettingsOrder({
                                        idOrder:  id,
                                        status: detailOrder.idEstatusOrden ,
                                        dataOrder:  detailOrder  ,
                                        arrayOrders :userArray, // settingsOrder.totalUser==-1 ? arrayUsers: settingsOrder.arrayOrders,
                                        totalOrder : decimalAdjust('round',parseFloat(getTotalOrder(arrayUsers)),-2),
                                        totalUser : decimalAdjust('round',parseFloat(userTotal),-2)  // arrayUsers[0].isChecked ? arrayUsers[0].total : settingsOrder.totalUser,// arrayUsers[0].total //settingsOrder.totalUser==-1 ? arrayUsers[0].total : settingsOrder.totalUser
                                    });
                                    SetIni(arrayUsers,2,id);
                                    //getSelectedAll(arrayUsers);
                                    //props.reload();
                                    //setShowLoad(false);
                                }
                            })
                        }
                    });
                }
            });
        }
    }

    const checkStatus = () => {
        var id = props.id;
        //var idchecking=false;
        if(isOrder && !isDelivery ){
            console.log('idOrden');
            console.log(id);
            checkStatusOrder(id,(okRes,isActive,stRes) => {
                if(okRes && isActive){
                    //console.log('Ejecuta getAllOrderRestaurant');
                    getAllOrderRestaurant(id,array => {
                        if(array.length !== 0){
                            //console.log('Ejecuta getStatusOrderRest');
                            getStatusOrderRest(id,(detailOrder) => {
                                //console.log('detailOrder');
                                //console.log(detailOrder);
                                //console.log('array');
                                //console.log(array[0]['idOrden']);
                                const arrayUsers = makeArraysUserOrders(id,array);
                                console.log('arrayUSERS');
                                console.log(arrayUsers);
                                //console.log('detailOrder');
                                //console.log(detailOrder);
                                //console.log(settingsOrder.totalUser);
                            
                                if(detailOrder !== null){
                                    //selectAll(arrayUsers);
                                    //idchecking=true;
                                    var userTotal=-1;
                                    var userArray=[];
                                    console.log('props.settingsOrder.totalOrder');
                                    console.log(props.settingsOrder.totalUser);
                                    if(props.settingsOrder.totalUser==-2){
                                        //setShowLoad(true);
                                        SetIni(arrayUsers,1,id);
                                        //setSettingsOrder({totalUser: -1})
                                        console.log('settingsOrder.totalUser');
                                        console.log(settingsOrder.totalUser);
                                        userTotal=decimalAdjust('round',parseFloat(arrayUsers[0].total),-2);
                                        userArray = arrayUsers;
                                        //selectAll(arrayUsers);
                                    }
                                    else{
                                        if(settingsOrder.totalUser==-1 ){
                                            userTotal=decimalAdjust('round',parseFloat(arrayUsers[0].total),-2);
                                            userArray = arrayUsers;
                                        }
                                        else {
                                            userTotal=settingsOrder.totalUser;
                                            userArray = settingsOrder.arrayOrders;
                                        }
                                    }
                                    
                                    setSettingsOrder({
                                        idOrder:  id,
                                        status: detailOrder.idEstatusOrden ,
                                        dataOrder:  detailOrder  ,
                                        arrayOrders :userArray, // settingsOrder.totalUser==-1 ? arrayUsers: settingsOrder.arrayOrders,
                                        totalOrder : decimalAdjust('round',parseFloat(getTotalOrder(arrayUsers)),-2),
                                        totalUser : decimalAdjust('round',parseFloat(userTotal),-2)  // arrayUsers[0].isChecked ? arrayUsers[0].total : settingsOrder.totalUser,// arrayUsers[0].total //settingsOrder.totalUser==-1 ? arrayUsers[0].total : settingsOrder.totalUser
                                    });
                                    SetIni(arrayUsers,2,id);
                                    //getSelectedAll(arrayUsers);
                                    //props.reload();
                                    //setShowLoad(false);
                                }
                            })
                        }
                    });
                }else if(okRes && !isActive){
                    setSettingsOrder({
                        //idOrder:  0,
                        status: 0 ,
                        dataOrder:  null  ,
                        arrayOrders :[],
                        totalOrder : 0,
                        totalUser : -1
                    });
                    
                    if(stRes===2){
                        //makeNormalDialog(<TranslateStr token="canceledOrder"/>,<TranslateStr token="lastOrderCenceled"/>,null);
                        makeDialogOrderEnd(<TranslateStr token="canceledOrder"/>,<TranslateStr token="lastOrderCenceled"/>,<TranslateStr token="numOrder"/>,id,null);
                    }
                    else{
                        //makeNormalDialog(<TranslateStr token="finishedOrder"/>,<TranslateStr token="lastOrderFinish"/>,null);
                        makeDialogOrderEnd(<TranslateStr token="finishedOrder"/>,<TranslateStr token="lastOrderFinish"/>,<TranslateStr token="numOrder"/>,id,null);
                    } 
                    //setShowLoad(true);
                    reload();    
                }
            });
            
        }
    }

    //Select List Orders
    const selectParent = (parent) => {
        var mainArray = [];
        var index; //mainArray.indexOf(parent);
        var total;
        if(props.settingsOrder.totalUser==-2){
            mainArray = parent;
            index = 0; //mainArray.indexOf(parent);
            total=-1;
            mainArray[index].children.map(item => {
                item.isChecked = mainArray[index].isChecked
            });
            //total=getTotalSelected(mainArray);
        }
        else{
            mainArray = settingsOrder.arrayOrders;
            index = mainArray.indexOf(parent);
            mainArray[index].isChecked = !mainArray[index].isChecked;
            mainArray[index].children.map(item => {
                item.isChecked = mainArray[index].isChecked
            });
            total= (mainArray[index].isChecked ? settingsOrder.totalOrder : 0 );
        }
       
       
       
        console.log('selectParent mainArray');
        console.log(mainArray);
        
        setSettingsOrder({
            idOrder: settingsOrder.idOrder,
            status: settingsOrder.status,
            dataOrder: settingsOrder.dataOrder,
            arrayOrders:mainArray,
            totalOrder:settingsOrder.totalOrder,
            totalUser: decimalAdjust('round',parseFloat(total),-2)  // mainArray[index].isChecked ? settingsOrder.totalOrder : 0 //getTotalSelected(mainArray)
        });
        
    }

    const SetIni = (Array,paso,IDCheck) => {
        if(props.settingsOrder.totalUser==-2){
            if(paso===1 && IDCheck==Array[0].idOrden){
            
                setSettingsOrder({
                    idOrder:  Array[0].idOrden,
                    status:0,
                    dataOrder: null,
                    arrayOrders:[],
                    totalOrder:0.0,
                    totalUser:-1
                });

                selectParent(Array);

                // props.setSettingsOrder({
                //     idOrder: Array[0].idOrden,
                //     status: settingsOrder.status,
                //     dataOrder: settingsOrder.dataOrder,
                //     arrayOrders : Array,
                //     totalOrder : settingsOrder.totalOrder,
                //     totalUser : -1
                //     });
            }
       
            if(paso===2){
                props.setSettingsOrder({
                idOrder: settingsOrder.idOrder,
                status: settingsOrder.status,
                dataOrder: settingsOrder.dataOrder,
                arrayOrders : Array,
                totalOrder : settingsOrder.totalOrder,
                totalUser : 0
                });
            }
        }
    }
    const getSelectedAll = () => {
        var mainArray = settingsOrder.arrayOrders;
        const index = 0;
        var array = [];
        //var val = false;
        mainArray[index].children.map(item => {
            var val = false;
            if(item.isChecked === true){
                val = true;
            }
            array.push(val);
        });
        //  return array;
        // setSettingsOrder({
        //     idOrder: settingsOrder.idOrder,
        //     status: settingsOrder.status,
        //     dataOrder: settingsOrder.dataOrder,
        //     arrayOrders : arrayOrders,
        //     totalOrder : settingsOrder.totalOrder,
        //     totalUser : settingsOrder.totalOrder
        // });
    }

    // const selectAll = (mainArray) => {
    //     const index = 0;
    //     mainArray[index].isChecked = true;
    //     mainArray[index].children.map(item => {
    //         item.isChecked = true;
    //     });
    //     //selectParent(mainArray);
    // }

    const selectChild = (parent,child) => {
        var mainArray = settingsOrder.arrayOrders;
        const index = mainArray.indexOf(parent);
        //mainArray[index].isChecked = !mainArray[index].isChecked;
        console.log('child ' + child);
        mainArray[index].children.map(item => {
            console.log('item');
            console.log(item);
            if(item.index==child){
                item.isChecked = !item.isChecked
            }
        });
        
        // var userItem = mainArray[index];
        // console.log('child ' + child);
        // mainArray[index].children[child].isChecked = ! mainArray[index].children[child].isChecked;
        const selected = getSelectedChildren( mainArray[index]);
        console.log('selected ' + selected);
        if(selected ===  mainArray[index].children.length){
            //userItem.isChecked = true;
            mainArray[index].isChecked = true;
        }
        //else if(selected ===  mainArray[index].children.length-1){
        else{
            mainArray[index].isChecked = false;
        }

        console.log('selectChild mainArray');
        console.log(mainArray);
        //console.log('userItem');
        //console.log(userItem);
        console.log('settingsOrder.totalOrder:' +  settingsOrder.totalOrder);
        
        setSettingsOrder({
            idOrder: settingsOrder.idOrder,
            status: settingsOrder.status,
            dataOrder: settingsOrder.dataOrder,
            arrayOrders:mainArray,
            totalOrder:settingsOrder.totalOrder,
            totalUser:mainArray[index].isChecked ? settingsOrder.totalOrder : decimalAdjust('round',parseFloat(getTotalSelected(mainArray)),-2) 
        });
    }

    const getSelectedChildren = (userItem) => {
        var count = 0;
        userItem.children.map(item => {
            if(item.isChecked === true){
                count++;
            }
            
        })
        return count;
    }

    return (
        <div className={classes.root}>
            {
                isOrder && !isDelivery ?
                    (function() {
                        switch (page) {
                            case 0:
                                return showLoad === true ?
                                <CircularProgress show={showLoad}/> 
                            :<div>
                                        {/* {
                                            settingsOrder.dataOrder !== null &&
                                            <Grid item container direction="row" justify="center" alignItems="center">
                                                <Grid item>
                                                    <p className={classes.msgStatus}>{setMsgstatus(settingsOrder.dataOrder.idEstatusOrden)}</p>
                                                </Grid>
                                            </Grid>
                                        } */}
                                        {/* <Grid container direction="row" alignItems="center" justify="center">
                                            <Grid xs={6} item container direction="column" alignItems="center" justify="center">
                                                <p className={classes.subTitle}><TranslateStr token="totalOrder"/></p>
                                                <p className={classes.labelCount}>{settingsOrder.totalOrder}</p>
                                            </Grid>
                                            <Grid xs={6} item container direction="column" alignItems="center" justify="center">
                                                <p className={classes.subTitle}><TranslateStr token="selectedTotal"/></p>
                                                <p className={classes.labelCount}>{settingsOrder.totalUser}</p>
                                            </Grid>
                                        </Grid> */}
                                        {/* <Grid item container direction="row" justify="center" alignItems="center">
                                            <Grid item>
                                            <p className={classes.title}><TranslateStr token="titleStatusOrder"/></p>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="row" justify="center" alignItems="center">
                                            <Grid item>
                                                <p className={classes.msgStatus}>{UserName}</p>
                                            </Grid>
                                        </Grid> */}
                                        <ListUsers
                                            selectChild={selectChild}
                                            selectParent={selectParent}
                                            arrayUsers={settingsOrder.arrayOrders}
                                            idStatus={settingsOrder.status}
                                            totalUser={decimalAdjust('round',parseFloat(settingsOrder.totalUser),-2)} 
                                            totalOrder={decimalAdjust('round',parseFloat(settingsOrder.totalOrder),-2)} 
                                            reload={props.reload}
                                            // {getTotalSelected(settingsOrder.arrayOrders)}
                                            getSelectedAll =  {getSelectedAll}
                                        />
                                        <Backdrop className={classes.backdrop} open={openBtn}/>
                                        <SpeedDial
                                            ariaLabel="speed"
                                            className={classes.speedDial}
                                            icon={<AssignmentTurnedInIcon/>}
                                            onClose={handleCloseOptions}
                                            onOpen={handleOpenOptions}
                                            open={openBtn}
                                            FabProps={{className : classes.fab}}>
                                                {
                                                    actions.map((action,index) => (
                                                        <SpeedDialAction
                                                            className={classes.speedDialAction}
                                                            style={{color: localStorage.getItem(color_secondary_)}}
                                                            key={index}
                                                            icon={action.icon}
                                                            tooltipTitle={action.name}
                                                            tooltipOpen
                                                            onClick={() => handleClickOption(index)}
                                                        />
                                                    ))
                                                }
                                        </SpeedDial>
                                    </div>
                            case 1 :
                                return <FormOpenpay isDelivery={false} countItems={getCountSelected(settingsOrder.arrayOrders)} total={settingsOrder.totalUser} arrayToPay={settingsOrder.arrayOrders} changePage={backFromPayment}/>
                        }
                    })()
                :
                    // <p className={classes.msgNoOrder}><TranslateStr token="noOrder"/></p>
                    <p></p>
            }
        </div>
    );
}
